import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  sessionObj: any = {}

  constructor(public router: Router) { }
  ngOnInit(): void {
    this.sessionObj = JSON.parse(localStorage.getItem('loginresponse'))
  }

  openoutreachqueue() {
    this.router.navigate(['encounterRoster'])
  }
  openreport() {
  this.router.navigate(['report'])

  }
  openpatientlookup() {
    this.router.navigate(['memberSearch'])

  }
  openpatientAssignment() {
    this.router.navigate(['memberAssignment'])

  }
  addpatient() {
    this.router.navigate(['addMember'])

  }
  addEncounter() {
    this.router.navigate(['addEncounter'])

  }

}
