<app-header></app-header>

<div class="container-fluid mt-4">

    <div class="row mb-3">
        <div class="container text-left align-items-center">
            <div class="row">
                <div class="col-1 text-left">
                    <button mat-mini-fab color="primary" class="mat-elevation-z0 backIcon"
                        [routerLink]="['/landingPage']">
                        <mat-icon class="material-icons-outlined">home</mat-icon>
                    </button>
                </div>
            </div>

        </div>
    </div>


    <div class="containerbg">
        <form [formGroup]="addEncounterForm">

            <div class="col-md-12">
                <div class="row encountersec">
                    <h5 class="mb-2">Encounter Details</h5>

                    <div class="col-lg-2 col-md-3 col-sm-4" *ngIf="isEditEncounter==true">
                        <mat-form-field appearance="outline">
                            <mat-label>Encounter ID</mat-label>
                            <input matInput type="text" placeholder="encounterId" name="encounterId"
                                formControlName="encounterId">
                        </mat-form-field>
                    </div>

                    <div class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <input [(ngModel)]="text" matInput class="capitalizetext" formControlName="mbrRefNo"
                                placeholder="MBR Ref No" [matAutocomplete]="auto" (input)="getInfo()">
                            <mat-autocomplete name="firstName" #auto="matAutocomplete">
                                <mat-option *ngFor="let facility of mbrRefNoList" [value]="facility"
                                    (click)="getInfo()">
                                    {{facility }}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>First Name<span style="color: red;"> *</span></mat-label>
                            <input [(ngModel)]="text1" matInput class="capitalizetext" formControlName="firstName"
                                placeholder="First Name" [matAutocomplete]="auto1" (input)="getInfo()">
                            <mat-autocomplete name="firstName" #auto1="matAutocomplete">
                                <mat-option *ngFor="let facility of firstNameList" [value]="facility"
                                    (click)="getInfo()">
                                    {{facility }}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Last Name<span style="color: red;"> *</span></mat-label>

                            <input [(ngModel)]="text2" matInput class="capitalizetext" formControlName="lastName"
                                placeholder="Last Name" [matAutocomplete]="auto2" (input)="getInfo()">
                            <mat-autocomplete name="lastName" #auto2="matAutocomplete">
                                <mat-option *ngFor="let last of lastNameList" [value]="last" (click)="getInfo()">
                                    {{last}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Date of Birth <span style="color: red;"> *</span></mat-label>
                            <input matInput type="date" placeholder="DOB" name="dob" formControlName="dob" [matAutocomplete]="auto3">
                            <mat-autocomplete name="lastName" #auto3="matAutocomplete">
                                <mat-option *ngFor="let last of dobList" [value]="last ">{{last}}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-md-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Choose a date</mat-label>
                            <input matInput [matDatepicker]="picker">
                            <mat-hint>MM-DD-YYYY</mat-hint>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                    </div> -->

                    <div class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Gender<span style="color: red;"> *</span></mat-label>
                            <mat-select name="gender" formControlName="gender">
                                <mat-option value="M">Male</mat-option>
                                <mat-option value="F">Female</mat-option>
                                <mat-option value="NA">NA</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div class="col-lg-1 col-md-3 col-sm-4 text-start mt-2">
                        <button color="warn" type="submit" mat-raised-button (click)="getMember()"
                            *ngIf="!isEditEncounter">
                            <mat-icon matSuffix class="searchIcon" matSuffix type="submit">search</mat-icon></button>

                    </div>



                    <div *ngIf="addmember" class="col-lg-1 col-md-3 col-sm-4 text-start mt-2">
                        <div class="container text-left">
                            <div class="row">
                                <div class="col-1 text-left">
                                    <button mat-flat-button color="primary" (click)="navigateToAddMember()">Add
                                        Member</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr />



                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline" required>
                            <mat-label>Program Type<span style="color: red;"> *</span></mat-label>
                            <mat-select name="lob" formControlName="lob" (selectionChange)="onchangelob($event.value)"
                                [(ngModel)]="lobname">
                                <mat-option *ngFor="let value of  lobs" [value]="value" [selected]="lobname==value">
                                    {{value}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Visit Type </mat-label>
                            <mat-select name="visitType" formControlName="visitType">
                                <mat-option *ngFor="let value of  visitType" [value]="value |titlecase">{{value |
                                    titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Encounter Type <span style="color: red;"> *</span> </mat-label>
                            <mat-select name="encounterType" formControlName="encounterType">
                                <mat-option *ngFor="let value of  encounterType" [value]="value |titlecase">{{value |
                                    titlecase}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="memberView&&isSNF" class="col-lg-2 col-md-3 col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Facility Name</mat-label>
                            <mat-select (selectionChange)="onchangeFacility($event.value)" name="facilityName"
                                formControlName="facilityName">
                                <mat-option *ngFor="let facility of  facilities" [value]="facility | titlecase">
                                    {{facility | titlecase}}</mat-option>

                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Source Facility </mat-label>
                            <input matInput type="text" placeholder="Source Facility" name="sourceProvider"
                                formControlName="sourceProvider">
                        </mat-form-field>
                    </div>
                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Admit Date</mat-label>
                            <input matInput type="date" placeholder="admitDate" name="admitDate"
                                formControlName="admitDate">
                        </mat-form-field>
                    </div>
                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Discharge Date</mat-label>
                            <input matInput type="date" placeholder="dischargeDate" name="dischargeDate"
                                formControlName="dischargeDate">
                        </mat-form-field>
                    </div>

                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Diagnosis</mat-label>
                            <mat-select formControlName="diagnosedFor" (selectionChange)="onChange($event)" multiple>
                                <mat-option *ngFor="let topping of toppingList" [value]="topping"
                                    matTooltip="{{topping}}" matTooltipPosition="left" matTooltipClass="changeFont">
                                    {{topping}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>


                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Payor</mat-label>
                            <input matInput type="text" placeholder="payor" name="payor" formControlName="payor">
                        </mat-form-field>
                    </div>

                    <div *ngIf="isCSNP&&memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Requestor</mat-label>
                            <input type="text" matInput formControlName="requestor" [matAutocomplete]="auto">
                            <mat-autocomplete name="requestor" #auto="matAutocomplete">
                                <mat-option *ngFor="let request of  requestors" [value]="request ">
                                    {{request }}</mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div *ngIf="isCSNP&&memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Requested On</mat-label>
                            <input matInput type="date" placeholder="requestedOn" name="requestedOn"
                                formControlName="requestedOn">
                        </mat-form-field>
                    </div>



                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2">
                        <mat-form-field appearance="outline">
                            <mat-label>Destination Facility</mat-label>
                            <input matInput type="text" placeholder="Destination Facility" name="destinationProvider"
                                formControlName="destinationProvider">
                        </mat-form-field>
                    </div>







                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Visited On</mat-label>
                            <input matInput type="date" placeholder="visitDate" name="visitDate"
                                formControlName="visitDate">
                        </mat-form-field>
                    </div>

                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Notified On</mat-label>
                            <input matInput type="date" placeholder="notifiedOn" name="notifiedOn"
                                formControlName="notifiedOn">
                        </mat-form-field>
                    </div>

                    <div *ngIf="memberView" class="col-lg-2 col-md-3 col-sm-4 mb-2 dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>Comments</mat-label>
                            <textarea matInput type="text" placeholder="comments" name="comments"
                                formControlName="comments"> </textarea>
                        </mat-form-field>
                    </div>

                    <div *ngIf="memberView" class="row align-items-center">
                        <div class="col-md-12">
                            <button color="default" type="submit" mat-stroked-button [routerLink]="['/landingPage']"
                                matTooltip="Close" style="margin-right: 10px;">Close</button>
                            <button *ngIf="isEditEncounter==false" color="primary" type="submit" mat-raised-button
                                (click)="addnewencounter()" matTooltip="Save" style="margin-right: 10px;">Save</button>
                            <button *ngIf="isEditEncounter==true" color="primary" type="submit" mat-raised-button
                                (click)="editEncounter()" matTooltip="Save">Save</button>
                        </div>
                    </div>
                </div>
            </div>

        </form>

        <div class="table-responsive">
            <table class="table member-responsivetable table-hover">
                <thead>
                    <tr>

                        <th>Actions</th>
                        <th>Program Type</th>
                        <th>Visit Type</th>
                        <th>Encounter Type</th>
                        <th>Source</th>
                        <th>Destination</th>
                        <th>Requested</th>
                        <th>Notified</th>
                        <th>Status</th>
                        <th>Reviewed By</th>
                        <th>Reviewed Dt</th>
                        <!-- <th>Last Follow up Dt</th>                   -->
                        <th>Assigned To</th>

                    </tr>
                </thead>
                
                <tbody>
                    <tr *ngFor="let ipatientList of memberdetails;">
                        <td>
                            <mat-icon (click)="copyEncounter(ipatientList)" matTooltip="Copy Encounter" class="icon"
                                style="color: #646161; font-size: 18px; cursor: pointer !important; margin-right: 10px;"
                                *ngIf="!isEditEncounter">file_copy</mat-icon>
                            <mat-icon (click)="rowclick(ipatientList,$event)" matTooltip="Open Encounter" class="icon"
                                style="color: #646161; font-size: 18px; cursor: pointer !important;">preview</mat-icon>
                        </td>
                        <td>{{ipatientList.lob}}</td>

                        <td>{{ipatientList.visitType}}</td>
                        <td>{{ipatientList.encounterType}}</td>

                        <td>{{ipatientList.sourceProvider}}</td>
                        <td>{{ipatientList.destinationProvider}}</td>
                        <td>{{ipatientList.requestedOn | date: 'MM-dd-yyyy'}}</td>
                        <td>{{ipatientList.notifiedOn | date: 'MM-dd-yyyy'}} </td>
                        <td>{{ipatientList.status}}</td>
                        <td>{{ipatientList.lastupdate_by}}</td>
                        <td>{{ipatientList.lastupdate_dt | date: 'MM-dd-yyyy'}} </td>
                        <td>{{ipatientList.assignedTo}}</td>
                    </tr>

                </tbody>
            </table>
            <div class="no-Data" *ngIf="haveMemberData==false" style="text-align: center;">No Rows To Show</div>


        </div>

    </div>
</div>