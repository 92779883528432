<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h1>Assign to Team Member</h1>
    <button mat-icon-button color="primary" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <h4 class="assign-detail-count"><b> Active Encounter(s) : </b> <strong>{{totalCount}}</strong><br><br/><b>Choose Team Member to Assign</b> </h4>    
    <mat-radio-group class="pharm-radio-group" [(ngModel)]="pharmTechSelect">
        <mat-radio-button class="pharm-radio-button" color="primary" *ngFor="let pharmTech of pharmTechMem" [value]="pharmTech.displayName">
            <div class="pharm-member-wrap-name"> {{pharmTech.displayName}} </div>
            <div class="pharm-member-wrap">
                <ng-container>
                Currently Assigned Encounters :<strong>{{pharmTech.encounterCount}}</strong>                    
                </ng-container>

            </div>
        </mat-radio-button>
    </mat-radio-group>
</div>

<div mat-dialog-actions fxLayoutAlign="center center" style="margin: 5px 0 0 0;">
    <button mat-flat-button type="button" color="warn" (click)="cancelAssign()" mat-dialog-close>Cancel</button>
    <button mat-flat-button type="submit" color="primary" (click)="confirmPharmTech()" mat-dialog-close>Assign</button>
</div>