import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { AddNewMedComponent } from '../add-new-med/add-new-med.component';
import { DataService } from '../data.service';
import { EditMedRegimeComponent } from '../edit-med-regime/edit-med-regime.component';
import { ToastrService } from 'ngx-toastr';
import { ProviderDataComponent } from '../provider-data/provider-data.component';
import { InterventionComponent } from '../intervention/intervention.component';
import { getDate, getDateTime } from '../utilityMethods/helperMethods';
import { InterventionManagmentComponent } from '../intervention-managment/intervention-managment.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { CONSTANT } from 'src/assets/appConstant';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive'
import { Location } from '@angular/common';
import { TimeoutDialogMemberDetailsComponent } from '../timeout-dialog-member-details/timeout-dialog-member-details.component';
import { IdleTimeoutService } from '../idle-timeout.service';
import { C } from '@angular/cdk/keycodes';


@Component({
  selector: 'app-memberdetails',
  templateUrl: './memberdetails.component.html',
  styleUrls: ['./memberdetails.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ]),
    trigger('widthGrow', [
      state('closed', style({
        width: 100,
      })),
      state('open', style({
        width: 500
      })),
      transition('* => *', animate(100))
    ])
  ]
})
export class MemberdetailsComponent implements OnInit, AfterViewInit {

  [x: string]: any;
  isAllexported: boolean = false
  dataSource1: any
  allergies: any
  dataSourceHistory1: any = []
  isAll1 = false
  showColumn = true; // Set this condition based on your logic

  encounterData: any
  copyService: any=[]
  globalSearchText: any = "";
  globalSearchText1: any = "";
  viewAll: boolean
  servicedatacopy: any = []
  initialLoaddatacopy: any
  isSpinner: boolean
  anythingChangedRec: boolean = false;
  anythingChangedRev: boolean = false;
  noKnownAllergies = false
  isOthersAllergyExist: any = []
  selectedMedications: any = [];
  allmedicationsifselected: any = [];
  allmedicationsifnotselected: any = [];
  isTargetedEncounter: boolean = false

  public patientDetail: any
  public caseDetail: any;
  public memberNotes: any = [];
  page: any = 1;
  Notespage: number = 1;
  Notespageintervention: number = 1;
  InteractionPage: number = 1
  medInteractionPage: number = 1
  foodInteractionPage: number = 1
  alcInteractionPage: number = 1
  memberNoteForm: FormGroup;
  updateMemberNoteForm: FormGroup;
  casesuccessMsg = false;
  casefailMsg = false;
  successMsg = false;
  failMsg = false;
  nltcomment: any
  isFlattenview: boolean = true
  isClassicview: boolean = true
  paginationPageSizeactive = 7
  isAll: boolean = false
  medSource = ["EHR", "INPATIENT", "SNF", "CLAIM", "SS", "MEMBER REPORTED"]
  medDecisionListTech = ["New", "RPh Review", "DC Medication", "Acknowledged"]
  medTechDict = { "N": "New", "R": "RPh Review", "D": "DC Medication", "A": "Acknowledged" }
  medDecisionListRph = ["New", "Changed", "Continued", "Held", "Stopped"]
  editrow = 'H'
  routeData : any = []

  selectedMedAllergyString: any = []
  selectedCatAllergyString: any = []
  // @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
  //   this.dataSource1.paginator = paginator;
  // }
  // @ViewChild('categoryPaginator') set categoryPaginator(paginator1: MatPaginator) {
  // }


  updatedTech: any = ''
  updatedrph: any = ''
  updateMedSrc: any = ''
  updatedPatComments: any = ''
  updatedProvComments: any = ''
  updatedIsTakingMed: any = ''
  updatedReviewerComments: any = ''
  parsedAllergyArray: any = []
  caseType: any;



  onAddFilterdatas = []
  onAddSortdatas = []
  searchForm: any;
  patientMrnValue: string;
  smaPrescriptionId: string;
  activityDetail: any;
  filteredPrescriptions
  showSmaScriptPrescription = true;
  showSureScriptPrescription = true;
  searchFail = false;
  currentOpen: boolean = false;
  commentsMaxChars = 2000;
  chars = 0;
  msID: string = localStorage.getItem('msId');
  isEditable: [true];
  disableEdit: true;
  editableRow: boolean = false;
  editRowId: any;
  anythingChanged: boolean = false;
  renewalStatus: string;
  extraColumns = true

  pres_type: any;

  displayedColumns1: string[] = ['selectedIntervention', 'drugCategory', 'gpi', 'medication', 'ehrNDC', 'claimNDC', 'ssNDC', 'dsNDC', 'snfNDC', 'patNDC', 'techCheck', 'rphCheck', 'medDecision', 'isTakingMed', 'reviewerComments', 'patComments', 'providerComments', 'edit', 'isedited', 'patTaking']

  displayedColumnsHistory: string[] = ['exportSelected', 'isedited', 'exportToRegime', 'DRUGNAME', 'LASTFILLEDDATE', 'QUANTITY', 'DAYSSUPPLY',  'RUNOUT_DATE', 'PRESCRIBERNAME', 'PRESCRIBERNPI', 'PRESCRIBERSPECIALTY',  'PHARMACYNAME', 'PHARMACYCONTACT', 'PHARMACYNPI','SRC', 'SUBSRC','DOSE','FORM', 'ROUTE', 'FREQUENCY', 'INSTRUCTIONS','WRITTENDATE', 'STRENGTH', 'UNITOFMEASURE', 'DRUGCATEGORY',  'NDC', 'GPI', 'ACTIVE_FLAG',  'PRN', 'REFILLNBR', 'TOTALREFILLS', 'CLAIMSTATUS', 'PTCOMMENTS', 'ISPATIENTTAKINGMED', 'DIAGNOSIS', 'DIAGNOSISDESC', 'DIAGNOSISEFFECTDT', 'ISMANUAL', 'CREATED_DT', "delete"]
  presvisible = true;
  toppings = new FormControl('');
  iscellPhone = true;
  isHomePhone = true;
  isWorkPhone = true;
  isWorkPhoneExt = true;
  isPhoneType = true;
  checked = false;
  Workphone: any
  prevHomePhone: any
  prevCellPhone: any
  prevWorkPhone: any
  prevWorkExt: any
  prevPhoneType: any
  disableSavePhone = true;
  isdemographicValueChanged = false;
  isOpenedByOthers: Number = 0
  leafletData: any
  ismedispan: any = true
  list_of_drugs: any
  list_of_medication: any
  temp: any
  ismedispandata: boolean = false
  demographiceNotes: String = "Patients Demographic Details Changed:"
  selectedDrawer: string;
  viewicon = false
  patientView = true
  viewdrug = false;
  viewcomment = false;
  viewEncounter = false;
  viewbpe = false;
  viewactionplan = false;
  viewdpatientoutreach = false;
  viewhealthoutcome = false;
  viewdailyliving = false;
  searchvalue: any
  @ViewChild('sortCol1') sortCol1: MatSort;
  @ViewChild('sortCol2') sortCol2: MatSort;
  offset: number;
  mrnValue: any[];
  isdisabled: true;
  currentIndex: number;
  modifiedData: any;
  val: any = "sfs"
  backButtonVisible: boolean = true;
  caseViewDetails: Object;
  originalData: any;
  snackBarRef: any;
  region: any
  searchText: any = "";
  cagm: any
  isbepVisible = false
  isShowing = false
  isbpebtn = true
  initialLoadData: any
  serviceData: any = []
  serviceDataBackup: any = []
  diagnosisList: any = []
  isDiagnosis: boolean = true
  isTechCheck: boolean = false;
  isRphCheck: boolean = false;
  isFinalCheck: boolean = false;
  isDropDown: boolean = false;
  isClosed: boolean = false;
  isReconciled: boolean = false
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  currentPath: String;
  idleSubscriptions: any;
  constructor(public dialog: MatDialog,
    private patientDataService: DataService, private _route: Router, private fb: FormBuilder, public route: ActivatedRoute, public changeDetector: ChangeDetectorRef, private toastr: ToastrService,private idle: Idle,
    private keepalive: Keepalive,private cd: ChangeDetectorRef,location:Location , public idleService:IdleTimeoutService) {
    this.hidepatientview()
    this.idleSubscription = this.idleService.idleTimeout.subscribe(() => {           
      this.performPreLogoutActions();

    });
   


  }



  classicview(): void {

    this.isclassicviewclicked = true;
    if (this.isFlattenview == false) {
      console.log('hiiiiiiiiiiiiiiiiiiii', this.isFlattenview)
      this.isFlattenview = true
      this.isClassicview = false
      this.filteredPrescriptionsflatten = []
      this.getActivityDetailsflatten(this.patientMrnValue, "true")
      console.log("--------------------------------6")






    }
    else {

      this.isFlattenview = false
      console.log('hellooooooooooo', this.isFlattenview)
      this.isClassicview = true
      this.getActivityDetails(this.patientMrnValue, "false")
      console.log("--------------------------------7")
      if (this.globalSearchText != "") {
        this.globalSearch()
      }
      if (this.globalSearchText1 != "") {
        this.globalSearchRegimen()
      }
      console.log("--------------------------------8")
    }


  }




  hidepatientview() {
    this.viewicon = true
    this.patientView = !this.viewicon;

  }
  drugDetailsView1() {

    this.isShowing = false
    this.patientView = !this.viewicon;
    this.viewicon = true
    this.viewcomment = false;
    this.viewdrug = false
    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;
    this.viewEncounter = false


  }
  viewPatient() {
    this.patientView = true;
    this.viewicon = !this.patientView
    this.isShowing = false
    this.viewcomment = false;
    this.viewdrug = false
    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;
    this.viewEncounter = false



  }
  viewdrugdetails() {
    this.viewdrug = true;

    if (this.isShowing == false) {
      this.isShowing = true
      this.drugInteraaction = this.drugInteraaction
    }




    this.patientView = false;
    this.viewicon = !this.patientView
    this.viewcomment = false;

    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;
    this.viewEncounter = false

  }

  viewcomments() {

    if (this.isShowing == false) {
      this.isShowing = true


    }


    this.viewcomment = true;
    this.patientView = false;
    this.viewdrug = false
    this.viewicon = !this.patientView
    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;
    this.viewEncounter = false


  }
  viewEncounterClick() {

    debugger


    this.viewdrug = false;

    if (this.isShowing == false) {
      this.isShowing = true
      this.viewEncounter = !this.viewEncounter
    }




    this.patientView = false;
    this.viewicon = !this.patientView
    this.viewcomment = false;
    this.viewEncounter = true

    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;


  }

  medispan(checked) {
    if (checked) {
      this.ismedispan = true


    }
    else {
      this.ismedispan = false

    }

  }



  ngOnInit() {
        
    console.log(this.val.toUpperCase())
    
    
    this.patientDataService.getdropdown("ROUTE").subscribe(data => {
      this.routeData = data["values"]
      console.log(this.routeData)
    }, error => {
    })

    this.route.params.subscribe((params: Params) => {
      localStorage.setItem("mberRefNo", params.mbrRefNO)
      this.patientDataService.getPatientData(params.mbrRefNO).subscribe(data => {

        this.memberdata = data[0]
        localStorage.setItem("name", this.memberdata.lastName + "_" + this.memberdata.firstName)
        this.callHistoryMedications()

        this.getEncounterDetails()



        this.patientDetail = data;
        this.getMemberNotes('N');
        this.callDiagnosisAPI()
        this.patientDataService.geteMemberAllegies(localStorage.getItem('mberRefNo')).subscribe(data => {
          this.allergies = data
          debugger
          this.parseAllergies(data)
          this.parseAllergiesReport(data)





        }, error => {
          this.toastr.error("Issue In Data Saving", '', {
            timeOut: 1500,
          });

        })
      }, (error: Error) => {
        console.log(error);

      })


    }, (error: Error) => {
      console.log(error);

    }

    );
    this.memberNoteForm = this.fb.group({
      mrnID: this.patientMrnValue,
      memNoteComments: ['', Validators.required],
    })

    






  }
  ngAfterViewInit() {

  }

  callDiagnosisAPI() {
    console.log('member', localStorage.getItem("mberRefNo"))
    this.patientDataService.getPatDiagnosis(localStorage.getItem("mberRefNo")).subscribe((data: any) => {
      console.log('hist data', data)
      this.diagnosisList = data
    })
  }

  getEncounterDetails() {
    let encounterId = localStorage.getItem("encounterId");
    this.patientDataService.getEncounterData(encounterId).subscribe(data => {
      this.encounterData = data[0]
      this.targetedEncounter = this.getTargetedEncounter(this.encounterData)
      this.callDrugRegimenApi();
      this.getTargetedEncounter(this.encounterData )

      if (this.encounterData.status == 'Data Entry' && this.encounterData.encounterType == CONSTANT.ENCOUNTER_TYPE_REVIEW) {
        this.isTechCheck = true;
        this.isRphCheck = true;
        this.isFinalCheck = false;
        

      }


      else if (this.encounterData.status == 'Data Entry') {
        this.isTechCheck = true;
        this.isRphCheck = false;
      }
      else if (this.encounterData.status == 'Rph Review') {
        this.isTechCheck = false;
        this.isRphCheck = true
      }
      else if (this.encounterData.status == 'Reviewed') {
        this.isTechCheck = false;
        this.isRphCheck = false;
        this.isFinalCheck = true;
        this.isReconciled=true
      }
      else if (this.encounterData.status == 'Reconciled') {
        this.isTechCheck = false;
        this.isRphCheck = false;
        this.isFinalCheck = true;
        this.isReconciled = true;
      }
      else if (this.encounterData.status == 'Closed') {
        this.isClosed = true;
        this.isReconciled = true;
        this.isFinalCheck = true;
      }
      else if (this.encounterData.status == 'Completed') {
        this.isClosed = true;
        this.isReconciled = true;
        this.isFinalCheck = false;
      }

      this.encounterData.diagnosedFor = this.encounterData.diagnosedFor.split('-')
      if (this.encounterData.diagnosedFor > 0 || this.encounterData.diagnosedFor == '') {
        this.isDiagnosis = false
      }
    }, error => {

    })
  }
  getTargetedEncounter(data) {
    
    debugger
    if (CONSTANT.ENCOUNTER_TYPE_REVIEW.includes(data.encounterType)) {
      localStorage.setItem('targetedEncounter', 'true')
      this.isTargetedEncounter=true
      this.showColumn = false; // Set this condition based on your logic

      return true
    }
    localStorage.setItem('targetedEncounter', 'false')
    this.isTargetedEncounter=false
    this.showColumn = true; // Set this condition based on your logic


    return false
  }
  callHistoryMedications() {
    let drugApiInput = {
      "id": "A001234",
      "mrn": "1035090",
      "lastname": this.memberdata.lastName,
      "firstname": this.memberdata.firstName,
      "dob": this.memberdata.dob
    }
    this.patientDataService.getActiveMedications(drugApiInput).subscribe(data => {
      this.servicedata = data
      this.initialLoadData = data;
      this.servicedata1 = this.servicedata.filter((element) => element.ACTIVE_FLAG == 'Y')




      this.servicedata = this.servicedata1.sort(this.sortByDate);
      console.log("servicedata:", this.servicedata)
      console.log("initialloaddata:", this.initialLoadData)
      this.servicedatacopy = data
      this.servicedatacopy = this.servicedatacopy.filter((element) => element.ACTIVE_FLAG == 'Y')
      this.initialLoaddatacopy = data      
      this.dataSourceHistory = new MatTableDataSource(this.servicedata);      
      this.dataSourceHistory.sort = this.sortCol1
      debugger
      console.log('viewAll', this.viewAll)
      this.viewALLMeds(this.viewAll)



    }, error => {




    });


    this.dataSourceHistory = new MatTableDataSource(this.dataHistory);
    this.dataSourceHistory.sort = this.sortCol1
    this.offset = new Date().getTimezoneOffset();
    this.region = this.patientDataService.region

    this.dataSourceHistory.paginator = this.paginator;

  }
  checkRAF(data:any){
    if(data!=null && data!=undefined && data!=''){
      return data
    }
    else{
      return 'NA'
    }
  }


  matchRoute(data){
    this.routeData.forEach(ele => {
      if(data === ele.split('-')[0].trim()){
        data = ele        
        return data
      }
      })
      return data
    }

  callHistoryMedicationsSelected() {

    let drugApiInput = {
      "id": "A001234",
      "mrn": "1035090",
      "lastname": this.memberdata.lastName,
      "firstname": this.memberdata.firstName,
      "dob": this.memberdata.dob
    }
    this.patientDataService.getActiveMedications(drugApiInput).subscribe(data => {
      this.servicedata = data
      this.initialLoadData = data;
      this.servicedata = this.servicedata.filter((element) => element.ACTIVE_FLAG == 'Y')
      console.log("servicedata:", this.servicedata)
      console.log("initialloaddata:", this.initialLoadData)
      this.servicedatacopy = data
      this.servicedatacopy = this.servicedatacopy.filter((element) => element.ACTIVE_FLAG == 'Y')
      this.initialLoaddatacopy = data
      this.dataSourceHistory = new MatTableDataSource(this.servicedata);

      this.dataSourceHistory.sort = this.sortCol1
    }, error => {




    });


    this.dataSourceHistory = new MatTableDataSource(this.dataHistory);

    this.offset = new Date().getTimezoneOffset();
    this.region = this.patientDataService.region

    this.dataSourceHistory.paginator = this.paginator;
    this.dataSourceHistory.sort = this.sortCol1


  }
  callDrugRegimenApi() {
    let encounterId = localStorage.getItem('encounterId')

    if (this.encounterData.status == 'Reconciled') {
      this.patientDataService.getMedregimen(encounterId).subscribe(data => {
        this.isSpinner = false
        this.serviceData = data
        this.serviceData.forEach(e => {

          let finalreview: any = []
          if (e['claimStrength'] != "" || e['claimUnits'] != "" || e['claimFrequency'] != "" || e['claimDaysOfSupply'] != "") {
            finalreview.push('Claims')
          }
          if (e['ssUnits'] != "" || e['ssStrength'] != "" || e['ssFrequency'] != "" || e['ssDaysOfSupply'] != "") {
            finalreview.push('POS')
          }
          if (e['dsUnits'] != "" || e['dsStrength'] != "" || e['dsFrequency'] != "" || e['dsDaysOfSupply'] != "") {
            finalreview.push('Inpatient')
          }
          if (e['snfUnits'] != "" || e['snfStrength'] != "" || e['snfFrequency'] != "" || e['snfDaysOfSupply'] != "") {
            finalreview.push('SNF')
          }
          if (e['ehrUnits'] != "" || e['ehrStrength'] != "" || e['ehrFrequency'] != "" || e['ehrDaysOfSupply'] != "") {
            finalreview.push('EHR')
          }
          if (e['patUnits'] != "" || e['patStrength'] != "" || e['patFrequency'] != "" || e['patDaysOfSupply'] != "") {
            finalreview.push('Member Reported')
          }
          e['finalReviews'] = finalreview
          // e.techCheck = e.techCheck.split('-')s        
          let list = []
          Array.from(e.techCheck).forEach(element => {
            list.push(this.medTechDict[String(element)])
          })
          e.techCheck = list
          e.isOpen = false
        });
        this.copyService = JSON.parse(JSON.stringify(this.serviceData));
        this.dataSource1 = new MatTableDataSource(this.serviceData);
        this.dataSource1.sort = this.sortCol2
        this.serviceDataBackup = this.serviceData
        this.callMedispanDrugInteraction(data)
        this.checkReportVisibility( this.serviceData)
      }, error => {




      });

    }
    else {
      this.patientDataService.getMedregimenws(encounterId).subscribe(data => {
        this.isSpinner = false
        this.serviceData = data
        this.serviceData.forEach(e => {

          let finalreview: any = []
          if (e['claimStrength'] != "" || e['claimUnits'] != "" || e['claimFrequency'] != "" || e['claimDaysOfSupply'] != "") {
            finalreview.push('Claims')
          }
          if (e['ssUnits'] != "" || e['ssStrength'] != "" || e['ssFrequency'] != "" || e['ssDaysOfSupply'] != "") {
            finalreview.push('POS')
          }
          if (e['dsUnits'] != "" || e['dsStrength'] != "" || e['dsFrequency'] != "" || e['dsDaysOfSupply'] != "") {
            finalreview.push('Inpatient')
          }
          if (e['snfUnits'] != "" || e['snfStrength'] != "" || e['snfFrequency'] != "" || e['snfDaysOfSupply'] != "") {
            finalreview.push('SNF')
          }
          if (e['ehrUnits'] != "" || e['ehrStrength'] != "" || e['ehrFrequency'] != "" || e['ehrDaysOfSupply'] != "") {
            finalreview.push('EHR')
          }
          if (e['patUnits'] != "" || e['patStrength'] != "" || e['patFrequency'] != "" || e['patDaysOfSupply'] != "") {
            finalreview.push('Member Reported')
          }
          e['finalReviews'] = finalreview
          // e.techCheck = e.techCheck.split('-')s        
          let list = []
          Array.from(e.techCheck).forEach(element => {
            list.push(this.medTechDict[String(element)])
          })
          e.techCheck = list
          e.isOpen = false
        });
        this.copyService = JSON.parse(JSON.stringify(this.serviceData));
        this.dataSource1 = new MatTableDataSource(this.serviceData);
        this.dataSource1.sort = this.sortCol2
        this.serviceDataBackup = this.serviceData
        this.callMedispanDrugInteraction(data)
        this.checkReportVisibility( this.serviceData)

      }, error => {




      });

    }





  }

  ngOnDestroy(): void {


  }
  checkReportVisibility(data){
   let regimenlength= data.length
   let counter=0;
   data.forEach(element => {
     if(element.rphCheck && element.medDecision){
      counter=counter+1
     }
   })
   if(counter==regimenlength &&regimenlength!=0 && this.encounterData.status=='Reviewed' ){

     this.showOutreachReport=true
   }



  }



  onSave() {
    this.memberNoteForm.patchValue({
      memNoteComments: "",
    });



  }
  interventionClick() {



    this.dialog.open(InterventionManagmentComponent, {
      height: '75%',
      width: '100%',
      data: {

      },
    });

  }

  resetSearch() {
    this.resetclicked = true;
    this.globalSearchText = "";

    // this.globalSearch();
    if (this.viewAll) {
      this.dataSourceHistory1 = this.initialLoaddatacopy.sort(this.sortByDate);

      this.dataSourceHistory = new MatTableDataSource(this.dataSourceHistory1);
    }
    else {
      this.dataSourceHistory1 = this.servicedatacopy.sort(this.sortByDate);

      this.dataSourceHistory = new MatTableDataSource(this.dataSourceHistory1)
    }
    this.dataSourceHistory.sort = this.sortCol1
  }

  resetRegimenSearch() {
    this.resetclicked = true;
    this.globalSearchText1 = "";
    this.dataSource1 = new MatTableDataSource(this.serviceData)
    // this.globalSearch();
    this.dataSource1.sort = this.sortCol2
  }






  onReset() {
    this.memberNoteForm.patchValue({
      memNoteComments: "",
    });

  }



  onMemNoteSave() {
    if (this.memberNoteForm.valid) {
      console.log('form>>', this.memberNoteForm.value)
      var obj = this.memberNoteForm.value;
      obj["createdBy"] = localStorage.getItem('usname');
      obj["notes"] = obj.memNoteComments;
      obj["mberRefNo"] = localStorage.getItem('mberRefNo')
      obj["encounterId"] = localStorage.getItem('encounterId')
      obj["isAuto"] = "N"
      if (obj.memNoteComments != '') {
        //obj["noteId"] = '';
        this.patientDataService.postMemberNote(obj).subscribe(data => {

          this.memberNotes = data;
          // window.location.reload();
          //this.successMsg = true;

          this.getMemberNotes('N');
          this.onSave();
          this.snackBarRef = this._snackBar.open('Member notes added successfully');
          setTimeout(() => {
            this.snackBarRef.dismiss();
          }, 2000);
        }, (error: Error) => {
          console.log(error);


          this.failMsg = true;
        })
      }
    }
  }

  rowEdit(row) {

    this.editRowId = this.smaPrescriptionId;
    console.log("clicked row", row);
    //row.isEditable = true;
    row.isEditable = !row.isEditable;
  }
  presClick(data) {

    this.presvisible = false;
    this.prevPresType = data
    console.log(this.prevPresType)

  }



  reset() {
    this.presvisible = true;
  }



  searchPrescriptions() {
    localStorage.setItem("presSearchTerm", this.searchText);
    if (this.searchText.includes("&")) {
      console.log(this.searchText.split("&"));

      this.filteredSystemStatus = this.searchDatapresc(this.patientList, this.searchText.split("&")[0]);
      for (let ic: any = 1; ic <= this.globalSearchText.split("&").length; ic++) {
        this.filteredSystemStatus = this.searchDatapresc(this.filteredSystemStatus, this.globalSearchText.split("&")[ic]);

      }

    }
    else {
      this.filteredSystemStatus = this.searchDatapresc(this.patientList, this.globalSearchText);
      this.page = 1;
      localStorage.setItem('page', this.page);
      console.log("finalSearch>>>>", this.filteredSystemStatus);
      //this.trackUser("Generic Search", this.globalSearchText);
    }
  }
  searchDatapresc(dataObj, searchValue) {
    console.log(dataObj);
    return dataObj.filter(ele =>
      Object.keys(ele)
        .some(key => String(ele[key]).toLowerCase().includes(searchValue.toLowerCase())))


  }







  getMemberNotes(flag) {
    let input = {
      "mberRefNo": localStorage.getItem('mberRefNo'),
      "isAuto": flag
    }
    this.patientDataService.getMemberNote(input).subscribe(data => {

      if (!data.hasOwnProperty('message')) {
        this.memberNotes = data;
        console.log('MemberNotes>>>', this.memberNotes)
      }
    }, (error: Error) => {
      console.log(error);

    })
  }
  getFormattedPhone(data) {
    if (this.checkNonEmtyData(data)) {
      if (data.length == 10) {
        return "(" + data.substring(0, 3) + ") " + + data.substring(3, 6) + "-" + data.substring(6, 10)
      }
      return data
    }

  }
  checkNonEmtyData(value: any): boolean {
    debugger
    if (value != '' && value != 'undefined' && typeof (value) != undefined && value != null) {
      return true
    }
    return false


  }





  isauto(value) {

    this.Notespage = 1
    this.NotespageComment = 1
    this.memberNotes = []


    if (value.index == 1) {
      this.comment = 'History'
      this.getMemberNotes("Y")
    }
    else {
      this.comment = 'Comment'
      this.getMemberNotes("N")

    }
  }
  viewAllNotes(memberNotes) {

  }
  viewNextNotes(mrn: number) {
    console.log("MemberNotes>>>>", mrn)
    this._route.navigate(["/notesMemHis/" + mrn], { relativeTo: this.route })


  }
  showFilterWRap = {
    customizeDiv: false,
    filterDiv: false,
  }

  goBack() {
    if (this.anythingChanged || this.anythingChangedRec || this.anythingChangedRev) {
      this.toastr.error('There Are Unsaved Or Incomplete Fields. Please Save Or Complete Before Proceeding.', '', {
        timeOut: 1500,
      })
    }
    else { window.history.back(); }
  }

  landingPage() {
    if (this.anythingChanged || this.anythingChangedRec || this.anythingChangedRev) {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '25%',
        width: '25%',
        data: { message: 'There Are Unsaved Or Incomplete Fields. Please Save Or Complete Before Proceeding.' },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === true) {
          this._route.navigate(['/landingPage'])

        } else {
          return
        }
      });


    }
    else { this._route.navigate(['/landingPage']) }
  }



  goBackHome() {

  }





  addMedication(fieldname, meddata): void {

    console.log(this.serviceData)
    const addDialogue = this.dialog.open(AddNewMedComponent, {
      height: '65%',
      width: '100%',
      data: {
        meddata: this.memberdata,
        encounterId: localStorage.getItem("encounterId"),
        historymed: this.servicedatacopy
      },
    });
    addDialogue.afterClosed().subscribe((res) => {
      this.callHistoryMedications();
    }, (err: Error) => {
      console.log(err)
    })

  }


  reconcile(e: CellClickedEvent): void {

    console.log(e);
    if (e.colDef.headerName.includes('Timeline')) {
      this.timelinedata('timeline', e)
    }
    if (e.colDef.headerName == 'NDC') {
      this.openNDCRelatedData(e)
    }
    if (e.colDef.headerName == 'GPI') {

      this.openDrugPriceData(e)
    }

    else if (e.colDef.field.includes('reconciliation') || e.colDef.field.includes('review')) {
      this.addMedication(e.colDef.field, e.data)
    }
    else if (e.colDef.field.includes('actionplan')) {
      this.ActionPlan('newmed', e)
    }

  }
  timelinedata(fieldname, meddata): void {

    console.log('hiiiiiiiiiiiiiiiiiii-------')

  }


  healthoutreachclick() {
    if (this.isShowing == true) {
      this.isShowing = false
    }

    this.patientView = false;
    this.viewicon = !this.patientView
    this.isShowing = false
    this.viewcomment = false;

    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = true;
    this.viewdailyliving = false;


  }


  dailylivingclick() {
    if (this.isShowing == true) {
      this.isShowing = false
    }
    this.patientView = false;
    this.viewicon = !this.patientView
    this.viewcomment = false;

    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = true;


  }
  actionplanclick() {

    if (this.isShowing == true) {
      this.isShowing = false
    }
    this.patientView = false;
    this.viewicon = !this.patientView
    this.viewcomment = false;

    this.viewbpe = false;
    this.viewactionplan = true;
    this.viewdpatientoutreach = false;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;


  }

  patientoutreachclick() {
    this.patientView = false;
    this.viewicon = !this.patientView
    this.isShowing = false
    this.viewcomment = false;

    this.viewbpe = false;
    this.viewactionplan = false;
    this.viewdpatientoutreach = true;
    this.viewhealthoutcome = false;
    this.viewdailyliving = false;


  }

  getZipCode(params) {
    return params.data.zipCode
  }
  getFormattedphone(params) {
    if (params.length == 10) {
      return "(" + params.substring(0, 3) + ")" + + params.substring(3, 6) + "-" + params.substring(6, 10)
    }
    return params

  }

  gettimeline() {
    return "timeline"
  }

  globalSearch() {
    localStorage.setItem("searchTerm", this.globalSearchText);
    if (this.globalSearchText.includes("&")) {
      console.log(this.globalSearchText.split("&"));

      this.medicationdatatab = this.searchData(this.meddata, this.globalSearchText.split("&")[0]);
      for (let ic: any = 1; ic <= this.globalSearchText.split("&").length; ic++) {
        this.medicationdatatab = this.searchData(this.medicationdatatab, this.globalSearchText.split("&")[ic]);

      }

    }
    else {
      this.medicationdatatab = this.searchData(this.meddata, this.globalSearchText);

      this.page = 1;
    }
  }
  searchData(dataObj, searchValue: any = '') {

    return dataObj.flatMap(ele => {
      const objHasSearchTerm = Object.entries(ele)
        .some(([key, value]) => key !== 'medicationDetails' && String(value).toLowerCase().includes(searchValue.toLowerCase()));

      if (objHasSearchTerm) return [ele]

      const matchedMedication = this.searchData(ele.medicationDetails ?? [], searchValue);
      let sd = objHasSearchTerm || matchedMedication.length > 0
        ? [{
          ...ele,
          medicationDetails: matchedMedication,
        }]
        : [];

      return sd;
    })

  }
  searchRegimenData(dataObj, searchValue: any = '') {
    let keysArray=[        "encounterId",
    "prescriptionId",
    "recordId",
    "mbrRefNo",
    "mrn",
    "firstName",
    "lastName",
    "dob",
    "gender",
    "gpi",
    "claimNDC",       
    "claimlastFilledDate",
    "claimDaysOfSupply",
    "claimUnits",
    "claimStrength",
    "claimRefilNbr",
    "claimRxNbr",
    "claimTotalRefill",
    "claimWrittenDate",
    "claimprescriptionEnddate",
    "claimstatus",
    "ssNDC",
    "sslastFilledDate",
    "ssDaysOfSupply",
    "ssUnits",
    "ssStrength",
    "ssRefilNbr",
    "ssRxNbr",
    "ssTotalRefill",
    "ssWrittenDate",
    "ssprescriptionEnddate",
    "ssstatus",
    "ssMessageId",
    "dsNDC",
    "dslastFilledDate",
    "dsDaysOfSupply",
    "dsUnits",
    "dsStrength",
    "dsRefilNbr",
    "dsRxNbr",
    "dsTotalRefill",
    "dsWrittenDate",
    "dsprescriptionEnddate",
    "dsstatus",
    "snfNDC",
    "snflastFilledDate",
    "snfDaysOfSupply",
    "snfUnits",
    "snfStrength",
    "snfRefilNbr",
    "snfRxNbr",
    "snfTotalRefill",
    "snfWrittenDate",
    "snfprescriptionEnddate",
    "snfstatus",
    "ehrNDC",
    "ehrlastFilledDate",
    "ehrDaysOfSupply",
    "ehrUnits",
    "ehrStrength",
    "ehrRefilNbr",
    "ehrRxNbr",
    "ehrTotalRefill",
    "ehrWrittenDate",
    "ehrprescriptionEnddate",
    "ehrstatus",
    "patNDC",
    "patlastFilledDate",
    "patDaysOfSupply",
    "patUnits",
    "patStrength",
    "patRefilNbr",
    "patRxNbr",
    "patTotalRefill",
    "patWrittenDate",
    "patprescriptionEnddate",
    "patstatus",
    "patTakingMed",
    "finalSource",
    "reviewBy",
    "reviewDatettime",
    "reconcilledBy",
    "reconcilledDatetime",
    "actionplan",
    "claimprescriberName",
    "claimprescriberNPI",
    "claimprescriberSpeciality",
    "claimPharmacyName",
    "claimPharmacyNPI",
    "claimPharmacyContact",
    "ssprescriberName",
    "ssprescriberNPI",
    "ssprescriberSpeciality",
    "ssPharmacyName",
    "ssPharmacyNPI",
    "ssPharmacyContact",
    "dsprescriberName",
    "dsprescriberNPI",
    "dsprescriberSpeciality",
    "dsPharmacyName",
    "dsPharmacyNPI",
    "dsPharmacyContact",
    "snfprescriberName",
    "snfprescriberNPI",
    "snfprescriberSpeciality",
    "snfPharmacyName",
    "snfPharmacyNPI",
    "snfPharmacyContact",
    "ehrprescriberName",
    "ehrprescriberNPI",
    "ehrprescriberSpeciality",
    "ehrPharmacyName",
    "ehrPharmacyNPI",
    "ehrPharmacyContact",
    "patprescriberName",
    "patprescriberNPI",
    "patprescriberSpeciality",
    "patPharmacyName",
    "patPharmacyNPI",
    "patPharmacyContact",
    "patDiagnosis",
    "patDiagnosisDesc",
    "patDiagnosisEffectDt",
    "ehrDiagnosis",
    "ehrDiagnosisDesc",
    "ehrDiagnosisEffectDt",
    "dsDiagnosis",
    "dsDiagnosisDesc",
    "dsDiagnosisEffectDt",
    "snfDiagnosis",
    "snfDiagnosisDesc",
    "snfDiagnosisEffectDt",
    "ssDiagnosis",
    "ssDiagnosisDesc",
    "ssDiagnosisEffectDt",
    "claimDiagnosis",
    "claimDiagnosisDesc",
    "claimDiagnosisEffectDt",
    "edit",
    "save",
    "clear",
    "ssQuantity",
    "dsQuantity",
    "snfQuantity",
    "patQuantity",
    "claimQuantity",
    "ehrQuantity",
    "ssPRN",
    "dsPRN",
    "snfPRN",
    "patPRN",
    "claimPRN",
    "ehrPRN",
    "ssIsManual",
    "dsIsManual",
    "snfIsManual",
    "patIsManual",
    "claimIsManual",
    "ehrIsManual"]

    return dataObj.flatMap(ele => {
      const objHasSearchTerm = Object.entries(ele)
        .some(([key, value]) => !keysArray.includes(key) && String(value).toLowerCase().includes(searchValue.toLowerCase()));

      if (objHasSearchTerm) return [ele]

      const matchedMedication = this.searchData(ele.medicationDetails ?? [], searchValue);
      let sd = objHasSearchTerm || matchedMedication.length > 0
        ? [{
          ...ele,
          medicationDetails: matchedMedication,
        }]
        : [];

      return sd;
    })

  }
  getSource(params) {
    if (params.data.twhistory == 'Y') {
      return "EMR"
    }
    if (params.data.claims == 'Y') {
      return "Claims"
    }
    if (params.data.surescripts == 'Y') {
      return "Pickup"
    }
    return "New"
  }

  test(a) {

    console.log(a)
    this.editrow = a.medication

  }
  editClicked(data, b) {

    console.log(data, b)
    if (this.anythingChanged || this.anythingChangedRec || this.anythingChangedRev) {
      this.toastr.error('There Are Unsaved Or Incomplete Fields. Please Save Or Complete Before Proceeding.', '', {
        timeOut: 1500,
      })
    }
    else {
      let dial = this.dialog.open(EditMedRegimeComponent, {
        height: '65%',
        width: '100%',
        data: {
          "medication": data

        },
      });

      dial.afterClosed().subscribe((res) => {
        this.callDrugRegimenApi();
      }, (err: Error) => {
        console.log(err)
      })
    }
  }

  exportToDrugRegime(element, sourceData) {
    debugger

    this.parseHistoryDataForRegime(element, sourceData.filteredData)


    console.log(element)
    console.log(sourceData.filteredData)

  }
  exportToDrugRegimeSelected(element, sourceData) {
    this.parseHistoryDataForRegimeSelected(element, sourceData.filteredData)


  }


  parseHistoryDataForRegime(sourceData, historyData) {
    let drugRegimeInput = {};
    drugRegimeInput["encounterId"] = localStorage.getItem('encounterId')
    drugRegimeInput["prescriptionId"] = localStorage.getItem('mberRefNo') + "-" + sourceData.GPI + "-" + getDateTime()
    drugRegimeInput["mberRefNo"] = localStorage.getItem('mberRefNo')
    drugRegimeInput["recordId"] = 123
    drugRegimeInput["mrn"] = sourceData.MRN
    drugRegimeInput["firstName"] = sourceData.FIRSTNAME
    drugRegimeInput["lastName"] = sourceData.LASTNAME
    drugRegimeInput["dob"] = sourceData.DOB
    drugRegimeInput["gender"] = sourceData.GENDER
    drugRegimeInput["medication"] = sourceData.DRUGNAME
    drugRegimeInput["drugCategory"] = sourceData.DRUGCATEGORY
    drugRegimeInput["gpi"] = sourceData.GPI
    drugRegimeInput["patTakingMed"] = sourceData.ISPATIENTTAKINGMED

    historyData.forEach(data => {
      if (sourceData.GPI == data.GPI) {
        if (data.SRC.toUpperCase() == 'CLAIMS' && data.SRC != sourceData.SRC) {
          drugRegimeInput["claimNDC"] = data.NDC
          drugRegimeInput["claimFrequency"] = data.FREQUENCY
          drugRegimeInput["claimlastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["claimDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["claimInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["claimUnits"] = data.UNITOFMEASURE
          drugRegimeInput["claimStrength"] = data.STRENGTH
          drugRegimeInput["claimRefilNbr"] = data.REFILLNBR
          drugRegimeInput["claimTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["claimWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["claimprescriptionEnddat"] = ""
          drugRegimeInput["claimstatus"] = data.CLAIMSTATUS
          drugRegimeInput["claimprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["claimprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["claimprescriberSpecialiy"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["claimPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["claimPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["claimPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["claimDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["claimDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["claimDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["claimForm"] = data.FORM
          drugRegimeInput["claimRoute"] = data.ROUTE
          drugRegimeInput["claimDose"] = data.DOSE
          drugRegimeInput["claimQuantity"] = data.QUANTITY
          drugRegimeInput["claimPRN"] = data.PRN
          drugRegimeInput['claimIsManual'] = 'N'
        }
        if (data.SRC.toUpperCase() == 'CLAIMS' && data.SRC == sourceData.SRC) {
          drugRegimeInput["claimNDC"] = sourceData.NDC
          drugRegimeInput["claimFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["claimlastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["claimDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["claimInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["claimUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["claimStrength"] = sourceData.STRENGTH
          drugRegimeInput["claimRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["claimTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["claimWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["claimprescriptionEnddat"] = ""
          drugRegimeInput["claimstatus"] = sourceData.CLAIMSTATUS
          drugRegimeInput["claimprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["claimprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["claimprescriberSpecialiy"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["claimPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["claimPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["claimPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["claimDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["claimDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["claimDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["claimForm"] = sourceData.FORM
          drugRegimeInput["claimRoute"] = sourceData.ROUTE
          drugRegimeInput["claimDose"] = sourceData.DOSE
          drugRegimeInput["claimQuantity"] = sourceData.QUANTITY
          drugRegimeInput["claimPRN"] = sourceData.PRN

        }
        else if (data.SRC.toUpperCase() == 'POS' && data.SRC != sourceData.SRC) {

          drugRegimeInput["ssNDC"] = data.NDC
          drugRegimeInput["ssFrequency"] = data.FREQUENCY
          drugRegimeInput["sslastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["ssDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["ssInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["ssUnits"] = data.UNITOFMEASURE
          drugRegimeInput["ssStrength"] = data.STRENGTH
          drugRegimeInput["ssRefilNbr"] = data.REFILLNBR
          drugRegimeInput["ssTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["ssWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["ssprescriptionEnddate"] = ""
          drugRegimeInput["ssprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["ssprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["ssprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["ssPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["ssPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["ssPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["ssDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["ssDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["ssDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["ssForm"] = data.FORM
          drugRegimeInput["ssRoute"] = data.ROUTE
          drugRegimeInput["ssDose"] = data.DOSE
          drugRegimeInput["ssQuantity"] = data.QUANTITY
          drugRegimeInput["ssPRN"] = data.PRN
          drugRegimeInput["ssPickedUpdt"] = data.PICKEDUP_DATE


        }
        else if (data.SRC.toUpperCase() == 'POS' && data.SRC == sourceData.SRC) {

          drugRegimeInput["ssNDC"] = sourceData.NDC
          drugRegimeInput["ssFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["sslastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["ssDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["ssInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["ssUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["ssStrength"] = sourceData.STRENGTH
          drugRegimeInput["ssRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["ssTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["ssWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["ssprescriptionEnddate"] = ""
          drugRegimeInput["ssprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["ssprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["ssprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["ssPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["ssPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["ssPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["ssDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["ssDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["ssDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["ssForm"] = sourceData.FORM
          drugRegimeInput["ssRoute"] = sourceData.ROUTE
          drugRegimeInput["ssDose"] = sourceData.DOSE
          drugRegimeInput["ssQuantity"] = sourceData.QUANTITY
          drugRegimeInput["ssPRN"] = sourceData.PRN
          drugRegimeInput["ssPickedUpdt"] = sourceData.PICKEDUP_DATE


        }
        else if (data.SRC.toUpperCase() == 'EHR' && data.SRC != sourceData.SRC) {
          drugRegimeInput["ehrNDC"] = data.NDC
          drugRegimeInput["ehrFrequency"] = data.FREQUENCY
          drugRegimeInput["ehrlastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["ehrDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["ehrInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["ehrUnits"] = data.UNITOFMEASURE
          drugRegimeInput["ehrStrength"] = data.STRENGTH
          drugRegimeInput["ehrRefilNbr"] = data.REFILLNBR
          drugRegimeInput["ehrTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["ehrWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["ehrprescriptionEnddate"] = ""
          drugRegimeInput["ehrprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["ehrprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["ehrprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["ehrPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["ehrPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["ehrPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["ehrDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["ehrDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["ehrDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["ehrForm"] = data.FORM
          drugRegimeInput["ehrRoute"] = data.ROUTE
          drugRegimeInput["ehrDose"] = data.DOSE
          drugRegimeInput["ehrQuantity"] = data.QUANTITY
          drugRegimeInput["ehrPRN"] = data.PRN

        }
        else if (data.SRC.toUpperCase() == 'EHR' && data.SRC == sourceData.SRC) {
          drugRegimeInput["ehrNDC"] = sourceData.NDC
          drugRegimeInput["ehrFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["ehrlastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["ehrDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["ehrInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["ehrUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["ehrStrength"] = sourceData.STRENGTH
          drugRegimeInput["ehrRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["ehrTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["ehrWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["ehrprescriptionEnddate"] = ""
          drugRegimeInput["ehrprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["ehrprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["ehrprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["ehrPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["ehrPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["ehrPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["ehrDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["ehrDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["ehrDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["ehrForm"] = sourceData.FORM
          drugRegimeInput["ehrRoute"] = sourceData.ROUTE
          drugRegimeInput["ehrDose"] = sourceData.DOSE
          drugRegimeInput["ehrQuantity"] = sourceData.QUANTITY
          drugRegimeInput["ehrPRN"] = sourceData.PRN

        }
        else if (data.SRC.toUpperCase() == 'SNF' && data.SRC != sourceData.SRC) {
          drugRegimeInput["snfNDC"] = data.NDC
          drugRegimeInput["snfFrequency"] = data.FREQUENCY
          drugRegimeInput["snflastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["snfDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["snfInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["snfUnits"] = data.UNITOFMEASURE
          drugRegimeInput["snfStrength"] = data.STRENGTH
          drugRegimeInput["snfRefilNbr"] = data.REFILLNBR
          drugRegimeInput["snfTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["snfWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["snfprescriptionEnddate"] = ""
          drugRegimeInput["snfprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["snfprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["snfprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["snfPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["snfPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["snfPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["snfDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["snfDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["snfDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["snfForm"] = data.FORM
          drugRegimeInput["snfRoute"] = data.ROUTE
          drugRegimeInput["snfDose"] = data.DOSE
          drugRegimeInput["snfQuantity"] = data.QUANTITY
          drugRegimeInput["snfPRN"] = data.PRN

        }
        else if (data.SRC.toUpperCase() == 'SNF' && sourceData.SRC == data.SRC) {
          drugRegimeInput["snfNDC"] = sourceData.NDC
          drugRegimeInput["snfFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["snflastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["snfDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["snfInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["snfUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["snfStrength"] = sourceData.STRENGTH
          drugRegimeInput["snfRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["snfTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["snfWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["snfprescriptionEnddate"] = ""
          drugRegimeInput["snfprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["snfprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["snfprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["snfPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["snfPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["snfPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["snfDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["snfDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["snfDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["snfForm"] = sourceData.FORM
          drugRegimeInput["snfRoute"] = sourceData.ROUTE
          drugRegimeInput["snfDose"] = sourceData.DOSE
          drugRegimeInput["snfQuantity"] = sourceData.QUANTITY
          drugRegimeInput["snfPRN"] = sourceData.PRN

        }
        else if (data.SRC.toUpperCase() == 'INPATIENT' && sourceData.SRC != data.SRC) {
          drugRegimeInput["dsNDC"] = data.NDC
          drugRegimeInput["dsFrequency"] = data.FREQUENCY
          drugRegimeInput["dslastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["dsDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["dsInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["dsUnits"] = data.UNITOFMEASURE
          drugRegimeInput["dsStrength"] = data.STRENGTH
          drugRegimeInput["dsRefilNbr"] = data.REFILLNBR
          drugRegimeInput["dsTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["dsWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["dsprescriptionEnddate"] = ""
          drugRegimeInput["dsprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["dsprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["dsprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["dsPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["dsPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["dsPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["dsDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["dsDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["dsDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["dsForm"] = data.FORM
          drugRegimeInput["dsRoute"] = data.ROUTE
          drugRegimeInput["dsDose"] = data.DOSE
          drugRegimeInput["dsQuantity"] = data.QUANTITY
          drugRegimeInput["dsPRN"] = data.PRN

        }
        else if (data.SRC.toUpperCase() == 'INPATIENT' && data.SRC == sourceData.SRC) {
          drugRegimeInput["dsNDC"] = sourceData.NDC
          drugRegimeInput["dsFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["dslastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["dsDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["dsInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["dsUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["dsStrength"] = sourceData.STRENGTH
          drugRegimeInput["dsRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["dsTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["dsWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["dsprescriptionEnddate"] = ""
          drugRegimeInput["dsprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["dsprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["dsprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["dsPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["dsPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["dsPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["dsDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["dsDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["dsDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["dsForm"] = sourceData.FORM
          drugRegimeInput["dsRoute"] = sourceData.ROUTE
          drugRegimeInput["dsDose"] = sourceData.DOSE
          drugRegimeInput["dsQuantity"] = sourceData.QUANTITY
          drugRegimeInput["dsPRN"] = sourceData.PRN

        }
        else if (data.SRC.toUpperCase() == 'MEMBER REPORTED' && data.SRC != sourceData.SRC) {

          drugRegimeInput["patNDC"] = data.NDC
          drugRegimeInput["patFrequency"] = data.FREQUENCY
          drugRegimeInput["patlastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["patDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["patInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["patUnits"] = data.UNITOFMEASURE
          drugRegimeInput["patStrength"] = data.STRENGTH
          drugRegimeInput["patRefilNbr"] = data.REFILLNBR
          drugRegimeInput["patTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["patWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["patprescriptionEnddate"] = ""
          drugRegimeInput["patprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["patprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["patprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["patPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["patPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["patPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["patDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["patDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["patDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["patForm"] = data.FORM
          drugRegimeInput["patRoute"] = data.ROUTE
          drugRegimeInput["patDose"] = data.DOSE
          drugRegimeInput["patQuantity"] = data.QUANTITY
          drugRegimeInput["patPRN"] = data.PRN


        }
        else if (data.SRC.toUpperCase() == 'MEMBER REPORTED' && data.SRC == sourceData.SRC) {

          drugRegimeInput["patNDC"] = sourceData.NDC
          drugRegimeInput["patFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["patlastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["patDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["patInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["patUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["patStrength"] = sourceData.STRENGTH
          drugRegimeInput["patRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["patTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["patWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["patprescriptionEnddate"] = ""
          drugRegimeInput["patprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["patprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["patprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["patPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["patPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["patPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["patDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["patDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["patDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["patForm"] = sourceData.FORM
          drugRegimeInput["patRoute"] = sourceData.ROUTE
          drugRegimeInput["patDose"] = sourceData.DOSE
          drugRegimeInput["patQuantity"] = sourceData.QUANTITY
          drugRegimeInput["patPRN"] = sourceData.PRN


        }
        if (drugRegimeInput["patTakingMed"] == 'No' && data.ISPATIENTTAKINGMED == 'No') {
          drugRegimeInput["patTakingMed"] = 'No'

        }
        else {
          drugRegimeInput["patTakingMed"] = 'yes'

        }
        drugRegimeInput['claimIsManual'] = 'N'
        drugRegimeInput['ehrIsManual'] = 'N'
        drugRegimeInput['ssIsManual'] = 'N'
        drugRegimeInput['snfIsManual'] = 'Y'
        drugRegimeInput['dsIsManual'] = 'Y'
        drugRegimeInput['patIsManual'] = 'Y'

      }
    })
    let flag = 0;

    this.patientDataService.getMedregimenws(localStorage.getItem("encounterId")).subscribe(data => {

      this.serviceData = data

      this.serviceData.forEach(element => {
        if (element.gpi == sourceData.GPI) {
          flag = 1;



        }

      });

      if (!flag) {

        this.addRegimenSelected(drugRegimeInput);



      }



      else {


        this.updateRegimenSeelcted(drugRegimeInput);



      }
    })


  }

  parseHistoryDataForRegimeSelected(sourceData, historyData) {

    console.log(historyData)

    let drugRegimeInput = {};
    drugRegimeInput["encounterId"] = localStorage.getItem('encounterId')
    drugRegimeInput["prescriptionId"] = localStorage.getItem('mberRefNo') + "-" + sourceData.GPI + "-" + getDateTime()
    drugRegimeInput["mberRefNo"] = localStorage.getItem('mberRefNo')
    drugRegimeInput["recordId"] = 123
    drugRegimeInput["mrn"] = sourceData.MRN
    drugRegimeInput["firstName"] = sourceData.FIRSTNAME
    drugRegimeInput["lastName"] = sourceData.LASTNAME
    drugRegimeInput["dob"] = sourceData.DOB
    drugRegimeInput["gender"] = sourceData.GENDER
    drugRegimeInput["medication"] = sourceData.DRUGNAME
    drugRegimeInput["drugCategory"] = sourceData.DRUGCATEGORY
    drugRegimeInput["gpi"] = sourceData.GPI
    drugRegimeInput["patTakingMed"] = sourceData.ISPATIENTTAKINGMED
    historyData.forEach(data => {
      if (sourceData.GPI == data.GPI) {
        if (data.SRC.toUpperCase() == 'CLAIMS' && data.SRC != sourceData.SRC) {
          drugRegimeInput["claimNDC"] = data.NDC
          drugRegimeInput["claimFrequency"] = data.FREQUENCY
          drugRegimeInput["claimlastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["claimDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["claimInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["claimUnits"] = data.UNITOFMEASURE
          drugRegimeInput["claimStrength"] = data.STRENGTH
          drugRegimeInput["claimRefilNbr"] = data.REFILLNBR
          drugRegimeInput["claimTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["claimWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["claimprescriptionEnddat"] = ""
          drugRegimeInput["claimstatus"] = data.CLAIMSTATUS
          drugRegimeInput["claimprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["claimprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["claimprescriberSpecialiy"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["claimPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["claimPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["claimPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["claimDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["claimDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["claimDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["claimForm"] = data.FORM
          drugRegimeInput["claimRoute"] = data.ROUTE
          drugRegimeInput["claimDose"] = data.DOSE
          drugRegimeInput["claimQuantity"] = data.QUANTITY
          drugRegimeInput["claimPRN"] = data.PRN
          drugRegimeInput["claimIsManual"] = "N"

        }
        if (data.SRC.toUpperCase() == 'CLAIMS' && data.SRC == sourceData.SRC) {
          drugRegimeInput["claimNDC"] = sourceData.NDC
          drugRegimeInput["claimFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["claimlastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["claimDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["claimInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["claimUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["claimStrength"] = sourceData.STRENGTH
          drugRegimeInput["claimRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["claimTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["claimWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["claimprescriptionEnddat"] = ""
          drugRegimeInput["claimstatus"] = sourceData.CLAIMSTATUS
          drugRegimeInput["claimprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["claimprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["claimprescriberSpecialiy"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["claimPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["claimPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["claimPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["claimDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["claimDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["claimDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["claimForm"] = sourceData.FORM
          drugRegimeInput["claimRoute"] = sourceData.ROUTE
          drugRegimeInput["claimDose"] = sourceData.DOSE
          drugRegimeInput["claimQuantity"] = sourceData.QUANTITY
          drugRegimeInput["claimPRN"] = sourceData.PRN
          drugRegimeInput["claimIsManual"] = "N"

        }
        else if (data.SRC.toUpperCase() == 'POS' && data.SRC != sourceData.SRC) {

          drugRegimeInput["ssNDC"] = data.NDC
          drugRegimeInput["ssFrequency"] = data.FREQUENCY
          drugRegimeInput["sslastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["ssDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["ssInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["ssUnits"] = data.UNITOFMEASURE
          drugRegimeInput["ssStrength"] = data.STRENGTH
          drugRegimeInput["ssRefilNbr"] = data.REFILLNBR
          drugRegimeInput["ssTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["ssWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["ssprescriptionEnddate"] = ""
          drugRegimeInput["ssprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["ssprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["ssprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["ssPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["ssPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["ssPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["ssDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["ssDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["ssDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["ssForm"] = data.FORM
          drugRegimeInput["ssRoute"] = data.ROUTE
          drugRegimeInput["ssDose"] = data.DOSE
          drugRegimeInput["ssQuantity"] = data.QUANTITY
          drugRegimeInput["ssPRN"] = data.PRN
          drugRegimeInput["ssIsManual"] = "N"
          drugRegimeInput["ssPickedUpdt"] = data.PICKEDUP_DATE


        }
        else if (data.SRC.toUpperCase() == 'POS' && data.SRC == sourceData.SRC) {

          drugRegimeInput["ssNDC"] = sourceData.NDC
          drugRegimeInput["ssFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["sslastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["ssDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["ssInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["ssUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["ssStrength"] = sourceData.STRENGTH
          drugRegimeInput["ssRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["ssTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["ssWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["ssprescriptionEnddate"] = ""
          drugRegimeInput["ssprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["ssprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["ssprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["ssPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["ssPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["ssPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["ssDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["ssDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["ssDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["ssForm"] = sourceData.FORM
          drugRegimeInput["ssRoute"] = sourceData.ROUTE
          drugRegimeInput["ssDose"] = sourceData.DOSE
          drugRegimeInput["ssQuantity"] = sourceData.QUANTITY
          drugRegimeInput["ssPRN"] = sourceData.PRN
          drugRegimeInput["ssIsManual"] = "N"
          drugRegimeInput["ssPickedUpdt"] = sourceData.PICKEDUP_DATE


        }
        else if (data.SRC.toUpperCase() == 'EHR' && data.SRC != sourceData.SRC) {
          drugRegimeInput["ehrNDC"] = data.NDC
          drugRegimeInput["ehrFrequency"] = data.FREQUENCY
          drugRegimeInput["ehrlastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["ehrDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["ehrInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["ehrUnits"] = data.UNITOFMEASURE
          drugRegimeInput["ehrStrength"] = data.STRENGTH
          drugRegimeInput["ehrRefilNbr"] = data.REFILLNBR
          drugRegimeInput["ehrTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["ehrWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["ehrprescriptionEnddate"] = ""
          drugRegimeInput["ehrprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["ehrprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["ehrprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["ehrPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["ehrPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["ehrPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["ehrDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["ehrDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["ehrDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["ehrForm"] = data.FORM
          drugRegimeInput["ehrRoute"] = data.ROUTE
          drugRegimeInput["ehrDose"] = data.DOSE
          drugRegimeInput["ehrQuantity"] = data.QUANTITY
          drugRegimeInput["ehrPRN"] = data.PRN
          drugRegimeInput["ehrIsManual"] = "N"

        }
        else if (data.SRC.toUpperCase() == 'EHR' && data.SRC == sourceData.SRC) {
          drugRegimeInput["ehrNDC"] = sourceData.NDC
          drugRegimeInput["ehrFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["ehrlastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["ehrDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["ehrInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["ehrUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["ehrStrength"] = sourceData.STRENGTH
          drugRegimeInput["ehrRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["ehrTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["ehrWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["ehrprescriptionEnddate"] = ""
          drugRegimeInput["ehrprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["ehrprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["ehrprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["ehrPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["ehrPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["ehrPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["ehrDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["ehrDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["ehrDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["ehrForm"] = sourceData.FORM
          drugRegimeInput["ehrRoute"] = sourceData.ROUTE
          drugRegimeInput["ehrDose"] = sourceData.DOSE
          drugRegimeInput["ehrQuantity"] = sourceData.QUANTITY
          drugRegimeInput["ehrPRN"] = sourceData.PRN
          drugRegimeInput["ehrIsManual"] = "N"

        }
        else if (data.SRC.toUpperCase() == 'SNF' && data.SRC != sourceData.SRC) {
          drugRegimeInput["snfNDC"] = data.NDC
          drugRegimeInput["snfFrequency"] = data.FREQUENCY
          drugRegimeInput["snflastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["snfDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["snfInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["snfUnits"] = data.UNITOFMEASURE
          drugRegimeInput["snfStrength"] = data.STRENGTH
          drugRegimeInput["snfRefilNbr"] = data.REFILLNBR
          drugRegimeInput["snfTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["snfWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["snfprescriptionEnddate"] = ""
          drugRegimeInput["snfprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["snfprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["snfprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["snfPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["snfPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["snfPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["snfDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["snfDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["snfDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["snfForm"] = data.FORM
          drugRegimeInput["snfRoute"] = data.ROUTE
          drugRegimeInput["snfDose"] = data.DOSE
          drugRegimeInput["snfQuantity"] = data.QUANTITY
          drugRegimeInput["snfPRN"] = data.PRN
          drugRegimeInput["snfIsManual"] = "Y"

        }
        else if (data.SRC.toUpperCase() == 'SNF' && sourceData.SRC == data.SRC) {
          drugRegimeInput["snfNDC"] = sourceData.NDC
          drugRegimeInput["snfFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["snflastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["snfDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["snfInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["snfUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["snfStrength"] = sourceData.STRENGTH
          drugRegimeInput["snfRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["snfTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["snfWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["snfprescriptionEnddate"] = ""
          drugRegimeInput["snfprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["snfprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["snfprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["snfPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["snfPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["snfPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["snfDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["snfDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["snfDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["snfForm"] = sourceData.FORM
          drugRegimeInput["snfRoute"] = sourceData.ROUTE
          drugRegimeInput["snfDose"] = sourceData.DOSE
          drugRegimeInput["snfQuantity"] = sourceData.QUANTITY
          drugRegimeInput["snfPRN"] = sourceData.PRN
          drugRegimeInput["snfIsManual"] = "Y"

        }
        else if (data.SRC.toUpperCase() == 'INPATIENT' && sourceData.SRC != data.SRC) {
          drugRegimeInput["dsNDC"] = data.NDC
          drugRegimeInput["dsFrequency"] = data.FREQUENCY
          drugRegimeInput["dslastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["dsDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["dsInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["dsUnits"] = data.UNITOFMEASURE
          drugRegimeInput["dsStrength"] = data.STRENGTH
          drugRegimeInput["dsRefilNbr"] = data.REFILLNBR
          drugRegimeInput["dsTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["dsWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["dsprescriptionEnddate"] = ""
          drugRegimeInput["dsprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["dsprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["dsprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["dsPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["dsPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["dsPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["dsDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["dsDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["dsDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["dsForm"] = data.FORM
          drugRegimeInput["dsRoute"] = data.ROUTE
          drugRegimeInput["dsDose"] = data.DOSE
          drugRegimeInput["dsQuantity"] = data.QUANTITY
          drugRegimeInput["dsPRN"] = data.PRN
          drugRegimeInput["dsIsManual"] = "Y"


        }
        else if (data.SRC.toUpperCase() == 'INPATIENT' && data.SRC == sourceData.SRC) {
          drugRegimeInput["dsNDC"] = sourceData.NDC
          drugRegimeInput["dsFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["dslastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["dsDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["dsInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["dsUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["dsStrength"] = sourceData.STRENGTH
          drugRegimeInput["dsRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["dsTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["dsWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["dsprescriptionEnddate"] = ""
          drugRegimeInput["dsprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["dsprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["dsprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["dsPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["dsPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["dsPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["dsDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["dsDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["dsDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["dsForm"] = sourceData.FORM
          drugRegimeInput["dsRoute"] = sourceData.ROUTE
          drugRegimeInput["dsDose"] = sourceData.DOSE
          drugRegimeInput["dsQuantity"] = sourceData.QUANTITY
          drugRegimeInput["dsPRN"] = sourceData.PRN
          drugRegimeInput["dsIsManual"] = "Y"


        }
        else if (data.SRC.toUpperCase() == 'MEMBER REPORTED' && data.SRC != sourceData.SRC) {

          drugRegimeInput["patNDC"] = data.NDC
          drugRegimeInput["patFrequency"] = data.FREQUENCY
          drugRegimeInput["patlastFilledDate"] = data.LASTFILLEDDATE
          drugRegimeInput["patDaysOfSupply"] = data.DAYSSUPPLY
          drugRegimeInput["patInstructions"] = data.INSTRUCTIONS
          drugRegimeInput["patUnits"] = data.UNITOFMEASURE
          drugRegimeInput["patStrength"] = data.STRENGTH
          drugRegimeInput["patRefilNbr"] = data.REFILLNBR
          drugRegimeInput["patTotalRefill"] = data.TOTALREFILLS
          drugRegimeInput["patWrittenDate"] = data.WRITTENDATE
          drugRegimeInput["patprescriptionEnddate"] = ""
          drugRegimeInput["patprescriberName"] = data.PRESCRIBERNAME
          drugRegimeInput["patprescriberNPI"] = data.PRESCRIBERNPI
          drugRegimeInput["patprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
          drugRegimeInput["patPharmacyName"] = data.PHARMACYNAME
          drugRegimeInput["patPharmacyNPI"] = data.PHARMACYNPI
          drugRegimeInput["patPharmacyContact"] = data.PHARMACYCONTACT
          drugRegimeInput["patDiagnosis"] = data.DIAGNOSIS
          drugRegimeInput["patDiagnosisDesc"] = data.DIAGNOSISDESC
          drugRegimeInput["patDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
          drugRegimeInput["patForm"] = data.FORM
          drugRegimeInput["patRoute"] = data.ROUTE
          drugRegimeInput["patDose"] = data.DOSE
          drugRegimeInput["patQuantity"] = data.QUANTITY
          drugRegimeInput["patPRN"] = data.PRN
          drugRegimeInput["patIsManual"] = "Y"



        }
        else if (data.SRC.toUpperCase() == 'MEMBER REPORTED' && data.SRC == sourceData.SRC) {

          drugRegimeInput["patNDC"] = sourceData.NDC
          drugRegimeInput["patFrequency"] = sourceData.FREQUENCY
          drugRegimeInput["patlastFilledDate"] = sourceData.LASTFILLEDDATE
          drugRegimeInput["patDaysOfSupply"] = sourceData.DAYSSUPPLY
          drugRegimeInput["patInstructions"] = sourceData.INSTRUCTIONS
          drugRegimeInput["patUnits"] = sourceData.UNITOFMEASURE
          drugRegimeInput["patStrength"] = sourceData.STRENGTH
          drugRegimeInput["patRefilNbr"] = sourceData.REFILLNBR
          drugRegimeInput["patTotalRefill"] = sourceData.TOTALREFILLS
          drugRegimeInput["patWrittenDate"] = sourceData.WRITTENDATE
          drugRegimeInput["patprescriptionEnddate"] = ""
          drugRegimeInput["patprescriberName"] = sourceData.PRESCRIBERNAME
          drugRegimeInput["patprescriberNPI"] = sourceData.PRESCRIBERNPI
          drugRegimeInput["patprescriberSpeciality"] = sourceData.PRESCRIBERSPECIALTY
          drugRegimeInput["patPharmacyName"] = sourceData.PHARMACYNAME
          drugRegimeInput["patPharmacyNPI"] = sourceData.PHARMACYNPI
          drugRegimeInput["patPharmacyContact"] = sourceData.PHARMACYCONTACT
          drugRegimeInput["patDiagnosis"] = sourceData.DIAGNOSIS
          drugRegimeInput["patDiagnosisDesc"] = sourceData.DIAGNOSISDESC
          drugRegimeInput["patDiagnosisEffectDt"] = sourceData.DIAGNOSISEFFECTDT
          drugRegimeInput["patForm"] = sourceData.FORM
          drugRegimeInput["patRoute"] = sourceData.ROUTE
          drugRegimeInput["patDose"] = sourceData.DOSE
          drugRegimeInput["patQuantity"] = sourceData.QUANTITY
          drugRegimeInput["patPRN"] = sourceData.PRN
          drugRegimeInput["patIsManual"] = "Y"




        }
        drugRegimeInput['claimIsManual'] = 'N'
        drugRegimeInput['ehrIsManual'] = 'N'
        drugRegimeInput['ssIsManual'] = 'N'
        drugRegimeInput['snfIsManual'] = 'Y'
        drugRegimeInput['dsIsManual'] = 'Y'
        drugRegimeInput['patIsManual'] = 'Y'
        if (drugRegimeInput["patTakingMed"] == 'No' && data.ISPATIENTTAKINGMED == 'No') {
          drugRegimeInput["patTakingMed"] = 'No'

        }
        else {
          drugRegimeInput["patTakingMed"] = 'yes'

        }

      }
    })
    let flag = 0;

    this.patientDataService.getMedregimenws(localStorage.getItem("encounterId")).subscribe(data => {
      this.serviceData = data

      this.serviceData.forEach(element => {
        if (element.gpi == sourceData.GPI) {
          flag = 1;



        }

      });

      if (!flag) {

        this.addRegimenSelected(drugRegimeInput);



      }



      else {


        this.updateRegimenSeelcted(drugRegimeInput);



      }
    })


  }
  clearMedRegimen() {
    this.callDrugRegimenApi()
  }
  updateMedRegimen(data, src) {
    console.log("sd")
    this.isDropDown = false
    let techShort = []
    data.techCheck.forEach(element => {
      techShort.push(element[0])
    })
    console.log("updateRegime", techShort)
    let obj = {
      "rphCheck": data.rphCheck,
      "techCheck": techShort.join(''),
      "medication": data.medication,
      "patComments": data.patComments,
      "providerComments": data.providerComments,
      "reviewerComments": data.reviewerComments,
      "medDecision": data.medDecision,
      "encounterId": localStorage.getItem('encounterId'),
      "reviewBy": this.reviewBy,
      "patTakingMed": data.patTakingMed,
      "gpi": data.gpi


    }
    this.isSpinner = true
    let arr = [];
    arr.push(obj)

    this.patientDataService.updateMedReview(arr).subscribe(data => {

      this.toastr.success('Saved To Drug Regimen', '', {
        timeOut: 1500,
      })
      this.callDrugRegimenApi()
      this.isSpinner = true
      this.anythingChanged = false
      this.anythingChangedRec = false
      this.anythingChangedRev = false

    }, error => {
      this.isSpinner = false



    });



  }

  onChangeProvComments(data, element) {
    this.updatedProvComments = data
    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);
    this.dataSource1.filteredData[idx].providerComments = data;
    this.dataSource1.filteredData[idx].isedited = 'yes';
    this.anythingChanged = true
  }
  onChangereviewerComments(data, element) {
    this.updatedReviewerComments = data
    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);
    this.dataSource1.filteredData[idx].reviewerComments = data;
    this.dataSource1.filteredData[idx].isedited = 'yes';
    this.anythingChanged = true

  }
  onChangeRphCheck(data, element) {
    debugger
    this.updatedrph = data
    this.reviewBy = localStorage.getItem('usname')
    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);
    this.dataSource1.filteredData[idx].rphCheck = data;
    this.dataSource1.filteredData[idx].isedited = 'yes';
    let finalReviewData=this.getFinalReview(element)
    this.dataSource1.filteredData[idx].finalReviews = finalReviewData
    this.dataSource1.filteredData[idx].medDecision = this.getMedDecision(finalReviewData)
    this.anythingChanged = true
    this.anythingChangedRev = true
  }
  getFinalReview(e) {
    
    let finalreview = []
  if (e['claimDose'] != "" || e['claimForm'] != "" || e['claimRoute'] != "" || e['claimFrequency'] != "" || e['claimInstructions'] != "") {
    finalreview.push('Claims')
  }
  if (e['ssDose'] != "" || e['ssForm'] != "" || e['ssRoute'] != "" || e['ssFrequency'] != "" || e['ssInstructions'] != "") {
    finalreview.push('POS')
  }
  if (e['dsDose'] != "" || e['dsForm'] != "" || e['dsRoute'] != "" ||  e['dsFrequency'] != "" || e['dsInstructions'] != "") {
    finalreview.push('Inpatient')
  }
  if (e['snfDose'] != "" || e['snfForm'] != "" || e['snfRoute'] != ""  || e['snfFrequency'] != "" || e['snfInstructions'] != "") {
    finalreview.push('SNF')
  }
  if (e['ehrDose'] != "" || e['ehrForm'] != "" || e['ehrRoute'] != "" || e['ehrFrequency'] != "" || e['ehrInstructions'] !="") {
    finalreview.push('EHR')
  }
  if (e['patDose'] != "" || e['patForm'] != "" || e['patRoute'] != "" || e['patFrequency'] != "" || e['patInstructions'] != "") {
    finalreview.push('Member Reported')
  }
  return finalreview

}
getMedDecision(e){
  debugger
  let medDecision = ''
  
  if(e.includes('Member Reported')){
    medDecision = 'Member Reported'
  }
  else  if(e.includes('SNF')){
    medDecision = 'SNF'
  }
  else if(e.includes('Inpatient')){
    medDecision = 'Inpatient'
  }
  else if(e.includes('POS')){
    medDecision = 'POS'
  }
  else if(e.includes('Claims')){
    medDecision = 'Claims'
  }
  
  else if(e.includes('EHR')){
    medDecision = 'EHR'
  }
  
  return medDecision
}
  onChangeTechCheck(data, element) {
    console.log(data, element)
    element.isOpen = true
    this.updatedTech = data
    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);
    this.dataSource1.filteredData[idx].techCheck = data.value;
    this.dataSource1.filteredData[idx].isedited = 'yes';
    console.log(this.dataSource1.filteredData[idx])
    this.anythingChanged = true
    this.anythingChangedRec = true

  }
  onChangeMedDec(data, element) {


    this.updateMedSrc = data
    this.oldMedSrc = element.medDecision
    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);
    this.dataSource1.filteredData[idx].medDecision = data;
    this.dataSource1.filteredData[idx].isedited = 'yes';
    this.anythingChanged = true
    this.anythingChangedRev = true

  }
  onChangePatComments(data, element) {

    console.log(this.dataSource1.filteredData)

    this.updatedPatComments = data

    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);

    this.dataSource1.filteredData[idx].patComments = data;
    this.dataSource1.filteredData[idx].isedited = 'yes';
    this.anythingChanged = true



  }
  onMedTakingChange(data, element) {

    const idx = this.dataSource1.filteredData.findIndex((x) => x.gpi === element.gpi);

    if (data.checked) {
      this.updatedIsTakingMed = 'Yes'


    }
    else {
      this.updatedIsTakingMed = 'No'

    }
    this.dataSource1.filteredData[idx].isedited = 'yes';
    this.dataSource1.filteredData[idx].patTakingMed = this.updatedIsTakingMed;
    this.anythingChanged = true




  }
  onChangeSelected(data, element) {
    this.isAll1 = false
    const idx = this.dataSourceHistory.filteredData.indexOf(element)
    if (data.checked) {
      this.dataSourceHistory.filteredData[idx].isedited = 'yes';



    }
    else {
      this.dataSourceHistory.filteredData[idx].isedited = 'no';


    }




  }
  onChangeSelectedAll(data, element) {

    this.isAll = false
    console.log("selected all")

    if (data.checked) {
      this.isAll = true
    }
    else {
      this.isAll = false
    }

  }
  async addRegimenSelected(input) {
    this.patientDataService.addDrugRegime(input).subscribe(data => {

      this.callDrugRegimenApi();
    }, error => {

      this.toastr.error("Some Error Occured In Exporting To Drug Regimen", '', {
        timeOut: 1500,
      })


    })

  }
  async updateRegimenSeelcted(input) {


    this.patientDataService.updateDrugRegime(input).subscribe(data => {
      this.callDrugRegimenApi()


    }, error => {
      this.toastr.error("Some Issue In Updating Med Regimen", '', {
        timeOut: 1500,
      })


    })

  }

  async exportSelected() {
    debugger

    let processedGPI = [];
    if (this.isAll && this.viewAll) {
      this.dataSourceHistory.filteredData.forEach(element => {
 if (element.isedited != 'no' && !processedGPI.includes(element.GPI)) {

      let sourceData = element;
          let historyData = this.dataSourceHistory.filteredData.sort(this.sortByDate);
        let drugRegimeInput = {};
          drugRegimeInput["encounterId"] = localStorage.getItem('encounterId')
          drugRegimeInput["prescriptionId"] = localStorage.getItem('mberRefNo') + "-" + sourceData.GPI + "-" + getDateTime()
          drugRegimeInput["mbrRefNo"] = localStorage.getItem('mberRefNo')
          drugRegimeInput["recordId"] = 123
          drugRegimeInput["mrn"] = sourceData.MRN
          drugRegimeInput["firstName"] = sourceData.FIRSTNAME
          drugRegimeInput["lastName"] = sourceData.LASTNAME
          drugRegimeInput["dob"] = sourceData.DOB
          drugRegimeInput["gender"] = sourceData.GENDER
          drugRegimeInput["medication"] = sourceData.DRUGNAME
          drugRegimeInput["drugCategory"] = sourceData.DRUGCATEGORY
          drugRegimeInput["gpi"] = sourceData.GPI
          drugRegimeInput["patTakingMed"] = sourceData.ISPATIENTTAKINGMED
          processedGPI.push(sourceData.GPI)

          let gpiHistoryObject = this.getMatchedGPISource(sourceData.GPI, sourceData.SRC, sourceData.SUBSRC)
          if (!gpiHistoryObject.filteredSource.includes(sourceData.SRC)) {
            gpiHistoryObject.filteredData.push(sourceData)
            gpiHistoryObject.filteredSource.push(sourceData.SRC)
          }
 gpiHistoryObject.filteredData.forEach(data => {

            if (sourceData.GPI == data.GPI) {
              if (gpiHistoryObject.filteredSource.includes('Claims') && data.SRC == 'Claims') {
                drugRegimeInput["claimNDC"] = data.NDC
                drugRegimeInput["claimFrequency"] = data.FREQUENCY
                drugRegimeInput["claimlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["claimDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["claimInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["claimUnits"] = data.UNITOFMEASURE
                drugRegimeInput["claimStrength"] = data.STRENGTH
                drugRegimeInput["claimRefilNbr"] = data.REFILLNBR
                drugRegimeInput["claimTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["claimWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["claimprescriptionEnddat"] = ""
                drugRegimeInput["claimstatus"] = data.CLAIMSTATUS
                drugRegimeInput["claimprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["claimprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["claimprescriberSpecialiy"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["claimPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["claimPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["claimPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["claimDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["claimDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["claimDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["claimForm"] = data.FORM
                drugRegimeInput["claimRoute"] = data.ROUTE
                drugRegimeInput["claimDose"] = data.DOSE
                drugRegimeInput["claimQuantity"] = data.QUANTITY
                drugRegimeInput["claimPRN"] = data.PRN
                drugRegimeInput["claimIsManual"] = data.ISMANUAL




              }
             
              else if (gpiHistoryObject.filteredSource.includes('POS') && data.SRC == 'POS') {

                drugRegimeInput["ssNDC"] = data.NDC
                drugRegimeInput["ssFrequency"] = data.FREQUENCY
                drugRegimeInput["sslastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["ssDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["ssInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["ssUnits"] = data.UNITOFMEASURE
                drugRegimeInput["ssStrength"] = data.STRENGTH
                drugRegimeInput["ssRefilNbr"] = data.REFILLNBR
                drugRegimeInput["ssTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["ssWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["ssprescriptionEnddate"] = ""
                drugRegimeInput["ssprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["ssprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["ssprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["ssPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["ssPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["ssPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["ssDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["ssDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["ssDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["ssForm"] = data.FORM
                drugRegimeInput["ssRoute"] = data.ROUTE
                drugRegimeInput["ssDose"] = data.DOSE
                drugRegimeInput["ssQuantity"] = data.QUANTITY
                drugRegimeInput["ssPRN"] = data.PRN
                drugRegimeInput["ssIsManual"] = data.ISMANUAL
                drugRegimeInput["ssPickedUpdt"] = data.PICKEDUP_DATE



              }
             
              else if (gpiHistoryObject.filteredSource.includes('EHR') && data.SRC == 'EHR') {
                drugRegimeInput["ehrNDC"] = data.NDC
                drugRegimeInput["ehrFrequency"] = data.FREQUENCY
                drugRegimeInput["ehrlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["ehrDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["ehrInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["ehrUnits"] = data.UNITOFMEASURE
                drugRegimeInput["ehrStrength"] = data.STRENGTH
                drugRegimeInput["ehrRefilNbr"] = data.REFILLNBR
                drugRegimeInput["ehrTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["ehrWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["ehrprescriptionEnddate"] = ""
                drugRegimeInput["ehrprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["ehrprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["ehrprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["ehrPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["ehrPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["ehrPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["ehrDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["ehrDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["ehrDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["ehrForm"] = data.FORM
                drugRegimeInput["ehrRoute"] = data.ROUTE
                drugRegimeInput["ehrDose"] = data.DOSE
                drugRegimeInput["ehrQuantity"] = data.QUANTITY
                drugRegimeInput["ehrPRN"] = data.PRN
                drugRegimeInput["ehrIsManual"] = data.ISMANUAL


              }
              
              else if (gpiHistoryObject.filteredSource.includes('SNF') && data.SRC == 'SNF') {
                drugRegimeInput["snfNDC"] = data.NDC
                drugRegimeInput["snfFrequency"] = data.FREQUENCY
                drugRegimeInput["snflastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["snfDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["snfInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["snfUnits"] = data.UNITOFMEASURE
                drugRegimeInput["snfStrength"] = data.STRENGTH
                drugRegimeInput["snfRefilNbr"] = data.REFILLNBR
                drugRegimeInput["snfTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["snfWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["snfprescriptionEnddate"] = ""
                drugRegimeInput["snfprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["snfprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["snfprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["snfPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["snfPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["snfPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["snfDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["snfDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["snfDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["snfForm"] = data.FORM
                drugRegimeInput["snfRoute"] = data.ROUTE
                drugRegimeInput["snfDose"] = data.DOSE
                drugRegimeInput["snfQuantity"] = data.QUANTITY
                drugRegimeInput["snfPRN"] = data.PRN
                drugRegimeInput["snfIsManual"] = data.ISMANUAL


              }
             
              else if (gpiHistoryObject.filteredSource.includes('Inpatient') && data.SRC == 'Inpatient') {
                drugRegimeInput["dsNDC"] = data.NDC
                drugRegimeInput["dsFrequency"] = data.FREQUENCY
                drugRegimeInput["dslastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["dsDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["dsInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["dsUnits"] = data.UNITOFMEASURE
                drugRegimeInput["dsStrength"] = data.STRENGTH
                drugRegimeInput["dsRefilNbr"] = data.REFILLNBR
                drugRegimeInput["dsTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["dsWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["dsprescriptionEnddate"] = ""
                drugRegimeInput["dsprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["dsprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["dsprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["dsPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["dsPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["dsPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["dsDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["dsDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["dsDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["dsForm"] = data.FORM
                drugRegimeInput["dsRoute"] = data.ROUTE
                drugRegimeInput["dsDose"] = data.DOSE
                drugRegimeInput["dsQuantity"] = data.QUANTITY
                drugRegimeInput["dsPRN"] = data.PRN
                drugRegimeInput["dsIsManual"] = data.ISMANUAL


              }
             
              else if (gpiHistoryObject.filteredSource.includes('Member Reported') && data.SRC == 'Member Reported') {

                drugRegimeInput["patNDC"] = data.NDC
                drugRegimeInput["patFrequency"] = data.FREQUENCY
                drugRegimeInput["patlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["patDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["patInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["patUnits"] = data.UNITOFMEASURE
                drugRegimeInput["patStrength"] = data.STRENGTH
                drugRegimeInput["patRefilNbr"] = data.REFILLNBR
                drugRegimeInput["patTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["patWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["patprescriptionEnddate"] = ""
                drugRegimeInput["patprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["patprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["patprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["patPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["patPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["patPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["patDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["patDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["patDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["patForm"] = data.FORM
                drugRegimeInput["patRoute"] = data.ROUTE
                drugRegimeInput["patDose"] = data.DOSE
                drugRegimeInput["patQuantity"] = data.QUANTITY
                drugRegimeInput["patPRN"] = data.PRN
                drugRegimeInput["patIsManual"] = data.ISMANUAL



              }
             
              drugRegimeInput['claimIsManual'] = 'N'
              drugRegimeInput['ehrIsManual'] = 'N'
              drugRegimeInput['ssIsManual'] = 'N'
              drugRegimeInput['snfIsManual'] = 'Y'
              drugRegimeInput['dsIsManual'] = 'Y'
              drugRegimeInput['patIsManual'] = 'Y'

              if (drugRegimeInput["patTakingMed"] == 'No' && data.ISPATIENTTAKINGMED == 'No') {
                drugRegimeInput["patTakingMed"] = 'No'

              }
              else {
                drugRegimeInput["patTakingMed"] = 'Yes'

              }

            }
          })
          let flag = 0;
          console.log("saved", drugRegimeInput)
          this.patientDataService.getMedregimenws(localStorage.getItem("encounterId")).subscribe(async data => {
            this.serviceData = data

            this.serviceData.forEach(element => {
              if (element.gpi == sourceData.GPI) {
                flag = 1;



              }

            });

            if (!flag) {

              await this.addRegimenSelected(drugRegimeInput);
}



            else {


              await this.updateRegimenSeelcted(drugRegimeInput);



            }
          })


        }


      });
    }
    else if (this.isAll && !this.viewAll) {
      this.dataSourceHistory.filteredData.forEach(element => {





        if (element.ACTIVE_FLAG == 'Y' && element.isedited != 'no' && !processedGPI.includes(element.GPI)) {




          let sourceData = element;
          let historyData = this.dataSourceHistory.filteredData.sort(this.sortByDate);



          let drugRegimeInput = {};
          drugRegimeInput["encounterId"] = localStorage.getItem('encounterId')
          drugRegimeInput["prescriptionId"] = localStorage.getItem('mberRefNo') + "-" + sourceData.GPI + "-" + getDateTime()
          drugRegimeInput["mbrRefNo"] = localStorage.getItem('mberRefNo')
          drugRegimeInput["recordId"] = 123
          drugRegimeInput["mrn"] = sourceData.MRN
          drugRegimeInput["firstName"] = sourceData.FIRSTNAME
          drugRegimeInput["lastName"] = sourceData.LASTNAME
          drugRegimeInput["dob"] = sourceData.DOB
          drugRegimeInput["gender"] = sourceData.GENDER
          drugRegimeInput["medication"] = sourceData.DRUGNAME
          drugRegimeInput["drugCategory"] = sourceData.DRUGCATEGORY
          drugRegimeInput["gpi"] = sourceData.GPI
          drugRegimeInput["patTakingMed"] = sourceData.ISPATIENTTAKINGMED
          processedGPI.push(sourceData.GPI)


          let gpiHistoryObject = this.getMatchedGPISource(sourceData.GPI, sourceData.SRC, sourceData.SUBSRC)
          if (!gpiHistoryObject.filteredSource.includes(sourceData.SRC)) {
            gpiHistoryObject.filteredData.push(sourceData)
            gpiHistoryObject.filteredSource.push(sourceData.SRC)
          }



          gpiHistoryObject.filteredData.forEach(data => {

            if (sourceData.GPI == data.GPI) {
              if (gpiHistoryObject.filteredSource.includes('Claims') && data.SRC == 'Claims') {
                drugRegimeInput["claimNDC"] = data.NDC
                drugRegimeInput["claimFrequency"] = data.FREQUENCY
                drugRegimeInput["claimlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["claimDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["claimInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["claimUnits"] = data.UNITOFMEASURE
                drugRegimeInput["claimStrength"] = data.STRENGTH
                drugRegimeInput["claimRefilNbr"] = data.REFILLNBR
                drugRegimeInput["claimTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["claimWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["claimprescriptionEnddat"] = ""
                drugRegimeInput["claimstatus"] = data.CLAIMSTATUS
                drugRegimeInput["claimprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["claimprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["claimprescriberSpecialiy"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["claimPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["claimPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["claimPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["claimDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["claimDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["claimDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["claimForm"] = data.FORM
                drugRegimeInput["claimRoute"] = data.ROUTE
                drugRegimeInput["claimDose"] = data.DOSE
                drugRegimeInput["claimQuantity"] = data.QUANTITY
                drugRegimeInput["claimPRN"] = data.PRN
                drugRegimeInput["claimIsManual"] = data.ISMANUAL




              }
             
              else if (gpiHistoryObject.filteredSource.includes('POS') && data.SRC == 'POS') {

                drugRegimeInput["ssNDC"] = data.NDC
                drugRegimeInput["ssFrequency"] = data.FREQUENCY
                drugRegimeInput["sslastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["ssDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["ssInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["ssUnits"] = data.UNITOFMEASURE
                drugRegimeInput["ssStrength"] = data.STRENGTH
                drugRegimeInput["ssRefilNbr"] = data.REFILLNBR
                drugRegimeInput["ssTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["ssWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["ssprescriptionEnddate"] = ""
                drugRegimeInput["ssprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["ssprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["ssprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["ssPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["ssPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["ssPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["ssDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["ssDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["ssDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["ssForm"] = data.FORM
                drugRegimeInput["ssRoute"] = data.ROUTE
                drugRegimeInput["ssDose"] = data.DOSE
                drugRegimeInput["ssQuantity"] = data.QUANTITY
                drugRegimeInput["ssPRN"] = data.PRN
                drugRegimeInput["ssIsManual"] = data.ISMANUAL
                drugRegimeInput["ssPickedUpdt"] = data.PICKEDUP_DATE



              }
         
              else if (gpiHistoryObject.filteredSource.includes('EHR') && data.SRC == 'EHR') {
                drugRegimeInput["ehrNDC"] = data.NDC
                drugRegimeInput["ehrFrequency"] = data.FREQUENCY
                drugRegimeInput["ehrlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["ehrDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["ehrInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["ehrUnits"] = data.UNITOFMEASURE
                drugRegimeInput["ehrStrength"] = data.STRENGTH
                drugRegimeInput["ehrRefilNbr"] = data.REFILLNBR
                drugRegimeInput["ehrTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["ehrWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["ehrprescriptionEnddate"] = ""
                drugRegimeInput["ehrprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["ehrprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["ehrprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["ehrPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["ehrPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["ehrPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["ehrDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["ehrDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["ehrDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["ehrForm"] = data.FORM
                drugRegimeInput["ehrRoute"] = data.ROUTE
                drugRegimeInput["ehrDose"] = data.DOSE
                drugRegimeInput["ehrQuantity"] = data.QUANTITY
                drugRegimeInput["ehrPRN"] = data.PRN
                drugRegimeInput["ehrIsManual"] = data.ISMANUAL


              }
             
              else if (gpiHistoryObject.filteredSource.includes('SNF') && data.SRC == 'SNF') {
                drugRegimeInput["snfNDC"] = data.NDC
                drugRegimeInput["snfFrequency"] = data.FREQUENCY
                drugRegimeInput["snflastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["snfDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["snfInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["snfUnits"] = data.UNITOFMEASURE
                drugRegimeInput["snfStrength"] = data.STRENGTH
                drugRegimeInput["snfRefilNbr"] = data.REFILLNBR
                drugRegimeInput["snfTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["snfWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["snfprescriptionEnddate"] = ""
                drugRegimeInput["snfprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["snfprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["snfprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["snfPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["snfPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["snfPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["snfDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["snfDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["snfDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["snfForm"] = data.FORM
                drugRegimeInput["snfRoute"] = data.ROUTE
                drugRegimeInput["snfDose"] = data.DOSE
                drugRegimeInput["snfQuantity"] = data.QUANTITY
                drugRegimeInput["snfPRN"] = data.PRN
                drugRegimeInput["snfIsManual"] = data.ISMANUAL


              }
              
              else if (gpiHistoryObject.filteredSource.includes('Inpatient') && data.SRC == 'Inpatient') {
                drugRegimeInput["dsNDC"] = data.NDC
                drugRegimeInput["dsFrequency"] = data.FREQUENCY
                drugRegimeInput["dslastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["dsDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["dsInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["dsUnits"] = data.UNITOFMEASURE
                drugRegimeInput["dsStrength"] = data.STRENGTH
                drugRegimeInput["dsRefilNbr"] = data.REFILLNBR
                drugRegimeInput["dsTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["dsWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["dsprescriptionEnddate"] = ""
                drugRegimeInput["dsprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["dsprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["dsprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["dsPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["dsPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["dsPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["dsDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["dsDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["dsDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["dsForm"] = data.FORM
                drugRegimeInput["dsRoute"] = data.ROUTE
                drugRegimeInput["dsDose"] = data.DOSE
                drugRegimeInput["dsQuantity"] = data.QUANTITY
                drugRegimeInput["dsPRN"] = data.PRN
                drugRegimeInput["dsIsManual"] = data.ISMANUAL


              }
              
              else if (gpiHistoryObject.filteredSource.includes('Member Reported') && data.SRC == 'Member Reported') {

                drugRegimeInput["patNDC"] = data.NDC
                drugRegimeInput["patFrequency"] = data.FREQUENCY
                drugRegimeInput["patlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["patDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["patInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["patUnits"] = data.UNITOFMEASURE
                drugRegimeInput["patStrength"] = data.STRENGTH
                drugRegimeInput["patRefilNbr"] = data.REFILLNBR
                drugRegimeInput["patTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["patWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["patprescriptionEnddate"] = ""
                drugRegimeInput["patprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["patprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["patprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["patPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["patPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["patPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["patDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["patDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["patDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["patForm"] = data.FORM
                drugRegimeInput["patRoute"] = data.ROUTE
                drugRegimeInput["patDose"] = data.DOSE
                drugRegimeInput["patQuantity"] = data.QUANTITY
                drugRegimeInput["patPRN"] = data.PRN
                drugRegimeInput["patIsManual"] = data.ISMANUAL



              }
              
              drugRegimeInput['claimIsManual'] = 'N'
              drugRegimeInput['ehrIsManual'] = 'N'
              drugRegimeInput['ssIsManual'] = 'N'
              drugRegimeInput['snfIsManual'] = 'Y'
              drugRegimeInput['dsIsManual'] = 'Y'
              drugRegimeInput['patIsManual'] = 'Y'

              if (drugRegimeInput["patTakingMed"] == 'No' && data.ISPATIENTTAKINGMED == 'No') {
                drugRegimeInput["patTakingMed"] = 'No'

              }
              else {
                drugRegimeInput["patTakingMed"] = 'Yes'

              }

            }
          })
          let flag = 0;
          this.patientDataService.getMedregimenws(localStorage.getItem("encounterId")).subscribe(async data => {
            this.serviceData = data

            this.serviceData.forEach(element => {
              if (element.gpi == sourceData.GPI) {
                flag = 1;



              }

            });

            if (!flag) {

              await this.addRegimenSelected(drugRegimeInput);



            }



            else {


              await this.updateRegimenSeelcted(drugRegimeInput);



            }
          })


        }


      });



    } else {


      this.dataSourceHistory.filteredData.forEach(element => {
        if (element.isedited == 'yes' && !processedGPI.includes(element.GPI)) {




          let sourceData = element;
          let historyData = this.dataSourceHistory.filteredData



          let drugRegimeInput = {};
          drugRegimeInput["encounterId"] = localStorage.getItem('encounterId')
          drugRegimeInput["prescriptionId"] = localStorage.getItem('mberRefNo') + "-" + sourceData.GPI + "-" + getDateTime()
          drugRegimeInput["mbrRefNo"] = localStorage.getItem('mberRefNo')
          drugRegimeInput["recordId"] = 123
          drugRegimeInput["mrn"] = sourceData.MRN
          drugRegimeInput["firstName"] = sourceData.FIRSTNAME
          drugRegimeInput["lastName"] = sourceData.LASTNAME
          drugRegimeInput["dob"] = sourceData.DOB
          drugRegimeInput["gender"] = sourceData.GENDER
          drugRegimeInput["medication"] = sourceData.DRUGNAME
          drugRegimeInput["drugCategory"] = sourceData.DRUGCATEGORY
          drugRegimeInput["gpi"] = sourceData.GPI
          drugRegimeInput["patTakingMed"] = sourceData.ISPATIENTTAKINGMED
          processedGPI.push(sourceData.GPI)
          debugger
          let gpiHistoryObject = this.getMatchedGPISource(sourceData.GPI, sourceData.SRC, sourceData.SUBSRC)
          if (!gpiHistoryObject.filteredSource.includes(sourceData.SRC)) {
            gpiHistoryObject.filteredData.push(sourceData)
            gpiHistoryObject.filteredSource.push(sourceData.SRC)
          }



          gpiHistoryObject.filteredData.forEach(data => {

            if (sourceData.GPI == data.GPI) {
              if (gpiHistoryObject.filteredSource.includes('Claims') && data.SRC == 'Claims') {
                drugRegimeInput["claimNDC"] = data.NDC
                drugRegimeInput["claimFrequency"] = data.FREQUENCY
                drugRegimeInput["claimlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["claimDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["claimInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["claimUnits"] = data.UNITOFMEASURE
                drugRegimeInput["claimStrength"] = data.STRENGTH
                drugRegimeInput["claimRefilNbr"] = data.REFILLNBR
                drugRegimeInput["claimTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["claimWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["claimprescriptionEnddat"] = ""
                drugRegimeInput["claimstatus"] = data.CLAIMSTATUS
                drugRegimeInput["claimprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["claimprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["claimprescriberSpecialiy"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["claimPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["claimPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["claimPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["claimDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["claimDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["claimDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["claimForm"] = data.FORM
                drugRegimeInput["claimRoute"] = data.ROUTE
                drugRegimeInput["claimDose"] = data.DOSE
                drugRegimeInput["claimQuantity"] = data.QUANTITY
                drugRegimeInput["claimPRN"] = data.PRN
                drugRegimeInput["claimIsManual"] = data.ISMANUAL




              }
              
              else if (gpiHistoryObject.filteredSource.includes('POS') && data.SRC == 'POS') {

                drugRegimeInput["ssNDC"] = data.NDC
                drugRegimeInput["ssFrequency"] = data.FREQUENCY
                drugRegimeInput["sslastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["ssDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["ssInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["ssUnits"] = data.UNITOFMEASURE
                drugRegimeInput["ssStrength"] = data.STRENGTH
                drugRegimeInput["ssRefilNbr"] = data.REFILLNBR
                drugRegimeInput["ssTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["ssWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["ssprescriptionEnddate"] = ""
                drugRegimeInput["ssprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["ssprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["ssprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["ssPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["ssPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["ssPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["ssDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["ssDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["ssDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["ssForm"] = data.FORM
                drugRegimeInput["ssRoute"] = data.ROUTE
                drugRegimeInput["ssDose"] = data.DOSE
                drugRegimeInput["ssQuantity"] = data.QUANTITY
                drugRegimeInput["ssPRN"] = data.PRN
                drugRegimeInput["ssIsManual"] = data.ISMANUAL
                drugRegimeInput["ssPickedUpdt"] = data.PICKEDUP_DATE



              }
              
              else if (gpiHistoryObject.filteredSource.includes('EHR') && data.SRC == 'EHR') {
                drugRegimeInput["ehrNDC"] = data.NDC
                drugRegimeInput["ehrFrequency"] = data.FREQUENCY
                drugRegimeInput["ehrlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["ehrDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["ehrInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["ehrUnits"] = data.UNITOFMEASURE
                drugRegimeInput["ehrStrength"] = data.STRENGTH
                drugRegimeInput["ehrRefilNbr"] = data.REFILLNBR
                drugRegimeInput["ehrTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["ehrWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["ehrprescriptionEnddate"] = ""
                drugRegimeInput["ehrprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["ehrprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["ehrprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["ehrPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["ehrPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["ehrPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["ehrDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["ehrDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["ehrDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["ehrForm"] = data.FORM
                drugRegimeInput["ehrRoute"] = data.ROUTE
                drugRegimeInput["ehrDose"] = data.DOSE
                drugRegimeInput["ehrQuantity"] = data.QUANTITY
                drugRegimeInput["ehrPRN"] = data.PRN
                drugRegimeInput["ehrIsManual"] = data.ISMANUAL


              }
              
              else if (gpiHistoryObject.filteredSource.includes('SNF') && data.SRC == 'SNF') {
                drugRegimeInput["snfNDC"] = data.NDC
                drugRegimeInput["snfFrequency"] = data.FREQUENCY
                drugRegimeInput["snflastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["snfDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["snfInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["snfUnits"] = data.UNITOFMEASURE
                drugRegimeInput["snfStrength"] = data.STRENGTH
                drugRegimeInput["snfRefilNbr"] = data.REFILLNBR
                drugRegimeInput["snfTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["snfWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["snfprescriptionEnddate"] = ""
                drugRegimeInput["snfprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["snfprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["snfprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["snfPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["snfPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["snfPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["snfDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["snfDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["snfDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["snfForm"] = data.FORM
                drugRegimeInput["snfRoute"] = data.ROUTE
                drugRegimeInput["snfDose"] = data.DOSE
                drugRegimeInput["snfQuantity"] = data.QUANTITY
                drugRegimeInput["snfPRN"] = data.PRN
                drugRegimeInput["snfIsManual"] = data.ISMANUAL


              }
              
              else if (gpiHistoryObject.filteredSource.includes('Inpatient') && data.SRC == 'Inpatient') {
                drugRegimeInput["dsNDC"] = data.NDC
                drugRegimeInput["dsFrequency"] = data.FREQUENCY
                drugRegimeInput["dslastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["dsDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["dsInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["dsUnits"] = data.UNITOFMEASURE
                drugRegimeInput["dsStrength"] = data.STRENGTH
                drugRegimeInput["dsRefilNbr"] = data.REFILLNBR
                drugRegimeInput["dsTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["dsWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["dsprescriptionEnddate"] = ""
                drugRegimeInput["dsprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["dsprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["dsprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["dsPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["dsPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["dsPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["dsDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["dsDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["dsDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["dsForm"] = data.FORM
                drugRegimeInput["dsRoute"] = data.ROUTE
                drugRegimeInput["dsDose"] = data.DOSE
                drugRegimeInput["dsQuantity"] = data.QUANTITY
                drugRegimeInput["dsPRN"] = data.PRN
                drugRegimeInput["dsIsManual"] = data.ISMANUAL


              }
              
              else if (gpiHistoryObject.filteredSource.includes('Member Reported') && data.SRC == 'Member Reported') {

                drugRegimeInput["patNDC"] = data.NDC
                drugRegimeInput["patFrequency"] = data.FREQUENCY
                drugRegimeInput["patlastFilledDate"] = data.LASTFILLEDDATE
                drugRegimeInput["patDaysOfSupply"] = data.DAYSSUPPLY
                drugRegimeInput["patInstructions"] = data.INSTRUCTIONS
                drugRegimeInput["patUnits"] = data.UNITOFMEASURE
                drugRegimeInput["patStrength"] = data.STRENGTH
                drugRegimeInput["patRefilNbr"] = data.REFILLNBR
                drugRegimeInput["patTotalRefill"] = data.TOTALREFILLS
                drugRegimeInput["patWrittenDate"] = data.WRITTENDATE
                drugRegimeInput["patprescriptionEnddate"] = ""
                drugRegimeInput["patprescriberName"] = data.PRESCRIBERNAME
                drugRegimeInput["patprescriberNPI"] = data.PRESCRIBERNPI
                drugRegimeInput["patprescriberSpeciality"] = data.PRESCRIBERSPECIALTY
                drugRegimeInput["patPharmacyName"] = data.PHARMACYNAME
                drugRegimeInput["patPharmacyNPI"] = data.PHARMACYNPI
                drugRegimeInput["patPharmacyContact"] = data.PHARMACYCONTACT
                drugRegimeInput["patDiagnosis"] = data.DIAGNOSIS
                drugRegimeInput["patDiagnosisDesc"] = data.DIAGNOSISDESC
                drugRegimeInput["patDiagnosisEffectDt"] = data.DIAGNOSISEFFECTDT
                drugRegimeInput["patForm"] = data.FORM
                drugRegimeInput["patRoute"] = data.ROUTE
                drugRegimeInput["patDose"] = data.DOSE
                drugRegimeInput["patQuantity"] = data.QUANTITY
                drugRegimeInput["patPRN"] = data.PRN
                drugRegimeInput["patIsManual"] = data.ISMANUAL



              }
             
              drugRegimeInput['claimIsManual'] = 'N'
              drugRegimeInput['ehrIsManual'] = 'N'
              drugRegimeInput['ssIsManual'] = 'N'
              drugRegimeInput['snfIsManual'] = 'Y'
              drugRegimeInput['dsIsManual'] = 'Y'
              drugRegimeInput['patIsManual'] = 'Y'

              if (drugRegimeInput["patTakingMed"] == 'No' && data.ISPATIENTTAKINGMED == 'No') {
                drugRegimeInput["patTakingMed"] = 'No'

              }
              else {
                drugRegimeInput["patTakingMed"] = 'Yes'

              }

            }
          })
          let flag = 0;
          this.patientDataService.getMedregimenws(localStorage.getItem("encounterId")).subscribe(async data => {
            this.serviceData = data

            this.serviceData.forEach(element => {
              if (element.gpi == sourceData.GPI) {
                flag = 1;
              }

            });

            if (!flag) {

              await this.addRegimenSelected(drugRegimeInput);



            }



            else {


              await this.updateRegimenSeelcted(drugRegimeInput);



            }
          })


        }
        element.isedited = 'no'


      });
    }
    if(processedGPI.length > 0){
    this.toastr.success("Selected Medications Exported To Drug Regimen", '', {
      timeOut: 1500,
    });
  }
    if(processedGPI.length == 0){
      this.toastr.error("Please Select Medications Before Export", '', {
        timeOut: 1500,
      });
  
    this.isAll = false
}




  }

  saveAllMedRegime() {
    this.isDropDown = false
    let drugInput = this.dataSource1.filteredData.filter(this.editable)
    drugInput.forEach(element => {
      let lis = []
      element.techCheck.forEach(ele => {
        lis.push(ele[0])
      })
      element.techCheck = lis.join('')
    }
    )
    this.patientDataService.updateMedReview(drugInput).subscribe(data => {

      this.toastr.success('Saved To Drug Regimen', '', {
        timeOut: 1500,
      })
      this.callDrugRegimenApi()
      this.anythingChanged = false
      this.anythingChangedRec = false
      this.anythingChangedRev = false
    }, error => {
      this.isSpinner = false
    });

  }
  editable(data) {
    return data.isedited == 'yes'

  }

  getMatchedGPISource(gpi: any, source: any, subsrc: any) {
    const sortByCreatedDt = ["SNF", "EHR", "Inpatient", "Member Reported"]
    let processedSource = []

    let fullHistoryData = this.dataSourceHistory.filteredData
    let filteredData = fullHistoryData.filter(item => item.GPI == gpi)
    let filteredSourceData = []

    if (sortByCreatedDt.includes(source)) {
      filteredData.sort(this.sortforRegimen);
    }
    else {
      filteredData.sort(this.sortforRegimen);


    }
    filteredData.forEach(e => {
      if (e.SRC == 'Claims' && subsrc == 'SS-Claims' && e.SUBSRC == 'OptumRx' && !processedSource.includes(e.SRC)) {
        filteredSourceData.push(e)
        processedSource.push(e.SRC)

      }

      else if (!processedSource.includes(e.SRC) && e.SRC != source) {
        filteredSourceData.push(e)
        processedSource.push(e.SRC)
      }
    })
    let data = {
      "filteredData": filteredSourceData,
      "filteredSource": processedSource

    }

    return data

  }
  sortforRegimen(a, b) {

    if (a.LASTFILLEDDATE != null && b.LASTFILLEDDATE != null) {


      let c: any = new Date(a.LASTFILLEDDATE.split(' ')[0])
      let d: any = new Date(b.LASTFILLEDDATE.split(' ')[0])
      return d - c;
    }

  }




  callMedispanDrugInteraction(data) {
    this.list_of_drugs = [];
    data.forEach(e => {
       let ndc = this.getNDCFromRegimen(e)
          if(ndc != ""){
            this.list_of_drugs.push
            ({
              "drug":
              {
                "ndc": ndc
              }
            });

          }
    })
    let interactionjson = {
      "severity": "minor",
      "patient": {
        "birthdate": this.getDateDDMMYYY(this.memberdata.dob),
        "gender": this.patientDetail.sex == 'M' ? 'Male' : 'Female'
      },
      "patientdrug": this.list_of_drugs


    }

    if (this.list_of_drugs.length > 0) {
      this.patientDataService.getMedispanDrugInteraction(interactionjson).subscribe(res => {

        this.isSpinnerMedispan = false;
        console.log(res, "result");
        this.medInteractionData = res["data"]["DrugInteractions"].drugDrugInteractions
        if (this.medInteractionData != null && this.medInteractionData.length > 0) {
          this.medInteractionData.sort(function (e, f) {

            if (e.documentationLevel == 'Established') {
              return -1
            }
            if (e.documentationLevel == 'Probable' && f.documentationLevel == 'suspected') {

              return -1
            }
            else {
              return 1
            }
          });
        }
        this.ismedispandata = true
        if (res["data"]["DrugInteractions"].hasOwnProperty('drugFoodInteractions') && res["data"]["DrugInteractions"].drugFoodInteractions != null) {
          this.foodInteractionData = res["data"]["DrugInteractions"].drugFoodInteractions
          if (this.foodInteractionData != null && this.foodInteractionData.length > 0) {
            this.foodInteractionData.sort(function (e, f) {

              if (e.documentationLevel == 'Established') {
                return -1
              }
              if (e.documentationLevel == 'Probable' && f.documentationLevel == 'suspected') {

                return -1
              }
              else {
                return 1
              }
            });
          }



        }
        if (res["data"]["DrugInteractions"].hasOwnProperty('drugAlcoholInteractions') && res["data"]["DrugInteractions"].drugAlcoholInteractions != null) {
          this.alcoholInteractionData = res["data"]["DrugInteractions"].drugAlcoholInteractions
          if (this.alcoholInteractionData != null && this.alcoholInteractionData.length > 0) {
            this.alcoholInteractionData.sort(function (e, f) {

              if (e.documentationLevel == 'Established') {
                return -1
              }
              if (e.documentationLevel == 'Probable' && f.documentationLevel == 'suspected') {

                return -1
              }
              else {
                return 1
              }
            });
          }

        }
      }, (error: Error) => {
        console.log(error);
      }
      )
    }
    else {
      this.ismedispandata = true
      this.medInteractionData = null
      this.foodInteractionData = null
      this.alcoholInteractionData = null
      this.isSpinnerMedispan = false;

    }

  }
  getDateDDMMYYY(date: String) {
    if (date != null && date != 'undefined') {
      let datearr: any = [] = date.split('-')
      return datearr[2] + "/" + datearr[1] + "/" + datearr[0]

    }
    return date;
  }
  getNDCFromRegimen(e) {


    if (e.patNDC != '' && e.patNDC != undefined && e.patNDC != "" && e.patNDC.length == 11) {
      return e.patNDC
    }
    else if (e.snfNDC != '' && e.snfNDC != undefined && e.snfNDC != "" && e.snfNDC.length == 11) {
      return e.snfNDC
    }
    else if (e.dsNDC != '' && e.dsNDC != undefined && e.dsNDC != "" && e.dsNDC.length == 11) {
      return e.dsNDC
    }
    else if (e.ssNDC != '' && e.ssNDC != undefined && e.ssNDC != "" && e.ssNDC.length == 11) {
      return e.ssNDC
    }
    else if (e.claimNDC != '' && e.claimNDC != undefined && e.claimNDC != "" && e.claimNDC.length == 11) {
      return e.claimNDC
    }
    else if (e.ehrNDC != '' && e.ehrNDC != undefined && e.ehrNDC != "" && e.ehrNDC.length == 11) {
      return e.ehrNDC
    }
return ""
}
  addRegimen(input) {



    this.patientDataService.addDrugRegime(input).subscribe(data => {

      this.toastr.success("Medication Exported To Drug Regimen", '', {
        timeOut: 1500,
      })
      this.callDrugRegimenApi();
    }, error => {

      this.toastr.error("Some Error Occured In Exporting To Drug Regimen", '', {
        timeOut: 1500,
      })

    })

  }

  updateRegimen(input) {


    this.patientDataService.updateDrugRegime(input).subscribe(data => {
      this.toastr.success("Med Regimen Updated For Exported Drug", '', {
        timeOut: 1500,
      })
      this.callDrugRegimenApi()


    }, error => {
      this.toastr.error("Some Issue In Updating Med Regimen", '', {
        timeOut: 1500,
      })


    })

  }
  updateReview() {
    let bool = true
    let input = {
      "encounterId": localStorage.getItem("encounterId"),
      "pharmacist": localStorage.getItem("usname"),
      "previousStatus": this.encounterData.status
    }

    console.log(this.copyService)
    this.copyService.forEach(element => {
      if ((element.rphCheck == '') || (element.medDecision == '') || (this.anythingChangedRev)) {
        bool = false
        console.log("aa", element.rphCheck, element.medDecision)
      }
    })
    this.isSpinner = true
    if (bool) {
      if(localStorage.getItem("targetedEncounter")=='true'){
        this.patientDataService.updateEncounterStatus(input, "reviewed", "false").subscribe(data => {
          this.isFinalCheck = true
          this.isTechCheck = false
          this.isRphCheck = false
          this.isReconciled = true
          
         if(localStorage.getItem("targetedEncounter")){
              this.encounterData.status = 'Reviewed'
              this.toastr.success("Reviewed Successfully", '', {
                timeOut: 1500,
              })
            }
            this.callDrugRegimenApi()
  
  
        }, error => {
          this.toastr.error("Some Issue Occured", '', {
            timeOut: 1500,
          })
          this.isSpinner = false
  
  
        })
      }
      if(localStorage.getItem("targetedEncounter")!='true'){
      this.patientDataService.updateEncounterStatus(input, "reconcile", "false").subscribe(data => {
        this.isFinalCheck = true
        this.isTechCheck = false
        this.isRphCheck = false
        this.isReconciled = true
       
          this.encounterData.status = 'Reconciled'
          this.toastr.success("Reconciled Successfully", '', {
            timeOut: 1500,
          })
          this.callDrugRegimenApi()


      }, error => {
        this.toastr.error("Some Issue Occured", '', {
          timeOut: 1500,
        })
        this.isSpinner = false


      })}
    }
    else {
      this.toastr.error("There Are Unsaved Or Incomplete Fields. Please Save Or Complete Before Proceeding.", '', {
        timeOut: 1500,
      })
      this.isSpinner = false
    }

  }
  updateRec() {
    let bool = true
    let input = {
      "encounterId": localStorage.getItem("encounterId"),
      "pharmacist": localStorage.getItem("usname"),
      "previousStatus": this.encounterData.status
    }
    console.log(this.copyService)
    this.copyService.forEach(element => {
      if ((element.techCheck == '') || (this.anythingChangedRec)) bool = false
    })
    if (bool) {
      
      this.patientDataService.updateEncounterStatus(input, "review", "false").subscribe(data => {
        this.toastr.success("Tech Check Completed", '', {
          timeOut: 1500,
        })
        this.isRphCheck = true
        this.isTechCheck = false
        this.encounterData.status = 'Rph Review'
        this.callDrugRegimenApi()


      }, error => {
        this.toastr.error("Some Issue Occured", '', {
          timeOut: 1500,
        })
        this.isSpinner = false


      })
    }
    
    else {
      this.toastr.error("There Are Unsaved Or Incomplete Fields. Please Save Or Complete Before Proceeding.", '', {
        timeOut: 1500,
      })
    }

  }
  revertStatus(status) {
    console.log(status)
    let input = {
      "encounterId": localStorage.getItem("encounterId"),
      "pharmacist": localStorage.getItem("usname"),
      "previousStatus": this.encounterData.status,
      "remarks": ""
    }
   
    if (status == 'Reconciled') {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '35%',
        width: '30%',
        data: { message: 'Are You Sure You Want To Revert To Rph Review?', isComments: true },
      });
      dialogRef.afterClosed().subscribe((result) => {
        input['remarks'] = result
        if (result.length > 0 || result === true) {
          this.isRphCheck = false
          this.isTechCheck = true
          this.isFinalCheck = false
          this.isReconciled = false

          this.patientDataService.updateEncounterStatus(input, "review", "true").subscribe(data => {
            this.encounterData.status = 'Rph Review'
            this.toastr.success("Reverted To Rph Review", '', {
              timeOut: 1500,
            })
            this.callDrugRegimenApi()
          }, error => {
            this.toastr.error("Some Issue Occured", '', {
              timeOut: 1500,
            })
            this.isSpinner = false
          })

        } else {
          return
        }
      });
    }
    else if (status == 'Rph Review') {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '35%',
        width: '30%',
        data: { message: 'Are You Sure You Want To Revert To Data Entry?', isComments: true },
      });
      dialogRef.afterClosed().subscribe((result) => {
        input['remarks'] = result
        if (result.length > 0 || result === true) {
          this.isTechCheck = true
          this.isRphCheck = false
          this.isFinalCheck = false
          this.patientDataService.updateEncounterStatus(input, "dataentry", "false").subscribe(data => {
            this.toastr.success("Reverted to Data Entry", '', {
              timeOut: 1500,
            })
            this.encounterData.status = 'Data Entry'
            this.callDrugRegimenApi()
          }, error => {
            this.toastr.error("Some Issue Occured", '', {
              timeOut: 1500,
            })
            this.isSpinner = false
          })

        } else {
          return
        }
      });
    }
    else if (status == 'Reviewed') {

      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '35%',
        width: '30%',
        data: { message: 'Are You Sure You Want To Revert To Data Entry?', isComments: true },
      });
      dialogRef.afterClosed().subscribe((result) => {
        input['remarks'] = result
        if (result.length > 0 || result === true) {
          this.isTechCheck = true;
          this.isRphCheck = true;
          this.isFinalCheck = false;
          this.isReconciled=false
          this.patientDataService.updateEncounterStatus(input, "dataentry", "false").subscribe(data => {
            this.toastr.success("Reverted to Data Entry", '', {
              timeOut: 1500,
            })
            this.encounterData.status = 'Data Entry'
            this.callDrugRegimenApi()
          }, error => {
            this.toastr.error("Some Issue Occured", '', {
              timeOut: 1500,
            })
            this.isSpinner = false
          })

        } else {
          return
        }
      });
    }
    else {
      this.toastr.error("You Can't Revert", '', {
        timeOut: 1500,
      })
    }
  }
  editDropDown(element) {
    if (element.isOpen == true) {
      element.isOpen = false
    }
    else {
      element.isOpen = true
    }

  }

  globalSearchMeds() {
    localStorage.setItem("searchTerm", this.globalSearchText);
    console.log(this.globalSearchText)
    if (this.viewAll) {
      if (this.globalSearchText.includes("&")) {
        console.log(this.globalSearchText.split("&"));

        this.initialLoadData = this.searchData(this.initialLoaddatacopy, this.globalSearchText.split("&")[0]);
        for (let ic: any = 1; ic <= this.globalSearchText.split("&").length; ic++) {
          this.initialLoadData = this.searchData(this.initialLoadData, this.globalSearchText.split("&")[ic]);
        }

      }
      else {
        console.log(this.initialLoaddatacopy)
        this.initialLoadData = this.searchData(this.initialLoaddatacopy, this.globalSearchText);
        this.page = 1; this.dataSourceHistory = new MatTableDataSource(this.initialLoadData);
      }
      this.dataSourceHistory1 = this.initialLoadData.sort(this.sortByDate);

      this.dataSourceHistory = new MatTableDataSource(this.dataSourceHistory1);
    }
    else {
      if (this.globalSearchText.includes("&")) {
        console.log(this.globalSearchText.split("&"));
        this.servicedata = this.searchData(this.servicedatacopy, this.globalSearchText.split("&")[0]);
        for (let ic: any = 1; ic <= this.globalSearchText.split("&").length; ic++) {
          this.servicedata = this.searchData(this.servicedata, this.globalSearchText.split("&")[ic]);
        }

      }
      else {
        this.servicedata = this.searchData(this.servicedatacopy, this.globalSearchText);
        this.page = 1;
      }
      console.log(this.servicedata)
      this.dataSourceHistory1 = this.servicedata.sort(this.sortByDate);

      this.dataSourceHistory = new MatTableDataSource(this.dataSourceHistory1);
    }

    this.dataSourceHistory.sort = this.sortCol1

  }

  globalSearchRegimen() {
    localStorage.setItem("searchTerm", this.globalSearchText1);
    console.log("Hi", this.globalSearchText1)
    console.log(this.serviceData)
    let filteredServiceData=[]
    this.serviceData.forEach(element => {
      const{
        encounterId ,
        prescriptionId ,
        recordId ,
        mbrRefNo,
        mrn ,
        firstName ,
        lastName ,
        dob ,
        gender ,
        gpi ,
        claimNDC ,       claimlastFilledDate ,
        claimDaysOfSupply ,
        claimUnits ,
        claimStrength ,
        claimRefilNbr ,
        claimRxNbr ,
        claimTotalRefill ,
        claimWrittenDate ,
        claimprescriptionEnddate ,
        claimstatus ,
        ssNDC ,
        sslastFilledDate ,
        ssDaysOfSupply ,
        ssUnits ,
        ssStrength ,
        ssRefilNbr ,
        ssRxNbr ,
        ssTotalRefill ,
        ssWrittenDate ,
        ssprescriptionEnddate ,
        ssstatus ,
        ssMessageId ,
        dsNDC ,
        dslastFilledDate ,
        dsDaysOfSupply ,
        dsUnits ,
        dsStrength ,
        dsRefilNbr ,
        dsRxNbr ,
        dsTotalRefill ,
        dsWrittenDate ,
        dsprescriptionEnddate ,
        dsstatus ,
        snfNDC ,
        snflastFilledDate ,
        snfDaysOfSupply ,
        snfUnits ,
        snfStrength ,
        snfRefilNbr ,
        snfRxNbr ,
        snfTotalRefill ,
        snfWrittenDate ,
        snfprescriptionEnddate ,
        snfstatus ,
        ehrNDC ,
        ehrlastFilledDate ,
        ehrDaysOfSupply ,
        ehrUnits ,
        ehrStrength ,
        ehrRefilNbr ,
        ehrRxNbr ,
        ehrTotalRefill ,
        ehrWrittenDate ,
        ehrprescriptionEnddate ,
        ehrstatus ,
        patNDC ,
        patlastFilledDate ,
        patDaysOfSupply ,
        patUnits ,
        patStrength ,
        patRefilNbr ,
        patRxNbr ,
        patTotalRefill ,
        patWrittenDate ,
        patprescriptionEnddate ,
        patstatus ,
        patTakingMed ,
        finalSource ,
        reviewBy ,
        reviewDatettime ,
        reconcilledBy ,
        reconcilledDatetime ,
        actionplan ,
        claimprescriberName ,
        claimprescriberNPI ,
        claimprescriberSpeciality ,
        claimPharmacyName ,
        claimPharmacyNPI ,
        claimPharmacyContact ,
        ssprescriberName ,
        ssprescriberNPI ,
        ssprescriberSpeciality ,
        ssPharmacyName ,
        ssPharmacyNPI ,
        ssPharmacyContact ,
        dsprescriberName ,
        dsprescriberNPI ,
        dsprescriberSpeciality ,
        dsPharmacyName ,
        dsPharmacyNPI ,
        dsPharmacyContact ,
        snfprescriberName ,
        snfprescriberNPI ,
        snfprescriberSpeciality ,
        snfPharmacyName ,
        snfPharmacyNPI ,
        snfPharmacyContact ,
        ehrprescriberName ,
        ehrprescriberNPI ,
        ehrprescriberSpeciality ,
        ehrPharmacyName ,
        ehrPharmacyNPI ,
        ehrPharmacyContact ,
        patprescriberName ,
        patprescriberNPI ,
        patprescriberSpeciality ,
        patPharmacyName ,
        patPharmacyNPI ,
        patPharmacyContact ,
        patDiagnosis ,
        patDiagnosisDesc ,
        patDiagnosisEffectDt ,
        ehrDiagnosis ,
        ehrDiagnosisDesc ,
        ehrDiagnosisEffectDt ,
        dsDiagnosis ,
        dsDiagnosisDesc ,
        dsDiagnosisEffectDt ,
        snfDiagnosis ,
        snfDiagnosisDesc ,
        snfDiagnosisEffectDt ,
        ssDiagnosis ,
        ssDiagnosisDesc ,
        ssDiagnosisEffectDt ,
        claimDiagnosis ,
        claimDiagnosisDesc ,
        claimDiagnosisEffectDt ,
        edit ,
        save ,
        clear ,
        ssQuantity ,
        dsQuantity ,
        snfQuantity ,
        patQuantity ,
        claimQuantity ,
        ehrQuantity ,
        ssPRN ,
        dsPRN ,
        snfPRN ,
        patPRN ,
        claimPRN ,
        ehrPRN ,
        ssIsManual ,
        dsIsManual ,
        snfIsManual ,
        patIsManual ,
        claimIsManual ,
        ehrIsManual ,...rest}=element
        filteredServiceData.push(rest)


     })

    
      console.log(filteredServiceData)

    if (this.globalSearchText1.includes("&")) {
      console.log(this.globalSearchText1.split("&"));


      filteredServiceData = this.searchData(filteredServiceData, this.globalSearchText1.split("&")[0]);
      for (let ic: any = 1; ic <= this.globalSearchText1.split("&").length; ic++) {
        filteredServiceData = this.searchData(filteredServiceData, this.globalSearchText1.split("&")[ic]);
      }

    }
    else {
      debugger
      console.log(this.serviceData)
      console.log(this.serviceDataBackup)
      filteredServiceData = this.searchData(filteredServiceData, this.globalSearchText1);
     // this.page = 1; this.dataSource1 = new MatTableDataSource(filteredServiceData);
    }
    // this.dataSource1 = this.serviceData.sort(this.sortByDate);
    let finalmedregimendata=[]
    filteredServiceData.forEach(element => {
      this.serviceData.forEach(e => {
        if ( e.medication == element.medication && e.drugCategory==element.drugCategory) {
          finalmedregimendata.push(e)
        }
      })


    })

    this.dataSource1 = new MatTableDataSource(finalmedregimendata);
  }







  viewALLMeds(event) {

    if (event) {
      this.viewAll = true;
      this.dataSourceHistory1 = this.initialLoadData.sort(this.sortByDate);

      this.dataSourceHistory = new MatTableDataSource(this.dataSourceHistory1);
    }
    if (!event) {
      this.viewAll = false
      this.dataSourceHistory2 = this.servicedata.sort(this.sortByDate);

      this.dataSourceHistory = new MatTableDataSource(this.dataSourceHistory2);
    }
    this.dataSourceHistory.sort = this.sortCol1
  }


  generatePatientReport() {

    this.patientDataService.getactionPlanPdfData(localStorage.getItem("encounterId")).subscribe(data => {
      this.configdata = data
      this.haveconfigData = true
      this.getMedicatiosFormatFromRegimen(this.dataSource1.filteredData)
    }, error => {
      this.toaster.error("issue in data saving");






    })
    setTimeout(() => {

    }, 2000);



  }
  getMedicatiosFormatFromRegimen(data: any) {
    console.log(data)
  }

 



  openProviderPopUp() {
    console.log(this.serviceData)
    this.getAllmedicationsFromRegime()
    const addDialogue = this.dialog.open(ProviderDataComponent, {
      height: '47%',
      data: {

        popupType:"Provider Report",
        encounterData: this.encounterData,
        memberdata: this.memberdata,
        allergies: this.parsedAllergyArray,
        drugRegimen: this.allmedicationsifnotselected,
        'diagnosisList': this.diagnosisList,



      },


    });

  }
  getEncounterPage() {
    this._route.navigate(['/editEncounter/' + localStorage.getItem("encounterId")]); //610882


  }
  getAge(dob) {
    if (dob != null || dob != undefined) {
      let dobarr = dob.split("-")
      let birthMonth: any = dobarr[1]
      let birthDay: any = dobarr[2];
      let birthYear: any = dobarr[0]
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();
      var calculatedAge = currentYear - birthYear;

      if (currentMonth < birthMonth - 1) {
        calculatedAge--;
      }
      if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
        calculatedAge--;
      }
      return calculatedAge;
    }
    else {
      return "NA"
    }
  }


  deleteMedRegimen(data) {
    if (this.anythingChanged || this.anythingChangedRec || this.anythingChangedRev) {
      this.toastr.error('There Are Unsaved Or Incomplete Fields. Please Save Or Complete Before Proceeding.', '', {
        timeOut: 1500,
      })
    }
    else {
      let input = {
        "encounterId": data.encounterId,
        "gpi": data.gpi
      }
      this.patientDataService.deleteMedRegimen(input).subscribe(data => {
        this.toastr.success("Deleted SuccessFully", '', {
          timeOut: 1500,
        })
        this.callDrugRegimenApi()


      }, error => {
        this.toastr.error("Some Issue Occured", '', {
          timeOut: 1500,
        })


      })
    }
  }
  toCamelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }

  getpickeduptooltip(data) {
    if (data.SRC == 'POS' && data.PICKEDUP_DATE != null && data.PICKEDUP_DATE != '' && data.PICKEDUP_DATE != undefined && data.PICKEDUP_DATE != 'null' && data.PICKEDUP_DATE != '1900-01-01') {
      let datearr=data.PICKEDUP_DATE.split("-")
      return "Picked up date: " + datearr[1]+"-"+datearr[2]+"-"+datearr[0]

    }

    return

  }
  getpickeduptooltipActive(data) {
    console.log("11111---------------")

    
    if (data != null && data != '' && data != undefined && data != 'null' && data != '1900-01-01') {
      console.log("---------------")
      console.log(data)
      let datarr=data.split("-")
      console.log(datarr)


      return "Picked up date: " + datarr[1]+"-"+datarr[2]+"-"+datarr[0]

    }

    return

  }
  sortByDate(a, b) {
    if (a.CREATED_DT != null && b.CREATED_DT != null) {


      let c: any = new Date(a.CREATED_DT.split(' ')[0])
      let d: any = new Date(b.CREATED_DT.split(' ')[0])
      console.log('hii')
      return d - c;
    }
  }
  parseAllergies(data: any) {
    data.forEach(element => {
      if (element.type == 'N' && element.activeFlag == 'A') {
        this.noKnownAllergies = true
      }


      if (element.type == 'M' && element.activeFlag == 'A') {
        this.selectedMedAllergyString.push(element.description)
      }
      if (element.type == 'C' && element.activeFlag == 'A') {
        this.selectedCatAllergyString.push(element.description)
      }
      if (element.type == 'O' && element.activeFlag == 'A') {
        this.isOthersAllergyExist.push(element.description)

      }
    });
   
  }
  parseAllergiesReport(data: any) {
    data.forEach(element => {
      if (element.type == 'N' && element.activeFlag == 'A') {
        this.parsedAllergyArray.push(element.description)
      }


      if (element.type == 'M' && element.activeFlag == 'A') {
        this.parsedAllergyArray.push(element.description )
      }
      if (element.type == 'C' && element.activeFlag == 'A') {
        this.parsedAllergyArray.push(element.description )
      }
      if (element.type == 'O' && element.activeFlag == 'A') {
        this.parsedAllergyArray.push(element.description)

      }
    });

  }
  formatAllergies(data: any) {

    debugger
    return data.toString().replaceAll(",", "\n");



  }
  createIntervention() {

    this.allmedicationsifnotselected = []
    debugger

    let dialogRef = this.dialog.open(InterventionComponent, {


      height: '98%',
      width: '100%',
      data: {
        selectedMedications: this.selectedMedications,
        allmedicationsifselected: this.allmedicationsifselected,
        allmedicationsifnotselected: this.getAllmedicationsFromRegime(),
        enconterDetails: this.encounterData,
        memberDetails: this.memberdata,
        allergies: this.parsedAllergyArray,
        'diagnosisList': this.diagnosisList,
      }
    })

    dialogRef.afterClosed().subscribe(result => {

      debugger

      this.isAllexported = false
      console.log(this.isAllexported1)

      this.exportAllDrugRegimen = false
      this.selectedMedications = []
      this.allmedicationsifselected = []

      this.callDrugRegimenApi();


    });







  }

  onChangeselectedIntervention(event, element) {
    debugger

    if (event.checked == true) {
      let data = {}
      if (element.medDecision.toLowerCase() == 'claims') {
        data['dose'] = element.claimDose,
          data['units'] = element.claimUnits,
          data['route'] = element.claimRoute,
          data['frequency'] = element.claimFrequency
        if (element.claimPRN == 'Yes') {
          data['instructions'] = " as needed for " + element.claimInstructions
        }
        if (element.claimPRN != 'Yes') {
          data['instructions'] = element.claimInstructions
        }
      }
      else if (element.medDecision.toLowerCase() == 'pos') {
        data['dose'] = element.ssDose,
          data['units'] = element.ssUnits,
          data['route'] = element.ssRoute,
          data['frequency'] = element.ssFrequency
        if (element.ssPRN == 'Yes') {
          data['instructions'] = " as needed for " + element.ssInstructions
        }
        if (element.ssPRN != 'Yes') {
          data['instructions'] = element.ssInstructions
        }

      }
      else if (element.medDecision.toLowerCase() == 'ehr') {
        data['dose'] = element.ehrDose,
          data['units'] = element.ehrUnits,
          data['route'] = element.ehrRoute,
          data['frequency'] = element.ehrFrequency
        if (element.ehrPRN == 'Yes') {
          data['instructions'] = " as needed for " + element.ehrInstructions
        }
        if (element.ehrPRN != 'Yes') {
          data['instructions'] = element.ehrInstructions
        }


      }
      else if (element.medDecision.toLowerCase() == 'inpatient') {
        data['dose'] = element.dsDose,
          data['units'] = element.dsUnits,
          data['route'] = element.dsRoute,
          data['frequency'] = element.dsrequency
        if (element.dsPRN == 'Yes') {
          data['instructions'] = " as needed for " + element.dsInstructions
        }
        if (element.dsPRN != 'Yes') {
          data['instructions'] = element.dsInstructions
        }

      }
      else if (element.medDecision.toLowerCase() == 'snf') {
        data['dose'] = element.snfDose,
          data['units'] = element.snfUnits,
          data['route'] = element.snfRoute,
          data['frequency'] = element.snfFrequency
        if (element.snfPRN == 'Yes') {
          data['instructions'] = " as needed for " + element.snfInstructions
        }
        if (element.snfPRN != 'Yes') {
          data['instructions'] = element.snfInstructions
        }


      }
      else if (element.medDecision.toLowerCase() == 'member reported') {
        data['dose'] = element.patDose,
          data['units'] = element.patUnits,
          data['route'] = element.patRoute,
          data['frequency'] = element.patFrequency
        if (element.patPRN == 'Yes') {
          data['instructions'] = " as needed for " + element.patInstructions
        }
        if (element.snfPRN != 'Yes') {
          data['instructions'] = element.patInstructions
        }


      }
      data["medDecision"] = element.medDecision
      data["medication"] = element.medication
      data["medicationName"] = element.medication



      this.selectedMedications.push(data)
    }
    else {
      const index = this.selectedMedications.indexOf(element.medication);
      if (index > -1) { // only splice array when item is found
        this.selectedMedications.splice(index, 1); // 2nd parameter means remove one item only
      }

    }


  }
  onChangeselectedInterventionAll(event, element) {
    this.allmedicationsifselected = []


    if (event.checked == true) {
      this.isAllexported = true
      this.exportAllDrugRegimen = true
      this.isAllexported = true
      this.dataSource1.filteredData.forEach(element => {


        let data = {}
        if (element.medDecision.toLowerCase() == 'claims') {
          data['dose'] = element.claimDose,
            data['units'] = element.claimUnits,
            data['route'] = element.claimRoute,
            data['frequency'] = element.claimFrequency
          data['daysOfSupply'] = element.snfDaysOfSupply
          data['quantity'] = element.claimQuantity
          data['lastFilledDate'] = element.claimLastFilledDate
          if (element.claimPRN == 'Yes') {
            data['instructions'] = " as needed for " + element.claimInstructions
          }
          if (element.claimPRN != 'Yes') {
            data['instructions'] = element.claimInstructions
          }
        }
        else if (element.medDecision.toLowerCase() == 'pos') {
          data['dose'] = element.ssDose,
            data['units'] = element.ssUnits,
            data['route'] = element.ssRoute,
            data['frequency'] = element.ssFrequency
          data['daysOfSupply'] = element.ssDaysOfSupply
          data['quantity'] = element.ssQuantity
          data['lastFilledDate'] = element.ssLastFilledDate
          if (element.ssPRN == 'Yes') {
            data['instructions'] = " as needed for " + element.ssInstructions
          }
          if (element.ssPRN != 'Yes') {
            data['instructions'] = element.ssInstructions
          }

        }
        else if (element.medDecision.toLowerCase() == 'ehr') {
          data['dose'] = element.ehrDose,
            data['units'] = element.ehrUnits,
            data['route'] = element.ehrRoute,
            data['frequency'] = element.ehrFrequency
          data['daysOfSupply'] = element.ehrDaysOfSupply
          data['quantity'] = element.ehrDaysOfSupplyQuantity
          data['lastFilledDate'] = element.ehrLastFilledDate
          if (element.ehrPRN == 'Yes') {
            data['instructions'] = " as needed for " + element.ehrInstructions
          }
          if (element.ehrPRN != 'Yes') {
            data['instructions'] = element.ehrInstructions
          }


        }
        else if (element.medDecision.toLowerCase() == 'inpatient') {
          data['dose'] = element.dsDose,
            data['units'] = element.dsUnits,
            data['route'] = element.dsRoute,
            data['frequency'] = element.dsrequency
          data['daysOfSupply'] = element.dsDaysOfSupply
          data['quantity'] = element.dsDaysOfSupplyQuantity
          data['lastFilledDate'] = element.dsLastFilledDate
          if (element.dsPRN == 'Yes') {
            data['instructions'] = " as needed for " + element.dsInstructions
          }
          if (element.dsPRN != 'Yes') {
            data['instructions'] = element.dsInstructions
          }

        }
        else if (element.medDecision.toLowerCase() == 'snf') {
          data['dose'] = element.snfDose,
            data['units'] = element.snfUnits,
            data['route'] = element.snfRoute,
            data['frequency'] = element.snfFrequency
          data['daysOfSupply'] = element.snfDaysOfSupply
          data['quantity'] = element.snfDaysOfSupplyQuantity
          data['lastFilledDate'] = element.snfLastFilledDate
          if (element.snfPRN == 'Yes') {
            data['instructions'] = " as needed for " + element.snfInstructions
          }
          if (element.snfPRN != 'Yes') {
            data['instructions'] = element.snfInstructions
          }


        }
        else if (element.medDecision.toLowerCase() == 'member reported') {
          data['dose'] = element.patDose,
            data['units'] = element.patUnits,
            data['route'] = element.patRoute,
            data['frequency'] = element.patFrequency
          data['daysOfSupply'] = element.patDaysOfSupply
          data['quantity'] = element.patDaysOfSupplyQuantity
          data['lastFilledDate'] = element.patLastFilledDate
          if (element.patPRN == 'Yes') {
            data['instructions'] = " as needed for " + element.patInstructions
          }
          if (element.snfPRN != 'Yes') {
            data['instructions'] = element.patInstructions
          }


        }
        data["medDecision"] = element.medDecision
        data['medication'] = element.medication
        data["medicationName"] = element.medication



        this.selectedMedications.push(data)
      }




      );

    }
    else {
      this.isAllexported = false
      this.exportAllDrugRegimen = false

      this.allmedicationsifnotselected = []
      this.dataSource1.filteredData.forEach(element => {

        this.allmedicationsifnotselected.push(element.medication)




      });

    }
  }
  getReportdate() {
    return getDate()
  }

  performPreLogoutActions(){
    if(this._route.url.includes('patientdetails')){
      this.saveAllMedRegime()
    }
  }
  getAllmedicationsFromRegime() {
    this.allmedicationsifnotselected = []
    this.dataSource1.filteredData.forEach(element => {

this.allmedicationsifnotselected.push(element)
    }





    );
    return this.allmedicationsifnotselected;

  }

  selectedTechCheck(data) {
    return data.split('-')
  }

  openDeleteMedicationPopUp(data, dataHistory) {


    console.log(data)

    const addDialogue = this.dialog.open(ConfirmDeleteComponent, {
      height: '30%',
      width: '30%',
      data: {
        isNeedReason: true,
        drugName: data.DRUGNAME
      },
    });


    addDialogue.afterClosed().subscribe((res) => {


      debugger

      if (res.data == 'Yes') {
        console.log(res.deleteReason)
        const index = dataHistory.filteredData.indexOf(data);
        if (index > -1) { // only splice array when item is found

          dataHistory.filteredData.splice(index, 1);

          this.dataSourceHistory = new MatTableDataSource(dataHistory.filteredData);

          let inputRequest = {

            "firstName": this.memberdata.firstName,
            "lastName": this.memberdata.lastName,
            "dob": this.memberdata.dob,
            "medsource": data.SRC,
            "drugName": data.DRUGNAME,
            "gpi": data.GPI,
            "deletedReason": res.deleteReason
          }

          this.patientDataService.deleteManuallyAddedMedication(inputRequest).subscribe(data => {

            this.toastr.success("Medication " + inputRequest.drugName + " Deleted Successfully !", '', {
              timeOut: 1500,
            })
            this.callHistoryMedications();

          }, error => {
            this.toastr.error("Some Error Occured", '', {
              timeOut: 1500,
            })

          })

        }
      }
    })

  }



}





export interface Data {
  medication: string;
  drugCategory: string;
  probability: string;
  id: string;
  status: string;
  reason: string;
  authoredOn: string;
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  lastUpdatedDate1: string;
}
const ELEMENT_DATA: Data[] = [
];
function downloadPatientReport(data: any) {
  throw new Error('Function not implemented.');
}

