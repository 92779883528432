<app-header></app-header>
<div>
    <div class="container-fluid mt-4">
        <div class="containerbg">
            <div class="row buttonList">
                <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <mat-card (click)="addEncounter()">
                        <mat-card-header class="align-items-center">
                            <div mat-card-avatar class="example-header-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="28.373" height="39"
                                    viewBox="0 0 28.373 39">
                                    <g id="Layer_1" transform="translate(-9.811 -4.5)">
                                        <path id="Path_121" data-name="Path 121"
                                            d="M28.941,8.069l-.392-.023a4.707,4.707,0,0,0-9.123,0l-.392.023H11.14A1.329,1.329,0,0,0,9.811,9.4V42.171A1.329,1.329,0,0,0,11.14,43.5H36.855a1.329,1.329,0,0,0,1.329-1.329V9.4a1.329,1.329,0,0,0-1.329-1.329Z"
                                            fill="#002677" />
                                        <path id="Path_122" data-name="Path 122"
                                            d="M35.2,41.136H12.8a.623.623,0,0,1-.623-.623V11.057a.623.623,0,0,1,.623-.623h3.124a3.511,3.511,0,0,0-.21,1.16v1.182H32.263V11.593a3.51,3.51,0,0,0-.21-1.16H35.2a.623.623,0,0,1,.623.623V40.513A.623.623,0,0,1,35.2,41.136Z"
                                            fill="#fff" />
                                        <path id="Path_123" data-name="Path 123"
                                            d="M21.933,32.636l-4.7-4.7a1.773,1.773,0,0,1,0-2.508h0a1.773,1.773,0,0,1,2.508,0l2.191,2.191L28.249,21.3a1.773,1.773,0,0,1,1.254-.519h0a1.773,1.773,0,0,1,1.254,3.027Z"
                                            fill="#ff612b" />
                                    </g>
                                </svg>
                            </div>
                            <mat-card-title>Add Encounter</mat-card-title>
                            <mat-card-subtitle>Create new encounters for reconciliation or review
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <mat-card (click)="openoutreachqueue()">
                        <mat-card-header class="align-items-center">
                            <div mat-card-avatar class="example-header-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                                    viewBox="0 0 38.976 38.976">
                                    <g id="Layer_1" transform="translate(-4.514 -4.491)">
                                        <path id="Path_65" data-name="Path 65"
                                            d="M22.482,22.459H6.836a2.322,2.322,0,0,1-2.322-2.322V6.813A2.322,2.322,0,0,1,6.836,4.491H20.16a2.322,2.322,0,0,1,2.322,2.322Z"
                                            fill="#ff612b" />
                                        <path id="Path_66" data-name="Path 66"
                                            d="M41.168,22.459H25.522V6.813a2.322,2.322,0,0,1,2.322-2.322H41.168A2.322,2.322,0,0,1,43.49,6.813V20.137A2.322,2.322,0,0,1,41.168,22.459Z"
                                            fill="#002677" />
                                        <path id="Path_67" data-name="Path 67"
                                            d="M20.16,43.467H6.836a2.322,2.322,0,0,1-2.322-2.322V27.821A2.322,2.322,0,0,1,6.836,25.5H22.482V41.145A2.322,2.322,0,0,1,20.16,43.467Z"
                                            fill="#ff612b" />
                                        <path id="Path_68" data-name="Path 68"
                                            d="M41.168,43.467H27.844a2.322,2.322,0,0,1-2.322-2.322V25.5H41.168a2.322,2.322,0,0,1,2.322,2.322V41.145A2.322,2.322,0,0,1,41.168,43.467Z"
                                            fill="#ff612b" />
                                    </g>
                                </svg>
                            </div>
                            <mat-card-title>Encounter Queue</mat-card-title>
                            <mat-card-subtitle>Manage encounters in work queue</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 mb-2" style="display: none;">
                    <mat-card (click)="addpatient()">
                        <mat-card-header class="align-items-center">
                            <div mat-card-avatar class="example-header-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38.987" height="38.382"
                                    viewBox="0 0 38.987 38.382">
                                    <g id="Layer_1" transform="translate(-4.503 -4.809)">
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="13.024" cy="13.024" r="13.024"
                                            transform="translate(6.963 7.269)" fill="#fff" />
                                        <g id="Group_157" data-name="Group 157">
                                            <path id="Path_74" data-name="Path 74"
                                                d="M33.11,28.323a15.293,15.293,0,0,0,2.154-10.087A15.684,15.684,0,0,0,21.848,4.919a15.408,15.408,0,0,0-4.015,30.553A15.293,15.293,0,0,0,27.92,33.318l9.4,8.813a3.616,3.616,0,0,0,6.169-2.546h0a3.556,3.556,0,0,0-1.077-2.546Zm-13.122,4.9A13.082,13.082,0,0,1,6.963,20.2,13.024,13.024,0,1,1,19.988,33.22Z"
                                                fill="#002677" />
                                        </g>
                                        <g id="Group_158" data-name="Group 158">
                                            <path id="Path_75" data-name="Path 75"
                                                d="M10.668,20.194a1.234,1.234,0,0,1-.153-.009,1.25,1.25,0,0,1-1.089-1.392,10.562,10.562,0,0,1,8.512-9.034,1.25,1.25,0,0,1,.45,2.459A8.041,8.041,0,0,0,11.908,19.1,1.25,1.25,0,0,1,10.668,20.194Z"
                                                fill="#ff612b" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <mat-card-title>Add Member</mat-card-title>
                            <mat-card-subtitle>Add member for reconciliation or review
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <mat-card (click)="openpatientAssignment()">
                        <mat-card-header class="align-items-center">
                            <div mat-card-avatar class="example-header-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="31.098" height="39"
                                    viewBox="0 0 31.098 39">
                                    <g id="Layer_1" transform="translate(-8.35 -4.5)">
                                        <g id="Group_153" data-name="Group 153">
                                            <g id="Group_149" data-name="Group 149">
                                                <g id="Group_148" data-name="Group 148">
                                                    <g id="Group_147" data-name="Group 147">
                                                        <path id="Path_69" data-name="Path 69"
                                                            d="M27.049,20.978v7.9a9.349,9.349,0,1,1-18.7,0V18.028a1.448,1.448,0,1,1,2.9,0V22.9H12.3V12.287a1.448,1.448,0,0,1,2.9,0V22.9h1.053V9.917a1.448,1.448,0,0,1,2.9,0V22.9H20.2V12.287a1.448,1.448,0,0,1,2.9,0v16.1l1.053.369V20.978a1.448,1.448,0,0,1,2.9,0Z"
                                                            fill="#002677" />
                                                    </g>
                                                </g>
                                            </g>
                                            <g id="Group_152" data-name="Group 152">
                                                <g id="Group_151" data-name="Group 151">
                                                    <g id="Group_150" data-name="Group 150">
                                                        <path id="Path_70" data-name="Path 70"
                                                            d="M38.9,26.245v7.9a9.349,9.349,0,1,1-18.7,0V23.3a1.448,1.448,0,1,1,2.9,0v4.867h1.053V17.555a1.448,1.448,0,0,1,2.9,0V28.162H28.1V15.184a1.448,1.448,0,0,1,2.9,0V28.17h1.053V17.555a1.448,1.448,0,0,1,2.9,0V31.87L36,32.239V26.245a1.448,1.448,0,0,1,2.9,0Z"
                                                            fill="#ff612b" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                        <g id="Group_154" data-name="Group 154">
                                            <path id="Path_71" data-name="Path 71"
                                                d="M27.859,11.712a1.145,1.145,0,0,1-1.129-.958l-.82-4.922a1.146,1.146,0,1,1,2.26-.376l.82,4.922a1.146,1.146,0,0,1-.942,1.318A1.239,1.239,0,0,1,27.859,11.712Z"
                                                fill="#002677" />
                                        </g>
                                        <g id="Group_155" data-name="Group 155">
                                            <path id="Path_72" data-name="Path 72"
                                                d="M32.039,11.721a1.167,1.167,0,0,1-.2-.017,1.146,1.146,0,0,1-.931-1.326l.861-4.915a1.146,1.146,0,0,1,2.257.4l-.861,4.915A1.146,1.146,0,0,1,32.039,11.721Z"
                                                fill="#002677" />
                                        </g>
                                        <g id="Group_156" data-name="Group 156">
                                            <path id="Path_73" data-name="Path 73"
                                                d="M35.806,13.582a1.145,1.145,0,0,1-.991-1.719L37.31,7.542a1.146,1.146,0,1,1,1.984,1.146L36.8,13.009A1.144,1.144,0,0,1,35.806,13.582Z"
                                                fill="#002677" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <mat-card-title>Encounter Assignment</mat-card-title>
                            <mat-card-subtitle>Assign encounters to individuals on the team</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <mat-card (click)="openpatientlookup()">
                        <mat-card-header class="align-items-center">
                            <div mat-card-avatar class="example-header-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38.987" height="38.382"
                                    viewBox="0 0 38.987 38.382">
                                    <g id="Layer_1" transform="translate(-4.503 -4.809)">
                                        <circle id="Ellipse_3" data-name="Ellipse 3" cx="13.024" cy="13.024" r="13.024"
                                            transform="translate(6.963 7.269)" fill="#fff" />
                                        <g id="Group_157" data-name="Group 157">
                                            <path id="Path_74" data-name="Path 74"
                                                d="M33.11,28.323a15.293,15.293,0,0,0,2.154-10.087A15.684,15.684,0,0,0,21.848,4.919a15.408,15.408,0,0,0-4.015,30.553A15.293,15.293,0,0,0,27.92,33.318l9.4,8.813a3.616,3.616,0,0,0,6.169-2.546h0a3.556,3.556,0,0,0-1.077-2.546Zm-13.122,4.9A13.082,13.082,0,0,1,6.963,20.2,13.024,13.024,0,1,1,19.988,33.22Z"
                                                fill="#002677" />
                                        </g>
                                        <g id="Group_158" data-name="Group 158">
                                            <path id="Path_75" data-name="Path 75"
                                                d="M10.668,20.194a1.234,1.234,0,0,1-.153-.009,1.25,1.25,0,0,1-1.089-1.392,10.562,10.562,0,0,1,8.512-9.034,1.25,1.25,0,0,1,.45,2.459A8.041,8.041,0,0,0,11.908,19.1,1.25,1.25,0,0,1,10.668,20.194Z"
                                                fill="#ff612b" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <mat-card-title>Member Management</mat-card-title>
                            <mat-card-subtitle>Manage existing or add new members
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-12 mb-2">
                    <mat-card (click)="openreport()">
                        <!--(click)="openreport()" -->
                        <mat-card-header class="align-items-center">
                            <div mat-card-avatar class="example-header-image">
                                <svg xmlns="http://www.w3.org/2000/svg" width="38.232" height="37.04"
                                    viewBox="0 0 38.232 37.04">
                                    <g id="Icon_PieChart_RGB" transform="translate(-4.884 -5.48)">
                                        <path id="Path_76" data-name="Path 76"
                                            d="M43.116,25.142A19.721,19.721,0,0,0,24.579,5.48v18.7L38.353,37.955A19.428,19.428,0,0,0,43.116,25.142Z"
                                            fill="#ff612b" />
                                        <g id="Group_159" data-name="Group 159">
                                            <path id="Path_77" data-name="Path 77"
                                                d="M22.262,7.763A17.329,17.329,0,0,0,5.97,31.131l16.292-7.239V7.763Z"
                                                fill="#002677" />
                                            <path id="Path_78" data-name="Path 78"
                                                d="M23.153,26.033,6.9,33.252a17.356,17.356,0,0,0,27.641,4.172Z"
                                                fill="#002677" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <mat-card-title>Reports</mat-card-title>
                            <mat-card-subtitle>Access and extract productivity reports</mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>

            </div>
        </div>

    </div>
</div>