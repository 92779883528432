import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';
import { titleCase } from '../utilityMethods/helperMethods';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-membersearch',
  templateUrl: './membersearch.component.html',
  styleUrls: ['./membersearch.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class MembersearchComponent implements OnInit {

  private gridApi;
  private gridColumnApi;
  private params;
  columnDefs: any;
  page: any;
  private defaultColDef;
  searchPatient: FormGroup;
  private rowHeight
  //rowData: Observable<any[]>;
  rowData: any = []
  isSpinner: boolean;
  dataNotFound: boolean;
  isHidden: boolean = true;
  pagination = true;
  paginationPageSize = 25;
  patientSearchData: any;
  dataSource: any;
  searchPatientObj = {
    mrn: '',
    firstName: '',
    primaryPhone: '',
    gender: '',
    dob: '',
    age: '',
    city: '',
    state: '',
    email: '',
    zipCode: '',

  }

  @HostListener('document:keypress', ['$event'])
keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      this.serverSearch()
    }
}


  constructor(private fb: FormBuilder, private http: HttpClient, public patientDataService: DataService, public route: Router, private ActRoute: ActivatedRoute, private toastr: ToastrService) {
    this.searchPatient = this.fb.group(this.searchPatientObj);
    this.rowData = this.rowData
    this.columnDefs = [

      { field: 'mbrRefNo', headerName: 'Mbr Ref No', sortable: true, filter: false, pinned: 'left', suppressSizeToFit: true, width: 160 },
      { valueGetter:this.getName, headerName: 'Name', sortable: true, filter: false, pinned: 'left', suppressSizeToFit: true, width: 220 },
      { field: 'gender',  headerName: 'Gender', sortable: true, filter: false, suppressSizeToFit: true, width: 110 },
      { valueGetter:this.getDOBAge, headerName: "DOB (Age)", sortable: true, filter: false, pinned: 'left', suppressSizeToFit: true, width: 150 },
      { valueGetter: this.getPCP, headerName: "PCP", sortable: true, filter: false, pinned: 'left', suppressSizeToFit: true, width: 200 },
      { valueGetter: this.getFormattedprimaryphone, headerName: 'Primary Phone', sortable: true, filter: false },
      { valueGetter: this.getFormattedsecondaryphone, headerName: 'Secondary Phone', sortable: true, filter: false },
      { valueGetter: this.getEmail, headerName: 'Email', sortable: true, filter: false },
      { valueGetter: this.getCity, headerName: 'City', sortable: true, filter: false },
      { field: 'state', headerName: 'State', sortable: true, filter: false, suppressSizeToFit: true, width: 100 },
      { valueGetter: this.getZipCode, headerName: 'Zip Code', sortable: true, filter: false, suppressSizeToFit: true, width: 150 },
      { field: 'preferredLanguage', headerName: 'Primary Language', sortable: true, filter: false, suppressSizeToFit: true, width: 150 },
      { valueGetter: this.getEligibiltyStatus, headerName: 'Eligibility Status', sortable: true, filter: false, suppressSizeToFit: true, width: 150 }
    ];
  }







  getName(params) {
    return titleCase(params.data.firstName)+ " " + titleCase(params.data.lastName);
  };
  getDOBAge(params) {
   let  dob=params.data.dob
   if (dob != null && dob != undefined) {
    var dobformat = formatDate(dob,'MM-dd-yyyy','en-US')
  }
    if (dob != null || dob != undefined) {
      let dobarr = dob.split("-")
      let birthMonth: any = dobarr[1]
      let birthDay: any = dobarr[2];
      let birthYear: any = dobarr[0]
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();
      var calculatedAge = currentYear - birthYear;

      if (currentMonth < birthMonth - 1) {
        calculatedAge--;
      }
      if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
        calculatedAge--;
      }
      return  dobformat+" ("+calculatedAge+")";
    }
    else {
      return " (NA)"
    }
    
    
  };
 
  getPCP(params) {
    return titleCase(params.data.pcp);
  };
  planName(params) {
    return params.data.abbrevation + " - " + params.data.name + " ";
  };
  getCity(params) {
    return titleCase(params.data.city);
  };
  getEmail(params) {
    return params.data.email.toLowerCase();
  };
  onRowSelect(event) {


    const selectedRows = this.gridApi.getSelectedRows();
    console.log(selectedRows);
    this.route.navigate(['/editMember/' + selectedRows[0]['mbrRefNo'].toString()]);


  }





  ngOnInit() {
   

    this.ActRoute.params.subscribe(params => {
      if (params.data) {
        this.searchPatient.get('mrn').setValue(params.data);
        this.serverSearch()
      }
    }, (err: Error) => {
      console.log(err)

    });
  }





  serverSearch() {
    this.isSpinner = true;
    let payload = this.searchPatient.value
    console.log("this.searchPatientObj::::" + payload);
    this.patientDataService.getserachmemberdata(payload).subscribe(data => {


      this.rowData = data
      if (this.rowData.length < 1) {
        this.toastr.error("Member Not Found In MR ,Please Click On Add Member",'',{
          timeOut: 1500,
        });
      }
      else {
        this.toastr.success("Member  Found In MR ,Please Create Encounter",'',{
          timeOut: 1500,
        });

      }
      this.isSpinner = false;


    }, error => {
      this.toastr.error("Some Issue Please Contact System Administrator",'',{
        timeOut: 1500,
      });

      this.isSpinner = false;

    })


  }
  getRowStyle(params) {
    if (params["data"].statusFlag != "Y") {
      return {
        background: 'yellow',
      };
    }
  }

  clearSearch() {
    this.searchPatient.patchValue({
      mrn: '',
      name: '',
      phone: '',
      sex: '',
      dob: '',
      age: '',
      city: '',
      state: '',
      email: '',
      zipCode: '',
      abbrevation: '',
      admitstart: '',
      admitend: '',
      Dischargedate: ''
    })




  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.headerHeightSetter();

  }

  defaultColDefs = {
    resizable: true, wrapText: true, filter: true,

    autoHeight: true, headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation">' +
        '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
        '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
        '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
        '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
        '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
        '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
        '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
        '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
        '  </div>' +
        '</div>',
    }
  }

  downloadPatientData() {
    this.gridApi.exportDataAsCsv();

  }

  goBackHome() {

  }
  getZipCode(params) {
    return params.data.zipCode
  }
  getEligibiltyStatus(params) {
    return params.data.planElStatus.substring(0, 1)
  }
  getFormattedprimaryphone(params) {
    if (params.data.primaryContactNumber.length == 10) {
      return "(" + params.data.primaryContactNumber.substring(0, 3) + ") " + + params.data.primaryContactNumber.substring(3, 6) + "-" + params.data.primaryContactNumber.substring(6, 10)
    }
    return params.data.primaryContactNumber

  }
  getFormattedsecondaryphone(params) {
    if (params.data.alternateContactNumber.length == 10) {
      return "(" + params.data.alternateContactNumber.substring(0, 3) + ") " + + params.data.alternateContactNumber.substring(3, 6) + "-" + params.data.alternateContactNumber.substring(6, 10)
    }
    return params.data.alternateContactNumber

  }
  navigateToAddMember() {
    this.route.navigate(['addMember'])
  }
  getAge(dob) {
   
  }

}

interface PlanNameAbb {
  abbValue: string;
  abbValueName: string;
}