import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmDeleteComponent } from '../confirm-delete/confirm-delete.component';
import { DataService } from '../data.service';
import { titleCase } from '../utilityMethods/helperMethods';
import { ShowEncounterLogComponent } from '../show-encounter-log/show-encounter-log.component';


@Component({
  selector: 'app-encounter-queue',
  templateUrl: './encounter-queue.component.html',
  styleUrls: ['./encounter-queue.component.css']
})
export class EncounterQueueComponent implements OnInit {

  queuespinner=true;
  itemsPerPage = 10;
  page: any;
  isSpinner: boolean;
  public ipatientDetails: any
  public errorMsg;
  patientMrnValue: string;
  patientList: any=[];
  currList:any;
  searchFail = false;
  totalPages = [1];
  currentPage = 1;
  filterPatient: string;
  onAddFilterdatas = []
  onAddSortdatas = []
  showNewCases = true;
  showPendingCases = true;
  filteredCategory: any;

  selectedCategory: any;
  selectedUpdatedBy: any;
  selectedPatName: any;
  filters = {}
  showFilterWRap = {
    customizeDiv: false,
    filterDiv: false,
  }
  globalSearchText: any = "";
  unassignedTech: any;
  texttech: any;
  isOtherButtonClicked = false

  showAssign = false;
  encounterData :any;
  filteredPatientDetails;
  patientDetail: Object;
  //  nestedPatientList: Object;
  filteredSystemStatus: Array<any>;
  medicationlist: Object;
  backButtonVisible: boolean = false;
  filteredDrugCategory: Observable<any[]>;
  filteredUpdatedBy: Observable<any[]>;
  filteredPatName: Observable<any[]>;
  drugCategory = new Set();
  medlastUpdatedBy = new Set();
  medPatName = new Set();
  noData: string;

  getMedCountd = new Set();
  ismuliserach: any;
  filter: any = "Search"

  snackBarRef: any;
  isNewCases = false
  isRenewCases = false
  region: any = ""
  constructor(public dialog: MatDialog, public router: Router, public dataservice: DataService, private toastr: ToastrService,) {



  }
  mrnCount: any;
  medCount: any;
  selectedRows: any;
  overAllMedcount = 0;
  memberdetails: any
  cdo: any = localStorage.getItem('cdo')
  isAll:boolean = false;
  isAssigned:boolean = false;
  updateStatusList:any = [];

  ngOnInit(): void {
    debugger
    this.getEncounterQueue()
  }

  getEncounterQueue() {
    this.isSpinner=true
    this.dataservice.getEncounterworkqueudata().subscribe((data:any) => {
      this.dataservice.getUpdateStatus().subscribe(ele =>{
        this.updateStatusList = ele

      data.forEach(ele =>{
        if(this.updateStatusList.includes(ele.encounterId)){
          ele.isStatus = "solid 8px #f2b411"
        }
      })

     // this.patientList = data
      data.forEach(ele=>{

       
        delete ele["gender"];
        delete ele["payor"];
        delete ele["facilityName"];
        delete ele["encounterDate"];
        delete ele["visitDate"];
        delete ele["requestor"];
        delete ele["comments"];
        delete ele["admitDate"];
        delete ele["dischargeDate"];
        delete ele["diagnosedFor"];
        delete ele["dataEntryBy"];
        delete ele["dataEntryDate"];
        delete ele["visitDate"];
        delete ele["reconciledDate"]
        delete ele["closedBy"]
        delete ele["closeDate"]
        delete ele["deletedBy"]
        delete ele["deletedDate"]
        delete ele[ "deletedReason"]
        delete ele["lastfollowupdt"]
        delete ele["createdDt"]
        delete ele["pharmacist"]
        delete ele["previousStatus"]
        delete ele["remarks"]
        delete ele["flag"]
        this.patientList.push(ele)

      })
      this.isSpinner = false
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review')||e['status'].toLowerCase()==('reconciled') || e['status'].toLowerCase()==('reviewed')));
      this.currList = this.memberdetails;
      this.assignOnlOad()

      this.queuespinner=false;
      })




    }, error => {
     this.isSpinner=false

    });
  }
  resetQueueState(){
    this.isAssigned = false;
    this.isAll = false;
    this.assigntome(false)
    this.allEncounters(false)


  }
  getAge(dob) {
    if (dob != null || dob != undefined) {
      let dobarr = dob.split("-")
      let birthMonth: any = dobarr[1]
      let birthDay: any = dobarr[2];
      let birthYear: any = dobarr[0]
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();
      var calculatedAge = currentYear - birthYear;

      if (currentMonth < birthMonth - 1) {
        calculatedAge--;
      }
      if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
        calculatedAge--;
      }
      return calculatedAge;
    }
    else {
      return "NA"
    }
  }

  viewUnassigned(event) {
    console.log(event);
    localStorage.setItem("unassignedTech", event.toString());
    window.location.reload();
  }
  viewTextworkqueue(event) {
    console.log(event);
    localStorage.setItem("texttech", event.toString());
    window.location.reload();
  }



  private _filter(source, value: string): string[] {
    const filterValue = value.toLowerCase();
    return source.filter(option => option.toLowerCase().includes(filterValue));

  }

  backbutton() {


  }

  FadeOutSearchFail() {
    setTimeout(() => {
      this.searchFail = false;
    }, 5000);
  }

  setPage(currentPage) {
    console.log("current Page>>", currentPage);
    localStorage.setItem('page', currentPage);

  }





  /* start */
  name: 'Indeterminate';
  isChecked: false;
  checkCount: false;
  allComplete: boolean = false;
  mrnArray: any[];

  updateAllComplete() {
    this.allComplete = this.filteredSystemStatus != null && this.filteredSystemStatus.every(t => t.isChecked);
    console.log(this.filteredSystemStatus);
    console.log("updateALL>>>>>", this.filteredSystemStatus.filter(t => t.isChecked))
    this.updateCount();
  }
  updateCount() {
    this.mrnCount = 0;
    this.medCount = 0;
    this.overAllMedcount = 0;
    //this.mrnCount = this.filteredSystemStatus.filter(t => t.isChecked === true).length;
    this.selectedRows = this.filteredSystemStatus.filter(t => t.isChecked === true);
    this.mrnCount = this.selectedRows.length;
    this.selectedRows.forEach((t) => this.medCount = this.medCount + t.medicationDetails.length)

    this.filteredSystemStatus.forEach((t) => this.overAllMedcount = this.overAllMedcount + t.medicationDetails.length)



  }

  someComplete(): boolean {
    if (this.filteredSystemStatus == null) {
      return false;
    }
    return this.filteredSystemStatus.filter(t => t.isChecked).length > 0 && !this.allComplete;
  }

  setAll(isChecked: boolean) {
    this.allComplete = isChecked;
    if (this.filteredSystemStatus == null) {

      return;
    }
    this.filteredSystemStatus.forEach(t => (t.isChecked = isChecked));
    this.updateCount();
  }
  edit(data) {

    this.isOtherButtonClicked = true
    if(data.status.toLowerCase() == 'closed'|| data.status.toLowerCase() == 'completed'){
      this.toastr.error("Encounter Is Already Closed Or Completed")
      this.isOtherButtonClicked = false
      return

    }

    this.router.navigate(['/editEncounter/' + data.encounterId]); //610882
  }
  rowclick(data, event) {
    debugger



    localStorage.setItem('encounterId', data.encounterId)


    if (!this.isOtherButtonClicked) {
      this.router.navigate(['/patientdetails/' + data.mbrRefNo]);
    }
  }
  assigntome(value) {

    console.log(value)
    if ((value)&&(!this.isAll)) {
      this.memberdetails = this.currList.filter(this.getassigned);
      this.currList = this.memberdetails
      this.isAssigned = true
      console.log('1')
    }
    else if((value)&&(this.isAll)){
      this.memberdetails = this.currList.filter(this.getassigned);
      this.currList = this.memberdetails
      this.isAssigned= true
      console.log('2')
    }
    else if((!value)&&(this.isAll)){
      this.memberdetails=this.patientList
      this.currList = this.memberdetails
      this.isAssigned = false
      console.log('3')
    }
    else {
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review')||e['status'].toLowerCase()==('reconciled') || e['status'].toLowerCase()==('reviewed')));
      this.currList = this.memberdetails
      this.isAssigned = false
      console.log('4')
    }
  }
  getassigned(obj) {
    return obj.assignedTo == localStorage.getItem('usname');
  }
  globalSearch() {
    localStorage.setItem("searchTerm", this.globalSearchText);
    if (this.globalSearchText.includes("&")) {
      console.log(this.globalSearchText.split("&"));

      this.memberdetails = this.searchData(this.currList, this.globalSearchText.split("&")[0]);
      for (let ic: any = 1; ic <= this.globalSearchText.split("&").length; ic++) {
        this.memberdetails = this.searchData(this.memberdetails, this.globalSearchText.split("&")[ic]);

      }

    }
    else {
      this.memberdetails = this.searchData(this.currList, this.globalSearchText);

      this.page = 1;
    }
  }
  searchData(dataObj, searchValue: any = '') {

    return dataObj.flatMap(ele => {
      const objHasSearchTerm = Object.entries(ele)
        .some(([key, value]) => key !== 'medicationDetails' && String(value).toLowerCase().includes(searchValue.toLowerCase()));

      if (objHasSearchTerm) return [ele]

      const matchedMedication = this.searchData(ele.medicationDetails ?? [], searchValue);
      let sd = objHasSearchTerm || matchedMedication.length > 0
        ? [{
          ...ele,
          medicationDetails: matchedMedication,
        }]
        : [];

      return sd;
    })

  }
  assignOnlOad(){
    debugger
    let value=this.isAssigned
    if ((value)&&(!this.isAll)) {
      this.memberdetails = this.currList.filter(this.getassigned);
      this.currList = this.memberdetails
      this.isAssigned = true
      console.log('1')
    }
    else if((value)&&(this.isAll)){
      this.memberdetails = this.currList.filter(this.getassigned);
      this.currList = this.memberdetails
      this.isAssigned= true
      console.log('2')
    }
    else if((!value)&&(this.isAll)){
      this.memberdetails=this.patientList
      this.currList = this.memberdetails
      this.isAssigned = false
      console.log('3')
    }
    else {
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review')||e['status'].toLowerCase()==('reconciled')  || e['status'].toLowerCase()==('reviewed')));
      this.currList = this.memberdetails
      this.isAssigned = false
      console.log('4')
    }

  }
  resetSearch() {

    this.globalSearchText = "";
    console.log("searchempy>>>", this.globalSearchText)

    this.globalSearchText = "";
    localStorage.setItem("searchTerm", this.globalSearchText);
    this.memberdetails = this.currList;
   
  }

  delete(data) {

    console.log(data)
    this.isOtherButtonClicked = true

    const addDialogue = this.dialog.open(ConfirmDeleteComponent, {
      height: '30%',
      width: '30%',
      data: {
        isNeedReason:false

      },
    });


    addDialogue.afterClosed().subscribe((res) => {

      if (res.data == 'Yes') {
        this.dataservice.deleteEncounter(data.encounterId).subscribe(data => {
          console.log(data)
          this.toastr.success("Encounter Deleted Successfully",'',{
            timeOut: 1500,
                    });
          this.isOtherButtonClicked = false
          this.getEncounterQueue()

        }, error => {
          this.toastr.error("Issue In Data Saving",'',{
            timeOut: 1500,
                    });

        })

      }

    }, (err: Error) => {
      console.log(err)
    })
  }
  close(data) {

    console.log(data)
    this.isOtherButtonClicked = true
    let input = {
      "encounterId": data.encounterId,
      "pharmacist": localStorage.getItem("usname"),
      "previousStatus":data.status
    }
    this.dataservice.updateEncounterStatus(input, "close","false").subscribe(data => {
      this.toastr.success("Encounter Closed Successfully",'',{
        timeOut: 1500,
                });
        this.isOtherButtonClicked = false
      this.getEncounterQueue()


    })

  }
  showLog(data){
    this.dataservice.getEncounterLog(data.encounterId).subscribe(data => {
      var element:any = data
      element.forEach((ele)=>(ele.createdDate = new Date(ele.createdDate)))
      console.log('asc',element)
      const addDialogue = this.dialog.open(ShowEncounterLogComponent, {
        height: '50%',
        width: '40%',
        data: element
      });
    })




  }
  getTitleCase(value) {
    return titleCase(value);
  }

  allEncounters(value){
    console.log(value)
    if ((value)&&(!this.isAssigned)) {
      this.memberdetails = this.patientList
      this.currList = this.memberdetails
      this.isAll = true
      console.log('1-2')
    }
    else if((value)&&(this.isAssigned)){
      this.currList = this.patientList
      this.memberdetails = this.currList.filter(this.getassigned)
      this.currList = this.memberdetails
      this.isAll = true
      console.log('2-2')
    }
    else if((!value)&&(this.isAssigned)){
      this.memberdetails=this.currList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review')||e['status'].toLowerCase()==('reconciled') || e['status'].toLowerCase()==('reviewed')))
      this.currList = this.memberdetails
      this.isAll = false
      console.log('3-2')
    }
    else {
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review')||e['status'].toLowerCase()==('reconciled') || e['status'].toLowerCase()==('reviewed')));
      this.currList = this.memberdetails
      this.isAll = false
      console.log('4-2')
    }

    }
  }




  /* End */




function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
