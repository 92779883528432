import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from '@angular/material/dialog';
import { TimeoutDialogMemberDetailsComponent } from './timeout-dialog-member-details/timeout-dialog-member-details.component';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { IdleTimeoutService } from './idle-timeout.service';
import { app_config } from 'src/assets/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{

  title = 'SubmitterDashboard';
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;

  constructor(private idle: Idle, keepalive: Keepalive, cd: ChangeDetectorRef,public dialog: MatDialog,private idleService: IdleTimeoutService, private router: Router) {
    // set idle parameters
    // idle.setIdle(20); // how long can they be inactive before considered idle, in seconds
    // idle.setTimeout(5); // how long can they be idle before considered timed out, in seconds
    // idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // // do something when the user becomes idle
    // idle.onIdleStart.subscribe(() => {
    //   this.idleState = "IDLE";
    //   console.log("getting idle")
    // });
    // // do something when the user is no longer idle
    // idle.onIdleEnd.subscribe(() => {
    //   console.log(" idle khtm ")

    //   this.idleState = "NOT_IDLE";
    //   console.log(`${this.idleState} ${new Date()}`)
    //   this.countdown = null;
    //   cd.detectChanges(); // how do i avoid this kludge?
    // });
    // // do something when the user has timed out
    // idle.onTimeout.subscribe(() => this.idleState = "TIMED_OUT");
    // // do something as the timeout countdown does its thing
    // idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);

    // // set keepalive parameters, omit if not using keepalive
    // keepalive.interval(15); // will ping at this interval while not idle, in seconds
    // keepalive.onPing.subscribe(() => this.lastPing = new Date()); // do something when it pings
  }
  ngOnInit(): void {
    // this.reset();
    this.idle.setIdle(app_config.timeout);
    // this.idle.setIdle(5) // 5 seconds of inactivity
    this.idle.setTimeout(10); // 10 seconds to timeout after idle
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.idle.onIdleEnd.subscribe(() => console.log('No longer idle.'));
    // this.idle.onTimeout.subscribe(() => this.logout());
    this.idle.onIdleStart.subscribe(() => {
      console.log(this.router.url)
      if(this.router.url != '/login'){
        this.openIdleDialog()
      }
      
    });
    this.idle.onTimeoutWarning.subscribe((countdown) => console.log('You will time out in ' + countdown + ' seconds!'));
    this.idle.watch();
   
  }
  
  openIdleDialog(): void {
    const dialogRef = this.dialog.open(TimeoutDialogMemberDetailsComponent,{
      height:'25%',
      width:'25%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result === 'continue') {
        this.idle.watch();
      } else {
        this.logout();

      }
    });
  }
  logout() {    
    console.log('logging out')
    this.idleService.triggerIdleTimeout();
    localStorage.clear()
    this.router.navigate(['/login'])  
    window.location.reload();
    setTimeout(() => {
    
    }, 2000);
    
    console.log('Logged out due to inactivity')
  }

  // reset() {
  //   // we'll call this method when we want to start/reset the idle process
  //   // reset any component state and be sure to call idle.watch()
  //   this.idle.watch();
  //   this.idleState = "NOT_IDLE";
  //   this.countdown = null;
  //   this.lastPing = null;
  // }

 
}
