<div fxLayout="column" class="innSpinner" *ngIf="isSpinner">

    <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>
<h2 mat-dialog-title>
    <div class="dialog-title row">

        <div class="col-md-12 d-flex align-items-center">
            <div class="col-md-11">
                <h2>
                    <span style="margin: 0 10px 0 0;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="21.961" height="21.741" viewBox="0 0 21.961 21.741">
                            <g id="Layer_1" transform="translate(-4.503 -4.702)">
                              <g id="Group_433" data-name="Group 433" transform="translate(4.503 4.702)">
                                <path id="Path_162" data-name="Path 162" d="M8.2,12.1a3.7,3.7,0,0,0,3.626-2.98h3.659l-.846.846a.717.717,0,1,0,1.013,1.013L18.228,8.4,15.651,5.822a.717.717,0,0,0-1.013,1.013l.846.847H11.826A3.7,3.7,0,1,0,8.2,12.1Zm0-5.96a2.259,2.259,0,0,1,2.136,1.547H8.36a.717.717,0,1,0,0,1.433h1.976A2.259,2.259,0,1,1,8.2,6.136Z" transform="translate(-4.503 -4.702)" fill="#002657"/>
                                <path id="Path_163" data-name="Path 163" d="M33.345,12.025v3.5l-.847-.847a.717.717,0,0,0-1.013,1.013l2.07,2.07h0l.506.506.506-.506h0l2.07-2.07a.717.717,0,1,0-1.013-1.013l-.847.847v-3.5a3.7,3.7,0,1,0-1.433,0Zm.717-5.889a2.257,2.257,0,0,1,.717,4.4V8.4a.717.717,0,1,0-1.433,0v2.136a2.257,2.257,0,0,1,.717-4.4Z" transform="translate(-15.797 -4.702)" fill="#002657"/>
                                <path id="Path_164" data-name="Path 164" d="M8.2,30.172a3.7,3.7,0,1,0,3.7,3.7A3.7,3.7,0,0,0,8.2,30.172Zm0,5.96a2.263,2.263,0,1,1,2.263-2.263A2.266,2.266,0,0,1,8.2,36.132Z" transform="translate(-4.503 -15.825)" fill="#002657"/>
                                <path id="Path_165" data-name="Path 165" d="M29.091,30.172a3.7,3.7,0,0,0-3.626,2.98H21.726l.846-.847a.717.717,0,1,0-1.013-1.013l-2.069,2.07h0l-.506.506.506.506h0l2.069,2.069a.717.717,0,0,0,1.013-1.013l-.846-.846h3.739a3.7,3.7,0,1,0,3.626-4.414Zm0,5.96a2.259,2.259,0,0,1-2.136-1.546h1.9a.717.717,0,1,0,0-1.433h-1.9a2.259,2.259,0,1,1,2.136,2.98Z" transform="translate(-10.826 -15.825)" fill="#002657"/>
                              </g>
                            </g>
                          </svg>                                               
                    </span>
                    <strong>Intervention Tracker</strong>
                </h2>
            </div>
            <div class="col-md-1 text-end">
                <button mat-icon-button color="primary" mat-dialog-close class="close-button">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>

    </div>
</h2>

<mat-dialog-content class="mat-typography" (scroll)="scrollHandler()">
    <div class="dialog-body">

        <div class="col-md-12 d-flex align-items-center mb-2">
            <div class="col-md-6 d-flex memberdetails" *ngIf="haveconfigData">
                <div class="col-md-5"><strong>Member Name :</strong> {{configdata.encounterDetails.firstName
                    |titlecase}} {{
                    configdata.encounterDetails.lastName }} </div>
                <div class="col-md-2"><strong>MRN :</strong> {{configdata.encounterDetails.mbrRefNo}}</div>

            </div>
            <div class="col-md-6 text-end intervention-type">
                <mat-label>Intervention Type</mat-label>
                <mat-radio-group aria-label="Select an option" [(ngModel)]="interventiontype">
                    <mat-radio-button *ngFor="let intervention of interventionTypeList"
                        [value]="intervention">{{intervention}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <form [formGroup]="interventionForm">

            <div class="col-md-12 intervention-sec">

                <div class="row g-3 part-two">
                    <div class="col-md-12 mb-2 d-flex align-items-center">
                       
                    </div>

                    <div class="col-auto" *ngFor="let data of medicationList">
                        <mat-chip-list class="mat-chip-list-stacked targeted" aria-label="Color selection"
                            name="medications" formControlName="medications">
                            <mat-chip [removable]="removableMed" (removed)="removeMed(data)">{{data}}
                                <mat-icon matChipRemove *ngIf="removableMed">cancel</mat-icon></mat-chip>
                        </mat-chip-list>
                    </div>
                </div>

                <div class="row g-3 part-one">
                    <div class="col-md-5 mb-1 Diag" >

                        <mat-label>Add Medication <span style="color: red;"> *</span></mat-label>

                        <mat-form-field (keyup)="getDrugData()" appearance="outline" (mouseover)="showDropdown()" >
                            <input matInput placeholder="Search/Add Medication" #DrugValue (input)="getDrug(DrugValue)"
                                formControlName="drugName" fxLayout="row" name="drugName" type="text"
                                [matAutocomplete]="auto" (mouseover)="openDropdown()">
                            <mat-autocomplete #auto="matAutocomplete" >
                                <span  *ngIf="!hideAutoPupulateOption">
                                <mat-optgroup *ngFor="let group of addMedicationDropdown" [label]="group.label"
                                    [disabled]="group.disabled">
                                    <mat-option *ngFor="let data of group.value" [value]="pokemon" (click)="onChangeMedication(data)">
                                        <span [matTooltip]="data" style="font-weight: bold; font-size: 15.5px;">
                                            {{data}}</span>
                                    </mat-option>
                                </mat-optgroup>
                            </span>
                            
                            </mat-autocomplete>

                        </mat-form-field>
                    </div>
                    <div class="col-md-1" style="margin-top: 40px;">
                     <button  *ngIf="freetextMed" color="warn" type="submit" mat-mini-fab  type="button" (click)="addFreeTextMed()"><mat-icon>add</mat-icon></button>
                    </div>
                    <div class="col-md-4">
                        <mat-label>Drug Therapy Problem <span style="color: red;"> *</span></mat-label>
                        <mat-form-field appearance="outline">
                            <mat-select name="medicationRelatedProblem" formControlName="medicationRelatedProblem"
                                (selectionChange)="onChangeDRP($event.value)">
                                <mat-option *ngFor="let target of medRelatedProblemList"
                                    [value]="target | titlecase">{{target |titlecase}}</mat-option>
                            </mat-select>
                        </mat-form-field>
 </div>
                   
                </div>



            </div>


            <mat-accordion class="example-headers-align">
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" (closed)="setStep(1)">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Medication Details</mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <div class="col-md-12">
                        <div class="row g-3">
                            <div class="col-md-3">
                                <mat-label>Intervention</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="intervention" formControlName="intervention">
                                        <mat-option *ngFor="let intervention of interventions"
                                            [value]="intervention |titlecase">
                                            {{intervention |titlecase}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-label>Disease State</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput type="text"  formControlName="interventionCat" [matAutocomplete]="auto2">                    
                                    <mat-autocomplete name="interventionCat" #auto2="matAutocomplete">
                                        <mat-option *ngFor="let intervention of interventionsCat"
                                        [value]="intervention">
                                        {{intervention }}
                                    </mat-option>
                                    </mat-autocomplete>
                                  
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-label>Outreach Status</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="status" formControlName="status">
                                        <mat-option *ngFor="let target of statusList"
                                            [value]="target |titlecase">{{target |titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-label>Severity Level</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="severityLevel" formControlName="severityLevel">
                                        <mat-option *ngFor="let target of severityLevelList"
                                            [value]="target.value">{{target.key}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="row g-3">
                           
                            <div class="col-md-4 grayedout" *ngIf="!nonadherence">
                                <mat-label>Med Gap </mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="medgap" formControlName="medgap" [disabled]="!nonadherence">
                                        <mat-option *ngFor="let target of medgapList" [value]="target">{{target
                                            |titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 defaultout" *ngIf="nonadherence">
                                <mat-label>Med Gap </mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="medgap" formControlName="medgap" [disabled]="!nonadherence">
                                        <mat-option *ngFor="let target of medgapList" [value]="target">{{target
                                            |titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 grayedout" *ngIf="!nonadherence">
                                <mat-label>Home Delivery</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="transfterToHomedelivery" formControlName="transfterToHomedelivery"
                                        [disabled]="!nonadherence">
                                        <mat-option *ngFor="let target of transfterToHomedeliveryList"
                                            [value]="target">{{target}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 defaultout" *ngIf="nonadherence">
                                <mat-label>Home Delivery</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="transfterToHomedelivery" formControlName="transfterToHomedelivery"
                                        [disabled]="!nonadherence">
                                        <mat-option *ngFor="let target of transfterToHomedeliveryList"
                                            [value]="target">{{target}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                        </div>

                        <div class="row g-3">
                            <div class="col-md-4">
                                <mat-label>Recommended Action For {{interventiontype}}</mat-label>
                                <mat-form-field appearance="outline">
                                    <textarea matInput type="text" name="recommededAction"
                                        formControlName="recommededAction"> </textarea>
                                </mat-form-field>
                            </div>
                          
                            <div class="col-md-4">
                                <mat-label> Pharmacist Notes</mat-label>
                                <mat-form-field appearance="outline">
                                    <textarea matInput type="text" name="notes" formControlName="notes"> </textarea>
                                </mat-form-field>
                            </div>
                        </div>

                        <hr style="color: #dedede; opacity: 1;">

                        <div class="row g-3">
                            <div class="col-md-4">
                                <mat-label>Outcome</mat-label>
                                <mat-form-field appearance="outline">
                                    <mat-select name="outcome" formControlName="outcome"
                                        (selectionChange)="onChangeOutcome($event.value)">
                                        <mat-option *ngFor="let outcome of outcomeList"
                                            [value]="outcome |titlecase">{{outcome |titlecase}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4 dobinput">
                                <mat-label>Date Of Outcome</mat-label>
                                <mat-form-field appearance="outline">
                                    <input matInput name="outcomeDate" formControlName="outcomeDate" type="date">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-label>Response Received From {{interventiontype}}</mat-label>
                                <mat-form-field appearance="outline">
                                    <textarea matInput type="text" name="responseReceived"
                                        formControlName="responseReceived"> </textarea>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>

                    <div class="dialog-footer mt-2 mb-2">
                        <button mat-stroked-button color="basic" matTooltip="Clear the interaction"
                            matTooltipClass="tooltiper" mat-dialog-close>Cancel</button>
                        <button mat-flat-button class="ml-5" color="primary" matTooltip="Save"
                            matTooltipClass="tooltiper" (click)="saveInterVention()">Save</button>
                    </div>

                </mat-expansion-panel>
            </mat-accordion>
        </form>



    </div>
    <div class="container-fluid">
      
    </div>

    <div class="col-md-12 d-flex align-items-center">
        <div class="col-md-11">
            <h4><strong>Intervention History</strong></h4>
        </div>
        <div class="col-md-1 text-start mt-1">
            <mat-label>Intervention Status </mat-label>
            <mat-form-field appearance="outline">
                <mat-select (selectionChange)="onChangeInterventionStatus($event.value)" multiple>
                    <mat-option *ngFor="let target of interventionstatuslist" [value]="target">{{target}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="tableWrap">
        <table mat-table [dataSource]="dataSource1" class="table-wrapper">
            <ng-container matColumnDef="selectedtrigger" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 30px;" class="check-width sticky-top" >Select
               </th>
                <td mat-cell *matCellDef="let element" class="check-width" style="width: 30px; border: solid thin red;">
                    <mat-checkbox (change)="exportParticularIntervention($event,element)"   ></mat-checkbox>
                </td>

            </ng-container>

            <ng-container matColumnDef="edit" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 110px;" class="sticky-top">Action </th>
                <td mat-cell *matCellDef="let element">
                    <button matSuffix type="edit" color="default" mat-icon-button
                        [disabled]="element.interventionStatus=='Closed'">
                        <mat-icon matTooltip="Edit" (click)="editInterVention(element)">mode_edit</mat-icon>
                    </button>
                    <button matSuffix type="delete" color="default" mat-icon-button
                        [disabled]="element.interventionStatus=='Closed'">
                        <mat-icon matTooltip="Delete" (click)="deleteInterVention(element)">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

           
            <ng-container matColumnDef="createdDate" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;" class="fixedstickywhite">Date </th>
                <td mat-cell *matCellDef="let element">{{element.createdDate | date: 'MM-dd-yyyy'}}</td>
            </ng-container>

            <ng-container matColumnDef="interventionType" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 100px;" class="fixedstickywhite">Type </th>
                <td mat-cell *matCellDef="let element" class="align-items-center">{{element.interventionType}}</td>
            </ng-container>

            <ng-container matColumnDef="intervention" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 160px;" class="fixedstickywhite">Intervention</th>
                <td mat-cell *matCellDef="let element">{{element.intervention}}</td>
            </ng-container>
            <ng-container matColumnDef="interventionCat" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 160px;" class="fixedstickywhite">Disease State</th>
                <td mat-cell *matCellDef="let element">{{element.interventionCat}}</td>
            </ng-container>

            <ng-container matColumnDef="medications" [sticky]="true">
                <th mat-header-cell *matHeaderCellDef style="width: 300px;" class="fixedstickywhite">Medications</th>
                <td mat-cell *matCellDef="let element" class="fixedsticky">
                    <span class="medbg" *ngFor='let data of element.trigger.split("||")'
                        style="padding: 20 px;">{{data}}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 165px;" class="sticky-top"> Outreach Status</th>
                <td mat-cell *matCellDef="let element">{{element.status}} </td>
            </ng-container>

            <ng-container matColumnDef="adherenceBarrier">
                <th mat-header-cell *matHeaderCellDef style="width: 170px;" class="sticky-top">Med Gap</th>
                <td mat-cell *matCellDef="let element"><span>{{element.adherenceBarrier}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="drugTherapyProblem">
                <th mat-header-cell *matHeaderCellDef style="width: 210px;" class="sticky-top">Drug Therapy Problem</th>
                <td mat-cell *matCellDef="let element"><span>{{element.drugTherapyProblem}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="transferredToHomedelivery">
                <th mat-header-cell *matHeaderCellDef style="width: 210px;" class="sticky-top">Home
                    Delivery</th>
                <td mat-cell *matCellDef="let element"><span>{{element.transferredToHomedelivery}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="severityLevel">
                <th mat-header-cell *matHeaderCellDef style="width: 120px;" class="sticky-top">Severity Level</th>
                <td mat-cell *matCellDef="let element"><span>{{element.severityLevel}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="outcome">
                <th mat-header-cell *matHeaderCellDef style="width: 120px;" class="sticky-top">Outcome</th>
                <td mat-cell *matCellDef="let element"><span>{{element.outcome}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="dateOfOutcome">
                <th mat-header-cell *matHeaderCellDef style="width: 120px;" class="sticky-top">Outcome Date</th>
                <td mat-cell *matCellDef="let element">
                    <!-- <span>{{element.dateOfOutcome.length>0?element.dateOfOutcome.split(" ")[0]: element.dateOfOutcome }}</span>  -->
                    <span>{{element.dateOfOutcome | date: "MM-dd-yyyy" }}</span>  
                </td>
            </ng-container>

            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Notes</th>
                <td mat-cell *matCellDef="let element"><span>{{element.notes}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="responseReceived">
                <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Response Received</th>
                <td mat-cell *matCellDef="let element"><span>{{element.responseReceived}}</span> </td>
            </ng-container>

            <ng-container matColumnDef="recommededAction">
                <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Recommeded Action</th>
                <td mat-cell *matCellDef="let element"><span>{{element.recommededAction}}</span> </td>
            </ng-container>


            <ng-container matColumnDef="interventionStatus">
                <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Intervention Status</th>
                <td mat-cell *matCellDef="let element"><span>{{element.interventionStatus}}</span> </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
        </table>
        <div class="no-Data" *ngIf="dataSource1.data.length === 0" style="text-align: center;">No Rows To Show</div>

    </div>

    <div class="col-md-12 text-end mt-3 mb-1">

        <button mat-flat-button class="mat-elevation-z0 text-end" color="primary" mat-stroked-button
            (click)="printIntervention()">Generate Report</button>
    </div>

</mat-dialog-content>




  


