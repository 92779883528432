import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MemberAllergy } from '../models/MemberAllergy';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { getDateTime, titleCase } from '../utilityMethods/helperMethods';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { MatDialog } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { TimeoutDialogMemberDetailsComponent } from '../timeout-dialog-member-details/timeout-dialog-member-details.component';
import { IdleTimeoutService } from '../idle-timeout.service';


@Component({
  selector: 'app-add-encounter',
  templateUrl: './add-encounter.component.html',
  styleUrls: ['./add-encounter.component.css']
})
export class AddEncounterComponent implements OnInit {

  medicationRelatedProblemList = ['Medication Problem', 'Returned to incorrect home dose', 'Returned to incorrect drug', 'Drug therapy non-adherence', 'Did not receive medication at discharge ', 'Medication list discrepancy', 'New medication therapy needed', 'BEERS Inappropriate medication', 'Stopped inpatient or SNF ', 'SNF to Home med rec ', 'Adverse drug reaction', 'Drug allergy', 'Drug Disease interaction ', 'Drug drug interaction', 'Drug interaction with food', 'Duplication ', 'Education needed', 'Improper medication administration technique', 'Medication dose too high', 'Medication dose too low', 'Medication monitoring', 'Medication not effective', 'Medication therapy unnecessary', 'New discharge medication ', 'Patient no longer taking ', 'Polypharmacy', 'Taking high risk medication']
  interventionList = ["Medication reconciliation", "Provider/care team notified ", "COPD intervention ", "CHF intervention ", "Start drug therapy", "Stop drug therapy", "Unable to access ", "Med Rec Not Complete ", "Patient education", "Other", "Administer immunization", "Change drug dose", "Change drug frequency", "Change drug product", "Initiate or increase medication monitoring", "Refer to other service", "Order lab/diagnostic test"]
  drugList = ["Other", "A.fib R&R", "AKI/Renal meds", "Antibiotic", "Anticholinergic", "Anti-coag", "Anti-platelet", "Asthma Daily", "Asthma Rescue", "BEERS med", "CHF meds", "Cholesterol", "COPD Daily", "COPD Rescue", "Diabetes oral", "Fall/AMS", "HTN", "Insulin", "Pain Meds"]
  outcomeList = ["Task sent to provider", "Provider acknowledged task", "Provider did not acknowledged task", "Pharmacist acted $", "Patient declined", "Unable to reach patient", "Complete by RN", "Hospice", "Patient died", "Discharge to Private Pay", "Discharge to LTC", "Discharge to ALF"]
  potentialSeverityList = ["Low - office visit", "Medium - ER", "High - hospitalization", "Other"]
  listSourceList = ["SNF DC", "MAR", "Not rcvd"]
  qualityList1 = ["CBP", "CMR", "HBD", "MAC", "MAD", "MAH", "OMW", "SPC", "SUPD", "TRC"]
  dataEntryBy = ["ABC", "DEF"]
  toppingList = ["DIA1", "DIA2", "DIA3"]
  lobs: any
  facilities: any
  visitType: any
  encounterType: any
  requestors: any
  panelOpenState = false;
  step = 0
  memberView: boolean = false
  isOtherButtonClicked = false
  memberdetails: any
  addmember: boolean = false
  isSNF: boolean = true
  isCSNP: boolean = false

  selectable = true;
  selectable1 = true;
  isOthersAllergyExist: any

  removable = true;
  removable1 = true;
  selectedMedAllergy: string[] = [];
  selectedCatAllergy: string[] = [];
  removedMedAllergy: any[] = [];

  medAllergyDropdown: string[] = [];

  catAllergyDropdown: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allergyFlag: any;
  selectedOptions: string = ""
  providerList: any;
  haveMemberData=false

  @ViewChild('medAllergyInput') medAllergyInput: ElementRef<HTMLInputElement>;
  @ViewChild('classAllergyInput') classAllergyInput: ElementRef<HTMLInputElement>;

  addEncounterForm: FormGroup = new FormGroup({
    mbrRefNo: new FormControl(),
    lastName: new FormControl(),
    firstName: new FormControl(),
    dob: new FormControl(),
    payor: new FormControl(),
    gender: new FormControl(),
    lob: new FormControl(),
    facilityName: new FormControl(),
    requestor: new FormControl(),
    sourceProvider: new FormControl(),
    destinationProvider: new FormControl(),
    encounterDate: new FormControl(),
    visitType: new FormControl(),
    encounterType: new FormControl(),

    visitDate: new FormControl(),
    requestedOn: new FormControl(),
    notifiedOn: new FormControl(),
    comments: new FormControl(),
    encounterId: new FormControl(),
    dischargeDate: new FormControl(),
    diagnosedFor: new FormControl(),
    admitDate: new FormControl(),
    dataEntryBy: new FormControl(),
    pharmacist: new FormControl(),

  })

  filteredOptions: Observable<string[]>;
  isEditEncounter = false;
  showAllFields = false
  lobname: any
  memberDetails: any
  selectedDiagnosis: String[] = []
  copyFlag: boolean;
  encounterId: String;

  firstNameList: any = []
  lastNameList: any = []
  mbrRefNoList: any = []
  dobList: any = []
  text = ""
  text1 = ""
  text2 = ""
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  currentPath: String;
  idleSubscription: any;
  constructor(public dataservice: DataService, private toastr: ToastrService, public router: Router, public route: ActivatedRoute, private idle: Idle
    , private keepalive: Keepalive, private cd: ChangeDetectorRef, public dialog: MatDialog, location: Location, public idleService: IdleTimeoutService) {

    this.idleSubscription = this.idleService.idleTimeout.subscribe(() => {
      console.log(this.router.url)
      this.performPreLogoutActions();

    });

  }
  performPreLogoutActions() {
    if (this.router.url === ('/addEncounter')) {
      this.addnewencounter()
    }
  }
  ngOnInit(): void {

    this.dataservice.getProviderList().subscribe(data1 => {
      this.providerList = data1


      if (this.router.url != '/addEncounter') {
        debugger
        this.addEncounterForm.controls['firstName'].disable();
        this.addEncounterForm.controls['lastName'].disable();
        this.addEncounterForm.controls['dob'].disable();
        this.addEncounterForm.controls['mbrRefNo'].disable();
        this.addEncounterForm.controls['gender'].disable();

        this.isEditEncounter = true;

        this.populateFormFields(this.route.snapshot.params['encounterId'])


      }
      else {
        this.isEditEncounter = false;


      }

      this.addEncounterForm.controls['encounterId'].disable();
      this.addEncounterForm.controls['pharmacist'].disable();
      this.dataservice.getdropdown("LOB").subscribe(data => {
        this.lobs = data["values"]

      }, error => {


      })
      this.dataservice.getdropdown("VISIT_TYPE").subscribe(data => {
        this.visitType = data["values"]

      }, error => {


      })
      this.dataservice.getdropdown("ENCOUNTER_TYPE").subscribe(data => {
        this.encounterType = ["Reconciliation", "Review"]

      }, error => {


      })
      this.dataservice.getdropdown("REQUESTOR").subscribe(data => {
        this.requestors = data["values"]

      }, error => {


      })

      if (localStorage.getItem('input')) {
        this.memberDetails = JSON.parse(localStorage.getItem('input'))
        console.log(this.memberDetails)
        this.addEncounterForm.patchValue({
          lastName: titleCase(this.memberDetails['lastName']),
          firstName: titleCase(this.memberDetails["firstName"]),
          dob: this.memberDetails["dob"],
          gender: this.memberDetails["gender"],
        });
        this.getMember()


      }
    });
  }


  ngOnDestroy(): void {
    console.log("aa")
    localStorage.removeItem('input')
  }
  populateFormFields(encounterId) {
    debugger
    this.memberView = true
    console.log("Inside populate")
    this.dataservice.getEncounterData(encounterId).subscribe(data => {
      this.addEncounterForm.patchValue({
        mbrRefNo: data[0]["mbrRefNo"],
        lastName: titleCase(data[0]["lastName"]),
        firstName: titleCase(data[0]["firstName"]),
        dob: data[0]["dob"],
        payor: titleCase(data[0]["payor"]),
        gender: data[0]["gender"],
        lob: data[0]["lob"],
        sourceProvider: data[0]["sourceProvider"],
        destinationProvider: data[0]["destinationProvider"],
        encounterDate: data[0]["encounterDate"].split(' ')[0],
        visitType: data[0]["visitType"],
        encounterType: data[0]["encounterType"],
        visitDate: this.splittedate(data[0]["visitDate"]),
        requestedOn: data[0]["requestedOn"].split(' ')[0],
        notifiedOn: this.splittedate(data[0]["notifiedOn"]),
        comments: data[0]["comments"],
        encounterId: data[0]["encounterId"],
        dischargeDate: data[0]["dischargeDate"].split(' ')[0],
        requestor: data[0]["requestor"],
        admitDate: data[0]["admitDate"].split(' ')[0],
        dataEntryBy: data[0]["dataEntryBy"],
        pharmacist: data[0]["pharmacist"],
        facilityName: data[0]["facilityName"]
      });
      this.lobname = data[0]["lob"]
      this.onchangelob(data[0]["lob"])
      console.log(data[0].facilityName)
      this.setvalueFacilityForm(data[0].facilityName, data[0].lob, data[0].sourceProvider)
      this.selectedDiagnosis = data[0]["diagnosedFor"].split('-')
      console.log('selected', this.selectedDiagnosis)
      //  this.callAllergiesAPI()
      this.callDiagnosisAPI()


    }, error => {
      this.toastr.error("Issue In Data Saving"), '', {
        timeOut: 1500,
      };

    })
  }
  splittedate(data) {
    if (data != null && data != '' && data != undefined) {
      return data.split(' ')[0]
    }
    return ""

  }

  setvalueFacilityForm(facilityName, lob, srcProvider) {

    this.addEncounterForm.patchValue({
      facilityName: facilityName,
      sourceProvider: srcProvider,
    })


  }
  onchangeFacility(value) {
    this.addEncounterForm.patchValue({
      sourceProvider: value
    })
  }

  addnewencounter(): void {
    if (titleCase(this.addEncounterForm.getRawValue().firstName) == null || this.addEncounterForm.getRawValue().firstName == "") {
      this.toastr.error("Please Provide First Name", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().lastName == null || this.addEncounterForm.getRawValue().lastName == "") {
      this.toastr.error("Please Provide Last Name", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().dob == null || this.addEncounterForm.getRawValue().dob == "") {
      this.toastr.error("Please Provide DOB", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().gender == null || this.addEncounterForm.getRawValue().gender == "") {
      this.toastr.error("Please Provide Gender", '', {
        timeOut: 1500,
      });
      return
    }

    else if (this.addEncounterForm.getRawValue().lob == '' || this.addEncounterForm.getRawValue().lob == null || this.addEncounterForm.getRawValue().lob == undefined) {
      this.toastr.error("Please Select Program Type", '', {
        timeOut: 1500,
      })
      return
    }
    else if (this.addEncounterForm.getRawValue().encounterType == '' || this.addEncounterForm.getRawValue().encounterType == null || this.addEncounterForm.getRawValue().encounterType == undefined) {
      this.toastr.error("Please Select Encounter Type", '', {
        timeOut: 1500,
      })
      return
    }
    let val = true;
    for (let ic: any = 0; ic < this.memberdetails.length; ic++) {
      if (this.memberdetails[ic].status != 'Completed' && this.memberdetails[ic].status != 'Closed') {
        if (this.memberdetails[ic].firstName == this.addEncounterForm.getRawValue().firstName && this.memberdetails[ic].lastName == this.addEncounterForm.getRawValue().lastName
          && this.memberdetails[ic].dob == this.addEncounterForm.getRawValue().dob && this.memberdetails[ic].gender == this.addEncounterForm.getRawValue().gender
          && this.memberdetails[ic].lob == this.addEncounterForm.getRawValue().lob && this.memberdetails[ic].visitType == this.addEncounterForm.getRawValue().visitType && this.memberdetails[ic].encounterType == this.addEncounterForm.getRawValue().encounterType) {
          this.toastr.error("Open Encounter With Same ProgramType VisitType and Encounter Type Combinaton Is Present. Please Refer To Exisitng Encounter", '', {
            timeOut: 1500,
          });
          val = false;
        }
      }
    }
    if (val) {
      console.log("sdvsd")
      let encounterId = this.addEncounterForm.getRawValue().mbrRefNo + '-' + getDateTime()
      let addEncounterDetails = {
        'mbrRefNo': this.addEncounterForm.getRawValue().mbrRefNo,
        'lastName': this.addEncounterForm.getRawValue().lastName,
        'firstName': this.addEncounterForm.getRawValue().firstName,
        'dob': this.addEncounterForm.getRawValue().dob,
        'payor': this.addEncounterForm.getRawValue().payor,
        'gender': this.addEncounterForm.getRawValue().gender,
        'lob': this.addEncounterForm.getRawValue().lob,
        'facilityName': this.addEncounterForm.getRawValue().facilityName,
        'sourceProvider': this.addEncounterForm.getRawValue().sourceProvider,
        'destinationProvider': this.addEncounterForm.getRawValue().destinationProvider,
        'encounterDate': this.addEncounterForm.getRawValue().encounterDate,
        'visitType': this.addEncounterForm.getRawValue().visitType,
        'encounterType': this.addEncounterForm.getRawValue().encounterType,
        'visitDate': this.addEncounterForm.getRawValue().visitDate,
        'requestor': this.addEncounterForm.getRawValue().requestor,
        'requestedOn': this.addEncounterForm.getRawValue().requestedOn,
        'notifiedOn': this.addEncounterForm.getRawValue().notifiedOn,
        'comments': this.addEncounterForm.getRawValue().comments,
        'encounterId': this.addEncounterForm.getRawValue().mbrRefNo + '-' + getDateTime(),
        "assignedTo": localStorage.getItem('usname'),
        "status": "Data Entry",
        "dischargeDate": this.addEncounterForm.getRawValue().dischargeDate,
        "diagnosedFor": this.selectedOptions,
        "admitDate": this.addEncounterForm.getRawValue().admitDate,
        "dataEntryBy": localStorage.getItem('usname'),
        "pharmacist": localStorage.getItem('usname')

      }
      console.log('Inside addencounter', addEncounterDetails)

      debugger

      this.dataservice.addEncounter(addEncounterDetails).subscribe(data => {
        this.toastr.success("Encounter Saved Successfully", '', {
          timeOut: 1500,
        });
        localStorage.removeItem('input')
        this.router.navigate(['/encounterRoster']);
        setTimeout(() => {
          window.location.reload();
        }, 2000);

      }, error => {
        this.toastr.error("Issue In Data Saving", '', {
          timeOut: 1500,
        });

      })
      if (this.copyFlag == true) {
        this.dataservice.getMedregimenws(this.encounterId).subscribe(data => {
          console.log("Hii", data, encounterId, this.encounterId)
          let drug_regim: any = data
          drug_regim.forEach(element => {
            element.encounterId = encounterId
            element.rphCheck = null
            element.techCheck = null
            this.dataservice.addDrugRegime(element).subscribe(data => {
              console.log('G', data)
              this.toastr.success("Encounter Copied, Proceed With Modifications And Save The Encounter", '', {
                timeOut: 1500,
              })
            }, error => {
              this.toastr.error("Some Error Occured In Exporting To Drug Regimen", '', {
                timeOut: 1500,
              })

            })
          });
          console.log(drug_regim)

        })
      }
    }
  }

  onChange(data) {
    console.log("dfgfasdg")
    console.log('Selected options:', data.value.join('-'), data);
    this.selectedOptions = data.value.join('-');

  }
  updateEncounter(): void {
    debugger
    if (titleCase(this.addEncounterForm.getRawValue().firstName) == null || this.addEncounterForm.getRawValue().firstName == "") {
      this.toastr.error("Please Provide First Name", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().lastName == null || this.addEncounterForm.getRawValue().lastName == "") {
      this.toastr.error("Please Provide Last Name", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().dob == null || this.addEncounterForm.getRawValue().dob == "") {
      this.toastr.error("Please Provide DOB", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().gender == null || this.addEncounterForm.getRawValue().gender == "") {
      this.toastr.error("Please Provide Gender", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.addEncounterForm.getRawValue().lob == '' || this.addEncounterForm.getRawValue().lob == null || this.addEncounterForm.getRawValue().lob == undefined) {
      this.toastr.error("Please Select Program Type", '', {
        timeOut: 1500,
      })
      return
    }
    else if (this.addEncounterForm.getRawValue().encounterType == '' || this.addEncounterForm.getRawValue().encounterType == null || this.addEncounterForm.getRawValue().encounterType == undefined) {
      this.toastr.error("Please Select Encounter Type", '', {
        timeOut: 1500,
      })
      return
    }
    console.log("adsd")



    console.log(this.addEncounterForm.getRawValue())
    let addEncounterDetails = {
      'mbrRefNo': this.addEncounterForm.getRawValue().mbrRefNo,
      'lastName': this.addEncounterForm.getRawValue().lastName,
      'firstName': this.addEncounterForm.getRawValue().firstName,
      'dob': this.addEncounterForm.getRawValue().dob,
      'payor': this.addEncounterForm.getRawValue().payor,
      'gender': this.addEncounterForm.getRawValue().gender,
      'lob': this.addEncounterForm.getRawValue().lob,
      'facilityName': this.addEncounterForm.getRawValue().facilityName,
      'sourceProvider': this.addEncounterForm.getRawValue().sourceProvider,
      'destinationProvider': this.addEncounterForm.getRawValue().destinationProvider,
      'encounterDate': this.addEncounterForm.getRawValue().encounterDate,
      'visitType': this.addEncounterForm.getRawValue().visitType,
      'encounterType': this.addEncounterForm.getRawValue().encounterType,
      'visitDate': this.addEncounterForm.getRawValue().visitDate,
      'requestedOn': this.addEncounterForm.getRawValue().requestedOn,
      'notifiedOn': this.addEncounterForm.getRawValue().notifiedOn,
      'comments': this.addEncounterForm.getRawValue().comments,
      'encounterId': this.addEncounterForm.getRawValue().encounterId,
      "assignedTo": localStorage.getItem('usname'),
      "status": "Data Entry",
      "diagnosedFor": this.selectedOptions,
      "dischargeDate": this.addEncounterForm.getRawValue().dischargeDate,
      "admitDate": this.addEncounterForm.getRawValue().admitDate,
      "dataEntryBy": localStorage.getItem('usname'),
      "pharmacist": localStorage.getItem('usname')

    }

    console.log("hi", addEncounterDetails)
    this.dataservice.updateEncounter(addEncounterDetails).subscribe(data => {
      debugger
      this.toastr.success("Encounter Saved Successfully", '', {
        timeOut: 1500,
      });
      localStorage.removeItem('input')
      // window.location.reload();
      this.populateFormFields(this.route.snapshot.params['encounterId'])
      let memberdata = []
      this.dataservice.getEncounterData(this.route.snapshot.params['encounterId']).subscribe(data => {
        memberdata.push({
          mbrRefNo: data[0]["mbrRefNo"],
          lastName: titleCase(data[0]["lastName"]),
          firstName: titleCase(data[0]["firstName"]),
          dob: data[0]["dob"],
          payor: titleCase(data[0]["payor"]),
          gender: data[0]["gender"],
          lob: data[0]["lob"],
          sourceProvider: data[0]["sourceProvider"],
          destinationProvider: data[0]["destinationProvider"],
          encounterDate: data[0]["encounterDate"].split(' ')[0],
          visitType: data[0]["visitType"],
          encounterType: data[0]["encounterType"],
          visitDate: this.splittedate(data[0]["visitDate"]),
          requestedOn: data[0]["requestedOn"].split(' ')[0],
          notifiedOn: this.splittedate(data[0]["notifiedOn"]),
          comments: data[0]["comments"],
          encounterId: data[0]["encounterId"],
          dischargeDate: data[0]["dischargeDate"].split(' ')[0],
          requestor: data[0]["requestor"],
          admitDate: data[0]["admitDate"].split(' ')[0],
          dataEntryBy: data[0]["dataEntryBy"],
          pharmacist: data[0]["pharmacist"],
          status: data[0]["status"],
          assignedTo: data[0]["assignedTo"]
        });
        this.memberdetails = memberdata
        this.haveMemberData=true


      }, error => {
        this.toastr.error("Issue In Data Saving", '', {
          timeOut: 1500,
        });

      })

    }, error => {
      this.toastr.error("Issue In Data Saving", '', {
        timeOut: 1500,
      });

    })

  }

  copyEncounter(data): void {
    debugger
    console.log("Hi", data, data.mbrRefNo)
    let encounterId = data.mbrRefNo + '-' + getDateTime()
    this.addEncounterForm.patchValue({
      'mbrRefNo': data.mbrRefNo,
      'lastName': data.lastName,
      'firstName': data.firstName,
      'dob': data.dob,
      'payor': data.payor,
      'gender': data.gender,
      'lob': data.lob,
      'facilityName': data.faciltyName,
      'sourceProvider': data.sourceProvider,
      'destinationProvider': data.destinationProvider,
      'encounterDate': data.encounterDate,
      'visitType': data.visitType,
      'encounterType': data.encounterType,
      'visitDate': data.visitDate.split(' ')[0],
      'requestor': data.requestor,
      'requestedOn': data.requestedOn.split(' ')[0],
      'notifiedOn': data.notifiedOn.split(' ')[0],
      'comments': data.comments,
      'encounterId': encounterId,
      "assignedTo": localStorage.getItem('usname'),
      "status": "Data Entry",
      "dischargeDate": data.dischargeDate.split(' ')[0],
      "admitDate": data.admitDate.split(' ')[0],
      "dataEntryBy": localStorage.getItem('usname'),
      "pharmacist": localStorage.getItem('usname'),
    })
    this.selectedOptions = data.diagnosedFor
    console.log("inside copy", data.diagnosedFor, data["diagnosedFor"].split('-'))
    this.selectedDiagnosis = data["diagnosedFor"].split('-')
    this.addEncounterForm.get('diagnosedFor')?.setValue(this.selectedDiagnosis)
    console.log(data.encounterId)
    this.copyFlag = true
    this.encounterId = data.encounterId
    this.onchangelobload(data)

  }
  onchangelobload(data) {
    debugger
    this.addEncounterForm.controls['sourceProvider'].reset()
    this.addEncounterForm.controls['facilityName'].reset()


    this.addEncounterForm.controls['requestor'].enable();
    this.addEncounterForm.controls['facilityName'].enable();


    let faciltyType = ''
    if (data.lob.startsWith('SNF')) {
      faciltyType = 'S'
      var facilityarr = this.providerList.filter(e => e['facilityType'] == faciltyType)
      this.isSNF = true
      this.isCSNP = false
      this.addEncounterForm.controls['requestor'].disable();
    }
    else if (data.lob == 'Acute To Home') {
      faciltyType = 'H'
      var facilityarr = this.providerList.filter(e => e['facilityType'] == faciltyType)
      this.isSNF = true
      this.isCSNP = false
      this.addEncounterForm.controls['requestor'].disable();

    }
    else if ((data.lob == 'CSNP') || (data.lob == 'DSNP')) {
      var facilityarr = this.providerList
      this.isSNF = true
      this.isCSNP = true
    }
    else {
      faciltyType = 'H'
      this.isSNF = false
      var facilityarr = this.providerList
      this.addEncounterForm.controls['facilityName'].disable();
      this.addEncounterForm.controls['requestor'].enable();
    }

    this.facilities = facilityarr.map(this.getFacility)
    this.populateSrcProvider(data);

  }

  cancel(): void {

  }
  submitfile() {

  }

  cancelFileUpload() { }

  onchangelob(data) {

    console.log("new", data)
    // this.addEncounterForm.patchValue({sourceProvider: ''})
    this.addEncounterForm.controls['sourceProvider'].reset()
    this.addEncounterForm.controls['facilityName'].reset()


    this.addEncounterForm.controls['requestor'].enable();
    this.addEncounterForm.controls['facilityName'].enable();


    let faciltyType = ''
    if (data.startsWith('SNF')) {
      faciltyType = 'S'
      var facilityarr = this.providerList.filter(e => e['facilityType'] == faciltyType)
      this.isSNF = true
      this.isCSNP = false
      this.addEncounterForm.controls['requestor'].disable();

    }
    else if (data == 'Acute To Home') {
      faciltyType = 'H'
      var facilityarr = this.providerList.filter(e => e['facilityType'] == faciltyType)
      this.isSNF = true
      this.isCSNP = false
      this.addEncounterForm.controls['requestor'].disable();
    }

    else if ((data.startsWith('CSNP')) || (data.startsWith('DSNP'))) {
      // console.log(this.providerList,"Hiii")
      var facilityarr = this.providerList
      this.isSNF = true
      this.isCSNP = true
    }
    else {
      faciltyType = 'H'
      var facilityarr = this.providerList
      this.isSNF = false
      this.isCSNP = true
      this.addEncounterForm.controls['facilityName'].disable();
      this.addEncounterForm.controls['requestor'].enable();
    }
    // console.log('sss',facilityarr)
    this.facilities = facilityarr.map(this.getFacility)
    // this.populateSrcProvider(data);



  }

  _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.facilities.filter(option => option.toLowerCase().includes(filterValue));
  }
  populateSrcProvider(data) {

    debugger
    this.addEncounterForm.patchValue({
      'facilityName': titleCase(data.facilityName),
      'sourceProvider': data.sourceProvider,
      'destinationProvider': data.destinationProvider,
      'requestor': data.requestor,
    })
    this.toastr.success("Encounter Copied Successfully", '', {
      timeOut: 1500,
    });
  }
  getFacility(data) {
    return data["facilityName"];
  }

  getInfo() {
    let firstName = []
    let lastName = []
    let mbrRefNo = []
    let dob = []
    // const newValue = (event.target as HTMLInputElement).value;
    let input = {
      "mbrRefNo": this.addEncounterForm.getRawValue().mbrRefNo,
      "firstName": this.addEncounterForm.getRawValue().firstName,
      "lastName": this.addEncounterForm.getRawValue().lastName,
      "dob": ""
    }
    console.log(input)
    this.dataservice.getPatientInfo(input).subscribe((data: any) => {
      data.forEach(element => {
        firstName.push(element.firstName.toUpperCase())
        lastName.push(element.lastName.toUpperCase())
        mbrRefNo.push(element.mbrRefNo.toUpperCase())
        dob.push(element.dob)
      })
      this.firstNameList = new Set(firstName)
      this.lastNameList = new Set(lastName)
      this.mbrRefNoList = new Set(mbrRefNo)
      this.dobList = new Set(dob)
    })
    console.log(this.firstNameList, this.lastNameList)
  }

  getMember() {
    let date = {}
    if (titleCase(this.addEncounterForm.getRawValue().firstName) == null || this.addEncounterForm.getRawValue().firstName == "") {
      this.toastr.error("Please Provide First Name", '', {
        timeOut: 1500,
      });
    }
    else if (this.addEncounterForm.getRawValue().lastName == null || this.addEncounterForm.getRawValue().lastName == "") {
      this.toastr.error("Please Provide Last Name", '', {
        timeOut: 1500,
      });
    }
    else if (this.addEncounterForm.getRawValue().dob == null || this.addEncounterForm.getRawValue().dob == "") {
      this.toastr.error("Please Provide DOB", '', {
        timeOut: 1500,
      });
    }
    else if (this.addEncounterForm.getRawValue().gender == null || this.addEncounterForm.getRawValue().gender == "") {
      this.toastr.error("Please Provide Gender", '', {
        timeOut: 1500,
      });
    }
    else {
      var inputPatient1 = {
        "firstName": titleCase(this.addEncounterForm.getRawValue().firstName),
        "lastName": titleCase(this.addEncounterForm.getRawValue().lastName),
        "dob": this.addEncounterForm.getRawValue().dob,
        "gender": this.addEncounterForm.getRawValue().gender
      }
      this.dataservice.getEncounterworkqueudataForMember(inputPatient1).subscribe(data => {
        let data1: any = data;
        if (data1.length > 0) {
          this.memberView = true
        }
        console.log(data)
        this.memberdetails = data;
        //this.patientList = data

      }, error => {

      });
      console.log(this.addEncounterForm.getRawValue().firstName, this.addEncounterForm.getRawValue().lastName, this.addEncounterForm.getRawValue().lastName,)
      var inputPatient = {
        "firstName": titleCase(this.addEncounterForm.getRawValue().firstName),
        "lastName": titleCase(this.addEncounterForm.getRawValue().lastName),
        "dob": this.addEncounterForm.getRawValue().dob,
        "sex": this.addEncounterForm.getRawValue().gender,


      }

      this.dataservice.searchMemberinEligibility(inputPatient).subscribe(data => {
        let data1: any = data;
        if (data1.length > 0) {
          this.memberView = true
          this.addEncounterForm.patchValue({
            mbrRefNo: data[0]["mbrRefNo"],
            lastName: titleCase(data[0]["lastName"]),
            firstName: titleCase(data[0]["firstName"]),
            dob: data[0]["dob"],
            gender: data[0]["gender"]
          });
          this.callAllergiesAPI()
          this.callDiagnosisAPI()
        }
        else {
          this.toastr.error("Member Not Found, Please Proceed With Add Member Option", '', {
            timeOut: 1500,
          });
          // this.router.navigate(['/addMember']);
          this.addmember = true

        }

      }, error => {
        this.toastr.error("Member Not Found, Please Proceed With Add Member Option", '', {
          timeOut: 1500,
        });
        // this.router.navigate(['/addMember']);
        this.addmember = true


      });
    }



  }
  editEncounter() {
    this.updateEncounter()

  }
  deleteEncounter() {

  }
  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  edit(data) {

    this.isOtherButtonClicked = true

    this.router.navigate(['/editEncounter/' + data.encounterId]); //610882
  }

  rowclick(data, event) {

    debugger



    localStorage.setItem('encounterId', data.encounterId)


    if (!this.isOtherButtonClicked) {
      this.router.navigate(['/patientdetails/' + data.mbrRefNo]);
    }
  }
  delete(data) {

    console.log(data)
    this.isOtherButtonClicked = true

    this.dataservice.deleteEncounter(data.encounterId).subscribe(data => {
      console.log(data)
      this.toastr.success("Encounter Deleted Successfully", '', {
        timeOut: 1500,
      });
      this.isOtherButtonClicked = false
      //this.getEncounterQueue()




    }, error => {
      this.toastr.error("Some Error Occured", '', {
        timeOut: 1500,
      });


    });


  }
  close(data) {

    console.log(data)
    this.isOtherButtonClicked = true

    this.dataservice.completeEncounter(data.encounterId).subscribe(data => {
      console.log(data)
      this.toastr.success("Encounter Completed Successfully", '', {
        timeOut: 1500,
      });
      this.isOtherButtonClicked = false
      //this.getEncounterQueue()




    }, error => {
      this.toastr.error("Some Error Occured", '', {
        timeOut: 1500,
      });


    });


  }

  navigateToAddMember() {
    this.router.navigate(['addMember'])
  }

  onchangefacility(value) {
    this.addEncounterForm.patchValue({
      sourceProvider: value
    })
  }
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedMedAllergy.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }
  addCatAllergy(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.selectedCatAllergy.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }
  remove(fruit: string): void {


    const index = this.selectedMedAllergy.indexOf(fruit);


    if (index >= 0) {
      let medAllergy = new MemberAllergy();
      medAllergy.setType("M")
      medAllergy.setActiveFlag("D")
      medAllergy.setDescription(fruit)
      medAllergy.setmbrRefNo(localStorage.getItem('mbeRefNo'))
      this.removedMedAllergy.push(medAllergy)
      this.selectedMedAllergy.splice(index, 1);
    }
  }
  removeAllergycategory(fruit: string): void {
    const index = this.selectedCatAllergy.indexOf(fruit);

    if (index >= 0) {
      let medAllergy = new MemberAllergy();
      medAllergy.setType("C")
      medAllergy.setActiveFlag("D")
      medAllergy.setDescription(fruit)
      medAllergy.setmbrRefNo(localStorage.getItem('mbeRefNo'))
      this.removedMedAllergy.push(medAllergy)
      this.selectedCatAllergy.splice(index, 1);
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedMedAllergy.push(event.option.viewValue);
    this.medAllergyInput.nativeElement.value = '';
  }
  selectedclassAllergy(event: MatAutocompleteSelectedEvent): void {
    this.selectedCatAllergy.push(event.option.viewValue);
    this.classAllergyInput.nativeElement.value = '';
  }
  getMedicationAllergyDropdown() {
    console.log(this.addEncounterForm.getRawValue().medicationAllergy)

    let input = {
      "drugName": this.addEncounterForm.getRawValue().medicationAllergy,
      "drugCategory": ""


    }
    this.dataservice.getAllergyDropdown(input).subscribe(data => {
      this.medAllergyDropdown = []
      console.log(data)
      let medDropdown: any = data

      medDropdown.forEach(element => {

        this.medAllergyDropdown.push(element.drugName)


      });
      console.log(this.medAllergyDropdown.length)


    }, error => {


    });

  }
  getMedicationCatAllergyDropdown() {

    let input = {
      "drugName": "",
      "drugCategory": this.addEncounterForm.getRawValue().medicationClassAllergy


    }
    this.dataservice.getAllergyDropdown(input).subscribe(data => {
      this.catAllergyDropdown = []
      console.log(data)
      let medDropdown: any = data

      medDropdown.forEach(element => {

        this.catAllergyDropdown.push(element.drugName)


      });
      console.log(this.medAllergyDropdown.length)


    }, error => {


    });

  }
  callAllergiesAPI() {
    debugger
    this.dataservice.geteMemberAllegies(this.addEncounterForm.getRawValue().mbrRefNo).subscribe(data => {
      console.log(data)
      this.parseAllergies(data)




    }, error => {
      this.toastr.error("Issue In Data Saving", '', {
        timeOut: 1500,
      });

    })
  }

  callDiagnosisAPI() {
    this.addEncounterForm.get('diagnosedFor')?.setValue(this.selectedDiagnosis)
    this.dataservice.getPatDiagnosis(this.addEncounterForm.getRawValue().mbrRefNo).subscribe((data: any) => {
      console.log('hist data', data)
      this.toppingList = data.map((obj: any) => obj['icdShortDesc']);
    })
  }

  parseAllergies(data: any) {
    this.selectedMedAllergy = [];
    this.selectedCatAllergy = []
    data.forEach(element => {

      if (element.type == 'M' && element.activeFlag == 'A') {
        this.selectedMedAllergy.push(element.description)

      }
      if (element.type == 'C' && element.activeFlag == 'A') {
        this.selectedCatAllergy.push(element.description)

      }
      if (element.type == 'O' && element.activeFlag == 'A') {
        this.isOthersAllergyExist = element.description;
        this.addEncounterForm.patchValue({
          othersAllergy: element.description

        })



      }



    });
  }
  getTitlcase(data) {
    return titleCase(data)
  }



}
