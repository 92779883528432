<h2 mat-dialog-title>
    <div class="dialog-title row">
        <div class="col-md-12 d-flex align-items-center">
            <div class="col-md-11 d-flex align-items-center">
                <mat-icon color="warn" class="material-icons-outlined mar-15">warning</mat-icon>
                <h2>Alert</h2>
            </div>
            <div class="col-md-1 text-end">
                <button mat-icon-button color="primary" mat-dialog-close class="close-button">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</h2>
<br>
<div class="heading">
    {{ data.message }}
</div>
<mat-dialog-content class="mat-typography" *ngIf="data.isComments">
    <textarea matInput placeholder="Comments" [(ngModel)]="inputText"
        style="border: solid 1px #dadada; outline: none !important; height: 60px; text-align: left; padding: 5px; width: 95%; font-size: 13px;"> inputText
    </textarea>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button style="margin: 0 10px 0 0;" mat-stroked-button color="default" mat-dialog-close (click)="cancel">Cancel
    </button>
    <button mat-flat-button color="primary" *ngIf="!data.isComments" (click)="confirm()">Confirm </button>
    <button mat-flat-button color="primary" *ngIf="data.isComments" (click)="doAction()">Confirm </button>
</mat-dialog-actions>