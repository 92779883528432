<div class="containerWrapper" fxLayoutAlign="start start">
    <div class="filterWrap" fxFlex="312px">
        <div class="filterList">
            <ul class="listWrapper" fxLayout="column" fxLayoutAlign="start center" [@enterAnimation]>
                <li>
                    <button mat-flat-button (click)="homeBtn()" [routerLink]="['/landingPage']">
                        <mat-icon class="material-icons-outlined">home</mat-icon> Home
                    </button>
                </li>
                <li> <button mat-flat-button (click)="MetricReportold('OUTREACH_REPORT')" [routerLink]="['/report/oureachreport']"
                        [routerLinkActive]="['active']"> Productivity Report </button></li>
                   

            </ul>

        </div>
    </div>
    <div class="contentWrap" fxLayout="column">
        <router-outlet></router-outlet>

    </div>
</div>