import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IdleTimeoutService } from '../idle-timeout.service';
import { Idle } from '@ng-idle/core';

@Component({
  selector: 'app-timeout-dialog-member-details',
  templateUrl: './timeout-dialog-member-details.component.html',
  styleUrls: ['./timeout-dialog-member-details.component.css']
})
export class TimeoutDialogMemberDetailsComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TimeoutDialogMemberDetailsComponent>,public _router: Router,
    @Inject(MAT_DIALOG_DATA) public data ,public idleService:IdleTimeoutService) {}

  timeLeft: number = 10;
  interval: any
  
  ngOnInit(): void {
    this.startTimer()
  }
  startTimer() {
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {                
        this.logout()
      }
    },1000)
  }

  continue(){    
    this.dialogRef.close('continue')
  }

  logout(){        
    this.dialogRef.close(true)    
  }


}
