<h2 mat-dialog-title>
  <div class="dialog-title row">

    <div class="col-md-12 d-flex align-items-center">
      <div class="col-md-11">
        <h2><strong>Session Timeout</strong></h2>
      </div>
      <div class="col-md-1 text-end">
        <button mat-icon-button color="primary" mat-dialog-close class="close-button">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

  </div>
</h2>


<mat-dialog-content class="mat-typography">
  <div class="dialog-body">
    <p>You will be logged out in {{timeLeft}} seconds due to inactivity. Do you want to continue your session?</p>
  </div>

  <div class="col-md-12 text-center mt-2 mb-1">
    <button mat-flat-button class="mat-elevation-z0 text-end" color="primary" mat-stroked-button (click)="continue()"
      style="margin-right: 10px;">Continue</button>
    <button mat-flat-button class="mat-elevation-z0 text-end" color="primary" mat-stroked-button
      (click)="logout()">Logout</button>
  </div>

</mat-dialog-content>