import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, fromEvent } from 'rxjs';
import { DataService } from '../data.service';
import { map, startWith, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MemberAllergy } from '../models/MemberAllergy';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { titleCase } from '../utilityMethods/helperMethods';




@Component({
  selector: 'app-add-new-patient',
  templateUrl: './add-new-patient.component.html',
  styleUrls: ['./add-new-patient.component.css']
})
export class AddNewPatientComponent implements OnInit, AfterViewInit {
  isEditPatient = false;
  showAllFields = false
  step = 0;
  step2 = 0;
  step3 = 0
  step4 = 0
  allergyhint: boolean = false
  allergyplaceholder = ""
  showaddencounter: boolean = false
  separatorKeysCodes: number[] = [ENTER, COMMA];
  allergyFlag: any = {
    "medicationAllergy": true,
    "medicationClassAllergy": true,
    "othersAllergy": true
  };
  mbrRefNo: any = ''
  cities: any = ["AA", "BB", "CC"]
  allergyType = 'Not-Selected'
  allergyList: any = []
  allergySaveDisabled: boolean = true
  diagnosisSaveDisabled: boolean = true


  systemFlag: any
  cdos: any = [{
    "region": "CO",
    "regionName": "Colorado"
  }, {
    "region": "ID",
    "regionName": "Idaho"
  }]
  visible = true;
  selectable = true;
  selectable1 = true;
  isOthersAllergyExist: any = ''

  diagnosisRemoval = false;
  allergyRemovable = false;
  selectedMedAllergy: string[] = [];
  selectedCatAllergy: string[] = [];
  selectedOtherAllergy: string[] = [];
  selectedNoKNownAllergy: string[] = [];
  initialselectedMedAllergy: string[] = [];
  initialselectedCatAllergy: string[] = [];
  initialselectedOtherAllergy: string[] = [];
  initialselectedNoKNownAllergy: string[] = [];
  removedMedAllergy: any[] = [];
  removedOtherAllergy: any[] = [];
  removedCatAllergy: any[] = [];
  removedNoKnownAllergy: any[] = [];

  medAllergyDropdown: string[] = [];

  catAllergyDropdown: string[] = [];
  disableNoKnowAllergy: boolean = false
  disableOtherAllergy: boolean = false

  // visible = true;
  selectable2 = true;
  removable = true;
  diagnosisCtrl = new FormControl();
  filteredDiagnosis: any = [];
  diagnosis: string[] = [];
  allDiagnosis: any
  isDiagnosis: boolean = false;
  currDiagnosis: any[] = new Array();
  deletedDiagnosis: string[] = [];
  isAllergyDeleted: boolean = false;
  allergyData: any;
  isRefreshAllergy: boolean = false;
  isRefreshDiagnosis: boolean = false;

  @ViewChild('medAllergyInput') medAllergyInput: ElementRef<HTMLInputElement>;
  @ViewChild('classAllergyInput') classAllergyInput: ElementRef<HTMLInputElement>;
  @ViewChild('diagnosisInput') diagnosisInput: ElementRef<HTMLInputElement>;
  disableAddButton: boolean = false;


  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === 13) {
      event.preventDefault()
    }
  }




  constructor(public dataservice: DataService, private toastr: ToastrService, public router: Router, public route: ActivatedRoute, private dialog: MatDialog) {

  }
  addPatientForm: FormGroup = new FormGroup({
    mbrRefNo: new FormControl(),
    mrn: new FormControl(),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    memberId: new FormControl(),
    gender: new FormControl('', [Validators.required]),
    dob: new FormControl('', [Validators.required]),

    preferredLanguage: new FormControl(),
    email: new FormControl(),
    primaryContactNumber: new FormControl('',),
    alternateContactNumber: new FormControl(),
    pcp: new FormControl(),
    pcpNPI: new FormControl(),
    payor: new FormControl(),
    addressline1: new FormControl(),
    addressline2: new FormControl(),
    zipCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    rafScore: new FormControl(),
    isActive: new FormControl(),
    inactiveReason: new FormControl(),
    presentINPM: new FormControl(),
    planElStatus: new FormControl(),
    planName: new FormControl(),
    lob: new FormControl(),
    extMemberId: new FormControl(),
    medicareHIC: new FormControl(),
    pcpPhone: new FormControl(),
    pcpFax: new FormControl(),
    pcpSpeciality: new FormControl(),
    pcpAddressline1: new FormControl(),
    pcpAddressline2: new FormControl(),
    pcpAddressline3: new FormControl(),
    pcpZipCode: new FormControl(),
    pcpCity: new FormControl(),
    pcpState: new FormControl(),
    systemFlag: new FormControl(),
    allergyType: new FormControl(),
    medicationAllergy: new FormControl(),
    diagnosis: new FormControl()

  })
  ngOnInit(): void {
    debugger

    if (this.router.url != '/addMember') {
      this.addPatientForm.controls['firstName'].disable();
      this.addPatientForm.controls['lastName'].disable();
      this.addPatientForm.controls['dob'].disable();
      this.addPatientForm.controls['mbrRefNo'].disable();
      this.addPatientForm.controls['gender'].disable();
      this.allergyRemovable = false;
      this.isEditPatient = true;
      this.populateFormFields(this.route.snapshot.params['mbrRefNo'])
      this.mbrRefNo = this.route.snapshot.params['mbrRefNo']
      this.showaddencounter = true
      this.dataservice.geteMemberAllegies(this.mbrRefNo).subscribe(data => {
        console.log(data)
        this.allergyData = data;
        this.parseAllergies(data)




      }, error => {
        this.toastr.error("Issue In Data Saving", '', {
          timeOut: 1500,
        });

      })



    }
    else {
      this.isEditPatient = false;

    }
    this.addPatientForm.controls['mbrRefNo'].disable();





  }

  ngAfterViewInit(): void {

  }

  getIcdData() {
    debugger
    let diagnosisJson = {
      "icdcode": this.addPatientForm.getRawValue().diagnosis,
      "icdshortdesc": ""
    }
    if (this.addPatientForm.getRawValue().diagnosis.length >= 3) {
      this.allergyhint = false;

      this.dataservice.getIcdCodes(diagnosisJson).subscribe((data: any) => {
        console.log('ICD', data)
        this.allDiagnosis = data.map((obj: any) => obj['icdcode'] + '-' + obj['icdshortdesc']);
        this.filteredDiagnosis = this.allDiagnosis
      });
    }
    if (this.addPatientForm.getRawValue().diagnosis.length < 3) {

      this.allergyhint = true;

    }
  }

  onUserInput(inputValue: string): void {
    console.log('User typed:', inputValue);

  }

  addNewDiagnosis() {
    if (this.router.url != '/addMember') {
      this.mbrRefNo = this.route.snapshot.params['mbrRefNo']

    }
    else {
      if (this.mbrRefNo == '') {
        this.toastr.error("Please Save Member Details Before Diagnosis", '', {
          timeOut: 1500,
        })
        return;
      }
      else {
        this.mbrRefNo = this.mbrRefNo;
      }
    }
    let dateTime = new Date()

    for (let value in this.diagnosis) {
      let icdCode = this.diagnosis[value].split("-")

      if (!this.currDiagnosis.some((dict) => dict['icdCode'] === icdCode[0])) {
        let desc = this.diagnosis[value].replace(icdCode[0] + "-", '')

        let temp = {
          "mbrRefNo": this.mbrRefNo,
          "icdCode": icdCode[0],
          "icdShortDesc": desc,
          "diagEffStrtDT": dateTime.toISOString().replace('T', ' ').slice(0, 23),
          "diagEffEndDT": dateTime.toISOString().replace('T', ' ').slice(0, 23),
          "activeFlag": "I",
          "lastUpdtDT": dateTime.toISOString().replace('T', ' ').slice(0, 23)
        }
        this.currDiagnosis.push(temp)
      }


    }
    this.diagnosisRemoval = false


    this.dataservice.updatePatDiagnosis(this.currDiagnosis).subscribe(data => {

      this.toastr.success("Diagnosis Saved Successfully", '', {
        timeOut: 1500,
      })
    }, error => {
      this.toastr.error("Issue In Diagnosis Saving", '', {
        timeOut: 1500,
      });

    })

    console.log(this.currDiagnosis)
    //location.reload()
  }

  editDiagnosis() {
    debugger
    this.diagnosisRemoval = true
  }

  icdCodesSearch(): void {
    this.dataservice.getPatDiagnosis(this.addPatientForm.get('mbrRefNo').value).subscribe((data: any) => {
      console.log('hist data', data)
      if (data.length > 0) {
        this.currDiagnosis = data
        this.diagnosisRemoval = false
        this.diagnosis = data.map((obj: any) => obj['icdCode'] + '-' + obj['icdShortDesc'])
        this.step2 = 1
        console.log(this.diagnosis)
      }
    })


  }





  addnewpatient() {
    this.addNewPatientApiCall()
  }
  addNewPatientApiCall(): void {

    if (this.addPatientForm.valid) {

      let patientInput = {
        mbrRefNo: "",
        mrn: this.addPatientForm.getRawValue().mrn,
        firstName: this.addPatientForm.getRawValue().firstName,
        lastName: this.addPatientForm.getRawValue().lastName,
        memberId: this.addPatientForm.getRawValue().memberId,
        gender: this.addPatientForm.getRawValue().gender,
        dob: this.addPatientForm.getRawValue().dob,
        diagnosisCode: "",
        diagnosisDesc: "",
        diagnosiseffFrom: "",
        diagnosiseffTo: "",
        preferredLanguage: this.addPatientForm.getRawValue().preferredLanguage,
        email: this.addPatientForm.getRawValue().email,
        primaryContactNumber: this.removeFormattedPhone(this.addPatientForm.getRawValue().primaryContactNumber),
        alternateContactNumber: this.removeFormattedPhone(this.addPatientForm.getRawValue().alternateContactNumber),
        pcp: this.addPatientForm.getRawValue().pcp,
        pcpNPI: this.addPatientForm.getRawValue().pcpNPI,
        addressline1: this.addPatientForm.getRawValue().addressline1,
        addressline2: this.addPatientForm.getRawValue().addressline2,
        zipCode: this.addPatientForm.getRawValue().zipCode,
        city: this.addPatientForm.getRawValue().city,
        state: this.addPatientForm.getRawValue().state,
        rafScore: this.addPatientForm.getRawValue().rafScore,
        allergies: "",
        payor: this.addPatientForm.getRawValue().payor,
        isActive: this.addPatientForm.getRawValue().isActive,
        inactiveReason: this.addPatientForm.getRawValue().inactiveReason,
        presentINPM: this.addPatientForm.getRawValue().presentINPM,
        planElStatus: this.addPatientForm.getRawValue().planElStatus,
        planName: this.addPatientForm.getRawValue().planName,
        planNPI: this.addPatientForm.getRawValue().pcpNPI,
        pcpAddressline1: this.addPatientForm.getRawValue().pcpAddressline1,
        pcpAddressline2: this.addPatientForm.getRawValue().pcpAddressline2,
        pcpAddressline3: this.addPatientForm.getRawValue().pcpAddressline3,
        pcpZipCode: this.addPatientForm.getRawValue().pcpZipCode,
        pcpCity: this.addPatientForm.getRawValue().pcpCity,
        pcpState: this.addPatientForm.getRawValue().pcpState,
        extMemberId: this.addPatientForm.getRawValue().extMemberId,
        medicareHIC: this.addPatientForm.getRawValue().medicareHIC,
        pcpPhone: this.removeFormattedPhone(this.addPatientForm.getRawValue().pcpPhone),
        pcpFax: this.removeFormattedPhone(this.addPatientForm.getRawValue().pcpFax),
        lob: this.addPatientForm.getRawValue().lob,
        pcpSpeciality: this.addPatientForm.getRawValue().pcpSpeciality,
        systemFlag: "M"
      }




      console.log(patientInput)
      if (!this.isEditPatient) {


        this.dataservice.addPatient(patientInput).subscribe(data => {
          if (data["message"] == "Duplicate members present") {
            this.toastr.error("Member Is Already Present,Please Add Encounter", '', {
              timeOut: 1500,
            });

          }
          else if (data["message"].startsWith("Member Saved Successfully")) {
            this.mbrRefNo = data["message"].split("-")[1]
            console.log(this.mbrRefNo)
            this.toastr.success("Patient Saved Successfully", '', {
              timeOut: 1500,
            });

          }
          else {
            this.toastr.success("Patient Saved Successfully", '', {
              timeOut: 1500,
            });

          }



        }, error => {
          this.toastr.error("Issue In Data Saving", '', {
            timeOut: 1500,
          });

        })
      } else {
        debugger
        if (this.addPatientForm.getRawValue().systemFlag != "M") {

          this.toastr.error("Patient Is Not Manually Added ,So Could Not Be Edited", '', {
            timeOut: 1500,
          });

          return;
        }
        patientInput["mbrRefNo"] = this.addPatientForm.getRawValue().mbrRefNo
        this.dataservice.editPatientData(patientInput).subscribe(data => {


          this.toastr.success("Patient Saved Successfully", '', {
            timeOut: 1500,
          });

        }, error => {
          this.toastr.error("Issue In Data Saving", '', {
            timeOut: 1500,
          });

        })

      }
    }



  }
  cancel(): void {

  }
  submitfile() {

  }
  cancelFileUpload() { }

  patientSearch() {
    this.checkPatientToc()

  }
  populateFormFields(mbrREfNo) {



    this.dataservice.getPatientData(mbrREfNo).subscribe(data => {
      this.systemFlag = data[0]["systemFlag"];
      this.addPatientForm.patchValue({
        mbrRefNo: data[0]["mbrRefNo"],
        mrn: data[0]["mrn"],
        firstName: titleCase(data[0]["firstName"]),
        lastName: titleCase(data[0]["lastName"]),
        memberId: data[0]["memberId"],
        gender: data[0]["gender"],
        dob: data[0]["dob"],

        preferredLanguage: data[0]["preferredLanguage"],
        email: data[0]["email"].toLowerCase(),
        primaryContactNumber: this.getFormattedPhone(data[0]["primaryContactNumber"]),
        alternateContactNumber: this.getFormattedPhone(data[0]["alternateContactNumber"]),
        pcp: titleCase(data[0]["pcp"]),
        pcpNPI: data[0]["pcpNPI"],
        addressline1: data[0]["addressline1"],
        addressline2: data[0]["addressline2"],
        zipCode: data[0]["zipCode"],
        lob: data[0]["lob"],
        city: titleCase(data[0]["city"]),
        state: data[0]["state"],
        rafScore: data[0]["rafScore"],
        payor: data[0]["payor"],
        isActive: data[0]["isActive"],
        inactiveReason: data[0]["inactiveReason"],
        presentINPM: data[0]["presentINPM"],
        planId: data[0]["planId"],
        planName: data[0]["planName"],
        planElStatus: data[0]["planElStatus"],
        extMemberId: data[0]["extMemberId"],
        medicareHIC: data[0]["medicareHIC"],
        pcpPhone: this.getFormattedPhone(data[0]["pcpPhone"]),
        pcpFax: this.getFormattedPhone(data[0]["pcpFax"]),
        pcpSpeciality: data[0]["pcpSpeciality"],
        pcpAddressline1: data[0]["pcpAddressline1"],
        pcpAddressline2: data[0]["pcpAddressline2"],
        pcpAddressline3: data[0]["pcpAddressline3"],
        pcpZipCode: data[0]["pcpZipCode"],
        pcpCity: data[0]["pcpCity"],
        pcpState: data[0]["pcpState"],
        systemFlag: data[0]["systemFlag"],


      });
      debugger
      if (data[0]["systemFlag"] != "M") {
        this.addPatientForm.controls['mbrRefNo'].disable()
        this.addPatientForm.controls['mrn'].disable()
        this.addPatientForm.controls['firstName'].disable()
        this.addPatientForm.controls['lastName'].disable()
        this.addPatientForm.controls['memberId'].disable()
        this.addPatientForm.controls['gender'].disable()
        this.addPatientForm.controls['dob'].disable()

        this.addPatientForm.controls['preferredLanguage'].disable()
        this.addPatientForm.controls['email'].disable()
        this.addPatientForm.controls['primaryContactNumber'].disable()
        this.addPatientForm.controls['alternateContactNumber'].disable()
        this.addPatientForm.controls['pcp'].disable()
        this.addPatientForm.controls['pcpNPI'].disable()
        this.addPatientForm.controls['payor'].disable()
        this.addPatientForm.controls['addressline1'].disable()
        this.addPatientForm.controls['addressline2'].disable()
        this.addPatientForm.controls['zipCode'].disable()
        this.addPatientForm.controls['city'].disable()
        this.addPatientForm.controls['state'].disable()
        this.addPatientForm.controls['rafScore'].disable()
        this.addPatientForm.controls['isActive'].disable()
        this.addPatientForm.controls['inactiveReason'].disable()
        this.addPatientForm.controls['presentINPM'].disable()
        this.addPatientForm.controls['planElStatus'].disable()
        this.addPatientForm.controls['planName'].disable()
        this.addPatientForm.controls['lob'].disable()
        this.addPatientForm.controls['extMemberId'].disable()
        this.addPatientForm.controls['medicareHIC'].disable()
        this.addPatientForm.controls['pcpPhone'].disable()
        this.addPatientForm.controls['pcpFax'].disable()
        this.addPatientForm.controls['pcpSpeciality'].disable()
        this.addPatientForm.controls['pcpAddressline1'].disable()
        this.addPatientForm.controls['pcpAddressline2'].disable()
        this.addPatientForm.controls['pcpAddressline3'].disable()
        this.addPatientForm.controls['pcpZipCode'].disable()
        this.addPatientForm.controls['pcpCity'].disable()
        this.addPatientForm.controls['pcpState'].disable()
        this.addPatientForm.controls['systemFlag'].disable()
      }


      this.toastr.success("Patient Fetched Successfully", '', {
        timeOut: 1500,
      });
      this.icdCodesSearch()

    }, error => {
      this.toastr.error("Issue In Getting Member", '', {
        timeOut: 1500,
      });


    })



  }
  genrateSeqNumber() {

    let seqNumber = ''
    this.dataservice.getPatientSeq().subscribe(data => {
      seqNumber = data["seqNumber"]
      this.addPatientForm.patchValue({
        mbrRefNo: data["seqNumber"]
      })




    }, error => {
      this.toastr.error("Issue In Data Saving", '', {
        timeOut: 1500,
      });

    })
    return seqNumber

  }
  deletePatient() {


    if (this.addPatientForm.getRawValue().systemFlag != "M") {

      this.toastr.error("Patient Is Not Manually Added ,So Could Not Be Deleted", '', {
        timeOut: 1500,
      });

      return;
    }


    this.dataservice.deletePatient(this.addPatientForm.getRawValue().mbrRefNo).subscribe(data => {

      this.toastr.success("Patient Deleted Successfully", '', {
        timeOut: 1500,
      });
    }, error => {
      this.toastr.error("Issue In Data Saving", '', {
        timeOut: 1500,
      });

    })



  }
  checkPatientINPM() {
    var inputPatient = {
      "firstName": this.addPatientForm.getRawValue().firstName,
      "lastName": this.addPatientForm.getRawValue().lastName,
      "dob": this.addPatientForm.getRawValue().dob

    }
    this.dataservice.searchMemberINPM(inputPatient).subscribe(data => {
      if (data.hasOwnProperty('mrn')) {
        this.toastr.success("Member Found , Please Add Any Additional Information And Save And Proceed To Add Encounter", '', {
          timeOut: 1500,
        });
        this.showaddencounter = true


      }
      else {
        this.toastr.error("Members Not In Pacman PM And Add Patient Manually", '', {
          timeOut: 1500,
        });

      }
      this.populateDatafrompacmanPM(data)

    }, error => {
      this.toastr.error("Member Not Found Please Enter The Information And Save", '', {
        timeOut: 1500,
      });
    });


  }
  checkPatientToc() {
    debugger

    var inputPatient = {
      "firstName": this.addPatientForm.getRawValue().firstName,
      "lastName": this.addPatientForm.getRawValue().lastName,
      "dob": this.addPatientForm.getRawValue().dob,
      "gender": this.addPatientForm.getRawValue().gender

    }
    this.dataservice.checkDuplicatePatient(inputPatient).subscribe(data => {
      if (data["message"] == "Duplicate members present") {
        this.toastr.error("Duplicate Member Present ,Delete Patient Before Manual Addition", '', {
          timeOut: 1500,
        });


      }
      else {
        this.checkPatientINPM()


      }

    }, error => {


    });

  }
  populateDatafrompacmanPM(data) {


    this.addPatientForm.patchValue({
      mrn: data["mrn"],
      firstName: titleCase(data["firstName"]),
      lastName: titleCase(data["lastName"]),
      gender: data["sex"],
      dob: data["dob"],
      preferredLanguage: data["patient_Primary_Language"],
      email: data["email"],
      primaryContactNumber: this.getPrimaryPhone(data),
      alternateContactNumber: this.getalternatePhone(data),
      addressline1: data["street1"],
      addressline2: data["street2"],
      zipCode: data["zipCode"],
      city: data["city"],
      state: data["state"],
      rafScore: data["rafScore"],
      isActive: data["statusFlag"],
      inactiveReason: data["statusReason"],
      presentINPM: 'Y',


    });



  }
  getPrimaryPhone(data) {
    if (data.primaryPhoneType == 'C') {
      return data.cellPhone
    }
    else if (data.primaryPhoneType == 'H') {
      return data.homePhone

    }
    else if (data.primaryPhoneType == 'W') {
      return data.workPhone

    }
    else {
      return data.cellPhone

    }

  }
  getalternatePhone(data) {

    if (data.primaryPhoneType == 'C' && data.homePhone) {
      return data.homePhone
    }
    else if (data.primaryPhoneType == 'H' && data.workPhone) {
      return data.workPhone
    }
    else {
      return data.cellPhone
    }

  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  setStep2(index: number) {
    this.step2 = index;
  }
  setStep3(index: number) {
    this.step3 = index;
  }


  nextStep2() {
    this.step2++;
  }

  prevStep2() {
    this.step4--;
  }
  setStep4(index: number) {
    this.step4 = index;
  }
  nextStep4() {
    this.step4++;
  }

  prevStep4() {
    this.step4--;
  }

  gotoaddencounter() {
    var inputPatient = {
      "firstName": this.addPatientForm.getRawValue().firstName,
      "lastName": this.addPatientForm.getRawValue().lastName,
      "dob": this.addPatientForm.getRawValue().dob,
      "gender": this.addPatientForm.getRawValue().gender

    }
    localStorage.setItem('input', JSON.stringify(inputPatient))
    this.router.navigate(['/addEncounter'])
  }
  add(event: MatChipInputEvent): void {
    debugger
    console.log('hiiiiiiii')

    const input = event.input;
    const value = event.value;
    // Add our fruit
    if ((value || '').trim()) {
      if (this.medAllergyDropdown.includes(value) && this.medAllergyDropdown.length > 0 && !this.selectedMedAllergy.includes(value)) {
        this.selectedMedAllergy.push(value.trim());
      }
      else {
        this.toastr.error("Please Select A Valid Dropdown")
      }
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }
  adddata() {
    console.log("called adddata")

  }

  addDiagnosis() {
    debugger

    const value = this.addPatientForm.getRawValue().diagnosis;
    this.addPatientForm.patchValue({
      "diagnosis": ""
    })


    if ((value || '').trim()) {
      if (this.filteredDiagnosis.includes(value) && this.filteredDiagnosis.length > 0 && !this.diagnosis.includes(value)) {
        this.diagnosis.push(value.trim());
        this.diagnosisSaveDisabled = false
      }
      else {
        this.toastr.error("Please Select A Valid Dropdown")
      }
      this.diagnosisRemoval = true
    }

    this.isDiagnosis = true
    // this.diagnosisCtrl.setValue(null);
  }

  removeDiagnosis(fruit: string): void {

    const index = this.diagnosis.indexOf(fruit);
    this.deletedDiagnosis.push(fruit.split('-')[0])
    let dateTime = new Date()
    if (index >= 0) {
      this.diagnosis.splice(index, 1);
    }
    if (this.deletedDiagnosis.length > 0) {
      for (let value in this.currDiagnosis) {
        if (this.deletedDiagnosis.includes(this.currDiagnosis[value]['icdCode'])) {
          console.log('Deleted', this.currDiagnosis[value]['icdCode'])
          this.currDiagnosis[value]['lastUpdtDT'] = dateTime.toISOString().replace('T', ' ').slice(0, 23);
          this.currDiagnosis[value]['activeFlag'] = 'D'
        }
      }
    }
    this.diagnosisSaveDisabled = false;


  }

  selectedDiagnosis(event: MatAutocompleteSelectedEvent): void {
    let foundIndex = this.diagnosis.indexOf(event.option.viewValue);
    console.log('found', foundIndex)
    if (foundIndex == -1) {
      this.diagnosis.push(event.option.viewValue);
      this.diagnosisInput.nativeElement.value = '';
      this.diagnosisCtrl.setValue(null);
      this.isDiagnosis = true
    }
    else {
      this.toastr.error("Selected Diagnosis Is Already Present", '', {
        timeOut: 1500,
      })
    }
  }

  private _filterDiagnosis(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.allDiagnosis.filter(diagnosis => diagnosis.toLowerCase().indexOf(filterValue) === 0);
  }


  addCatAllergy(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit

    if (this.catAllergyDropdown.includes(value) && this.catAllergyDropdown.length > 0) {
      this.selectedCatAllergy.push(value.trim());
    }
    else {
      this.toastr.error("Please Select A Valid Dropdown", '', {
        timeOut: 1500,
      })
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

  }
  removeMedAllergy(fruit: string): void {
    debugger

    this.allergySaveDisabled = false
    this.initialselectedMedAllergy = Object.assign({}, this.selectedMedAllergy);
    const index = this.selectedMedAllergy.indexOf(fruit);


    if (index >= 0) {

      this.removedMedAllergy.push(fruit)
      this.selectedMedAllergy.splice(index, 1);
    }
    debugger
    if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
      this.disableNoKnowAllergy = true

    }
    else if (this.selectedNoKNownAllergy.length > 0) {
      this.disableOtherAllergy = true

    }
    else {
      this.disableNoKnowAllergy = false
      this.disableOtherAllergy = false

    }
    console.log(this.removedMedAllergy, this.selectedMedAllergy)
    this.isAllergyDeleted = true


  }
  removeAllergycategory(fruit: string): void {
    debugger
    this.initialselectedCatAllergy = Object.assign({}, this.selectedCatAllergy)

    const index = this.selectedCatAllergy.indexOf(fruit);

    if (index >= 0) {

      this.removedCatAllergy.push(fruit)
      this.selectedCatAllergy.splice(index, 1);
      if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
        this.disableNoKnowAllergy = true

      }
      else if (this.selectedNoKNownAllergy.length > 0) {
        this.disableOtherAllergy = true

      }
      else {
        this.disableNoKnowAllergy = false
        this.disableOtherAllergy = false

      }
    }
    this.allergySaveDisabled = false
    this.isAllergyDeleted = true

  }
  removenoKnownAllergy(fruit: string): void {
    debugger
    this.initialselectedNoKNownAllergy = Object.assign({}, this.selectedNoKNownAllergy)

    const index = this.selectedNoKNownAllergy.indexOf(fruit);

    if (index >= 0) {
      this.removedNoKnownAllergy.push(fruit)
      this.selectedNoKNownAllergy.splice(index, 1);
      if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
        this.disableNoKnowAllergy = true

      }
      else if (this.selectedNoKNownAllergy.length > 0) {
        this.disableOtherAllergy = true

      }
      else {
        this.disableNoKnowAllergy = false
        this.disableOtherAllergy = false

      }
      this.allergySaveDisabled = false
    }
    this.isAllergyDeleted = true
  }
  removeotherAllergy(fruit: string): void {
    debugger
    this.initialselectedOtherAllergy = Object.assign({}, this.selectedOtherAllergy)

    const index = this.selectedOtherAllergy.indexOf(fruit);

    if (index >= 0) {

      this.removedOtherAllergy.push(fruit)
      this.selectedOtherAllergy.splice(index, 1);
      if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
        this.disableNoKnowAllergy = true

      }
      else if (this.selectedNoKNownAllergy.length > 0) {
        this.disableOtherAllergy = true

      }
      else {
        this.disableNoKnowAllergy = false
        this.disableOtherAllergy = false

      }

    }
    this.allergySaveDisabled = false
    this.isAllergyDeleted = true

  }
  setallergy(allergyValue: HTMLInputElement) {
    var allergy = allergyValue.value

  }
  addAllergy() {
    debugger
    this.allergyRemovable = true
    if (this.allergyList.length > 0) {
      let newAllergyList = []
      this.allergyList.forEach(e => {
        newAllergyList.push(this.getTitleCase(e))
      })
      this.allergyList = newAllergyList
    }


    if (this.allergyType == 'M') {

      if (this.allergyList.includes(this.addPatientForm.getRawValue().medicationAllergy) && this.allergyList.length > 0 && (!this.selectedMedAllergy.includes(this.addPatientForm.getRawValue().medicationAllergy))) {
        this.selectedMedAllergy.push(this.addPatientForm.getRawValue().medicationAllergy)
        this.allergySaveDisabled = false
      }
      else if (this.selectedMedAllergy.includes(this.addPatientForm.getRawValue().medicationAllergy)) {
        this.toastr.error("Allergy Already Added", '', {
          timeOut: 1500,
        })
      }
      else if (!this.allergyList.includes(this.addPatientForm.getRawValue().medicationAllergy) && this.allergyList.length > 0) {
        this.toastr.error("Please Select A Valid Allergy", '', {
          timeOut: 1500,
        })
      }
      else if (this.allergyList.length == 0) {
        this.toastr.error("Please Select A Valid Allergy", '', {
          timeOut: 1500,
        })
      }


    }
    else if (this.allergyType == 'C') {
      let upperCatAllergy = this.addPatientForm.getRawValue().medicationAllergy
      if (this.allergyList.includes(upperCatAllergy) && this.allergyList.length > 0 && (!this.selectedCatAllergy.includes(this.addPatientForm.getRawValue().medicationAllergy))) {
        this.selectedCatAllergy.push(this.addPatientForm.getRawValue().medicationAllergy)
        this.allergySaveDisabled = false

      }
      else if (this.selectedCatAllergy.includes(this.addPatientForm.getRawValue().medicationAllergy)) {
        this.toastr.error("Allergy Already Added", '', {
          timeOut: 1500,
        })
      }
      else if (!this.allergyList.includes(this.addPatientForm.getRawValue().medicationAllergy) && this.allergyList.length > 0) {
        this.toastr.error("Please Select A Valid Allergy", '', {
          timeOut: 1500,
        })
      }
      else if (this.allergyList.length == 0) {
        this.toastr.error("Please Select A Valid Allergy", '', {
          timeOut: 1500,
        })
      }

    }
    else if (this.allergyType == 'O') {
      if (!this.selectedOtherAllergy.includes(this.addPatientForm.getRawValue().medicationAllergy)) {
        this.selectedOtherAllergy.push(this.addPatientForm.getRawValue().medicationAllergy)
        this.allergySaveDisabled = false

      }
      else if (this.selectedOtherAllergy.includes(this.addPatientForm.getRawValue().medicationAllergy)) {
        this.toastr.error("Allergy Already Added", '', {
          timeOut: 1500,
        })
      }



    }
    else if (this.allergyType == 'N') {
      if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
        this.toastr.error("Please Remove No Other Allergies Before Adding", '', {
          timeOut: 1500,
        })
        return;
      }

      if (!this.selectedNoKNownAllergy.includes("No Known Allergies")) {
        this.selectedNoKNownAllergy.push("No Known Allergies")
        this.allergySaveDisabled = false
      }
      else if (this.selectedNoKNownAllergy.includes("No Known Allergies")) {
        this.toastr.error("No Known Allergies Already Added", '', {
          timeOut: 1500,
        })
      }



    }


    if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
      this.disableNoKnowAllergy = true

    }
    else if (this.selectedNoKNownAllergy.length > 0) {
      this.disableOtherAllergy = true

    }
    else {
      this.disableNoKnowAllergy = false
      this.disableOtherAllergy = false

    }
    this.addPatientForm.patchValue({
      "medicationAllergy": ""
    })

  }
  editAllergy() {
    this.allergyRemovable = true

  }
  getMedicationAllergyDropdown() {
    debugger

    if (this.allergyType == 'M') {
      let input = {
        "drugName": this.addPatientForm.getRawValue().medicationAllergy,
        "drugCategory": ""
      }
      this.getMedicationAllergy(input)

    }
    else if (this.allergyType == 'C') {
      let input = {
        "drugName": "",
        "drugCategory": this.addPatientForm.getRawValue().medicationAllergy
      }
      this.getMedicationCatAllergyDropdown(input)

    }





  }
  getMedicationAllergy(input) {
    this.dataservice.getAllergyDropdown(input).subscribe(data => {
      console.log(data)
      let medDropdown: any = data
      this.allergyList = []

      medDropdown.forEach(element => {

        this.allergyList.push(element.drugName)


      });
      console.log(this.medAllergyDropdown.length)


    }, error => {


    });

  }

  getTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  getMedicationCatAllergyDropdown(input) {
    this.dataservice.getAllergyDropdown(input).subscribe(data => {
      this.allergyList = []
      console.log(data)
      let medDropdown: any = data

      medDropdown.forEach(element => {

        this.allergyList.push(element.drugName)


      });
      console.log(this.medAllergyDropdown.length)


    }, error => {


    });

  }

  selected(event: MatAutocompleteSelectedEvent): void {

    let value = event.option.viewValue

    if (this.medAllergyDropdown.includes(value) && this.medAllergyDropdown.length > 0 && (!this.selectedMedAllergy.includes(value))) {
      this.selectedMedAllergy.push(value.trim());
      this.medAllergyInput.nativeElement.value = '';
      this.disableNoKnowAllergy = true
    }
    else {
      this.toastr.error("Please Select A Valid Dropdown", '', {
        timeOut: 1500,
      })
    }

  }
  selectedclassAllergy(event: MatAutocompleteSelectedEvent): void {
    debugger
    let value = event.option.viewValue

    if (value.length > 0 && this.catAllergyDropdown.includes(value.toUpperCase()) && this.catAllergyDropdown.length > 0 && (!this.selectedCatAllergy.includes(value))) {
      this.selectedCatAllergy.push(value.trim());
      this.classAllergyInput.nativeElement.value = '';
      this.disableNoKnowAllergy = true
    }
    else {
      this.toastr.error("Please Select A Valid Dropdown", '', {
        timeOut: 1500,
      })
    }

  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.medAllergyDropdown.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
  saveAllergy() {
    if (this.selectedCatAllergy.length <= 0 && this.selectedMedAllergy.length <= 0 && this.selectedOtherAllergy.length <= 0 && this.selectedNoKNownAllergy.length <= 0 && this.removedCatAllergy.length <= 0 && this.removedMedAllergy.length <= 0 && this.removedOtherAllergy.length <= 0 && this.removedNoKnownAllergy.length <= 0) {
      this.toastr.error("Please Add Or Delete Allergies Before Saving", '', {
        timeOut: 1500,
      })
      return;
    }

    debugger
    let memberAllergyList: any = [];
    let memberAllergy = new MemberAllergy();
    let mbrRefNo: any
    if (this.router.url != '/addMember') {
      this.mbrRefNo = this.route.snapshot.params['mbrRefNo']

    }
    else {
      if (this.mbrRefNo == '') {
        this.toastr.error("Please Save Member Details Before Allergy", '', {
          timeOut: 1500,
        })
        return;
      }
      else {
        this.mbrRefNo = this.mbrRefNo;
      }
    }

    if (this.selectedNoKNownAllergy.length > 0) {
      let memberAllergy = new MemberAllergy();
      memberAllergy.setType("N");
      memberAllergy.setActiveFlag("A")
      memberAllergy.setDescription("No Known Allergies");
      memberAllergy.setmbrRefNo(this.mbrRefNo)
      memberAllergyList.push(memberAllergy);
      this.disableNoKnowAllergy = true


    }


    if (this.selectedMedAllergy.length > 0) {
      this.selectedMedAllergy.forEach(allergy => {
        let memberAllergy = new MemberAllergy();
        memberAllergy.setType("M");
        memberAllergy.setActiveFlag("A")
        memberAllergy.setDescription(allergy);
        memberAllergy.setmbrRefNo(this.mbrRefNo)
        memberAllergyList.push(memberAllergy);

      })
    }
    if (this.removedMedAllergy.length > 0) {
      this.removedMedAllergy.forEach(allergy => {
        if (!this.selectedMedAllergy.includes(allergy)) {
          let memberAllergy = new MemberAllergy();
          memberAllergy.setType("M");
          memberAllergy.setActiveFlag("D")
          memberAllergy.setDescription(allergy);
          memberAllergy.setmbrRefNo(this.mbrRefNo)
          memberAllergyList.push(memberAllergy);
        }
      })
    }
    if (this.selectedCatAllergy.length > 0) {
      this.selectedCatAllergy.forEach(allergy => {
        let memberAllergy = new MemberAllergy();
        memberAllergy.setType("C");
        memberAllergy.setActiveFlag("A")
        memberAllergy.setDescription(allergy);
        memberAllergy.setmbrRefNo(this.mbrRefNo)
        memberAllergyList.push(memberAllergy);

      })
    }
    if (this.removedCatAllergy.length > 0) {
      this.removedCatAllergy.forEach(allergy => {
        if (!this.selectedCatAllergy.includes(allergy)) {
          let memberAllergy = new MemberAllergy();
          memberAllergy.setType("C");
          memberAllergy.setActiveFlag("D")
          memberAllergy.setDescription(allergy);
          memberAllergy.setmbrRefNo(this.mbrRefNo)
          memberAllergyList.push(memberAllergy);
        }
      })
    }
    if (this.selectedOtherAllergy.length > 0) {
      this.selectedOtherAllergy.forEach(allergy => {
        let memberAllergy = new MemberAllergy();
        memberAllergy.setType("O");
        memberAllergy.setActiveFlag("A")
        memberAllergy.setDescription(allergy);
        memberAllergy.setmbrRefNo(this.mbrRefNo)
        memberAllergyList.push(memberAllergy);

      })
    }
    if (this.removedOtherAllergy.length > 0) {
      this.removedOtherAllergy.forEach(allergy => {
        if (!this.selectedOtherAllergy.includes(allergy)) {
          let memberAllergy = new MemberAllergy();
          memberAllergy.setType("O");
          memberAllergy.setActiveFlag("D")
          memberAllergy.setDescription(allergy);
          memberAllergy.setmbrRefNo(this.mbrRefNo)
          memberAllergyList.push(memberAllergy);
        }
      })
    }
    if (this.removedNoKnownAllergy.length > 0) {
      this.removedNoKnownAllergy.forEach(allergy => {
        if (!this.selectedNoKNownAllergy.includes(allergy)) {
          let memberAllergy = new MemberAllergy();
          memberAllergy.setType("N");
          memberAllergy.setActiveFlag("D")
          memberAllergy.setDescription(allergy);
          memberAllergy.setmbrRefNo(this.mbrRefNo)
          memberAllergyList.push(memberAllergy);
        }
      })


    }

    console.log(memberAllergyList)
    if (this.isAllergyDeleted) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '30%',
        width: '30%',
        data: { message: 'Delete Member Allergies, Please Confirm?' },
      });


      dialogRef.afterClosed().subscribe((result) => {
        debugger
        if (result === true) {

          this.dataservice.saveMemberAllegies(memberAllergyList).subscribe(data => {
            this.isAllergyDeleted = false
            this.toastr.success("Allergies Updated Successfully", '', {
              timeOut: 1500,
            })
            this.dataservice.geteMemberAllegies(this.mbrRefNo).subscribe(data => {
              console.log(data)
              this.allergyData = data;
              this.parseAllergies(data)




            }, error => {
              this.toastr.error("Issue In Data Saving", '', {
                timeOut: 1500,
              });
            })
            this.removedMedAllergy = [];
            this.removedOtherAllergy = [];
            this.removedNoKnownAllergy = [];
            this.removedCatAllergy = [];
            this.allergyRemovable = false;
            this.isRefreshAllergy = false
            this.allergyRemovable = false
          }, error => {
            this.toastr.error("Some Error Occured", '', {
              timeOut: 1500,
            });

          })
        }
        else {
          this.isRefreshAllergy = true;
          this.dataservice.geteMemberAllegies(this.mbrRefNo).subscribe(data => {
            console.log(data)
            this.allergyData = data;
            this.parseAllergies(data)
            this.allergyRemovable = false
            this.removedMedAllergy = [];
            this.removedOtherAllergy = [];
            this.removedNoKnownAllergy = [];
            this.removedCatAllergy = [];
            this.allergyRemovable = false;
            this.isRefreshAllergy = false
            this.allergyRemovable = false

          })
        }
      });

    }
    else {

      this.dataservice.saveMemberAllegies(memberAllergyList).subscribe(data => {


        console.log(data)
        this.toastr.success("Allergies Saved Successfully", '', {
          timeOut: 1500,
        })
        this.dataservice.geteMemberAllegies(this.mbrRefNo).subscribe(data => {
          console.log(data)
          this.allergyData = data;
          this.parseAllergies(data)
        })

        this.allergyRemovable = false
        this.removedMedAllergy = [];
        this.removedOtherAllergy = [];
        this.removedNoKnownAllergy = [];
        this.removedCatAllergy = [];
        this.allergyRemovable = false;
        this.isRefreshAllergy = false



      }, error => {
        this.toastr.error("Some Error Occured ", '', {
          timeOut: 1500,
        })


      });
    }
  }

  clearAllergy() {
    this.selectedMedAllergy = [];
    this.selectedCatAllergy = []
    this.addPatientForm.patchValue({
      othersAllergy: ""

    })
    this.disableNoKnowAllergy = false
  }
  deleteAllergies() {

    if (this.router.url != '/addMember') {
      this.mbrRefNo = this.route.snapshot.params['mbrRefNo']

    }
    else {
      if (this.mbrRefNo == '') {
        this.toastr.error("Please Save Member Details Before Diagnosis", '', {
          timeOut: 1500,
        })
        return;
      }
      else {
        this.mbrRefNo = this.mbrRefNo;
      }
    }
    console.log(this.mbrRefNo)
    if (!checkNonEmtyData(this.addPatientForm.getRawValue().othersAllergy) && this.isOthersAllergyExist) {

      let memberAllergy = new MemberAllergy();
      memberAllergy.setType("O");
      memberAllergy.setActiveFlag("D")
      memberAllergy.setDescription(this.isOthersAllergyExist);
      memberAllergy.setmbrRefNo(this.mbrRefNo)
      this.removedMedAllergy.push(memberAllergy);



    }
    if (this.addPatientForm.getRawValue().allergyType == 'N') {
      let memberAllergy = new MemberAllergy();
      memberAllergy.setType("N");
      memberAllergy.setActiveFlag("D")
      memberAllergy.setDescription("No Known Allergies");
      memberAllergy.setmbrRefNo(this.mbrRefNo)
      this.removedMedAllergy.push(memberAllergy);
      this.disableNoKnowAllergy = true


    }
    this.dataservice.saveMemberAllegies(this.removedMedAllergy).subscribe(data => {
      console.log(data)
      this.toastr.success("Allergies Deleted Successfully", '', {
        timeOut: 1500,
      })
    }, error => {
      this.toastr.error("Some Error Occured", '', {
        timeOut: 1500,
      })


    });




  }
  deleteNoKnownAllergy() {
    if (this.router.url != '/addMember') {
      this.mbrRefNo = this.route.snapshot.params['mbrRefNo']

    }
    else {
      if (this.mbrRefNo == '') {
        this.toastr.error("Please Save Member Details Before Diagnosis", '', {
          timeOut: 1500,
        })
        return;
      }
      else {
        this.mbrRefNo = this.mbrRefNo;
      }
    }
    if (this.addPatientForm.getRawValue().allergyType == 'N') {
      let memberAllergy = new MemberAllergy();
      memberAllergy.setType("N");
      memberAllergy.setActiveFlag("D")
      memberAllergy.setDescription("No Known Allergies");
      memberAllergy.setmbrRefNo(this.mbrRefNo)
      this.removedMedAllergy.push(memberAllergy);
      this.disableNoKnowAllergy = true


    }
    this.dataservice.saveMemberAllegies(this.removedMedAllergy).subscribe(data => {
      console.log(data)
      this.toastr.success("Allergies Deleted Successfully", '', {
        timeOut: 1500,
      })
    }, error => {
      this.toastr.error("Some Error Occured", '', {
        timeOut: 1500,
      })


    });

  }
  parseAllergies(data: any) {
    this.selectedMedAllergy = []
    this.selectedCatAllergy = []
    this.selectedOtherAllergy = []
    this.selectedNoKNownAllergy = []


    data.forEach(element => {
      if (element.type == 'N' && element.activeFlag == 'A') {

        this.selectedNoKNownAllergy.push(element.description)
        this.disableNoKnowAllergy = false

      }


      if (element.type == 'M' && element.activeFlag == 'A') {
        this.selectedMedAllergy.push(element.description)
        this.disableNoKnowAllergy = true

      }
      if (element.type == 'C' && element.activeFlag == 'A') {
        this.selectedCatAllergy.push(element.description)
        this.disableNoKnowAllergy = true

      }
      if (element.type == 'O' && element.activeFlag == 'A') {
        this.isOthersAllergyExist = element.description;
        this.disableNoKnowAllergy = true
        this.selectedOtherAllergy.push(element.description)



      }








    }
    );
    if (this.selectedMedAllergy.length > 0 || this.selectedCatAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
      this.disableNoKnowAllergy = true

    }
    else if (this.selectedNoKNownAllergy.length > 0) {
      this.disableOtherAllergy = true

    }
    else {
      this.disableNoKnowAllergy = false
      this.disableOtherAllergy = false

    }
    debugger
    if (this.addPatientForm.getRawValue().allergyType == 'N') {
      this.disableNoKnowAllergy = false

    }

  }




  onchangeAllergy(value: any) {
    debugger
    this.allergyType = value;

    if (value == 'C') {
      this.allergyplaceholder = "Search Medication Class"
    }
    if (value == 'M') {
      this.allergyplaceholder = "Search Medications"
    }
    if (value == 'O') {
      this.allergyplaceholder = ""
    }
    if (value == 'N') {
      this.allergyplaceholder = " "
    }
    this.addPatientForm.patchValue({
      "medicationAllergy": ""
    })
    this.allergyList = []
    if (this.selectedCatAllergy.length > 0 || this.selectedMedAllergy.length > 0 || this.selectedOtherAllergy.length > 0) {
      this.disableNoKnowAllergy = true

    }
    else if (this.addPatientForm.getRawValue().allergyType == 'N') {
      this.disableNoKnowAllergy = false
      this.addPatientForm.get('medicationAllergy').disable()


    }
    if (this.addPatientForm.getRawValue().allergyType == 'N') {
      this.disableAddButton = true

    }
    else {
      this.disableAddButton = false
      this.addPatientForm.get('medicationAllergy').enable()

    }

  }
  getOthersAllergyData() {
    if (checkNonEmtyData(this.addPatientForm.getRawValue().othersAllergy)) {
      this.disableNoKnowAllergy = true
    }
    else {
      this.disableNoKnowAllergy = false

    }


  }

  openConfirmationDialog(): void {
    if (this.router.url != '/addMember') {
      this.mbrRefNo = this.route.snapshot.params['mbrRefNo']

    }
    else {
      if (this.mbrRefNo == '') {
        this.toastr.error("Please Save Member Details Before Diagnosis", '', {
          timeOut: 1500,
        })
        return;
      }
      else {
        this.mbrRefNo = this.mbrRefNo;
      }
    }
    debugger
    if (this.deletedDiagnosis.length > 0) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        height: '26%',
        width: '30%',
        data: { message: 'Delete Member Diagnosis, Please Confirm ?' },
      });


      dialogRef.afterClosed().subscribe((result) => {
        debugger
        if (result === true) {
          // User confirmed, proceed with saving
          this.addNewDiagnosis();
          this.deletedDiagnosis = []
        } else {
          this.isRefreshDiagnosis = true;
          debugger
          this.refreshDiagnosis()
        }
      });
    }
    else {
      this.addNewDiagnosis();

    }
  }

  refreshAllergy() {
    this.parseAllergies(this.allergyData)

    // this.selectedMedAllergy = this.removedMedAllergy
    // this.selectedCatAllergy = this.removedCatAllergy

    // this.selectedOtherAllergy = this.removedOtherAllergy
    // this.selectedNoKNownAllergy = this.removedNoKnownAllergy
    this.removedMedAllergy = [];
    this.removedOtherAllergy = [];
    this.removedNoKnownAllergy = [];
    this.removedCatAllergy = [];
    this.allergyRemovable = false;
    this.isRefreshAllergy = false


  }
  refreshDiagnosis() {
    this.icdCodesSearch()
    this.deletedDiagnosis = []
    this.diagnosisRemoval = true
    this.isRefreshDiagnosis = false
  }
  getFormattedPhone(data) {
    if (checkNonEmtyData(data)) {
      if (data.length == 10) {
        return "(" + data.substring(0, 3) + ") " + + data.substring(3, 6) + "-" + data.substring(6, 10)
      }
      return data
    }

  }
  removeFormattedPhone(data) {
    debugger
    if (checkNonEmtyData(data)) {
      let formattedData = data.replaceAll("(", '').replaceAll("-", '').replaceAll(")", '').replaceAll(" ", "")
      return formattedData
    }

  }


}
function checkNonEmtyData(value: any): boolean {
  debugger
  if (value != '' && value != 'undefined' && typeof (value) != undefined && value != null) {
    return true
  }
  return false


}
