import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';
import {ERROR_CONSTANT} from 'src/assets/errorMsgConstant';
import {CONSTANT} from 'src/assets/appConstant';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  failedMsg = false;
  noAccessMsg = false;
  username: string;
  password: string;
  isSpinner: boolean = false;

  cdos: any = [
    {
    "region": "UT",
    "regionName": "Utah"
  },
   {
    "region": "ID",
    "regionName": "Idaho"

  }
]

  loginForm: FormGroup = new FormGroup({
    username: new FormControl(),
    password: new FormControl(),
    cdos: new FormControl()
  })
  cdosregion: any;
  keyDownEnter(event) {
    if (event.keyCode == 13) {
      this.loginusingMSID();
    }
  }


  constructor(public dataservice: DataService, public _router: Router, private toastr: ToastrService) {

  }

  ngOnInit(): void {
    debugger

    this.loginwithstargate()

    if(window.location.href.includes('https://pacman-mr.optum.com')){
      window.location.href='https://pacm-mr.optum.com'
    }
    this.createLoginForm();

  }

  createLoginForm() {

  }

  loginusingMSID(): void {
   if(this.loginForm.getRawValue().cdos=='' || this.loginForm.getRawValue().cdos==null || this.loginForm.getRawValue().cdos==undefined  ){
    this.toastr.error(ERROR_CONSTANT.ERROR_LOGIN_REGION);
    return
   }
   if(this.loginForm.getRawValue().username=='' ||this.loginForm.getRawValue().username==null || this.loginForm.getRawValue().username==undefined  ){
    this.toastr.error(ERROR_CONSTANT.ERROR_LOGIN_USERNAME);
    return
   }
   if(this.loginForm.getRawValue().password=='' ||this.loginForm.getRawValue().password==null || this.loginForm.getRawValue().password==undefined  ){
    this.toastr.error(ERROR_CONSTANT.Error_LOGIN_PASSWORD);
    return
   }





    let logindata = {
      'username': this.loginForm.getRawValue().username,
      'userpassword': this.loginForm.getRawValue().password
    }

    if(logindata.username=='admin' && logindata.userpassword=='admin'){
      this._router.navigate(['/landingPage'])


    }


//     this.dataservice.loginUsingMSID(logindata).subscribe(data => {

//       debugger

//       let loginout: any=data
//       if (loginout.length <= 1) {
//         let logindata = JSON.parse(loginout[0])
//         localStorage.setItem('loginresponse', loginout[0])

//         localStorage.setItem('usname', logindata['name'])
//         localStorage.setItem('msId', logindata.msid);
//         let listcdos = this.cdos
//         let cdosregion = listcdos.filter(cdo => cdo.region === this.loginForm.getRawValue().cdos)[0]
//         localStorage.setItem('cdoRegion', cdosregion.regionName);
//         localStorage.setItem('regionName', cdosregion.region);
//         localStorage.setItem('token',logindata.token);

//         this.dataservice.region = localStorage.getItem('regionName')
//         console.log(this.dataservice.region)
//         this.dataservice.getLookup(logindata.token).subscribe(res => {

//         });

//         if(this.checkAccess(logindata)){
//           this._router.navigate(['/landingPage'])
//         }
//         else{
//          this.toastr.error(ERROR_CONSTANT.Error_AUNAUTHORIZED_USER);

//       }

//  }
//  if(data[0].includes("INVALID_CREDENTIALS")){
//   this.toastr.error(ERROR_CONSTANT.Error_INVALID_CREDENTIALS);

//   }
// },error=>{
//   this.toastr.error(ERROR_CONSTANT.Error_SERVER);

// })
  }
  OHIDlogin(): void {
    window.location.href = "https://identity.nonprod.onehealthcareid.com/oidc/authorize?client_id=PAC028509N&response_type=code&scope=openid+profile+email&redirect_uri=https://pacman-dev.optum.com/";
  }

  checkAccess(logindata:any): boolean {

    if(logindata.hasOwnProperty('portal') && localStorage.getItem('regionName')==CONSTANT.REGION_IDAHO){

      return true
    }
    else if(logindata.hasOwnProperty('portalUtah') && localStorage.getItem('regionName')==CONSTANT.REGION_UTAH){

      return true
    }

    return false;

  }
  loginwithstargate(){
    this.dataservice.getstargateToken().subscribe(data=>{
      localStorage.setItem('stargateToken',data['access_token'])

      console.log(data)
    })
  }
  


}
