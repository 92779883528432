import { Injectable ,EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  idleTimeout = new EventEmitter<void>();

  triggerIdleTimeout() {
    this.idleTimeout.emit();
  }

  constructor() { }
}
