import { Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from '../data.service';
import { MatTableDataSource } from '@angular/material/table';
import { CONSTANT } from '../../assets/appConstant';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Toast, ToastrService } from 'ngx-toastr';
import { formatAMPM, formatDataDDMMYYYY, formatDataDDMMYYYYDashed, formatDataDDMMYYYYhyphennated, formatToDDMMYYFromYYMMDDHHMMSS, getDate, getDateTime } from '../utilityMethods/helperMethods';
import { saveAs } from 'file-saver';
import { IdleTimeoutService } from '../idle-timeout.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatCheckbox } from '@angular/material/checkbox';
import { ProviderDataComponent } from '../provider-data/provider-data.component';
import * as moment from 'moment-timezone';



@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.css']
})
export class InterventionComponent implements OnInit {
  isSpinner: any = false
  medicationList: any = []
  interventionTypeList: any = []
  interventionsCat: any = []
  interventions: any = []
  statusList: any = []
  medgapList: any = []
  configdata: any
  isEdit = false
  interventionid: any
  outcomeList: any = []
  drug; any
  noofpages = [1, 2, 3, 4]
  freetextMed = false
  transfterToHomedeliveryList: any = ['Yes', 'No']
  targetedMedicationList: any = ['Yes', 'No']
  medRelatedProblemList: any = []
  interventiontype: any = "Provider"
  reportdata: any = []
  interventionstatuslist = ["Open", "Closed"]
  removableMed = true
  addMedicationDropdown: any = []
  step = 0;
  isAll: boolean = false
  docSpinner: boolean = false
  drugDetails: any = []
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChildren('checkbox') checkboxes: QueryList<MatCheckbox>;




  severityLevelList: any = [
    {
      "key": "",
      "value": ""

    },
    {
      "key": "Low (Office Visit)",
      "value": "Low"

    },
    {
      "key": "Medium (ED Visit)",
      "value": "Medium"

    },
    {
      "key": "High (Hospital Admission)",
      "value": "High"

    },]


  displayedColumns: string[] = ['item', 'cost'];





  interventionForm = new FormGroup({
    intervention: new FormControl(),
    addMedication: new FormControl(),
    medication: new FormControl(),
    medications: new FormControl(),
    drugName: new FormControl(),
    status: new FormControl(),
    medgap: new FormControl(),
    medicationRelatedProblem: new FormControl(),
    transfterToHomedelivery: new FormControl(),
    severityLevel: new FormControl(),
    outcome: new FormControl(),
    outcomeDate: new FormControl(),
    notes: new FormControl(),
    responseReceived: new FormControl(),

    recommededAction: new FormControl(),
    freetextDrugName: new FormControl(),
    interventionCat: new FormControl()

  });
  displayedColumns1: any = ["selectedtrigger", "createdDate", "interventionType", "intervention", "interventionCat", "medications", "status", "adherenceBarrier", "drugTherapyProblem", "transferredToHomedelivery", "severityLevel", "outcome", "dateOfOutcome", "notes", "responseReceived", "recommededAction", "interventionStatus", "edit"]
  dataSource1: any = [];
  serviceData: any = []
  selectedMedications: any = []
  allmedicationsifselected: any = []
  allmedicationsifnotselected: any = []
  generateInterventionData: any = []
  isAllintervention: boolean;
  haveconfigData: boolean = false;
  nonadherence: boolean = false;
  dataService1Copy: any = [];
  encounterDetails: any
  memberDetails: any
  currentTime: string;
  allergy: any;
  diagnosisList: any;
  hideAutoPupulateOption: boolean = false;





  constructor(public dataService: DataService, public dialogRef: MatDialogRef<InterventionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public toaster: ToastrService, private idleService: IdleTimeoutService,public dialog: MatDialog) {

    this.idleService.idleTimeout.subscribe(() => {
      this.dialogRef.close()
    })

    this.medicationList = []
    this.selectedMedications = data.selectedMedications
    this.allmedicationsifselected = data.allmedicationsifselected
    this.allmedicationsifnotselected = []

    this.allmedicationsifnotselected = data.allmedicationsifnotselected
    this.medicationList = []
    this.encounterDetails = data.enconterDetails
    this.memberDetails = data.memberDetails
    this.diagnosisList = getDiagnosisList(data.diagnosisList)
    this.allergy = data.allergies
    console.log(this.memberDetails)
    console.log(this.encounterDetails)
    this.addMedicationDropdown = []

    this.populcateMedicationList()
    debugger
    this.pupulateAddMedicationDropdown()
  }
  populcateMedicationList(): any {
    if (this.selectedMedications.length > 0) {
      this.getMedication(this.selectedMedications)
    }
    else if (this.allmedicationsifselected.length > 0) {
      this.getMedication(this.allmedicationsifselected)
    }
    else {
      this.medicationList = []

    }

  }
  pupulateAddMedicationDropdown() {
    this.addMedicationDropdown = []
    if (this.allmedicationsifnotselected.length > 0) {
      let regimenMed = []
      this.allmedicationsifnotselected.forEach(element => {
        regimenMed.push(element.medication)
      });
      this.addMedicationDropdown.push({
        "label": "Regimen Medications",
        "value": regimenMed
      })


    }

  }
  getMedication(selectedMedications: any): any {
    let data = []
    selectedMedications.forEach(element => {
      this.medicationList.push(element.medication)

    })
    return data
  }

  ngOnInit(): void {
    this.loadropdowns()
    this.getInterventions()
    this.getConfig()
    this.dataSource1 = new MatTableDataSource(this.serviceData);
    this.dataService1Copy = new MatTableDataSource(this.serviceData);

    this.allmedicationsifnotselected.forEach(element => {
      this.drugDetails.push(element.medication)
    })
    this.drugDetails.push("Add New Medication")

  }


  loadropdowns() {
    this.dataService.getdropdown("INTERVENTION").subscribe(data => {
      this.interventions = data["values"]

    }, error => {


    })
    this.dataService.getdropdown("DRUG_THP_PRBLM").subscribe(data => {
      this.medRelatedProblemList = data["values"]

    }, error => {


    })
    this.dataService.getdropdown("OUTCOME").subscribe(data => {
      this.outcomeList = data["values"]

    }, error => {


    })
    this.dataService.getdropdown("CALL_STATUS").subscribe(data => {
      this.statusList = data["values"]

    }, error => {


    })
    this.dataService.getdropdown("INTERVENTION_TYPE").subscribe(data => {
      this.interventionTypeList = data["values"]

    }, error => {


    })
    this.dataService.getdropdown("INTERVENTION_CAT").subscribe(data => {
      this.interventionsCat = data["values"]

    }, error => {


    })


    this.dataService.getdropdown("MEDGAP").subscribe(data => {
      this.medgapList = data["values"]

    }, error => {


    })
  }
  getAge(dob) {
    if (dob != null || dob != undefined) {
      let dobarr = dob.split("-")
      let birthMonth: any = dobarr[1]
      let birthDay: any = dobarr[2];
      let birthYear: any = dobarr[0]
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();
      var calculatedAge = currentYear - birthYear;

      if (currentMonth < birthMonth - 1) {
        calculatedAge--;
      }
      if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
        calculatedAge--;
      }
      return calculatedAge;
    }
    else {
      return "NA"
    }
  }

  printIntervention() {

    // if (this.generateInterventionData.length < 1) {
    //   this.toaster.error("Please Select Intervention", '', {
    //     timeOut: 1500,
    //   });
    //   return
    // }
    debugger
    const localDate = moment();
    console.log(localDate.tz('America/Chicago').format('MM-DD-YYYY'));
    const centralTimezone = moment.tz(moment.tz.guess()).zoneAbbr();  
    console.log(centralTimezone);


    let data = {}
    data["sendTo"] = getFormattedprimaryphone(this.memberDetails.pcpFax),
      data["cdoName"] = "OptumCare " + this.configdata.actionPlanConfig.state
    data["cdoAddress"] = this.configdata.actionPlanConfig.address,
      data["cdoPhone"] = getFormattedprimaryphone(this.configdata.actionPlanConfig.phone),
      data["cdoFax"] = getFormattedprimaryphone(this.configdata.actionPlanConfig.fax),
      data["recipientProvider"] = this.memberDetails.pcp,
      data["recipientProviderPhone"] = getFormattedprimaryphone(this.memberDetails.pcpPhone),
      data["recipientProviderFax"] = getFormattedprimaryphone(this.memberDetails.pcpFax),
      data["facilityName"] = this.configdata.encounterDetails.facilityName,
      data["reportTitle"] = CONSTANT.ENCOUNTER_TYPE_REVIEW.includes(this.configdata.encounterDetails.encounterType) ? 'Medication Review ' : 'Medication Reconciliation';
    data["patientName"] = this.configdata.encounterDetails.firstName + " " + this.configdata.encounterDetails.lastName,
      data["patientDob"] = formatDataDDMMYYYYDashed(this.configdata.encounterDetails.dob)
    data["patientgender"] = this.configdata.encounterDetails.gender
    data["patientAge"] = this.getAge(this.configdata.encounterDetails.dob) + ""
    data["admitdate"] = formatToDDMMYYFromYYMMDDHHMMSS(this.configdata.encounterDetails.admitDate),
      data["reviewedDate"] = localDate.tz('America/New_York').format('MM-DD-YYYY')
    data["reviewedBy"] = formatProviderName(localStorage.getItem("usname"))
    data["providerName"] = this.configdata.encounterDetails.destinationProvider
    data["severity"] = this.configdata.encounterDetails.severityLevel
    data["encounterType"] = this.configdata.encounterDetails.encounterType
    data["diagnosis"] = this.configdata.encounterDetails.diagnosedFor.split('-')
    data["allergies"] = this.allergy
    data["interventionDetails"] = this.parseInterVentionData(this.generateInterventionData)
    debugger

    let listInterventionId = [];
    this.generateInterventionData.forEach( res => {
      listInterventionId.push({
        'interventionId':res.interventionId,
        'generatedBy':localStorage.getItem("usname")
      })
    })

    debugger
    this.openProviderPopUp(data,listInterventionId)
// this.dataService.getinterventionpdf(data).subscribe(response => {

    //   let data: any = response
    //   const blob = new Blob([data], { type: 'blob' })
    //   this.generateInterventionData = []

    //   saveAs(blob, this.getFileName("Intervention") + '.docx');
    //   this.getInterventions()


    // }, error => {


    // });




  }
  parseInterVentionData(data: any) {
    let interventionData = []
    let counter = 1

    data.forEach(element => {
      let tempdata = {
        "interventionId": counter + "",
        "drugRelatedProblem": element.drugTherapyProblem,
        "severity": element.severityLevel,
        "medications": element.medications,
        "recommedndedAction": element.recommededAction,
        "intervention": element.intervention,
        "interventionCat": element.interventionCat,

      }
      interventionData.push(tempdata)
      counter = counter + 1
    });
    return interventionData

  }
  openProviderPopUp(data,listInterventionId) {
    const addDialogue = this.dialog.open(ProviderDataComponent, {
      height: '47%',
      data: {
        "popupType":'Intervention Tracker',
        "interventiondata": data,
        "interventionList": listInterventionId
      },


    });
    addDialogue.afterClosed().subscribe(result => {

      this.generateInterventionData = []
      this.getInterventions()


    }
  );

  }

  getReportdate() {
    this.currentTime = formatAMPM(new Date())
    return getDate()
  }

  parseDataforPdf(generateInterventionData: any) {

    let i = 0;


    generateInterventionData.forEach(element => {

      element.pageNo = i + 1

      if (element.medications.length <= 4) {
        element.interventionNumber = i + 1
        element.pageNo = i + 1
        this.reportdata.push(element)
      }
      else {
        let chunkSize = 4;
        element.interventionNumber = i + 1
        for (let i = 0; i < element.medications.length; i += chunkSize) {
          const chunk = element.medications.slice(i, i + chunkSize);
          let tempdata = JSON.parse(JSON.stringify(element))
          tempdata.medications = chunk
          tempdata.pageNo = i + 1
          this.reportdata.push(tempdata)
        }
      }
      i++



    }
    );

    this.reportdata.forEach(element => {

      element.medications.forEach(ele => {
        ele.medicationName = this.getFormattedName(ele.medicationName);
      })
    });
    this.docSpinner = true



    setTimeout(() => {
      this.docSpinner = false

    }, 2000);


  }
  getFormattedName(medName: any): any {
    let medicationName;
    this.allmedicationsifnotselected.forEach(element => {
      if (element.medication == medName) {
        medicationName = this.getTitleCase(medName) + " " + this.checkNUll(element.dose + element.units) + " " + this.checkNUll(element.route) + " " + this.checkNUll(element.frequency) + " " + this.getTitleCase(this.checkNUll(element.instructions)) + " | #" + this.checkNUll(element.quantity) + "/" + this.checkNUll(element.daysOfSupply) + " | " + this.checkNUll(element.lastFilledDate)
        return medicationName;
      }
    });
    return medicationName
  }
  checkNUll(data: any) {
    if (data != '' && data != 'null' && data != null) {
      return data
    }
    else {
      return ""
    }
  }

  print() {

    var printContents = document.getElementById('pdfTable').innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;

  }


  getFileName(name: any) {
    return this.getTitleCase(this.memberDetails.lastName) + this.memberDetails.firstName.charAt(0).toUpperCase() + "_" + this.memberDetails.dob.replaceAll("-", "") + "_" + name + "_" + formatDataDDMMYYYY(this.getReportdate())


  }



  saveInterVention() {


    if (this.medicationList.length == 0) {

      this.toaster.error("Please Add Medication", '', {
        timeOut: 1500,
      });
      return
    }
    else if (this.interventionForm.getRawValue().medicationRelatedProblem === null || this.interventionForm.getRawValue().medicationRelatedProblem === "" || this.interventionForm.getRawValue().medicationRelatedProblem === undefined) {

      this.toaster.error("Please Enter Drug Therapy Problem", '', {
        timeOut: 1500,
      });
      return
    }

    this.freetextMed = false


    let datesuffix = new Date().getTime()

    let data = {
      "interventionId": this.isEdit ? this.interventionid : localStorage.getItem("encounterId") + "-" + datesuffix,
      "encounterId": localStorage.getItem("encounterId"),
      "mbrRefNo": localStorage.getItem("mberRefNo"),
      "trigger": this.getTrigger(this.medicationList),
      "prescriptionIds": null,
      "medications": this.getMedications(this.medicationList, this.isEdit ? this.interventionid : localStorage.getItem("encounterId") + "-" + datesuffix),
      "targetedMedication": "",
      "status": this.interventionForm.getRawValue().status,
      "drugTherapyProblem": this.interventionForm.getRawValue().medicationRelatedProblem,
      "adherenceBarrier": this.interventionForm.getRawValue().medgap,
      "intervention": this.interventionForm.getRawValue().intervention,
      "interventionCat": this.interventionForm.getRawValue().interventionCat,
      "transferredToHomedelivery": this.interventionForm.getRawValue().transfterToHomedelivery,
      "severityLevel": this.interventionForm.getRawValue().severityLevel,
      "interventionType": this.interventiontype,
      "interventionStatus": getInterVentionStatus(this.interventionForm.getRawValue().outcome),
      "recommededAction": this.interventionForm.getRawValue().recommededAction,
      "responseReceived": "",
      "responseReceivedDateTime": "",
      "outcome": this.interventionForm.getRawValue().outcome,
      "dateOfOutcome": this.interventionForm.getRawValue().outcomeDate,
      "notes": this.interventionForm.getRawValue().notes,
      "createdBy": localStorage.getItem("usname"),
      "createdDate": "2024-04-15",
      "lastUpdatedDate": "2024-04-15",
      "isDeleted": "N",
      "reviewedBy": localStorage.getItem("usname"),
    }
    let input = {
      encounterId: localStorage.getItem("encounterId")
    }


    if (!this.isEdit) {
      this.serviceData.push(data);
      this.dataService.addIntervention(data).subscribe(data => {
        console.log(data)
        this.getInterventions()
        this.clearForm()
        this.dataService.completeEncounterStatus(input).subscribe(data => {
          console.log(data)
        })
      }, error => { console.log(error) })
    }
    else if (this.isEdit) {
      data["responseReceived"] = this.interventionForm.getRawValue().responseReceived,
        data["responseReceivedDateTime"] = this.interventionForm.getRawValue().responseReceivedDateTime,
        data["outcome"] = this.interventionForm.getRawValue().outcome,
        data["dateOfOutcome"] = this.interventionForm.getRawValue().outcomeDate
      this.dataService.editIntervention(data).subscribe(data => {
        console.log(data)
        this.clearForm();
        this.isEdit = false
        this.getInterventions()
        this.dataService.completeEncounterStatus(input).subscribe(data => {
          console.log(data)
        })
      }, error => { console.log(error) })


    }
    this.generateInterventionData=[]




  }
  clearForm() {

    this.medicationList = []
    this.interventionForm.patchValue({
      intervention: "",
      interventionCat: "",
      status: "",
      medgap: "",
      medicationRelatedProblem: "",
      transfterToHomedelivery: "",
      severityLevel: "",
      outcome: "",
      outcomeDate: "",
      notes: "",
      responseReceived: "",
      recommededAction: "",
      medications: "",
      addMedication: ""
    })



    this.isEdit = false
  }
  getTrigger(medicationList: any) {

    return medicationList.join("||");

  }
  getMedications(medications: any, id: any) {

    let medList = []



    medications.forEach(element => {
      let med = {
        "medicationName": element,
        "targetedMedication": "Yes",
        "interventionId": id


      }
      medList.push(med)

    })
    return medList
  }
  onDrugChange(value) {



    this.drugDetails.forEach(element => {
    });




  }
  formatMedications(medications: any) {

  }
  getInterventions() {

    this.isSpinner = true
    this.dataService.getInterventions(localStorage.getItem("encounterId")).subscribe(data => {

      this.serviceData = data
      this.serviceData = this.serviceData.filter((ele: any) => ele.encounterId == localStorage.getItem("encounterId") && ele.isDeleted == "N")
      this.serviceData.sort(this.sortByDate)
      this.dataSource1 = new MatTableDataSource(this.serviceData);
      this.dataService1Copy = new MatTableDataSource(this.serviceData);
      this.isSpinner = false

    }, error => { console.log(error) })
  }
  editInterVention(data) {

    this.isEdit = true
    this.removableMed = false
    console.log(data)
    this.interventionid = data.interventionId
    this.interventiontype = data.interventionType,

      this.medicationList = data.trigger.split("||")

    this.interventionForm.patchValue({

      intervention: data.intervention,
      status: data.status,
      interventionCat: data.interventionCat,
      medgap: data.adherenceBarrier,
      medicationRelatedProblem: data.drugTherapyProblem,
      transfterToHomedelivery: data.transferredToHomedelivery,
      severityLevel: data.severityLevel,
      outcome: data.outcome,
      outcomeDate: this.formatOutcomedateData(data.dateOfOutcome),
      notes: data.notes,
      responseReceived: data.responseReceived,
      recommededAction: data.recommededAction,
    })
  }
  deleteInterVention(data) {

    this.dataService.deleteIntervention(data.interventionId).subscribe(data => {
      this.getInterventions()
    }, error => { console.log(error) })

  }
  completeInterVention(data) {

  }

  getConfig() {
    this.dataService.getactionPlanPdfData(localStorage.getItem("encounterId")).subscribe(data => {
      this.configdata = data
      this.haveconfigData = true
    }, error => {
      this.toaster.error("issue in data saving");

    })
  }


  openDropdown() {
    this.autocompleteTrigger.openPanel();


  }
  onChangeMedication(data) {
    if (data == "Add New Medication") {
      this.freetextMed = true
      return
    }


    const index = this.medicationList.indexOf(data);
    if (index == -1) { // only splice array when item is found
      this.medicationList.push(data) // 2nd parameter means remove one item only
      this.interventionForm.patchValue({
        "drugName": ""
      })
      this.pupulateAddMedicationDropdown()

    }
    else {
      this.toaster.error("Medication already added")
      this.interventionForm.patchValue({
        "drugName": ""
      })

    }

  }
  addFreeTextMed() {
    debugger
    if (this.medicationList.indexOf(this.interventionForm.getRawValue().drugName.trim()) != -1) {
      this.toaster.error("Medication already added")
      this.interventionForm.patchValue({
        drugName: ""
      })
      this.freetextMed = false

      return

    }
    if (this.interventionForm.getRawValue().freetextDrugName != '') {
      this.medicationList.push(this.interventionForm.getRawValue().drugName.trim())
      this.freetextMed = false
      this.interventionForm.patchValue({
        drugName: ""
      })

    }
    else {
      this.toaster.error("Please enter medication")
    }
  }
  clearFreeTextMed() {
    this.freetextMed = false
    this.interventionForm.patchValue({
      freetextDrugName: ""
    })
    this.interventionForm.patchValue({
      drugName: ""
    })
  }
  exportAllIntervention(event, element) {
    debugger


    if (event.checked == true) {
      this.isAllintervention = true
      this.dataSource1.filteredData.forEach(element => {

        this.generateInterventionData.push(element)




      });

    }
    else {

      this.isAllintervention = false
      this.generateInterventionData = [];
    }
  }
  exportParticularIntervention(event, element) {
    debugger


    if (event.checked == true) {
      const index = this.generateInterventionData.indexOf(element);
      if (index == -1) {
        this.generateInterventionData.push(element)

      }
    }
    else {
      const index = this.generateInterventionData.indexOf(element);
      if (index > -1) { // only splice array when item is found
        this.generateInterventionData.splice(index, 1);
      }
    }
    console.log(this.generateInterventionData)

    return this.allmedicationsifnotselected;

  }
  scrollHandler() {
    this.hideAutoPupulateOption = true
  }
  getDrugData() {
    this.freetextMed = false
    this.pupulateAddMedicationDropdown()


    // this.medform.controls['medName'].enable();
    console.log(this.interventionForm.getRawValue().drugName.length)
    if (this.interventionForm.getRawValue().drugName.length > 3) {

      let inputdata =
      {

        "drug_name": this.interventionForm.getRawValue().drugName,

        "label_name": "",

        "ndc": "",

        "identifier": "D",

        "match": "E"

      }

      this.dataService.getMedFromNDCGPI(inputdata).subscribe(data => {
        debugger
        let meddata: any = data
        if (meddata.length == 0) {
          this.freetextMed = true
        }
        else {
          this.freetextMed = false

        }

        this.drugDetails = []
        let regimendata = []
        this.addMedicationDropdown = []


        meddata.forEach(element => {
          this.drugDetails.push(element.drug_name)


        });
        if (meddata.length > 0) {
          this.addMedicationDropdown.push({
            "label": "Medication",
            "value": this.drugDetails
          })
        }
        this.allmedicationsifnotselected.forEach(element => {

          if (element.medication.toLowerCase().includes(this.interventionForm.getRawValue().drugName.toLowerCase())) {

            regimendata.push(element.medication)
          }
        })
        if (regimendata.length > 0) {
          this.addMedicationDropdown.push({
            "label": "Regimen Medications",
            "value": regimendata
          })
        }
      }, error => {


      })
    }
    else {
      this.pupulateAddMedicationDropdown()

    }
  }
  getDrugDataDefault() {
    this.pupulateAddMedicationDropdown()
  }
  getDrug(DrugValue: HTMLInputElement) {
    var drugs = DrugValue.value
    console.log("ddddddddddddddddddddd", drugs)
    this.drug = drugs
  }
  openAutocomplete() {
    this.interventionForm.patchValue({
      "drugName": " ",

    })


  }
  showDropdown() {
    this.hideAutoPupulateOption = false
  }



  getTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  setStep(index: number) {

    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  onChangeDRP(value) {
    if (value == "Medication Non-adherence") {
      this.nonadherence = true
      console.log(value)

    }
    else {
      this.nonadherence = false
      this.interventionForm.patchValue({
        "medgap": "",
        "transfterToHomedelivery": "",
      })

    }
  }
  formatOutcomedateData(data: any) {
    if (data == null) {
      return ""
    }
    else {

      return data.split(" ")[0]



    }





  }
  onChangeInterventionStatus(value) {


    if (value.length == 2) {
      this.dataSource1 = this.dataService1Copy
    }
    else if (value.length == 1) {

      this.dataSource1 = this.dataSource1.filteredData.filter((ele: any) => ele.interventionStatus == value[0])
    }
    else {
      this.dataSource1 = this.dataService1Copy

    }




  }
  removeMed(data) {

    const index = this.medicationList.indexOf(data);
    if (index > -1) { // only splice array when item is found
      this.medicationList.splice(index, 1); // 2nd parameter means remove one item only
    }

  }

  onChangeOutcome(outcome) {

    let outcomeinitiated = ["Patient Initiated", "Pharmacist Initiated", "Other"]
    if (outcome != '' && outcome != null) {
      if (outcomeinitiated.includes(outcome)) {
        this.interventionForm.patchValue({
          outcomeDate: getDate()
        })

      }
      else {
        this.interventionForm.patchValue({
          outcomeDate: getDate()
        })
      }

    }

  }

  sortByDate(a, b) {
    if (a.createdDate != null && b.createdDate != null) {


      let c: any = new Date(a.createdDate.split(' ')[0])
      let d: any = new Date(b.createdDate.split(' ')[0])
      console.log('hii')
      return d - c;
    }

  }



}


function getInterVentionStatus(outcome: any) {


  if (outcome != '' && outcome != null) {
    if (CONSTANT.INTERVENTION_INI.includes(outcome)) {
      return CONSTANT.INTERVENTION_OPEN
    }
    else {
      return CONSTANT.INTERVENTION_CLOSED
    }

  }
  return CONSTANT.INTERVENTION_OPEN
}

function getInterventionOutcomeDate(outcome: any) {

  if (outcome != '' || outcome != null) {
    if (CONSTANT.INTERVENTION_INI.includes(outcome)) {
      return ""
    }
    else {
      return getDate()
    }
  }



}



function getDiagnosisList(diagnosisList: any): any {
  let data = []
  diagnosisList.forEach(element => {
    data.push(element.icdShortDesc)
  });
  return data
}

function formatProviderName(data: any) {
  debugger

  if (data != null && data != 'null' && data != '' && data != undefined) {
    let nameArray = data.split(",")
    if (nameArray.length > 1) {
      return nameArray[1] + " " + nameArray[0]
    }
    else {
      return data
    }

  }
  return ""

}


function getFormattedprimaryphone(data: any) {
  if (data != undefined && data != '' && data != null && data.length == 10) {
    return "(" + data.substring(0, 3) + ") " + data.substring(3, 6) + "-" + data.substring(6, 10)
  }
  return data

}
