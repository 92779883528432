import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { MembersearchComponent } from './membersearch/membersearch.component';
import { ReportgridComponent } from './reportgrid/reportgrid.component';
import { OureachreportviewComponent } from './oureachreportview/oureachreportview.component';
import { AssignmemberComponent } from './assignmember/assignmember.component';
import { MemberdetailsComponent } from './memberdetails/memberdetails.component';
import { AddNewPatientComponent } from './add-new-patient/add-new-patient.component';
import { AddEncounterComponent } from './add-encounter/add-encounter.component';
import { EncounterQueueComponent } from './encounter-queue/encounter-queue.component';
import { AuthGuard } from './auth-guard/auth.guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'workqueue', component: EncounterQueueComponent, canActivate: [AuthGuard] },
  { path: 'addEncounter', component: AddEncounterComponent, canActivate: [AuthGuard] },

  { path: 'editEncounter/:encounterId', component: AddEncounterComponent, canActivate: [AuthGuard] },

  { path: 'encounterQueue', component: EncounterQueueComponent, canActivate: [AuthGuard] },
  { path: 'addMember', component: AddNewPatientComponent, canActivate: [AuthGuard] },
  { path: 'editMember/:mbrRefNo', component: AddNewPatientComponent, canActivate: [AuthGuard] },
  { path: 'editEncounter/:encounterId', component: AddEncounterComponent, canActivate: [AuthGuard] },


  { path: 'patientdetails/:mbrRefNO', component: MemberdetailsComponent, canActivate: [AuthGuard] },
  { path: 'landingPage', component: LandingpageComponent, canActivate: [AuthGuard] },
  { path: 'memberSearch', component: MembersearchComponent, canActivate: [AuthGuard] },
  { path: 'memberAssignment', component: AssignmemberComponent, canActivate: [AuthGuard] },
  { path: 'encounterRoster', component: EncounterQueueComponent, canActivate: [AuthGuard] },
  {
    path: 'report', component: ReportgridComponent, canActivate: [AuthGuard], children: [
      { path: 'oureachreport', component: OureachreportviewComponent } ]
  },
  { path: '', redirectTo: 'login', pathMatch: 'full' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })

  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }
