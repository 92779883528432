import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IdleTimeoutService } from '../idle-timeout.service';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent implements OnInit {

  deleteReason:string=''
  isNeedReason:boolean=false
  constructor(public dialog: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public toastr:ToastrService, private idleService:IdleTimeoutService) {
      this.isNeedReason=data.isNeedReason
      this.idleService.idleTimeout.subscribe(() => {
        this.dialog.close()
      })

  }

  ngOnInit(): void {

  }
  npsConfirm() {
    if(this.isNeedReason && this.deleteReason.length<=0){
    this.toastr.error('Please Enter Reason For Deletion','',{
      timeOut: 1500,
              });
    return
    
    }

    this.closeDialog();
  }

  closeDialog() {
    this.dialog.close({
      data: 'Yes',
      deleteReason: this.deleteReason

    });
  }


}
