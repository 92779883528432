<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
    <h4 class="mt-1">
        Activity Log 
    </h4>
    <button mat-icon-button color="primary" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>
<div>
   
    <div class="tableWrap">        
        <table mat-table  [dataSource]="dataSource" class="table-wrapper">
            <ng-container matColumnDef="remarks">
                <th  mat-header-cell *matHeaderCellDef style="width: 160px; ">Action Date</th>
                <td mat-cell *matCellDef="let element">{{element.createdDate| date :'yyyy-MM-dd'}}</td>
            </ng-container> 
            <ng-container matColumnDef="previousStatus">
                <th mat-header-cell *matHeaderCellDef style="width: 120px;">Initial state</th>
                <td mat-cell *matCellDef="let element">{{element.previousStatus}}</td>
            </ng-container> 
            <ng-container matColumnDef="currentStatus">
                <th mat-header-cell *matHeaderCellDef style="width: 120px; ">End state</th>
                <td mat-cell *matCellDef="let element">{{element.currentStatus}} </td>
            </ng-container>                      
            <ng-container matColumnDef="changedBy">
                <th mat-header-cell *matHeaderCellDef style="width: 160px; ">Peformed By</th>
                <td mat-cell *matCellDef="let element">{{element.changedBy}}</td>
            </ng-container>
            <ng-container matColumnDef="createdDate">
                <th mat-header-cell *matHeaderCellDef style="width: 140px;">Remarks</th>
                <td mat-cell *matCellDef="let element">{{element.remarks}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;  sticky: true"
                (click)="test(row)"></tr>
         </table>  

    </div>
</div>
