   
    
<div fxLayout="column" class="innSpinner" *ngIf="isSpinner">

    <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</div>

<h2 mat-dialog-title>
    <div class="dialog-title row">

        <div class="col-md-12 mb-2 d-flex align-items-center">
            <div class="col-md-11">
                <h2>
                    <span style="margin-right: 10px;"><svg xmlns="http://www.w3.org/2000/svg" width="13.499" height="18.556" viewBox="0 0 13.499 18.556">
                    <g id="Layer_1" transform="translate(-9.814 -4.5)">
                      <g id="Group_432" data-name="Group 432" transform="translate(9.814 4.5)">
                        <path id="Path_158" data-name="Path 158" d="M22.681,6.2H18.916l-.186-.011a2.239,2.239,0,0,0-4.341,0L14.2,6.2H10.446a.632.632,0,0,0-.632.632V22.423a.632.632,0,0,0,.632.632H22.681a.632.632,0,0,0,.632-.632V6.83A.632.632,0,0,0,22.681,6.2Zm-.493,15.436a.3.3,0,0,1-.3.3H11.235a.3.3,0,0,1-.3-.3V7.619a.3.3,0,0,1,.3-.3h1.486a1.671,1.671,0,0,0-.1.552v.562H20.5V7.875a1.671,1.671,0,0,0-.1-.552h1.5a.3.3,0,0,1,.3.3V21.634Z" transform="translate(-9.814 -4.5)" fill="#002657"/>
                        <path id="Path_159" data-name="Path 159" d="M22.963,32.791H16.047a.559.559,0,1,0,0,1.118h6.916a.559.559,0,1,0,0-1.118Z" transform="translate(-12.788 -19.331)" fill="#002657"/>
                        <path id="Path_160" data-name="Path 160" d="M22.963,25.739H16.047a.559.559,0,1,0,0,1.118h6.916a.559.559,0,1,0,0-1.118Z" transform="translate(-12.788 -15.634)" fill="#002657"/>
                        <path id="Path_161" data-name="Path 161" d="M22.963,18.686H16.047a.559.559,0,1,0,0,1.118h6.916a.559.559,0,0,0,0-1.118Z" transform="translate(-12.788 -11.937)" fill="#002657"/>
                      </g>
                    </g>
                  </svg>
                  </span>
                    <strong>Member Intervention History</strong>
                </h2></div>
            <div class="col-md-1 text-end">
                <button mat-icon-button color="primary" mat-dialog-close class="close-button">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
        
    </div>
</h2>
<div class="tableWrap">
    <table mat-table [dataSource]="dataSource1" class="table-wrapper" >
        <ng-container matColumnDef="selectedtrigger" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 30px;" class="check-width sticky-top">
            </th>
            <td mat-cell *matCellDef="let element" class="check-width">
                <mat-checkbox (change)="exportParticularIntervention($event,element)"></mat-checkbox>
            </td>

        </ng-container>

        <ng-container matColumnDef="edit" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 110px;" class="sticky-top">Action </th>
            <td mat-cell *matCellDef="let element">
                <button matSuffix type="edit" color="default" mat-icon-button
                    [disabled]="element.interventionStatus=='Closed'">
                    <mat-icon matTooltip="Edit" (click)="editInterVention(element)">mode_edit</mat-icon>
                </button>
                <button matSuffix type="delete" color="default" mat-icon-button
                    [disabled]="element.interventionStatus=='Closed'">
                    <mat-icon matTooltip="Delete" (click)="deleteInterVention(element)">delete</mat-icon>
                </button>
            </td>
        </ng-container>
       <ng-container matColumnDef="createdDate" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 100px;" class="fixedstickywhite">Date </th>
            <td mat-cell *matCellDef="let element">{{element.createdDate | date: 'MM-dd-yyyy'}}</td>
        </ng-container>

        <ng-container matColumnDef="interventionType" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 100px;" class="fixedstickywhite">Type </th>
            <td mat-cell *matCellDef="let element" class="align-items-center">{{element.interventionType}}</td>
        </ng-container>

        <ng-container matColumnDef="intervention" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 160px;" class="fixedstickywhite">Intervention</th>
            <td mat-cell *matCellDef="let element">{{element.intervention}}</td>
        </ng-container>
        <ng-container matColumnDef="interventionCat" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 160px;" class="fixedstickywhite">Disease State</th>
            <td mat-cell *matCellDef="let element" >{{element.interventionCat}}</td>
        </ng-container>

        <ng-container matColumnDef="medications" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 300px;" class="fixedstickywhite">Medications</th>
            <td mat-cell *matCellDef="let element" class="fixedsticky">
                <span class="medbg" *ngFor='let data of element.trigger.split("||")' style="padding: 20 px;">{{data}}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef style="width: 165px;" class="sticky-top"> Outreach Status</th>
            <td mat-cell *matCellDef="let element">{{element.status}} </td>
        </ng-container>

        <ng-container matColumnDef="adherenceBarrier">
            <th mat-header-cell *matHeaderCellDef style="width: 170px;" class="sticky-top">Med Gap</th>
            <td mat-cell *matCellDef="let element"><span>{{element.adherenceBarrier}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="drugTherapyProblem">
            <th mat-header-cell *matHeaderCellDef style="width: 210px;" class="sticky-top">Drug Therapy Problem</th>
            <td mat-cell *matCellDef="let element"><span>{{element.drugTherapyProblem}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="transferredToHomedelivery">
            <th mat-header-cell *matHeaderCellDef style="width: 210px;" class="sticky-top">Home
                Delivery</th>
            <td mat-cell *matCellDef="let element"><span>{{element.transferredToHomedelivery}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="severityLevel">
            <th mat-header-cell *matHeaderCellDef style="width: 120px;" class="sticky-top">Severity Level</th>
            <td mat-cell *matCellDef="let element"><span>{{element.severityLevel}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="outcome">
            <th mat-header-cell *matHeaderCellDef style="width: 120px;" class="sticky-top">Outcome</th>
            <td mat-cell *matCellDef="let element"><span>{{element.outcome}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="dateOfOutcome">
            <th mat-header-cell *matHeaderCellDef style="width: 120px;" class="sticky-top">Outcome Date</th>
            <td mat-cell *matCellDef="let element">
                <!-- <span>{{element.dateOfOutcome.length>0?element.dateOfOutcome.split(" ")[0]: element.dateOfOutcome }}</span>  -->
                <span>{{element.dateOfOutcome | date: "MM-dd-yyyy" }}</span> 
            </td>
        </ng-container>

        <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Notes</th>
            <td mat-cell *matCellDef="let element"><span>{{element.notes}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="responseReceived">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Response Received</th>
            <td mat-cell *matCellDef="let element"><span>{{element.responseReceived}}</span> </td>
        </ng-container>

        <ng-container matColumnDef="recommededAction">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;" class="sticky-top">Recommeded Action</th>
            <td mat-cell *matCellDef="let element"><span>{{element.recommededAction}}</span> </td>
        </ng-container>


        <ng-container matColumnDef="interventionStatus"  class="sticky-top" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef style="width: 180px;">Intervention Status</th>
            <td mat-cell *matCellDef="let element"><span>{{element.interventionStatus}}</span> </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
      
    </table>
  
      <div class="no-Data" *ngIf="dataSource1.data.length === 0" style="text-align: center;">No Rows To Show</div>

</div>
