<app-header></app-header>

<div class="containerWrapper" fxLayout="row">
    <div fxLayout="column" class="innSpinner" *ngIf="isSpinner">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>

    <mat-sidenav-container class="sidenav-full">

        <mat-sidenav opened #patientDetails mode="side" position="start" role="navigation"
            class="sidenav-wv patientslide" *ngIf="patientView" [@widthGrow]="state">
            <div fxLayout="column" class="sidenav-normal">

                <mat-icon (click)="hidepatientview()" class="material-icons-outlined patientbtn expand_circle">
                    expand_circle_down</mat-icon>

                <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start center" class="filterList"
                    *ngIf="memberdata" [@enterAnimation]>
                    <div class="profileLetters">
                        <span *ngIf="memberdata.gender == 'F'">
                            <img src="assets/images/woman.svg" alt="">
                        </span>
                        <span *ngIf="memberdata.gender == 'M'">
                            <img src="assets/images/boss.svg" alt="">
                        </span>
                    </div>
                    <h3>{{memberdata.firstName | titlecase}} {{memberdata.lastName | titlecase}}</h3>
                    <div fxFlex="100%" fxLayout="column" fxLayoutAlign="space-between stretch" class="rowWrap">
                        <div class="userWrapper" fxLayout="row">
                            <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                <mat-icon class="material-icons-outlined">contacts</mat-icon>
                            </div>
                            <div fxLayout="row" fxFlex="90%">
                                <div fxFlex="56%" class="labelText">
                                    <span>Patient Ref No</span>{{memberdata.mbrRefNo}}
                                </div>
                                <div fxFlex="44%" class="labelText" fxLayoutAlign="end end" fxLayout="column">
                                    <span>RAF Score</span> {{checkRAF(memberdata.raf)}}
                                </div>
                            </div>
                        </div>
                        <!-- <div class="userWrapper" fxLayout="row">
                            <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                            </div>
                            <div fxFlex="100%" class="labelText">
                                <span>First/Last Name</span> {{memberdata.firstName |titlecase}} {{memberdata.lastName |
                                titlecase}}
                            </div>
                        </div> -->
                        <div class="userWrapper" fxLayout="row">
                            <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                <mat-icon class="material-icons-outlined">female</mat-icon>
                                <mat-icon class="femaleIcon material-icons-outlined">male</mat-icon>
                            </div>
                            <div fxLayout="row" fxFlex="90%">
                                <div fxFlex="100%" class="labelText">
                                    <span>Gender</span> {{memberdata.gender}}
                                </div>
                            </div>
                        </div>


                        <div class="userWrapper bordernone" fxLayout="row">
                            <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                <mat-icon class="material-icons-outlined">cake</mat-icon>
                            </div>
                            <div fxLayout="row" fxFlex="90%">
                                <div fxFlex="75%" class="labelText">
                                    <label>Date of Birth</label> {{memberdata.dob | date: 'MM-dd-yyyy'}}
                                </div>
                                <div fxFlex="25%" class="labelText" fxLayoutAlign="end end" fxLayout="column">
                                    <span>Age</span>{{getAge(memberdata.dob)}}
                                </div>
                            </div>
                        </div>


                        <mat-accordion>
                            <mat-expansion-panel opened>
                                <mat-expansion-panel-header class="accordionhead">
                                    <mat-panel-title>Contact</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">phone_iphone</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span> Primary Phone</span> {{getFormattedPhone(memberdata.primaryContactNumber)}}
                                    </div>
                                </div>

                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">phone</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Secondary Phone</span>{{getFormattedPhone(memberdata.alternateContactNumber)}}
                                    </div>
                                </div>


                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-symbols-outlined">alternate_email</mat-icon>
                                    </div>
                                    <div fxLayout="row" fxFlex="90%">
                                        <div fxFlex="100%" class="labelText">
                                            <span>Email</span>{{memberdata.email}}
                                        </div>

                                    </div>
                                </div>


                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Address</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">place</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Address Line 1</span>{{memberdata.addressline1}}
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">place</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Address Line 2</span> {{memberdata.addressline2}}
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">place</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Address Line 3</span> {{memberdata.addressline3}}
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">villa</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>City</span> {{memberdata.city}}
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">location_city</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>State</span> {{memberdata.state}}
                                    </div>
                                </div>

                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">flag</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Zip Code</span> {{memberdata.zipCode}}
                                    </div>
                                </div>
                            </mat-expansion-panel>

                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Medical Plan</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <!-- <mat-icon class="material-icons-outlined">next_plan</mat-icon> -->
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.094" height="13.814" viewBox="0 0 20.094 13.814">
                                            <g id="Icon_IDCard_Blk_RGB" transform="translate(-4.625 -10.68)">
                                              <path id="Path_166" data-name="Path 166" d="M19.829,25.43h2.512V23.547h1.878V21.035H22.341V19.151H19.829v1.884H17.946v2.512h1.884Z" transform="translate(-6.413 -4.078)" fill="#212121"/>
                                              <path id="Path_167" data-name="Path 167" d="M23.463,10.68H5.882a1.256,1.256,0,0,0-1.256,1.256v11.3a1.256,1.256,0,0,0,1.256,1.256H23.463a1.256,1.256,0,0,0,1.256-1.256v-11.3A1.256,1.256,0,0,0,23.463,10.68Zm0,12.558H5.882V13.823H23.463v9.415Z" transform="translate(0 0)" fill="#212121"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Plan Name</span>
                                        {{memberdata.planName}}
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <mat-icon class="material-icons-outlined">verified</mat-icon>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Plan Eligibility Status</span>
                                        {{memberdata.planElStatus}}

                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Diagnosis</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="userWrapper" fxLayout="column" *ngFor="let diagnosis of diagnosisList">
                                    <div fxFlex="100%" class="labelText">
                                        {{diagnosis.icdShortDesc | titlecase}}
                                    </div>
                                </div>
                            </mat-expansion-panel>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>Allergies</mat-panel-title>
                                </mat-expansion-panel-header>
                                <div class="userWrapper" fxLayout="row" *ngIf="noKnownAllergies ">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <svg xmlns=" http://www.w3.org/2000/svg" width="23.474" height="23.475"
                                            viewBox="0 0 23.474 23.475">
                                            <g id="Icon_HappySmile_Blk_RGB" transform="translate(-5 -5)">
                                                <path id="Path_139" data-name="Path 139"
                                                    d="M19.724,12.814a6.91,6.91,0,1,0,6.91,6.91A6.91,6.91,0,0,0,19.724,12.814Zm0,12.584A5.674,5.674,0,1,1,25.4,19.724,5.681,5.681,0,0,1,19.724,25.4Z"
                                                    transform="translate(-2.987 -2.987)" />
                                                <path id="Path_140" data-name="Path 140"
                                                    d="M24.219,5.576a.576.576,0,0,0-.833-.515h0a.576.576,0,0,0-.318.515v3.1h1.152Z"
                                                    transform="translate(-6.906)" />
                                                <path id="Path_141" data-name="Path 141"
                                                    d="M12.653,13.467l.814-.814-2.191-2.191a.576.576,0,0,0-.953.225h0a.576.576,0,0,0,.139.589Z"
                                                    transform="translate(-2.023 -2.023)" />
                                                <path id="Path_142" data-name="Path 142"
                                                    d="M8.676,23.068h-3.1a.576.576,0,0,0-.515.833h0a.576.576,0,0,0,.515.318h3.1V23.068Z"
                                                    transform="translate(0 -6.907)" />
                                                <path id="Path_143" data-name="Path 143"
                                                    d="M10.461,34.76a.576.576,0,0,0,.225.953h0a.576.576,0,0,0,.589-.139l2.191-2.191-.814-.814Z"
                                                    transform="translate(-2.023 -10.538)" />
                                                <path id="Path_144" data-name="Path 144"
                                                    d="M23.068,40.149a.576.576,0,0,0,.833.515h0a.576.576,0,0,0,.318-.515v-3.1H23.068Z"
                                                    transform="translate(-6.907 -12.251)" />
                                                <path id="Path_145" data-name="Path 145"
                                                    d="M33.383,32.569l-.814.814,2.191,2.191a.576.576,0,0,0,.814-.814Z"
                                                    transform="translate(-10.538 -10.538)" />
                                                <path id="Path_146" data-name="Path 146"
                                                    d="M40.664,23.386h0a.576.576,0,0,0-.515-.318h-3.1V24.22h3.1A.576.576,0,0,0,40.664,23.386Z"
                                                    transform="translate(-12.251 -6.907)" />
                                                <path id="Path_147" data-name="Path 147"
                                                    d="M35.574,11.276a.576.576,0,0,0-.225-.953h0a.576.576,0,0,0-.589.139l-2.191,2.191.814.814Z"
                                                    transform="translate(-10.538 -2.023)" />
                                                <path id="Path_148" data-name="Path 148"
                                                    d="M25.16,24a.571.571,0,0,0-.566.491,3.455,3.455,0,0,1-6.84,0,.576.576,0,0,0-1.141.157,4.607,4.607,0,0,0,9.123,0A.576.576,0,0,0,25.16,24Z"
                                                    transform="translate(-4.437 -7.263)" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        No Known Allergies
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row"
                                    *ngIf="!noKnownAllergies  && selectedMedAllergyString.length>0">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center"
                                        style="padding-left: 6px;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="33"
                                            viewBox="0 0 18.973 39">
                                            <g id="Layer_1" transform="translate(-14.486 -4.5)">
                                                <g id="Group_393" data-name="Group 393">
                                                    <path id="Path_129" data-name="Path 129"
                                                        d="M32.4,4.5H15.544a1.058,1.058,0,0,0-1.058,1.058v6.321H15.54V41.392A2.106,2.106,0,0,0,17.649,43.5H30.3a2.105,2.105,0,0,0,2.108-2.108V11.878h1.054V5.558A1.058,1.058,0,0,0,32.4,4.5Zm-2.1,30H17.649V17.639H30.3Z" />
                                                    <path id="Path_130" data-name="Path 130"
                                                        d="M21.164,25.231H22.6L24.559,28,22.632,30.8h1.643l1.106-1.608L26.5,30.8h1.676l-1.957-2.821,1.738-2.467H26.333l-.878,1.4-1.435-2.02c.049-.023.094-.049.141-.076a1.82,1.82,0,0,0,.923-1.337,2.139,2.139,0,0,0,.006-.51,1.745,1.745,0,0,0-.677-1.236,1.561,1.561,0,0,0-.141-.094,2.54,2.54,0,0,0-1.41-.288H19.773v6.4h1.391V25.231Zm0-2.782h1.391c.816,0,1.18.269,1.18.873a.8.8,0,0,1-.336.7,1.49,1.49,0,0,1-.9.182H21.164V22.449Z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Medication</span>
                                        <div *ngFor="let allergy of selectedMedAllergyString"> {{allergy|titlecase}} </div>
                                        <!-- {{selectedMedAllergyString |titlecase}} -->
                                        

                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row"
                                    *ngIf="!noKnownAllergies &&  selectedCatAllergyString.length>0 ">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.423"
                                            viewBox="0 0 22 22.423">
                                            <g id="Layer_1" transform="translate(-4.868 -4.5)">
                                                <path id="Path_138" data-name="Path 138"
                                                    d="M26.865,8.776a.246.246,0,0,0-.246-.284H19.83a3.413,3.413,0,0,0-2.907,2.191l-.439,1.854V9.043a2.314,2.314,0,1,0-1.173,0v3.745l-.5-2.106a3.413,3.413,0,0,0-2.907-2.191H5.117a.246.246,0,0,0-.246.284,2.156,2.156,0,0,0,1.445,1.84,2.217,2.217,0,0,0,1.3,2.3,1.748,1.748,0,0,0,1.476,2.1h6.13a.456.456,0,0,0,.093-.022v1.3H13.178a1.8,1.8,0,0,0,0,3.6h2.133v1.254H13.178a1.8,1.8,0,0,0,0,3.6h2.133v1.583a.587.587,0,0,0,1.173,0V24.753h3.6a.587.587,0,0,0,0-1.173h-3.6V22.326h2.391a1.8,1.8,0,1,0,0-3.6H16.484V17.472h2.391a1.8,1.8,0,0,0,1.8-1.8,1.784,1.784,0,0,0-.128-.655h2.1a1.748,1.748,0,0,0,1.476-2.1,2.217,2.217,0,0,0,1.3-2.3A2.156,2.156,0,0,0,26.865,8.776ZM14.522,6.814A1.375,1.375,0,1,1,15.9,8.189,1.377,1.377,0,0,1,14.522,6.814ZM13.178,18.726a.627.627,0,1,1,0-1.254h2.133v1.254H13.178Zm0,4.854a.627.627,0,0,1,0-1.254h2.133V23.58H13.178ZM19.5,20.526a.628.628,0,0,1-.627.627H16.484V19.9h2.391A.628.628,0,0,1,19.5,20.526ZM18.876,16.3H16.484V15.045h2.391a.627.627,0,0,1,0,1.254Z"
                                                    transform="translate(0 0)" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span> Medication Class</span>
                                        <div *ngFor="let allergy of selectedCatAllergyString"> {{allergy|titlecase}} </div>
                                        <!-- {{selectedCatAllergyString | titlecase}} -->
                                    </div>
                                </div>
                                <div class="userWrapper" fxLayout="row"
                                    *ngIf="!noKnownAllergies && isOthersAllergyExist.length>0">
                                    <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.817" height="22.038"
                                            viewBox="0 0 24.817 22.038">
                                            <g id="Icon_HealthyFood_Blk_RGB" transform="translate(-4.565 -6.703)">
                                                <path id="Path_128" data-name="Path 128"
                                                    d="M29.382,14.688,28.082,14,29.338,13l-2.6-1.367-1.949,1.541a4.729,4.729,0,0,0-1.99-.445q-.107,0-.214,0a5.119,5.119,0,0,0-2.533.732,4.962,4.962,0,0,0-6.983-4.162V6.7l-1.482.658V9.317A4.966,4.966,0,0,0,5,15.863l.962,2.163h0s1.3,2.914,1.356,3.026a3.806,3.806,0,0,0,4.937,1.724C10.411,25.071,8.9,26.965,8.863,27.01a1.094,1.094,0,0,0,.632,1.7,1.153,1.153,0,0,0,.261.03,1.092,1.092,0,0,0,.386-.07c.082-.031,14.524-6.557,14.524-6.557a4.887,4.887,0,0,0,2.728-5.853Zm-4.6,5.645a3.446,3.446,0,0,1-.729.43c-3.747,1.693-10.107,4.567-13,5.87,2.088-2.611,6.814-8.513,8.911-11.131a3.339,3.339,0,0,1,2.676-1.284q.075,0,.149,0a3.145,3.145,0,0,1,1.908.62A3.455,3.455,0,0,1,26.111,17.6,3.419,3.419,0,0,1,24.785,20.334Z"
                                                    transform="translate(0 0)" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div fxFlex="100%" class="labelText">
                                        <span>Other</span>
                                        <div *ngFor="let allergy of isOthersAllergyExist"> {{allergy|titlecase}} </div>
                                        <!-- {{isOthersAllergyExist | titlecase}} -->

                                    </div>
                                </div>
                            </mat-expansion-panel>

                        </mat-accordion>
                    </div>
                </div>

                <!-- <button (click)="nps_dialog()" matTooltip="NPS Survey" class="toggle-Mbtn new--item nps pt-nps">
                    <span class="pdbtn2">NPS Survey</span>
                    <mat-icon class="material-icons-outlined">sentiment_satisfied</mat-icon>
                </button> -->
            </div>

        </mat-sidenav>

        <mat-sidenav-content class="patientDetilsOpen">
            <mat-sidenav-container class="sidenav-full">
                <mat-sidenav #drugDetails mode="side" position="end" role="navigation" class="sidenav-wv"
                    [opened]="isShowing">
                    <div class="sidePanelCtrl">
                        <!--Add Comments Section-->
                        <div fxLayout="column" class="sidenav-normal">
                            <mat-icon (click)="drugDetailsView1()" class="material-icons-outlined commentsbtn">
                                expand_circle_down</mat-icon>
                            <div fxFlex="25%" fxLayout="column" *ngIf="viewcomment">
                                <div class="notes contentPanel card">
                                    <h3>Add Comments </h3>
                                    <div class="notes-form">
                                        <form fxLayout="column" [formGroup]="memberNoteForm">
                                            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" style="margin-top: 5px;">
                                                <mat-form-field appearance="outline" fxFlex="100%">
                                                    <mat-label>Comments</mat-label>
                                                    <textarea matInput name="memNoteComments"
                                                        formControlName="memNoteComments"
                                                        [maxlength]="commentsMaxChars"></textarea>
                                                    <mat-hint class="charLength">Characters Remaining : {{memberNoteForm.value['memNoteComments']?.length}}/{{commentsMaxChars}}
                                                    </mat-hint>
                                                </mat-form-field>
                                            </div>
                                            <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center" fxFlex="100%" class="buttonWrapper">
                                                <button type="button" mat-flat-button color="primary" [disabled]="!memberNoteForm.valid" (click)="onMemNoteSave()" style="color: #fff; cursor: pointer;;">Save</button>
                                                <button type="button" (click)="onReset()" mat-flat-button>Reset</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="notes contentPanel card">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <mat-tab-group fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between end"
                                            (selectedTabChange)="isauto($event)">
                                            <mat-tab label="Member">

                                                <div class="notes-wrapper">
                                                    <div class="notes-wrap" *ngFor="let memNotes of memberNotes | paginate: {id:'memberHistoryComment', itemsPerPage: 5, currentPage: NotespageComment };">
                                                        <div fxLayoutAlign="space-between center">
                                                            <span> {{memNotes.createdDt | date: "MM-dd-yyyy HH:mm" }}</span>
                                                            <strong>{{memNotes.createdBy}} </strong>
                                                        </div>
                                                        <div class="labelText">
                                                            <label>Comments</label>
                                                            <p style="white-space: pre-wrap;">{{memNotes.notes}}</p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="memberNotes?.length == 0" class="noDataFound" style="text-align: center !important; margin: 5px 0 !important;">No Comments Found
                                                    </div>
                                                </div>

                                                <div class="notes-footer">
                                                    <!-- <button mat-flat-button color="primary" (click)="viewAllNotes(memberNotes)" [disabled]="memberNotes?.length == 0">View all</button> -->
                                                    <div class="paginationWrapper pagination-wrapper-detail" style="margin: 0 auto; text-align: center !important;">
                                                        <pagination-controls id="memberHistoryComment" (pageChange)="NotespageComment = $event" class="paginationWrap"></pagination-controls>
                                                    </div>
                                                </div>

                                            </mat-tab>

                                            <mat-tab label="System">

                                                <div class="notes-wrapper">
                                                    <div class="notes-wrap"
                                                        *ngFor="let memNotes of memberNotes | paginate: {id:'memberHistoryPaginate', itemsPerPage: 5, currentPage: Notespage };">
                                                        <div fxLayoutAlign="space-between center">
                                                            <span> {{memNotes.createdDate |
                                                                date: "MM-dd-yyyy
                                                                HH:mm" }}
                                                            </span>
                                                            <strong>{{memNotes.createdBy}} </strong>
                                                        </div>
                                                        <div class="labelText">
                                                            <label>Comments</label>
                                                            <p style="white-space: pre-wrap;">{{memNotes.notes}}</p>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="memberNotes?.length == 0" class="noDataFound" style="text-align: center !important; margin: 5px 0 !important;">No Comments Found</div>
                                                </div>

                                                <div class="notes-footer">
                                                    <!-- <button mat-flat-button color="primary" (click)="viewAllNotes(memberNotes)" [disabled]="memberNotes?.length == 0">View all</button> -->
                                                    <div class="paginationWrapper pagination-wrapper-detail" style="margin: 0 auto; text-align: center !important;">
                                                        <pagination-controls id="memberHistoryPaginate"
                                                            (pageChange)="Notespage = $event" class="paginationWrap">
                                                        </pagination-controls>
                                                    </div>
                                                </div>

                                            </mat-tab>

                                        </mat-tab-group>

                                    </div>


                                </div>
                            </div>
                        </div>
                        <!--Drug Interaction Section-->
                        <div fxLayout="column" class="sidenav-normal">

                            <mat-icon (click)="drugDetailsView1()" class="material-icons-outlined commentsbtn">expand_circle_down</mat-icon>


                            <div class="notes-wrapper contentPanel drugIntSec card" *ngIf="viewdrug">
                                <div class="drugIntSec-Main">
                                    <div class="drugIntSec-Scroll">
                                            <div class="notes-wrapper" *ngIf="ismedispan && medInteractionData !=null ">
                                                <h4>Drug drug interaction</h4>

                                                <div class="notes-wrap" *ngFor="let interactions of medInteractionData| paginate: {id:'drugInteractionPaginate', itemsPerPage: 1, currentPage: medInteractionPage };">
            
                                                    <div class="drugNames">
                                                        <div fxLayout="column" fxLayoutAlign="space-between center" *ngFor="let qualifyingDrugs of  interactions.qualifyingDrugs">
                                                            <div class="drug-one"><strong class="medicationName">{{qualifyingDrugs.genericName}}</strong></div>
                                                        </div>
                                                    </div>
                                                    <span><strong style="font-weight: bold;">Documentation Level :</strong>{{interactions.documentationLevel}}</span><br>
                                                    <span style="font-weight: bold;"> <strong>Management Level :</strong>{{interactions.managementLevel}}</span><br>
                                                    <span><strong>On set :</strong> {{interactions.onset}}</span><br>
                                                    <span style="font-weight: bold;"><strong>Severity :</strong>{{interactions.severity}}</span><br>
                                                    <div class="labelText">
                                                        <p style="white-space: pre-wrap;">{{interactions.message}}</p>

                     
                                                    </div>
                                                </div>
                                                <div class="notes-footer" *ngIf="ismedispan">
                                                    <div class="paginationWrapper pagination-wrapper-detail" style="margin: 0 auto; text-align: center !important;">
                                                        <pagination-controls id="drugInteractionPaginate"
                                                            (pageChange)="medInteractionPage = $event" class="paginationWrap">
                                                        </pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(medInteractionData==null || medInteractionData?.length == 0) && ismedispan && ismedispandata===true" class="noDataFound">
                                                No drug drug interaction found
                                            </div>
                                            <div class="notes-wrapper" *ngIf="ismedispan && foodInteractionData!=null">
                                                <h4>Drug food interaction </h4>
                                                <div class="notes-wrap"
                                                    *ngFor="let interactions of foodInteractionData | paginate: {id:'foodInteractionPaginate', itemsPerPage: 1, currentPage: foodInteractionPage };">
                                                    <div class="drugNames">
                                                        <div fxLayout="column" fxLayoutAlign="space-between center"
                                                            *ngFor="let qualifyingDrugs of  interactions.qualifyingDrugs">
                                                            <div class="drug-one">
                                                                <strong class="medicationName">{{qualifyingDrugs.genericName}}</strong>
                                                            </div>
                                                            <!-- <div class="interaction">
                                                            <mat-icon color="primary"> sync </mat-icon>
                                                        </div> -->
            
                                                        </div>
                                                    </div>
            
                                                    <span><b>Documentation Level :</b>
                                                        {{interactions.documentationLevel}}</span><br>
                                                    <span><b>Management Level :</b>
                                                        {{interactions.managementLevel}}</span><br>
                                                    <span><b>Onset :</b> {{interactions.onset}}</span><br>
                                                    <span><b>Severity :</b> {{interactions.severity}}</span><br>
                                                    <div class="labelText">
                                                        <p style="white-space: pre-wrap; word-break: break-all; text-align: justify;">{{interactions.message}}</p>
                                                    </div>
                                                </div>
                                                <div class="notes-footer" *ngIf="ismedispan">
                                                    <!-- <button mat-flat-button color="primary" (click)="viewAllNotes(memberNotes)" [disabled]="memberNotes?.length == 0">View all</button> -->
                                                    <div class="paginationWrapper pagination-wrapper-detail" style="margin: 0 auto; text-align: center !important;">
                                                        <pagination-controls id="foodInteractionPaginate"
                                                            (pageChange)="foodInteractionPage = $event" class="paginationWrap">
                                                        </pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(foodInteractionData==null || foodInteractionData?.length==0) && ismedispan && ismedispandata===true "class="noDataFound">
                                                No drug food interaction found
                                            </div>
                                            <div class="notes-wrapper" *ngIf="ismedispan && alcoholInteractionData!=null" >
                                                <h4>Drug alcohol interaction </h4>
                                                <div class="notes-wrap"
                                                    *ngFor="let interactions of alcoholInteractionData | paginate: {id:'alcoholInteractionPaginate', itemsPerPage: 1, currentPage: alcInteractionPage };">
                                                    <div class="drugNames">
                                                        <div fxLayout="column" fxLayoutAlign="space-between center"
                                                            *ngFor="let qualifyingDrugs of  interactions.qualifyingDrugs">
                                                            <div class="drug-one">
                                                                <strong class="medicationName">{{qualifyingDrugs.genericName}}</strong>
                                                            </div>
                                                            <!-- <div class="interaction">
                                                                <mat-icon color="primary"> sync </mat-icon>
                                                            </div> -->
            
                                                        </div>
                                                    </div>
                                                    <span><strong>Documentation Level :</strong>
                                                        {{interactions.documentationLevel}}</span><br>
                                                    <span><strong>Management Level :</strong>
                                                        {{interactions.managementLevel}}</span><br>
                                                    <span><b>Onset :</b> {{interactions.onset}}</span><br>
                                                    <span><b>Severity :</b> {{interactions.severity}}</span><br>
                                                    <div class="labelText">
                                                        <p style="white-space: pre-wrap; word-break: break-all; text-align: justify;">{{interactions.message}}</p>
                                                    </div>
                                                </div>
                                                <div class="notes-footer">
                                                    <!-- <button mat-flat-button color="primary" (click)="viewAllNotes(memberNotes)" [disabled]="memberNotes?.length == 0">View all</button> -->
                                                    <div class="paginationWrapper pagination-wrapper-detail" style="margin: 0 auto; text-align: center !important;">
                                                        <pagination-controls id="alcoholInteractionPaginate"
                                                            (pageChange)="alcInteractionPage = $event" class="paginationWrap">
                                                        </pagination-controls>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="(alcoholInteractionData==null || alcoholInteractionData?.length == 0) && ismedispan && ismedispandata===true" class="noDataFound">
                                                No drug alcohol interaction found
                                            </div>
                                        
                                        
                                    </div>
                                </div>

                                
                            </div>


                            <!-- Encounter Details-->
                            <div class="notes contentPanel encounterSec card" *ngIf="viewEncounter">
                                <div class="notes-wrapper">


                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">checklist</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Program Type</span>
                                            {{encounterData.lob}}

                                        </div>
                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">tour</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Visit Type</span>
                                            {{encounterData.visitType}}

                                        </div>
                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">tour</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Encounter Type</span>
                                            {{encounterData.encounterType}}

                                        </div>
                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">date_range</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Admit Date</span>                                            
                                             <!-- {{encounterData.admitDate.length>0?encounterData.admitDate.split("
                                            ")[0]:encounterData.admitDate}}  -->
                                            {{encounterData.admitDate | date: "MM-dd-yyyy" }}
                                        </div>
                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">date_range</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Discharge Date</span>
                                            <!-- {{encounterData.dischargeDate.length>0?encounterData.dischargeDate.split("
                                            ")[0]:encounterData.dischargeDate}} -->
                                            {{encounterData.dischargeDate | date: "MM-dd-yyyy" }}
                                        </div>

                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">event</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Visited On</span>
                                            <!-- {{encounterData.visitDate.length>0?encounterData.visitDate.split("
                                            ")[0]:encounterData.visitDate}} -->
                                            {{encounterData.visitDate | date: "MM-dd-yyyy" }}
                                        </div>

                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">event</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Notified On</span>
                                            <!-- {{encounterData.notifiedOn.length>0?encounterData.notifiedOn.split("
                                            ")[0]:encounterData.notifiedOn}} -->
                                            {{encounterData.notifiedOn | date: "MM-dd-yyyy" }}
                                        </div>

                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start">
                                            <mat-icon class="material-icons-outlined">monetization_on</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Payor </span>
                                            {{encounterData.payor}}
                                        </div>

                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start start"
                                            class="diagnosisIcon">
                                            <svg xmlns=" http://www.w3.org/2000/svg" width="32.46" height="38.8"
                                                viewBox="0 0 32.46 38.8">
                                                <g id="Layer_1" transform="translate(-7.815 -4.63)">
                                                    <g id="Group_394" data-name="Group 394">
                                                        <circle id="Ellipse_11" data-name="Ellipse 11" cx="5.58"
                                                            cy="5.58" r="5.58" transform="translate(18.47 4.63)" />
                                                        <path id="Path_131" data-name="Path 131"
                                                            d="M24.05,14.96h0a9.406,9.406,0,0,0-9.41,9.41V43.43H33.47V24.37A9.421,9.421,0,0,0,24.05,14.96Z" />
                                                    </g>
                                                    <g id="Group_400" data-name="Group 400">
                                                        <path id="Path_132" data-name="Path 132"
                                                            d="M36.66,40.15H11.43a2.992,2.992,0,0,1-2.99-2.99V21.05a2.992,2.992,0,0,1,2.99-2.99H36.66a2.992,2.992,0,0,1,2.99,2.99V37.16A2.992,2.992,0,0,1,36.66,40.15Z"
                                                            stroke="#fafafa" stroke-miterlimit="10"
                                                            stroke-width="1.25" />
                                                        <g id="Group_399" data-name="Group 399">
                                                            <g id="Group_395" data-name="Group 395">
                                                                <path id="Path_133" data-name="Path 133"
                                                                    d="M24.05,38.41a1.25,1.25,0,0,1-1.25-1.25V21.38a1.25,1.25,0,0,1,2.5,0V37.16A1.25,1.25,0,0,1,24.05,38.41Z"
                                                                    fill="#fafafa" />
                                                            </g>
                                                            <g id="Group_396" data-name="Group 396">
                                                                <path id="Path_134" data-name="Path 134"
                                                                    d="M28.58,35.7h-9a1.25,1.25,0,0,1,0-2.5h9a1.25,1.25,0,1,1,0,2.5Z"
                                                                    fill="#fafafa" />
                                                            </g>
                                                            <g id="Group_397" data-name="Group 397">
                                                                <path id="Path_135" data-name="Path 135"
                                                                    d="M30.08,24.98h-12a1.25,1.25,0,0,1,0-2.5h12a1.25,1.25,0,0,1,0,2.5Z"
                                                                    fill="#fafafa" />
                                                            </g>
                                                            <g id="Group_398" data-name="Group 398">
                                                                <path id="Path_136" data-name="Path 136"
                                                                    d="M32.08,30.34h-16a1.25,1.25,0,0,1,0-2.5h16a1.25,1.25,0,0,1,0,2.5Z"
                                                                    fill="#fafafa" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>

                                        </div>
                                        <div fxFlex="100%" class="labelText" fxLayout="column">
                                            <span>Diagnosis</span>
                                            <div fxLayout="column" class="diagnosis-data">
                                                <ng-container *ngFor="let value of encounterData.diagnosedFor;index as i">
                                                    <p *ngIf="isDiagnosis" style=" margin: 0; text-overflow: ellipsis !important; max-width: 270px;">{{value |titlecase}}</p>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                            <mat-icon class="material-icons-outlined">badge</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Facility Name</span> {{encounterData.facilityName}}
                                        </div>

                                    </div> -->
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                            <mat-icon class="material-icons-outlined">device_hub</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Source Facility</span>
                                            {{encounterData.sourceProvider}}
                                        </div>

                                    </div>
                                    <div class="userWrapper" fxLayout="row">
                                        <div class="iconWrapper" fxFlex="40px" fxLayoutAlign="start center">
                                            <mat-icon class="material-icons-outlined">where_to_vote</mat-icon>
                                        </div>
                                        <div fxFlex="100%" class="labelText">
                                            <span>Destination Facility</span>
                                            {{encounterData.destinationProvider}}

                                        </div>

                                    </div>


                                </div>
                            </div>
                            <!-- End Encounter Details-->

                        </div>
                        <div *ngIf="isSpinnerMedispan">
                            <mat-spinner></mat-spinner>
                        </div>
                    </div>
                </mat-sidenav>
                <mat-sidenav-content class="drugDetailsOpen" fxLayout="row">
                    <div fxLayout="column">
                        <button (click)="viewPatient()" class="toggle-Mbtn new--item patientdetails" *ngIf="viewicon"
                            matTooltip="View Patient Details">
                            <span class="pdbtn">Member Details</span>
                            <mat-icon class="material-icons-outlined">account_box</mat-icon>
                        </button>

                        <!-- <button (click)="viewcomments()" class="toggle-Mbtn new--item dummy" [ngClass]="{ active: viewcomment==true }"
                            [ngClass]="{ notactive: viewcomment==false } " matTooltip="View Comments History">
                            <mat-icon class="material-icons-outlined">comment</mat-icon>
                            <span> Encounter Log</span>
                        </button> -->
                    </div>

                    <div class="maincontainer">



                        <div class="contentWrap" fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start start"
                            *ngIf="patientDetail" [@enterAnimation]>
                            <div fxLayout="column" [@enterAnimation] fxLayoutGap="15px" fxFlex="100%">
                                <div class="contentPanel card" fxLayout="column" [@enterAnimation]>
                                    <div fxLayout="row mb-0">
                                        <div fxFlex="100%">
                                            <div fxFlex="100%" fxLayout="row" fxLayoutAlign="start center" class="table-header">
                                                <button mat-flat-button color="primary" matTooltipClass="tooltiper" (click)="landingPage()">
                                                    <mat-icon class="material-icons-outlined">home</mat-icon>
                                                </button>
                                                <button mat-flat-button class="mat-elevation-z0 backIcon" mat-stroked-button (click)="goBack()">
                                                    <mat-icon class="material-icons-outlined">arrow_back</mat-icon> Back
                                                </button>
                                                <h3 *ngIf="encounterData!=undefined && encounterData!=null"> Drug Regimen for  <span style="color: #FF612B;"> {{memberdata.firstName | titlecase}} {{memberdata.lastName | titlecase}}  </span> &nbsp;DOB : {{memberdata.dob | date: 'MM-dd-yyyy'}} &nbsp; Status : {{encounterData.status}} </h3>
                
                                                <!-- <h3 *ngIf="encounterData!=undefined && encounterData!=null" style="padding: 0 0 0 20px;"></h3>   -->
                                            </div>                                            
                                        </div>
                                    </div>

                                    <div class="col-12 breadcrumb-section">
                                        <div class="col-lg-12 row p-2" style="margin: 0 !important; padding-right: 0 !important">
                                            <!-- <div class="col-3 text-left ml-0" style="padding: 0 0 0 8px;" *ngIf="encounterData!=undefined && encounterData!=null">
                                                <h3 class="mt-1">Drug Regimen &nbsp;&nbsp;&nbsp; Status : {{encounterData.status}}</h3>       
                                            </div>     -->
                                            <div class="col-md-4"></div>
                                            <div class="col-3 align-self-end" style="margin: 0 !important; padding: 5px 0 5px 0 !important;">
                                                <div class="searchWrap"
                                                    matTooltip="Eg: chemical..."
                                                    matTooltipClass="tooltipSearch">
                                                    <mat-form-field appearance="standard"
                                                        (keyup)="globalSearchRegimen()">
                                                        <mat-label>Search</mat-label>
                                                        <input matInput [(ngModel)]="globalSearchText1"
                                                            placeholder="Eg:ANTIHYPERLIPIDEMICS">
                                                        <mat-icon matPrefix class="searchIcon">search</mat-icon>
                                                        <button matSuffix class="resetBtn" type="reset"
                                                            color="accent" mat-icon-button (click)="resetRegimenSearch()">
                                                            <mat-icon class="closeIcon">close</mat-icon>
                                                        </button>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            
                                            <div class="col-5 align-self-end text-end" style="padding: 0;">
                                                <button mat-flat-button class="mat-elevation-z0"  color="primary" mat-stroked-button (click)="saveAllMedRegime()">Save All</button>
                                                <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" *ngIf="isFinalCheck" (click)="openProviderPopUp()"  matTooltip="Print Outreach Reports" style="margin-left: 15px;">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.221" height="22.39" viewBox="0 0 18.221 22.39">
                                                        <g id="Icon_Printer_Blk_RGB" transform="translate(-7.314 -3.5)">
                                                          <g id="Group_436" data-name="Group 436" transform="translate(9.479 3.5)">
                                                            <path id="Path_166" data-name="Path 166" d="M12.1,12.682a.818.818,0,0,0-.818.818v1.6h1.677v-2.42H12.1Z" transform="translate(-11.279 -7.668)" fill="#fff"/>
                                                            <path id="Path_167" data-name="Path 167" d="M34.5,12.682h-.859V15.1h1.677V13.5A.818.818,0,0,0,34.5,12.682Z" transform="translate(-21.43 -7.668)" fill="#fff"/>
                                                            <g id="Group_435" data-name="Group 435" transform="translate(1.131)">
                                                              <path id="Path_168" data-name="Path 168" d="M21.541,4.046l2.891,2.891v4H13.9V4.389a.343.343,0,0,1,.343-.343h7.3m.226-.546H14.239a.89.89,0,0,0-.889.889V11.48H24.978V6.711l-.16-.16L21.927,3.66l-.16-.16Z" transform="translate(-13.35 -3.5)" fill="#fff"/>
                                                            </g>
                                                          </g>
                                                          <g id="Group_437" data-name="Group 437" transform="translate(18.802 4.046)">
                                                            <path id="Path_169" data-name="Path 169" d="M28.35,4.5l2.891,2.891H28.657a.308.308,0,0,1-.308-.308V4.5Z" transform="translate(-28.35 -4.5)" fill="#fff"/>
                                                          </g>
                                                          <g id="Group_438" data-name="Group 438" transform="translate(7.314 10.934)">
                                                            <path id="Path_170" data-name="Path 170" d="M10.989,21.14a.661.661,0,1,0,.661.661A.661.661,0,0,0,10.989,21.14Z" transform="translate(-8.682 -18.941)" fill="none"/>
                                                            <path id="Path_171" data-name="Path 171" d="M17.747,33.28h6.6a.255.255,0,0,1,0,.511h-6.6a.255.255,0,1,1,0-.511Zm0,1.792h6.6a.255.255,0,0,1,0,.511h-6.6a.255.255,0,1,1,0-.511Zm0,1.792h6.6a.255.255,0,0,1,0,.511h-6.6a.255.255,0,1,1,0-.511Z" transform="translate(-11.933 -24.451)" fill="none"/>
                                                            <path id="Path_172" data-name="Path 172" d="M15.167,21.14a.661.661,0,1,0,.661.661A.661.661,0,0,0,15.167,21.14Z" transform="translate(-10.578 -18.941)" fill="none"/>
                                                            <path id="Path_173" data-name="Path 173" d="M17.747,33.791h6.6a.255.255,0,0,0,0-.511h-6.6a.255.255,0,1,0,0,.511Z" transform="translate(-11.933 -24.451)" fill="#fff"/>
                                                            <path id="Path_174" data-name="Path 174" d="M17.747,37.073h6.6a.255.255,0,0,0,0-.511h-6.6a.255.255,0,0,0,0,.511Z" transform="translate(-11.933 -25.941)" fill="#fff"/>
                                                            <path id="Path_175" data-name="Path 175" d="M17.747,40.356h6.6a.255.255,0,0,0,0-.511h-6.6a.255.255,0,1,0,0,.511Z" transform="translate(-11.933 -27.431)" fill="#fff"/>
                                                            <path id="Path_176" data-name="Path 176" d="M24.463,17.113H8.386a1.072,1.072,0,0,0-1.072,1.072v8.053A1.072,1.072,0,0,0,8.386,27.31H10.61v3.967a.794.794,0,0,0,.793.793H21.446a.794.794,0,0,0,.793-.793V27.31h2.224a1.072,1.072,0,0,0,1.072-1.072V18.185A1.072,1.072,0,0,0,24.463,17.113ZM9.621,20.635a.661.661,0,1,1,.661-.661A.661.661,0,0,1,9.621,20.635ZM11.9,19.312a.661.661,0,1,1-.661.661A.661.661,0,0,1,11.9,19.312Zm9.951,5.5h-.16v6.46a.247.247,0,0,1-.247.247H11.4a.247.247,0,0,1-.247-.247v-6.46h-.22a.255.255,0,1,1,0-.511H21.853a.255.255,0,1,1,0,.511Z" transform="translate(-7.314 -17.113)" fill="#fff"/>
                                                          </g>
                                                        </g>
                                                      </svg>
                                                      
                                                    <!-- <mat-icon>print</mat-icon> -->
                                                </button>
                                            </div>
                                        </div>
                                    </div>



                                    <div class="tableWrap">
                                        <table mat-table #sortCol2="matSort" matSort [dataSource]="dataSource1"
                                            class="table-wrapper">
                                            <ng-container matColumnDef="selectedIntervention" [sticky]="true">
                                                <th mat-header-cell *matHeaderCellDef class="check-width">
                                                    <mat-checkbox
                                                        (change)="onChangeselectedInterventionAll($event,element)"
                                                        [checked]='exportAllDrugRegimen'></mat-checkbox>
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="check-width">
                                                    <mat-checkbox
                                                        (change)="onChangeselectedIntervention($event,element)"
                                                        [checked]='(exportAllDrugRegimen && isAllexported) '>
                                                    </mat-checkbox>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="drugCategory" [sticky]="true">
                                                <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                    style="width: 150px;"> Drug
                                                    Category </th>
                                                <td mat-cell *matCellDef="let element"> {{element.drugCategory}} </td>
                                            </ng-container>
                                            <ng-container matColumnDef="gpi">
                                                <th mat-sort-header mat-header-cell *matHeaderCellDef class="hide"> GPI
                                                </th>
                                                <td mat-cell *matCellDef="let element" class="hide"> {{element.gpi}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="isedited">
                                                <th mat-header-cell *matHeaderCellDef class="hide"> isedited</th>
                                                <td mat-cell *matCellDef="let element" class="hide">{{element.isedited}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="patTaking" class="hide">
                                                <th mat-header-cell *matHeaderCellDef class="hide"> patTaking</th>
                                                <td mat-cell *matCellDef="let element" class="hide">
                                                    {{element.patTakingMed}} </td>
                                            </ng-container>
                                            patTaking
                                            <ng-container matColumnDef="medication" [sticky]="true">
                                                <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                    style="width: 200px;" class="fixedstickywhite"> Drug Name</th>
                                                <td mat-cell *matCellDef="let element"><span class="fixedsticky">
                                                        {{element.medication}}</span> </td>
                                            </ng-container>
                                            <ng-container matColumnDef="claimNDC">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;"> Claims</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <strong></strong>
                                                    {{element.claimDose}} {{element.claimForm}}
                                                    {{element.claimRoute}}
                                                    {{element.claimFrequency}}
                                                    {{element.claimInstructions}}

                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="ssNDC">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;">
                                                    POS </th>

                                               <td mat-cell *matCellDef="let element" matTooltip=" {{getpickeduptooltipActive(element.ssPickedUpdt)}}" >
                                                    <strong></strong>
                                                    {{element.ssDose}} {{element.s}}
                                                    {{element.ssRoute}}
                                                    {{element.ssFrequency}}
                                                    {{element.ssInstructions}}
                                                    </td>
                                            </ng-container>
                                            <ng-container matColumnDef="dsNDC">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;"> Inpatient
                                                </th>

                                                <td mat-cell *matCellDef="let element">
                                                    <strong></strong>
                                                    {{element.dsDose}} {{element.dsForm}}
                                                    {{element.dsRoute}}
                                                    {{element.dsFrequency}}
                                                    {{element.dsInstructions}}
                                             
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="snfNDC">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;">SNF </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <strong></strong>
                                                    {{element.snfDose}} {{element.snfForm}}
                                                    {{element.snfRoute}}
                                                    {{element.snfFrequency}}
                                                    {{element.snfInstructions}}
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="ehrNDC">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;">EHR</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <strong></strong>
                                                    {{element.ehrDose}} {{element.ehrForm}}
                                                    {{element.ehrRoute}}
                                                    {{element.ehrFrequency}}
                                                    {{element.ehrInstructions}}
                                                   
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="patNDC">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;">Member
                                                    Reported</th>
                                                <td mat-cell *matCellDef="let element">
                                                    <strong></strong>
                                                    {{element.patDose}} {{element.patForm}}
                                                    {{element.patRoute}}
                                                    {{element.patFrequency}}
                                                    {{element.patInstructions}}
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="isTakingMed">

                                                <th mat-header-cell *matHeaderCellDef style="width: 100px;">Taking</th>
                                                <td mat-cell *matCellDef="let element" [disabled]="editrow!=element.medication" style="width: 100px;">
                                                    <mat-slide-toggle class="example-margin text-end"
                                                        (change)="onMedTakingChange($event,element)"
                                                        style="font-family: 'segoeUI' !important; "
                                                        [checked]='element.patTakingMed=="Yes"' [disabled]="isReconciled">
                                                    </mat-slide-toggle>
                                                </td>
                                            </ng-container>

                                            <!-- <ng-container matColumnDef="isTakingMed" class="toggle">
                                                <th mat-header-cell *matHeaderCellDef style="width: 100px;">Taking</th>
                                                <td mat-cell *matCellDef="let element" [disabled]="editrow!=element.medication" style="width: 100px;">
                                                    <mat-slide-toggle class="example-margin text-end"
                                                        (change)="onMedTakingChange($event,element)"
                                                        style="font-family: 'segoeUI' !important; "
                                                        [checked]='element.patTakingMed=="Yes"' [disabled]="isReconciled">
                                                    </mat-slide-toggle>
                                                </td>
                                            </ng-container> -->
                                            <ng-container matColumnDef="techCheck">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;" [ngClass]="{'hidden-column': !showColumn}"> Tech Check
                                                </th>
                                                <td mat-cell *matCellDef="let element"
                                                    [disabled]="editrow!=element.medication" [ngClass]="{'hidden-column': !showColumn}">
                                                    <!-- <select class="form-select" [(ngModel)]="element.techCheck"
                                                        (ngModelChange)="onChangeTechCheck($event,element)">
                                                        <option *ngFor="let caseGI of medDecisionListTech"
                                                            [value]="caseGI">
                                                            {{caseGI}}</option>
                                                    </select> -->

                                                    <div class="col-md-12 d-flex"  
                                                        (click)="editDropDown(element)" style="margin: 5px 0;">
                                                        <div class="new" title="New"
                                                            *ngIf="element.techCheck.includes('New')">
                                                            <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                                                width="21.761" height="21.485"
                                                                viewBox="0 0 21.761 21.485">
                                                                <path id="Path_142" data-name="Path 142"
                                                                    d="M26.01,24.508l-3.865-4.049V13.686a7.022,7.022,0,0,0-5.366-6.812V6.149a1.649,1.649,0,0,0-3.3,0v.725a7.022,7.022,0,0,0-5.367,6.812v6.774L4.249,24.508h8.939a2.015,2.015,0,0,0,3.884,0ZM9.216,20.9V13.686a5.914,5.914,0,1,1,11.827,0V20.9l2.392,2.506H6.824Z"
                                                                    transform="translate(-4.249 -4.5)" fill="#fff" />
                                                            </svg>
                                                        </div>
                                                        <div class="rp" title="RPh Review"
                                                            *ngIf="element.techCheck.includes('RPh Review')">
                                                            <svg style="margin: 1px 0 0 4px;" xmlns="http://www.w3.org/2000/svg" width="20.631" height="19.952" viewBox="0 0 20.631 19.952">
                                                                <g id="Layer_1" transform="translate(-4.503 -5.171)">
                                                                  <g id="Group_433" data-name="Group 433" transform="translate(4.503 5.171)">
                                                                    <path id="Path_159" data-name="Path 159" d="M19.162,24.708A.713.713,0,0,0,18.449,24H10.6a.713.713,0,0,0,0,1.425h7.854A.713.713,0,0,0,19.162,24.708Z" transform="translate(-7.033 -14.022)" fill="#fff"/>
                                                                    <path id="Path_160" data-name="Path 160" d="M21.607,33.835H17.19a.713.713,0,1,0,0,1.425h4.418a.713.713,0,1,0,0-1.425Z" transform="translate(-10.133 -18.649)" fill="#fff"/>
                                                                    <path id="Path_161" data-name="Path 161" d="M10.6,14.666h7.854a.713.713,0,1,0,0-1.425H10.6a.713.713,0,1,0,0,1.425Z" transform="translate(-7.033 -8.965)" fill="#fff"/>
                                                                    <path id="Path_162" data-name="Path 162" d="M10.6,19.863h7.854a.713.713,0,1,0,0-1.425H10.6a.713.713,0,0,0,0,1.425Z" transform="translate(-7.033 -11.409)" fill="#fff"/>
                                                                    <path id="Path_163" data-name="Path 163" d="M24.79,10.251a1.176,1.176,0,0,0-1.663,0l-3.66,3.66V5.463a.292.292,0,0,0-.292-.292H4.8a.292.292,0,0,0-.292.292V24.83a.292.292,0,0,0,.292.292H19.175a.292.292,0,0,0,.292-.292V17.236l5.323-5.323A1.176,1.176,0,0,0,24.79,10.251ZM18.042,23.405a.292.292,0,0,1-.292.292H6.221a.292.292,0,0,1-.292-.292V6.888A.292.292,0,0,1,6.221,6.6H17.75a.292.292,0,0,1,.292.292v8.447l-2.35,2.35a1.316,1.316,0,0,0-.335.569l-.3,1.061a.542.542,0,0,0,.67.67l1.061-.3a1.316,1.316,0,0,0,.569-.335l.687-.687v4.744Z" transform="translate(-4.503 -5.171)" fill="#fff"/>
                                                                  </g>
                                                                </g>
                                                              </svg>                                                              
                                                        </div>
                                                        <div class="dc" title="DC Medication" 
                                                            *ngIf="element.techCheck.includes('DC Medication')">
                                                            <svg style="margin: 0 0 0 -1px;" xmlns="http://www.w3.org/2000/svg" width="22.465" height="18.951" viewBox="0 0 22.465 18.951">
                                                                <g id="Icon_Home_Blk_RGB" transform="translate(-4.5 -7.5)">
                                                                  <g id="Layer_1_00000171703388195859765480000014728800793333499577_" transform="translate(15.733 7.5)">
                                                                    <g id="Group_440" data-name="Group 440">
                                                                      <path id="Path_181" data-name="Path 181" d="M24,7.5" transform="translate(-24 -7.5)" fill="#fff"/>
                                                                    </g>
                                                                  </g>
                                                                  <g id="Group_441" data-name="Group 441" transform="translate(4.5 7.5)">
                                                                    <path id="Path_182" data-name="Path 182" d="M25.467,25.184V16.659h1.5L15.733,7.5l-5.645,4.608V9.516H7.323v4.839L4.5,16.659H6v8.525a1.324,1.324,0,0,0-.806,1.267H26.216A1.329,1.329,0,0,0,25.467,25.184Zm-13.94-4.32H8.7V18.041h2.823Zm6.336,4.2h-4.2V20.115a2.065,2.065,0,0,1,2.074-2.074,2.2,2.2,0,0,1,2.131,2.131v4.9Zm4.9-4.2H19.938V18.041H22.76Z" transform="translate(-4.5 -7.5)" fill="#fff"/>
                                                                  </g>
                                                                </g>
                                                              </svg>                                                                                                                                                                                                                                                      
                                                        </div>
                                                        <div class="acknowledged" title="Acknowledged"
                                                            *ngIf="element.techCheck.includes('Acknowledged')">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="19.728"
                                                                height="16" viewBox="0 0 19.728 16">
                                                                <path id="Icon_Checkmark_Blk_RGB"
                                                                    d="M21.775,8.3a2.375,2.375,0,0,0-1.666.707l-8.631,8.58L8.4,14.508a2.286,2.286,0,0,0-3.18,0l-.151.2a2.286,2.286,0,0,0,0,3.18l3.079,3.079h0L11.479,24.3,23.441,12.338A2.354,2.354,0,0,0,21.775,8.3Z"
                                                                    transform="translate(-4.425 -8.3)" fill="#fff" />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div style="margin-bottom: 5px;">
                                                        <mat-select class="matselect" [disabled]="!isTechCheck"
                                                            [ngStyle]="{'background-color':!isTechCheck?'#F0F0F0':null}"
                                                            (selectionChange)="onChangeTechCheck($event,element)"
                                                            *ngIf="!element.techCheck.length>0 || element.isOpen"
                                                            [(ngModel)]="element.techCheck" multiple
                                                            matTooltip="{{element.techCheck}}"
                                                            >
                                                            <mat-option *ngFor="let caseGI of medDecisionListTech"
                                                             [value]="caseGI">{{caseGI}}</mat-option>
                                                        </mat-select>
                                                    </div>

                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="rphCheck">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;"> RPh Check
                                                </th>
                                                <td mat-cell *matCellDef="let element"
                                                    [disabled]="editrow!=element.medication">
                                                    <select class="form-select" [(ngModel)]="element.rphCheck"
                                                        (ngModelChange)="onChangeRphCheck($event,element)"
                                                        [disabled]="!isRphCheck ">
                                                        <option *ngFor="let caseGI of medDecisionListRph"
                                                            [value]="caseGI">
                                                            {{caseGI}} </option>
                                                    </select>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="reviewerComments">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;"> Tech/RPh
                                                    Comments </th>
                                                <td mat-cell *matCellDef="let element" style="padding: 10px;"
                                                    [disabled]="editrow!=element.medication">

                                                    <textarea [(ngModel)]="element.reviewerComments" matInput [disabled]="isReconciled"
                                                        style="border: solid 1px #ededed; outline: none !important;"
                                                        (change)="onChangereviewerComments($event.target.value,element)"
                                                        [maxlength]="commentsMaxChars">{{element.reviewerComments}}
                                                                                                    </textarea>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="patComments">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;"> Patient
                                                    Comments </th>
                                                <td mat-cell *matCellDef="let element" style="padding: 10px;"
                                                    [disabled]="editrow!=element.medication">

                                                    <textarea [(ngModel)]="element.patComments" matInput [disabled]="isReconciled"
                                                        style="border: solid 1px #ededed; outline: none !important;"
                                                        (change)="onChangePatComments($event.target.value,element)"
                                                        [maxlength]="commentsMaxChars">{{element.patComments}}
                                                    </textarea>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="providerComments">
                                                <th mat-header-cell *matHeaderCellDef style="width: 180px;"> Provider
                                                    Comments </th>
                                                <td mat-cell *matCellDef="let element" style="padding-right: 10px;"
                                                    [disabled]="editrow!=element.medication">
                                                    <textarea [(ngModel)]="element.providerComments" matInput [disabled]="isReconciled"
                                                        style="border: solid 1px #ededed; outline: none !important;"
                                                        name="memNoteComments"
                                                        (change)="onChangeProvComments($event.target.value,element)"
                                                        [maxlength]="commentsMaxChars">{{element.providerComments}}
                                                    </textarea>
                                                </td>
                                            </ng-container>

                                            <ng-container matColumnDef="medDecision">
                                                <th mat-header-cell *matHeaderCellDef style="width: 170px;"> Final
                                                    Review </th>
                                                <td mat-cell *matCellDef="let element"
                                                    [disabled]="editrow!=element.medication">
                                                    <select class="form-select" name="gapIdentified"
                                                        [(ngModel)]="element.medDecision"
                                                        (ngModelChange)="onChangeMedDec($event,element)"
                                                        [disabled]="!isRphCheck">
                                                        <option *ngFor="let caseGI of element.finalReviews"
                                                            [value]="caseGI">
                                                            {{caseGI}}</option>
                                                    </select>
                                                </td>
                                            </ng-container>
                                            <ng-container matColumnDef="edit">
                                                <th mat-header-cell *matHeaderCellDef>Actions</th>
                                                <td mat-cell *matCellDef="let element">
                                                  
                                                    <mat-icon matTooltip="Edit Encounter" (click)="editClicked(element,'E')"
                                                    [disabled]="editrow!=element.medication||isReconciled"
                                                    matTooltipClass="tooltiper" style="color: #b3b3b3;">edit</mat-icon>
                                                    <mat-icon color="warn" matTooltip="delete"
                                                    (click)="deleteMedRegimen(element)"
                                                    [disabled]="editrow!=element.medication||isReconciled"
                                                    matTooltipClass="tooltiper" style="color: #b3b3b3;">delete</mat-icon>
                                            
                                                    <mat-icon mat-icon-button color="dafault" matTooltip="Save"
                                                    (click)="updateMedRegimen(element,dataSource1)"
                                                    [disabled]="editrow!=element.medication||isReconciled"
                                                    matTooltipClass="tooltiper" style="color: #b3b3b3;">
                                                    save</mat-icon>
                                              
                                                  
                                                </td>
                                            </ng-container>
                                          
                                            <tr mat-header-row *matHeaderRowDef="displayedColumns1; sticky: true"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns1;  sticky: true"
                                                (click)="test(row)"></tr>
                                        </table>
                                        <div class="no-Data" *ngIf="dataSource1.data.length === 0" style="text-align: center;">No Rows To Show</div>
                                        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
                                            style="border: solid 1px #ededed; margin-bottom: 10px; "></mat-paginator> -->
                                    </div>


                                    <div class="row align-items-start mb-1 mt-1" *ngIf="encounterData!='undefined' && encounterData!=null">
                                        <div class="col-md-12">
                                            <!-- <button mat-raised-button disabled color="disabled" (click)="updateRec()"
                                            style="margin-right: 10px;">Tech Check Complete</button> -->
                                            <ng-container *ngIf="!isClosed && encounterData.status != 'Data Entry'"><button mat-raised-button color="warn" 
                                                (click)="revertStatus(encounterData.status)"
                                                style="margin-right: 10px;">Revert</button></ng-container>
                                            <ng-container *ngIf="isClosed"><button mat-raised-button disabled style="margin-right: 10px;">Revert</button></ng-container>                                                
                                            <button mat-raised-button color="primary" *ngIf=" copyService!=null && copyService!='undefined' && isTechCheck && !isTargetedEncounter && copyService.length>0"
                                                (click)="updateRec()" style="margin-right: 10px;">Tech Check
                                                Complete</button>
                                            <button mat-raised-button color="primary" *ngIf="isRphCheck && copyService.length>0"
                                                (click)="updateReview()" style="margin-right: 10px;">RPh Check
                                                Complete</button>
                                            <!-- <button mat-raised-button color="primary" *ngIf="isFinalCheck"
                                                (click)="openProviderPopUp()" style="margin-right: 10px;">Provider
                                                Report</button>
                                            <button mat-raised-button color="primary" matTooltipClass="tooltiper"
                                                *ngIf="isFinalCheck" (click)="generatePatientReport()"
                                                style="margin-right: 10px;">Patient
                                                Report</button> -->
                                            <button mat-flat-button class="mat-elevation-z0" color="primary"
                                                 mat-stroked-button
                                                (click)="createIntervention()" *ngIf=" copyService!=null && copyService!='undefined' && copyService.length > 0" >Create
                                                & Print Intervention</button>
                                                <button mat-flat-button class="mat-elevation-z0" color="primary"
                                                 mat-stroked-button
                                                (click)="createIntervention()" *ngIf=" copyService!=null && copyService!='undefined' && copyService.length == 0">Create
                                                New Intervention</button>
                                        </div>
                                    </div>
                                    <!-- <div class="col-8 text-end actionsbtn-section">
                                        <button mat-flat-button class="mat-elevation-z0" color="primary"
                                            mat-stroked-button (click)="createIntervention()">Create Intervention</button>
                                    </div> -->

                                </div>

                                <div fxLayout="row" [@enterAnimation] style="width: 100%;">

                                    <div class="contentPanel card mt-1 pb-3">

                                        <div class="col-12 breadcrumb-section">
                                            <div class="row mb-3">
                                                <div class="col-4 align-self-start">
                                                    <h3 class="mt-1">Medications</h3>
                                                </div>
                                                <div class="col-3 align-self-end" style="margin: 0 !important; padding: 5px 0 5px 0 !important;">
                                                    <div class="searchWrap"
                                                        matTooltip="Eg: Pending&diab or new&anti..."
                                                        matTooltipClass="tooltipSearch">
                                                        <mat-form-field appearance="standard"
                                                            (keyup)="globalSearchMeds()">
                                                            <mat-label>Search</mat-label>
                                                            <input matInput [(ngModel)]="globalSearchText"
                                                                placeholder="Eg: Pending&diab or new&anti...">
                                                            <mat-icon matPrefix class="searchIcon">search</mat-icon>
                                                            <button matSuffix class="resetBtn" type="reset"
                                                                color="accent" mat-icon-button (click)="resetSearch()">
                                                                <mat-icon class="closeIcon">close</mat-icon>
                                                            </button>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="col-5 align-self-end text-end">
                                                    <span style="margin: 0 25px 0 0;" class="active-thumb">Active+30 &nbsp;
                                                        <mat-slide-toggle class="active-thumb example-margin text-end" [checked]="viewAll==true"
                                                            (change)="viewALLMeds($event.checked)" color="primary" style="font-family: 'segoeUI' !important; ">All
                                                        </mat-slide-toggle>
                                                    </span>
                                                    <ng-container *ngIf="!isReconciled">
                                                    <button class="mr-1" mat-raised-button color="primary" matTooltipClass="tooltiper" (click)="exportSelected()"> Export
                                                        Selected To Regimen</button></ng-container>
                                                    <ng-container *ngIf="isReconciled">
                                                        <button class="mr-1" mat-raised-button disabled> Export Selected To Regimen</button></ng-container>    
                                                    <ng-container *ngIf="!isReconciled"><button mat-raised-button color="primary" matTooltipClass="tooltiper" 
                                                        (click)="addMedication('newmed','')" >Add Medication</button> </ng-container>                                                   
                                                    <ng-container *ngIf="isReconciled"><button mat-raised-button disabled >Add Medication</button></ng-container>

                                                </div>
                                            </div>
                                        </div>

                                        <!-- <div class="col-12 breadcrumb-section">
                                            <div class="col-lg-12 row p-2 mb-1">
                                                <div class="col-4 text-left ml-0" style="padding: 0 0 0 5px;">
                                                    <h3 class="mt-1">Medications</h3>
                                                </div>
                                                <div class="col-8 text-end actionsbtn-section">
                                                    <div fxFlex="350px" fxLayout="row" fxLayoutGap="0px"
                                                        fxLayoutAlign="start center" class="searchWrap"
                                                        matTooltip="Eg: Pending&diab or new&anti..."
                                                        matTooltipClass="tooltipSearch">

                                                        <mat-form-field appearance="standard"
                                                            (keyup)="globalSearchMeds()">
                                                            <mat-label>Search</mat-label>
                                                            <input matInput [(ngModel)]="globalSearchText"
                                                                placeholder="Eg: Pending&diab or new&anti...">
                                                            <mat-icon matPrefix class="searchIcon">search</mat-icon>
                                                            <button matSuffix class="resetBtn" type="reset"
                                                                color="accent" mat-icon-button (click)="resetSearch()">
                                                                <mat-icon class="closeIcon">close</mat-icon>
                                                            </button>
                                                        </mat-form-field>

                                                    </div>
                                                    <div style="width: 350px;" class="searchWrap" matTooltip="Eg: Pending&diab or new&anti..."
                                                        matTooltipClass="tooltipSearch">
                                                        <mat-form-field appearance="standard" (keyup)="globalSearchMeds()">
                                                            <mat-label>Search</mat-label>
                                                            <input matInput [(ngModel)]="globalSearchText" placeholder="Eg: Pending&diab or new&anti...">
                                                            <mat-icon matPrefix class="searchIcon">search</mat-icon>
                                                            <button matSuffix class="resetBtn" type="reset" color="accent" mat-icon-button (click)="resetSearch()">
                                                                <mat-icon class="closeIcon">close</mat-icon>
                                                            </button>
                                                        </mat-form-field>
                                                    </div>
                                                    <span style="margin: 0 25px 0 0;">Active+30 &nbsp;<mat-slide-toggle
                                                            class="example-margin text-end"
                                                            (change)="viewALLMeds($event.checked)" color="primary"
                                                            style="font-family: 'segoeUI' !important; ">All
                                                        </mat-slide-toggle>
                                                    </span>
                                                    <button class="mr-1" mat-raised-button color="default"
                                                        matTooltipClass="tooltiper">Export all To Regimen</button>
                                                    <button mat-raised-button color="primary"  matTooltipClass="tooltiper"
                                                        (click)="addMedication('newmed','')">Add Medication</button>
                                                </div>
                                            </div>
                                        </div> -->

                                        <!-- <div class="row">
                                            <div class="col-7 text-left"><h3 class="mt-1">Medications</h3></div>
                                            <div class="col-2 mb-1 text-end"><div class="text-end example-margin">Active
                                                <mat-slide-toggle class="example-margin text-end" (change)="viewALLMeds($event.checked)"
                                                     color="primary">All</mat-slide-toggle></div></div>
                                            <div class="col-3 mb-2 text-end">
                                                <button class="mr-1" mat-raised-button color="default" matTooltipClass="tooltiper">Export all To Regimen</button>
                                                <button mat-raised-button color="primary" matTooltipClass="tooltiper" (click)="addMedication('newmed','')">Add Medication</button>
                                            </div>
                                        </div> -->

                                        <div class="tableWrap">
                                            <table mat-table #sortCol1="matSort" [dataSource]="dataSourceHistory"
                                                class="table-wrapper" matSort>
                                                <ng-container matColumnDef="exportSelected" [sticky]="true">
                                                    <th mat-header-cell *matHeaderCellDef class="check-width">
                                                        <mat-checkbox (change)="onChangeSelectedAll($event,element)"
                                                            [checked]='isAll'></mat-checkbox>
                                                    </th>
                                                    <td mat-cell *matCellDef="let element" class="check-width">
                                                        <mat-checkbox (change)="onChangeSelected($event,element)"
                                                            [checked]='(element.isedited=="yes" || isAll)'>
                                                        </mat-checkbox>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="isedited">
                                                    <th mat-header-cell *matHeaderCellDef style="width: 150px; "
                                                        class="hide">isedited</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.isedited}}</td>
                                                </ng-container>

                                                <ng-container matColumnDef="exportToRegime" [sticky]="true">
                                                    <th mat-header-cell *matHeaderCellDef style="width: 145px;"
                                                        class="hide">Export To Regimen</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        <button mat-icon-button color="warn"
                                                            (click)="exportToDrugRegime(element,dataSourceHistory)"
                                                            matTooltip="Export to Drug regime"
                                                            matTooltipClass="tooltiper">
                                                            <mat-icon>import_export</mat-icon>
                                                        </button>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DRUGCATEGORY">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 170px;"> Drug Category</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.DRUGCATEGORY}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DRUGNAME" [sticky]="true">
                                                    <!--[sticky]="true"-->
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 250px; margin-left: 0%;" class="fixedstickywhite">
                                                        Drug Name</th>
                                                    <td mat-cell *matCellDef="let element"> <span
                                                            class="fixedsticky">{{element.DRUGNAME}}</span>
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="NDC">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">NDC</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.NDC}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="GPI">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">GPI</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.GPI}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="STRENGTH">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px;">Strength</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.STRENGTH}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="UNITOFMEASURE">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 160px; ">Unit Of Measure</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.UNITOFMEASURE}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="QUANTITY">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px;">Quantity</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.QUANTITY}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PRN">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px;" class="hide">PRN </th>
                                                    <td mat-cell *matCellDef="let element" class="hide"> {{element.PRN}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="FREQUENCY">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px;">Frequency</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.FREQUENCY}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="FORM">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">Form </th>
                                                    <td mat-cell *matCellDef="let element">{{element.FORM}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DOSE">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">Dosage </th>
                                                    <td mat-cell *matCellDef="let element">{{element.DOSE}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="ROUTE">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">Route </th>
                                                    <td mat-cell *matCellDef="let element">{{element.ROUTE}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="DAYSSUPPLY">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px; ">Days Supply </th>
                                                    <td mat-cell *matCellDef="let element"> {{element.DAYSSUPPLY}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="WRITTENDATE">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px; ">Written Date </th>
                                                    <td mat-cell *matCellDef="let element">{{element.WRITTENDATE | date: 'MM-dd-yyyy'}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="LASTFILLEDDATE">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 150px; ">Last Filled Date </th>
                                                    <td mat-cell *matCellDef="let element"
                                                        matTooltip="{{getpickeduptooltip(element)  }}">
                                                        {{element.LASTFILLEDDATE | date: 'MM-dd-yyyy'}} </td>
                                                </ng-container>
                                                <ng-container matColumnDef="INSTRUCTIONS">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">Instructions </th>
                                                    <td mat-cell *matCellDef="let element">{{element.INSTRUCTIONS}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="REFILLNBR">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px;" class="hide">Refill Number </th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.REFILLNBR}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="TOTALREFILLS">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px;" class="hide">Total Refill </th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.TOTALREFILLS}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="CLAIMSTATUS">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px;" class="hide">Claim Status </th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.CLAIMSTATUS}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PTCOMMENTS">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px;" class="hide">Patient Comment </th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.PTCOMMENTS}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="isPatientTakingMed">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; " class="hide"></th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.isPatientTakingMed}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="SRC">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 130px;">Med Source</th>
                                                    <td mat-cell *matCellDef="let element">{{element.SRC}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="ISPATIENTTAKINGMED">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 200px;" class="hide">Patient Taking Med</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.ISPATIENTTAKINGMED}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PRESCRIBERNAME">

                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;">
                                                        Prescriber Name</th>
                                                    <td mat-cell *matCellDef="let element">{{element.PRESCRIBERNAME |
                                                        uppercase}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PRESCRIBERNPI">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px;">Prescriber NPI</th>
                                                    <td mat-cell *matCellDef="let element">{{element.PRESCRIBERNPI}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="PRESCRIBERSPECIALTY">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;">Prescriber Speciality</th>
                                                    <td mat-cell *matCellDef="let element">
                                                        {{element.PRESCRIBERSPECIALTY}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PHARMACYNAME">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;">Pharmacy Name</th>
                                                    <td mat-cell *matCellDef="let element">{{element.PHARMACYNAME}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PHARMACYNPI">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 140px;">Pharmacy NPI</th>
                                                    <td mat-cell *matCellDef="let element">{{element.PHARMACYNPI}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="PHARMACYCONTACT">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;">Pharmacy Contact</th>
                                                    <td mat-cell *matCellDef="let element">{{element.PHARMACYCONTACT | phoneformat}}
                                                    </td>
                                                </ng-container>
                                                <ng-container matColumnDef="RUNOUT_DATE">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef

                                                        style="width: 180px; ">Next Fill Date</th>
                                                    <td mat-cell *matCellDef="let element">{{element.RUNOUT_DATE | date: 'MM-dd-yyyy'}}</td>

                                                </ng-container>
                                                <ng-container matColumnDef="ACTIVE_FLAG">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">Active Flag</th>
                                                    <td mat-cell *matCellDef="let element">{{element.ACTIVE_FLAG}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="SUBSRC">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 120px; ">Sub Source</th>
                                                    <td mat-cell *matCellDef="let element">{{element.SUBSRC}}</td>
                                                </ng-container>
                                                
                                                <ng-container matColumnDef="DIAGNOSISDESC">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;" class="hide">Diagnosis Description</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.DIAGNOSISDESC}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="DIAGNOSIS">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;" class="hide">Diagnosis</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.DIAGNOSIS}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="DIAGNOSISEFFECTDT">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px;" class="hide">Diagnosis Effct Dt</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.DIAGNOSISEFFECTDT | date: 'MM-dd-yyyy'}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="ISMANUAL">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px; " class="hide">ISMANUAL</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.ISMANUAL}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="CREATED_DT">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px; " class="hide">CREATED_DT</th>
                                                    <td mat-cell *matCellDef="let element" class="hide">
                                                        {{element.CREATED_DT | date: 'MM-dd-yyyy'}}</td>
                                                </ng-container>
                                                <ng-container matColumnDef="delete">
                                                    <th mat-sort-header mat-header-cell *matHeaderCellDef
                                                        style="width: 180px; ">Actions</th>
                                                    <td mat-cell *matCellDef="let element"><button mat-icon-button
                                                            color="warn" matTooltip="Delete"
                                                            (click)="openDeleteMedicationPopUp(element,dataSourceHistory)"
                                                            [disabled]="element.ISMANUAL!='Y'"
                                                            matTooltipClass="tooltiper">
                                                            <mat-icon>delete</mat-icon>
                                                        </button></td>
                                                </ng-container>

                                                <tr mat-header-row
                                                    *matHeaderRowDef="displayedColumnsHistory; sticky: true"> </tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumnsHistory;"
                                                    (click)="test(row)"></tr>
                                            </table>
                                            <div class="no-Data" *ngIf="dataSourceHistory.data.length === 0" style="text-align: center;">No Rows To Show</div>
                                            <!-- <mat-paginator #categoryPaginator [pageSizeOptions]="[5, 10, 20]"
                                                showFirstLastButtons
                                                style="border: solid 1px #ededed; margin-bottom: 10px; ">
                                            </mat-paginator> -->

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </mat-sidenav-content>
            </mat-sidenav-container>
            <div class="drugInteractionBtns">
                <button (click)="viewcomments()" [ngClass]="{ active: viewcomment==true }"
                    [ngClass]="{ notactive: viewcomment==false } " class="align-center color-white historybtn"
                    matTooltip="View Comments History" matTooltipPosition="before">
                    <mat-icon class="material-icons-outlined">comment</mat-icon>
                    <span> Encounter Log</span>
                </button>
                <button (click)="viewEncounterClick()" [ngClass]="{ active2: viewEncounter == true }"
                    [ngClass]="{ notactive: viewEncounter==false } " class="align-center color-white encounterbtn"
                    matTooltip="View Encounter Details" matTooltipPosition="before">
                    <mat-icon class="material-icons-outlined">info</mat-icon>
                    <span> Encounter Details</span>
                </button>
                <button (click)="interventionClick()" [ngClass]="{ active: viewactionplan == true }"
                    [ngClass]="{ notactive: viewactionplan==false } " class="align-center color-white actionplanbtn"
                    matTooltip="View Intervention List" matTooltipPosition="before">
                    <mat-icon class="material-icons-outlined">fact_check</mat-icon>
                    <span> Intervention List</span>
                </button>
                <button (click)="viewdrugdetails()" [ngClass]="{ active2: viewdrug == true }"
                    [ngClass]="{ notactive: viewdrug==false } " class="align-center color-white drugbtn"
                    matTooltip="View Drug Interaction" matTooltipPosition="before">
                    <mat-icon class="material-icons-outlined">vaccines</mat-icon>
                    <span> Drug Interaction</span>
                </button>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>


