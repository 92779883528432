<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mat-dialog-title" class="reportpopup">
    <h5 fxLayoutAlign="center center headingsec mb-1"><span color="primary">Print Outreach Reports</span></h5>
    <button class="mb-1" mat-icon-button color="primary" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<label style="width: 200px; margin-bottom: 10px; font-weight: bold;">Document List</label>

<mat-select (selectionChange)="onDoclIstChange($event.value)" (click)="onDoclIstChange($event.value)"
    style="width: 450px; border: solid 1px #c9c8c8; height: 40px; border-radius: 5px;">
    <mat-option [value]="data" *ngFor="let data of docList">{{data}}</mat-option>
</mat-select>
<br>

<label style="margin: 20px 0 10px 0; font-weight: bold; font-size: 16px;" *ngIf="havingCoverLatter">Response
    Requested</label>
<textarea matInput *ngIf="havingCoverLatter"
    style="border: solid 1px #ddd; outline: none !important; width: 100%; height: 50%; align-items: center;"
    (change)="onChangeResponseRequested($event.target.value)" maxlength="750">
</textarea>

<div class="col-12 text-center mt-2">
    <button mat-raised-button color="primary" class="mt-1" (click)="generateReport()">Generate Report</button>
</div>