<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between start" class="mat-dialog-title">
    <h5 fxLayoutAlign="center center headingsec mb-1"><span color="primary">Edit Drug Regimen</span></h5>
    <button class="mb-1" mat-icon-button color="primary" mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
</div>

<form [formGroup]="medform">
    <mat-tab-group animationDuration="0ms">
    <mat-tab label="EHR">

            <div class="row mt-4" *ngIf="false">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>EncounterID</mat-label>
                        <input matInput type="text" placeholder="encounterId" name="encounterId"
                            formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescription ID</mat-label>
                        <input matInput type="text" placeholder="Precription ID" name="prescriptionId"
                            formControlName="prescriptionId">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Record ID</mat-label>
                        <input matInput type="text" placeholder="Record ID" name="recordId" formControlName="recordId">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Category</mat-label>
                        <input matInput type="text" placeholder="Drug Category" name="drugCategory"
                            formControlName="drugCategory">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" style="display: none;" *ngIf="false">
                        <mat-label>Tech check</mat-label>
                        <input matInput type="text" placeholder="Tech Check" name="techCheck" formControlName="techCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" style="display: none;" *ngIf="false">
                        <mat-label>RPH check</mat-label>
                        <input matInput type="text" placeholder="RPH Check" name="rphCheck" formControlName="rphCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" style="display: none;" *ngIf="false">
                        <mat-label>Final Source </mat-label>
                        <input matInput type="text" placeholder="finalSource" name="finalSource"
                            formControlName="finalSource">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>Review By </mat-label>
                        <input matInput type="text" placeholder="Review By" name="reviewBy" formControlName="reviewBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>Review Datetime </mat-label>
                        <input matInput type="date" placeholder="Review Datetime" name="reviewDatettime" formControlName="reviewDatettime">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>Reconciled By </mat-label>
                        <input matInput type="text" placeholder="Reconciled By" name="reconcilledBy"
                            formControlName="reconcilledBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>Reconciled Datetime</mat-label>
                        <input matInput type="date" placeholder="Reconciled Datetime" name="reconcilledDatetime" formControlName="reconcilledDatetime">
                    </mat-form-field>
                </div>
                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>NDC</mat-label>
                        <input matInput type="number" placeholder="NDC" name="ehrNDC" formControlName="ehrNDC">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GPI </mat-label>
                        <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>Prescription End Date</mat-label>
                        <input matInput type="date" placeholder="Prescription End Date" name="ehrprescriptionEnddate"
                            formControlName="ehrprescriptionEnddate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Medication</mat-label>
                        <input matInput type="text" placeholder="medication" name="medication" formControlName="medication">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dosage</mat-label>
                        <input matInput type="text" placeholder="Dosage" name="ehrDose" formControlName="ehrDose">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Form</mat-label>
                        <input matInput type="text"  formControlName="ehrForm" [matAutocomplete]="auto1">                    
                        <mat-autocomplete name="form" #auto1="matAutocomplete">
                            <mat-option *ngFor="let data of formData" [value]="data ">
                                {{data }}</mat-option>
                        </mat-autocomplete>
                 
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route</mat-label>
                        <mat-select (selectionChange)="onChangeRoute($event.value,'ehr')" name="ehrRoute"  formControlName="ehrRoute">
                            <mat-option *ngFor="let formdata of routeData" [value]="formdata">
                                {{formdata}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route Desc</mat-label>
                        <input matInput type="text" placeholder="Route Value" name="ehrRouteDesc"
                            formControlName="ehrRouteDesc">
                    </mat-form-field>
                </div>                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency</mat-label>
                        <mat-select (selectionChange)="onChangeFrequency($event.value,'ehr')" name="ehrFrequency"
                            formControlName="ehrFrequency">
                            <mat-option *ngFor="let formdata of frequencyData" [value]="formdata.meaning">
                                {{formdata.abbrMeaning}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency Desc</mat-label>
                        <input matInput type="text" placeholder="Frequency Value" name="freqValehr"
                            formControlName="freqValehr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PRN</mat-label>
                        <mat-select name="ehrPRN" formControlName="ehrPRN">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <input matInput type="text" placeholder="Instructions" name="ehrInstructions"formControlName="ehrInstructions">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Patient Taking Medication</mat-label>
                        <mat-select name="patTakingMed" formControlName="patTakingMed">
                            <mat-option *ngFor="let formdata of patientTakingDropdown" [value]="formdata">
                                {{formdata}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Written Date</mat-label>
                        <input matInput type="date" placeholder="written Date" name="ehrWrittenDate"
                            formControlName="ehrWrittenDate">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Filled Date</mat-label>
                        <input matInput type="date" placeholder="Last Filled Date" name="claimlastFilledDate"
                            formControlName="ehrlastFilledDate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" placeholder="Strength" name="ehrStrength" formControlName="ehrStrength">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit of Measure</mat-label>
                        <input matInput type="text" placeholder="Unit of Measure" name="ehrUnits"
                            formControlName="ehrUnits">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="text" placeholder="Quantity" name="ehrQuantity" formControlName="ehrQuantity">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Days Supply</mat-label>
                        <input matInput type="number" placeholder="Days Supply" name="ehrDaysOfSupply"
                            formControlName="ehrDaysOfSupply">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Refill Number</mat-label>
                        <input matInput type="text" placeholder="Refill Number" name="ehrRefilNbr"
                            formControlName="ehrRefilNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Total No of Refill</mat-label>
                        <input matInput type="number" placeholder="Total No of Refill" name="ehrTotalRefill"
                            formControlName="ehrTotalRefill">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Claim Number</mat-label>
                        <input matInput type="text" placeholder="Claim Number" name="ehrRxNbr" formControlName="ehrRxNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput type="text" placeholder="Diagnosis" name="ehrDiagnosis"
                            formControlName="ehrDiagnosis">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis Description</mat-label>
                        <input matInput type="text" placeholder="Description" name="ehrDiagnosisDescription"
                            formControlName="ehrDiagnosisDescription">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <input matInput type="date" placeholder="Effective Date" name="ehrDiagnosisEffectFrom"
                            formControlName="ehrDiagnosisEffectFrom">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="ehrprescriberName"
                            formControlName="ehrprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber NPI</mat-label>
                        <input matInput type="text" placeholder="NPI" name="ehrprescriberNPI"
                            formControlName="ehrprescriberNPI">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Speciality</mat-label>
                        <input matInput type="text" placeholder="Speciality" name="ehrprescriberSpeciality"
                            formControlName="ehrprescriberSpeciality">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="ehrprescriberName"
                            formControlName="ehrprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Store</mat-label>
                        <input matInput type="text" placeholder="Store No" name="ehrPharmacyStore"
                            formControlName="ehrPharmacyStore">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Contact No</mat-label>
                        <input matInput type="text" placeholder="Contact" name="ehrPharmacyContact"
                            formControlName="ehrPharmacyContact">
                    </mat-form-field>
                </div>
            </div>

     </mat-tab>


        <mat-tab label="Claims">

            <div class="row mt-4" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" fxFlex="33.3%">
                        <mat-label>EncounterID</mat-label>
                        <input matInput type="text" placeholder="encounterId" name="encounterId"
                            formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Prescription ID</mat-label>
                        <input matInput type="text" placeholder="Precription ID" name="prescriptionId"
                            formControlName="prescriptionId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Record ID</mat-label>
                        <input matInput type="text" placeholder="Record ID" name="recordId" formControlName="recordId">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>NDC</mat-label>
                        <input matInput type="number" placeholder="NDC" name="claimNDC" formControlName="claimNDC">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GPI </mat-label>
                        <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Medication</mat-label>
                        <input matInput type="text" placeholder="medication" name="medication" formControlName="medication">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Category</mat-label>
                        <input matInput type="text" placeholder="Drug Category" name="drugCategory"
                            formControlName="drugCategory">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Tech check</mat-label>
                        <input matInput type="text" placeholder="Tech Check" name="techCheck" formControlName="techCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline"  >
                        <mat-label>RPH check</mat-label>
                        <input matInput type="text" placeholder="RPH Check" name="rphCheck" formControlName="rphCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Final Source </mat-label>
                        <input matInput type="text" placeholder="finalSource" name="finalSource"
                            formControlName="finalSource">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Review By </mat-label>
                        <input matInput type="text" placeholder="Review By" name="reviewBy" formControlName="reviewBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Review Datetime </mat-label>
                        <input matInput type="date" placeholder="Review Datetime" name="reviewDatettime"
                            formControlName="reviewDatettime">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Reconciled By </mat-label>
                        <input matInput type="text" placeholder="Reconciled By" name="reconcilledBy"
                            formControlName="reconcilledBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Reconciled Datetime</mat-label>
                        <input matInput type="date" placeholder="Reconciled Datetime" name="reconcilledDatetime"
                            formControlName="reconcilledDatetime">
                    </mat-form-field>
                </div>
                
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Prescription End Date</mat-label>
                        <input matInput type="date" placeholder="Prescription End Date" name="claimprescriptionEnddate"
                            formControlName="claimprescriptionEnddate">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <input matInput type="text" placeholder="Status" name="claimstatus" formControlName="claimstatus">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dosage</mat-label>
                        <input matInput type="text" placeholder="Dosage" name="claimDose" formControlName="claimDose">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Form</mat-label>
                        <input matInput type="text"  formControlName="claimForm" [matAutocomplete]="auto1">                    
                        <mat-autocomplete name="form" #auto1="matAutocomplete">
                            <mat-option *ngFor="let data of formData" [value]="data ">
                                {{data }}</mat-option>
                        </mat-autocomplete>
                 
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route</mat-label>
                        <mat-select  (selectionChange)="onChangeRoute($event.value,'claim')"name="claimRoute" formControlName="claimRoute">
                            <mat-option *ngFor="let formdata of routeData" [value]="formdata">
                                {{formdata}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>            
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route Desc</mat-label>
                        <input matInput type="text" placeholder="Route Value" name="claimRouteDesc"
                            formControlName="claimRouteDesc">
                    </mat-form-field>
                </div>                    
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency</mat-label>
                        <mat-select (selectionChange)="onChangeFrequency($event.value,'claim')" name="claimFrequency"
                            formControlName="claimFrequency">
                            <mat-option *ngFor="let formdata of frequencyData" [value]="formdata.meaning">
                                {{formdata.abbrMeaning}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency Desc</mat-label>
                        <input matInput type="text" placeholder="Frequency Value" name="freqValclaim"
                            formControlName="freqValclaim">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PRN</mat-label>
                        <mat-select name="claimPRN" formControlName="claimPRN">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <input matInput type="text" placeholder="Instructions" name="claimInstructions"
                            formControlName="claimInstructions">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Patient Taking Medication</mat-label>
                        <mat-select name="patTakingMed" formControlName="patTakingMed">
                            <mat-option *ngFor="let formdata of patientTakingDropdown" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Written Date</mat-label>
                        <input matInput type="date" placeholder="written Date" name="claimWrittenDate" formControlName="claimWrittenDate">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Filled Date</mat-label>
                        <input matInput type="date" placeholder="Last Filled Date" name="claimlastFilledDate" formControlName="claimlastFilledDate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row"  *ngIf="false">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Refill Number</mat-label>
                        <input matInput type="text" placeholder="Refill Number" name="claimRefilNbr"
                            formControlName="claimRefilNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Total No of Refill</mat-label>
                        <input matInput type="number" placeholder="Total No of Refill" name="claimTotalRefill"
                            formControlName="claimTotalRefill">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Claim Number</mat-label>
                        <input matInput type="text" placeholder="Claim Number" name="claimRxNbr"
                            formControlName="claimRxNbr">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" placeholder="Strength" name="claimStrength" formControlName="claimStrength">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit of Measure</mat-label>
                        <input matInput type="text" placeholder="Unit of Measure" name="claimUnits"
                            formControlName="claimUnits">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="text" placeholder="Quantity" name="claimQuantity" formControlName="claimQuantity">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Days Supply</mat-label>
                        <input matInput type="number" placeholder="Days Supply" name="claimDaysOfSupply" formControlName="claimDaysOfSupply">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput type="text" placeholder="Diagnosis" name="claimDiagnosis" formControlName="claimDiagnosis">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis Description</mat-label>
                        <input matInput type="text" placeholder="Description" name="claimDiagnosisDescription" formControlName="claimDiagnosisDescription">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <input matInput type="date" placeholder="Effective Date" name="claimDiagnosisEffectFrom" formControlName="claimDiagnosisEffectFrom">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="claimprescriberName" formControlName="claimprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber NPI</mat-label>
                        <input matInput type="text" placeholder="NPI" name="claimprescriberNPI" formControlName="claimprescriberNPI">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Speciality</mat-label>
                        <input matInput type="text" placeholder="Speciality" name="claimprescriberSpeciality" formControlName="claimprescriberSpeciality">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="claimPharmacyName" formControlName="claimPharmacyName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Store</mat-label>
                        <input matInput type="text" placeholder="Store No" name="claimPharmacyStore" formControlName="claimPharmacyStore">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Contact No</mat-label>
                        <input matInput type="text" placeholder="Contact" name="claimPharmacyContact" formControlName="claimPharmacyContact">
                    </mat-form-field>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="POS">

            <div class="row mt-4" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>EncounterID</mat-label>
                        <input matInput type="text" placeholder="encounterId" name="encounterId"
                            formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Prescription ID</mat-label>
                        <input matInput type="text" placeholder="Precription ID" name="prescriptionId"
                            formControlName="prescriptionId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Record ID</mat-label>
                        <input matInput type="text" placeholder="Record ID" name="recordId" formControlName="recordId">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Medication</mat-label>
                        <input matInput type="text" placeholder="medication" name="medication" formControlName="medication">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>category</mat-label>
                        <input matInput type="text" placeholder="Drug Category" name="drugCategory"
                            formControlName="drugCategory">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Tech check</mat-label>
                        <input matInput type="text" placeholder="Tech Check" name="techCheck" formControlName="techCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>RPH check</mat-label>
                        <input matInput type="text" placeholder="RPH Check" name="rphCheck" formControlName="rphCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Final Source </mat-label>
                        <input matInput type="text" placeholder="finalSource" name="finalSource"
                            formControlName="finalSource">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Review By </mat-label>
                        <input matInput type="text" placeholder="Review By" name="reviewBy" formControlName="reviewBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Review Datetime </mat-label>
                        <input matInput type="date" placeholder="Review Datetime" name="reviewDatettime"
                            formControlName="reviewDatettime">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Reconciled By </mat-label>
                        <input matInput type="text" placeholder="Reconciled By" name="reconcilledBy"
                            formControlName="reconcilledBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Reconciled Datetime</mat-label>
                        <input matInput type="date" placeholder="Reconciled Datetime" name="reconcilledDatetime"
                            formControlName="reconcilledDatetime">

                    </mat-form-field>
                    <mat-form-field appearance="outline" >
                        <mat-label>SS pickedupdt</mat-label>
                        <input matInput type="text" placeholder="Reconciled Datetime" name="ssPickedUpdt"
                            formControlName="ssPickedUpdt">
                    </mat-form-field>
                   
                    
                </div>
                
                <div class="col-md-2">
                    
                    <mat-form-field appearance="outline">
                        <mat-label>NDC</mat-label>
                        <input matInput type="number" placeholder="NDC" name="ssNDC" formControlName="ssNDC">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GPI </mat-label>
                        <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dosage</mat-label>
                        <input matInput type="text" placeholder="Dosage" name="ssDose" formControlName="ssDose">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Form</mat-label>
                        <input matInput type="text"  formControlName="ssForm" [matAutocomplete]="auto1">                    
                        <mat-autocomplete name="form" #auto1="matAutocomplete">
                            <mat-option *ngFor="let data of formData" [value]="data ">
                                {{data }}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route</mat-label>
                        <mat-select (selectionChange)="onChangeRoute($event.value,'ss')" name="ssRoute" formControlName="ssRoute">
                            <mat-option *ngFor="let formdata of routeData" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route Desc</mat-label>
                        <input matInput type="text" placeholder="Route Value" name="ssRouteDesc"
                            formControlName="ssRouteDesc">
                    </mat-form-field>
                </div>                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency</mat-label>
                        <mat-select (selectionChange)="onChangeFrequency($event.value,'ss')" name="ssFrequency" formControlName="ssFrequency">
                            <mat-option *ngFor="let formdata of frequencyData" [value]="formdata.meaning">{{formdata.abbrMeaning}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency Desc</mat-label>
                        <input matInput type="text" placeholder="Frequency Value" name="freqValss"
                            formControlName="freqValss">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PRN</mat-label>
                        <mat-select name="patPRN" formControlName="patPRN">
                            <mat-option value="Yes"> Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <input matInput type="text" placeholder="Instructions" name="ssInstructions"
                            formControlName="ssInstructions">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Patient Taking Medication</mat-label>
                        <mat-select name="patTakingMed" formControlName="patTakingMed">
                            <mat-option *ngFor="let formdata of patientTakingDropdown" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Written Date</mat-label>
                        <input matInput type="date" placeholder="written Date" name="ssWrittenDate"formControlName="ssWrittenDate">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Filled Date</mat-label>
                        <input matInput type="date" placeholder="Last Filled Date" name="sslastFilledDate" formControlName="sslastFilledDate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" placeholder="Strength" name="ssStrength" formControlName="ssStrength">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit of Measure</mat-label>
                        <input matInput type="text" placeholder="Unit of Measure" name="ssUnits" formControlName="ssUnits">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="text" placeholder="Quantity" name="ssQuantity" formControlName="ssQuantity">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Days Supply</mat-label>
                        <input matInput type="number" placeholder="Days Supply" name="ssDaysOfSupply" formControlName="ssDaysOfSupply">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Claim Number</mat-label>
                        <input matInput type="text" placeholder="Claim Number" name="ssRxNbr" formControlName="ssRxNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Notification Id</mat-label>
                        <input matInput type="text" placeholder="Notification Id" name="ssMessageId" formControlName="ssMessageId">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescription End Date</mat-label>
                        <input matInput type="date" placeholder="Prescription End Date" name="ssprescriptionEnddate" formControlName="ssprescriptionEnddate">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Refill Number</mat-label>
                        <input matInput type="text" placeholder="Refill Number" name="ssRefilNbr" formControlName="ssRefilNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Total No of Refill</mat-label>
                        <input matInput type="number" placeholder="Total No of Refill" name="ssTotalRefill" formControlName="ssTotalRefill">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput type="text" placeholder="Diagnosis" name="ssDiagnosis" formControlName="ssDiagnosis">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis Description</mat-label>
                        <input matInput type="text" placeholder="Description" name="ssDiagnosisDescription" formControlName="ssDiagnosisDescription">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <input matInput type="date" placeholder="Effective Date" name="ssDiagnosisEffectFrom" formControlName="ssDiagnosisEffectFrom">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="ssprescriberName" formControlName="ssprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber NPI</mat-label>
                        <input matInput type="text" placeholder="NPI" name="ssprescriberNPI" formControlName="ssprescriberNPI">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Speciality</mat-label>
                        <input matInput type="text" placeholder="Speciality" name="ssprescriberSpeciality" formControlName="ssprescriberSpeciality">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="ssprescriberName" formControlName="ssprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Store</mat-label>
                        <input matInput type="text" placeholder="Store No" name="ssPharmacyStore" formControlName="ssPharmacyStore">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Contact No</mat-label>
                        <input matInput type="text" placeholder="Contact" name="ssPharmacyContact" formControlName="ssPharmacyContact">
                    </mat-form-field>
                </div>
            </div>

        </mat-tab>

        <mat-tab label="Inpatient">

            <div class="row mt-4" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>EncounterID</mat-label>
                        <input matInput type="text" placeholder="encounterId" name="encounterId" formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescription ID</mat-label>
                        <input matInput type="text" placeholder="Precription ID" name="prescriptionId" formControlName="prescriptionId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Record ID</mat-label>
                        <input matInput type="text" placeholder="Record ID" name="recordId" formControlName="recordId">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Medication</mat-label>
                        <input matInput type="text" placeholder="Medication" name="medication" formControlName="medication">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Copy from source</mat-label>
                        <mat-select (selectionChange)="onChangeSource($event.value,'Inpatient')" name="source">
                            <mat-option *ngFor="let list of sourceListInpatient" [value]="list">{{list}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Category</mat-label>
                        <input matInput type="text" placeholder="Drug Category" name="drugCategory" formControlName="drugCategory">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Tech check</mat-label>
                        <input matInput type="text" placeholder="Tech Check" name="techCheck" formControlName="techCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>RPH check</mat-label>
                        <input matInput type="text" placeholder="RPH Check" name="rphCheck" formControlName="rphCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Final Source </mat-label>
                        <input matInput type="text" placeholder="finalSource" name="finalSource" formControlName="finalSource">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Review By </mat-label>
                        <input matInput type="text" placeholder="Review By" name="reviewBy" formControlName="reviewBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false"> 
                    <mat-form-field appearance="outline"  >
                        <mat-label>Review Datetime </mat-label>
                        <input matInput type="date" placeholder="Review Datetime" name="reviewDatettime" formControlName="reviewDatettime">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false"> 
                    <mat-form-field appearance="outline" >
                        <mat-label>Reconciled By </mat-label>
                        <input matInput type="text" placeholder="Reconcilled By" name="reconcilledBy" formControlName="reconcilledBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false"> 
                    <mat-form-field appearance="outline" >
                        <mat-label>Reconciled Datetime</mat-label>
                        <input matInput type="date" placeholder="Reconciled Datetime" name="reconcilledDatetime" formControlName="reconcilledDatetime">
                    </mat-form-field>
                </div>
                
                <div class="col-md-2"> 
                    <mat-form-field appearance="outline">
                        <mat-label>NDC</mat-label>
                        <input matInput type="number" placeholder="NDC" name="dsNDC" formControlName="dsNDC">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GPI </mat-label>
                        <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dosage</mat-label>
                        <input matInput type="text" placeholder="Dosage" name="dsDose" formControlName="dsDose">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Form</mat-label>
                        <input matInput type="text"  formControlName="dsForm" [matAutocomplete]="auto1">                    
                        <mat-autocomplete name="form" #auto1="matAutocomplete">
                            <mat-option *ngFor="let data of formData" [value]="data ">
                                {{data }}</mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route</mat-label>
                        <mat-select (selectionChange)="onChangeRoute($event.value,'ds')" name="dsRoute" formControlName="dsRoute">
                            <mat-option *ngFor="let formdata of routeData" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>               
                <div class="col-md-2" >
                    <mat-form-field appearance="outline">
                        <mat-label>Route Desc</mat-label>
                        <input matInput type="text" placeholder="Route Value" name="dsRouteDesc"
                            formControlName="dsRouteDesc">
                    </mat-form-field>
                </div>                 
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency</mat-label>
                        <mat-select (selectionChange)="onChangeFrequency($event.value,'ds')" name="dsFrequency" formControlName="dsFrequency">
                            <mat-option *ngFor="let formdata of frequencyData" [value]="formdata.meaning">{{formdata.abbrMeaning}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency Desc</mat-label>
                        <input matInput type="text" placeholder="Frequency Value" name="freqValds" formControlName="freqValds">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PRN</mat-label>
                        <mat-select name="dsPRN" formControlName="dsPRN">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <input matInput type="text" placeholder="Instructions" name="dsInstructions" formControlName="dsInstructions">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Patient Taking Medication</mat-label>
                        <mat-select name="patTakingMed" formControlName="patTakingMed">
                            <mat-option *ngFor="let formdata of patientTakingDropdown" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Written Date</mat-label>
                            <input matInput type="date" placeholder="written Date" name="dsWrittenDate" formControlName="dsWrittenDate">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Filled Date</mat-label>
                        <input matInput type="date" placeholder="Last Filled Date" name="dslastFilledDate" formControlName="dslastFilledDate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" placeholder="Strength" name="dsStrength" formControlName="dsStrength">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit of Measure</mat-label>
                        <input matInput type="text" placeholder="Unit of Measure" name="dsUnits" formControlName="dsUnits">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="text" placeholder="Quantity" name="dsQuantity" formControlName="dsQuantity">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Days Supply</mat-label>
                        <input matInput type="number" placeholder="Days Supply" name="dsDaysOfSupply" formControlName="dsDaysOfSupply">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Prescription End Date</mat-label>
                        <input matInput type="date" placeholder="Prescription End Date" name="dsprescriptionEnddate" formControlName="dsprescriptionEnddate">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Refill Number</mat-label>
                        <input matInput type="text" placeholder="Refill Number" name="dsRefilNbr" formControlName="dsRefilNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Total No of Refill</mat-label>
                        <input matInput type="number" placeholder="Total No of Refill" name="dsTotalRefill" formControlName="dsTotalRefill">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Claim Number</mat-label>
                        <input matInput type="text" placeholder="Claim Number" name="dsRxNbr" formControlName="dsRxNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput type="text" placeholder="Diagnosis" name="dsDiagnosis" formControlName="dsDiagnosis">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis Description</mat-label>
                        <input matInput type="text" placeholder="Description" name="dsDiagnosisDescription" formControlName="dsDiagnosisDescription">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <input matInput type="date" placeholder="Effective Date" name="dsDiagnosisEffectFrom" formControlName="dsDiagnosisEffectFrom">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="dsprescriberName" formControlName="dsprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber NPI</mat-label>
                        <input matInput type="text" placeholder="NPI" name="dsprescriberNPI" formControlName="dsprescriberNPI">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Speciality</mat-label>
                        <input matInput type="text" placeholder="Speciality" name="dsprescriberSpeciality" formControlName="dsprescriberSpeciality">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="dsprescriberName" formControlName="dsprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Store</mat-label>
                        <input matInput type="text" placeholder="Store No" name="dsPharmacyStore" formControlName="dsPharmacyStore">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Contact No</mat-label>
                        <input matInput type="text" placeholder="Contact" name="dsPharmacyContact" formControlName="dsPharmacyContact">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="SNF">

            <div class="row mt-4" *ngIf="false">
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>EncounterID</mat-label>
                        <input matInput type="text" placeholder="encounterId" name="encounterId"
                            formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Prescription ID</mat-label>
                        <input matInput type="text" placeholder="Precription ID" name="prescriptionId"
                            formControlName="prescriptionId">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Record ID</mat-label>
                        <input matInput type="text" placeholder="Record ID" name="recordId" formControlName="recordId">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Medication</mat-label>
                        <input matInput type="text" placeholder="medication" name="medication" formControlName="medication">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Copy from source</mat-label>
                        <mat-select (selectionChange)="onChangeSource($event.value,'SNF')" name="source">
                            <mat-option *ngFor="let list of sourceListSnf" [value]="list">{{list}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>category</mat-label>
                        <input matInput type="text" placeholder="Drug Category" name="drugCategory" formControlName="drugCategory">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Tech check</mat-label>
                        <input matInput type="text" placeholder="Tech Check" name="techCheck" formControlName="techCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>RPH check</mat-label>
                        <input matInput type="text" placeholder="RPH Check" name="rphCheck" formControlName="rphCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Final Source </mat-label>
                        <input matInput type="text" placeholder="finalSource" name="finalSource" formControlName="finalSource">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Review By </mat-label>
                        <input matInput type="text" placeholder="Review By" name="reviewBy" formControlName="reviewBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Review Datetime</mat-label>
                        <input matInput type="date" placeholder="Review Datetime" name="reviewDatettime" formControlName="reviewDatettime">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Reconciled By </mat-label>
                        <input matInput type="text" placeholder="Reconciled By" name="reconcilledBy" formControlName="reconcilledBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Reconciled Datetime</mat-label>
                        <input matInput type="date" placeholder="Reconciled Datetime" name="reconcilledDatetime" formControlName="reconcilledDatetime">
                    </mat-form-field>
                </div>
               
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>NDC</mat-label>
                        <input matInput type="number" placeholder="NDC" name="snfNDC" formControlName="snfNDC">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GPI </mat-label>
                        <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dosage</mat-label>
                        <input matInput type="text" placeholder="Dosage" name="snfDose" formControlName="snfDose">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Form</mat-label>
                        <input matInput type="text"  formControlName="snfForm" [matAutocomplete]="auto1">                    
                        <mat-autocomplete name="form" #auto1="matAutocomplete">
                            <mat-option *ngFor="let data of formData" [value]="data ">
                                {{data }}</mat-option>
                        </mat-autocomplete>
                        <!-- <input *ngIf = "enableForm['snf']" matInput type="text" placeholder="Form Value" name="snfForm" formControlName="snfForm">
                        <mat-select *ngIf = "!enableForm['snf']" (selectionChange) = "onChangeForm($event.value,'snf','N')" name="snfForm" formControlName="snfForm">                        
                            <mat-option *ngFor="let formdata of formData" [value]="formdata">
                                {{formdata}}
                            </mat-option>
                        </mat-select> -->
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route</mat-label>
                        <mat-select (selectionChange)="onChangeRoute($event.value,'snf')" name="snfRoute" formControlName="snfRoute">
                            <mat-option *ngFor="let formdata of routeData" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route Desc</mat-label>
                        <input matInput type="text" placeholder="Route Value" name="snfRouteDesc"
                            formControlName="snfRouteDesc">
                    </mat-form-field>
                </div>                
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency</mat-label>
                        <mat-select (selectionChange)="onChangeFrequency($event.value,'snf')" name="snfFrequency" formControlName="snfFrequency">
                            <mat-option *ngFor="let formdata of frequencyData" [value]="formdata.meaning">{{formdata.abbrMeaning}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency Desc</mat-label>
                        <input matInput type="text" placeholder="Frequency Value" name="freqValsnf" formControlName="freqValsnf">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PRN</mat-label>
                        <mat-select name="snfPRN" formControlName="snfPRN">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <input matInput type="text" placeholder="Instructions" name="snfInstructions" formControlName="snfInstructions">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Patient Taking Medication</mat-label>
                        <mat-select name="patTakingMed" formControlName="patTakingMed">
                            <mat-option *ngFor="let formdata of patientTakingDropdown" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Written Date</mat-label>
                        <input matInput type="date" placeholder="written Date" name="snfWrittenDate" formControlName="snfWrittenDate">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Filled Date</mat-label>
                        <input matInput type="date" placeholder="Last Filled Date" name="snflastFilledDate" formControlName="snflastFilledDate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" placeholder="Strength" name="snfStrength" formControlName="snfStrength">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit of Measure</mat-label>
                        <input matInput type="text" placeholder="Unit of Measure" name="snfUnits" formControlName="snfUnits">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="text" placeholder="Quantity" name="snfQuantity" formControlName="snfQuantity">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Days Supply</mat-label>
                        <input matInput type="number" placeholder="Days Supply" name="snfDaysOfSupply" formControlName="snfDaysOfSupply">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2">
                    <mat-form-field appearance="outline" fxFlex="33.3%" *ngIf="false">
                        <mat-label>Claim Number</mat-label>
                        <input matInput type="text" placeholder="Claim Number" name="snfRxNbr" formControlName="snfRxNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" fxFlex="33.3%" *ngIf="false">
                        <mat-label>Prescription End Date</mat-label>
                        <input matInput type="date" placeholder="Prescription End Date" name="snfprescriptionEnddate" formControlName="snfprescriptionEnddate">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Refill Number</mat-label>
                        <input matInput type="text" placeholder="Refill Number" name="snfRefilNbr" formControlName="snfRefilNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Total No of Refill</mat-label>
                        <input matInput type="number" placeholder="Total No of Refill" name="snfTotalRefill" formControlName="snfTotalRefill">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput type="text" placeholder="Diagnosis" name="snfDiagnosis" formControlName="snfDiagnosis">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis Description</mat-label>
                        <input matInput type="text" placeholder="Description" name="snfDiagnosisDescription" formControlName="snfDiagnosisDescription">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <input matInput type="date" placeholder="Effective Date" name="snfDiagnosisEffectFrom" formControlName="snfDiagnosisEffectFrom">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="snfprescriberName" formControlName="snfprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber NPI</mat-label>
                        <input matInput type="text" placeholder="NPI" name="snfprescriberNPI" formControlName="snfprescriberNPI">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Speciality</mat-label>
                        <input matInput type="text" placeholder="Speciality" name="snfprescriberSpeciality" formControlName="snfprescriberSpeciality">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="snfprescriberName" formControlName="snfprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Store</mat-label>
                        <input matInput type="text" placeholder="Store No" name="snfPharmacyStore" formControlName="snfPharmacyStore">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Contact No</mat-label>
                        <input matInput type="text" placeholder="Contact" name="snfPharmacyContact" formControlName="snfPharmacyContact">
                    </mat-form-field>
                </div>
            </div>
        </mat-tab>

        <mat-tab label="Member Reported">

            <div class="row ">
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>EncounterID</mat-label>
                        <input matInput type="text" placeholder="encounterId" name="encounterId" formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Prescription ID</mat-label>
                        <input matInput type="text" placeholder="Precription ID" name="prescriptionId" formControlName="prescriptionId">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Record ID</mat-label>
                        <input matInput type="text" placeholder="Record ID" name="recordId" formControlName="recordId">
                    </mat-form-field>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Medication</mat-label>
                        <input matInput type="text" placeholder="medication" name="medication" formControlName="medication">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Copy from source</mat-label>
                        <mat-select (selectionChange)="onChangeSource($event.value,'Member Reported')" name="source">
                            <mat-option *ngFor="let list of sourceListMemberReported" [value]="list">{{list}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>category</mat-label>
                        <input matInput type="text" placeholder="Drug Category" name="drugCategory" formControlName="drugCategory">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Tech check</mat-label>
                        <input matInput type="text" placeholder="Tech Check" name="techCheck" formControlName="techCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>RPH check</mat-label>
                        <input matInput type="text" placeholder="RPH Check" name="rphCheck" formControlName="rphCheck">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Final Source </mat-label>
                        <input matInput type="text" placeholder="finalSource" name="finalSource" formControlName="finalSource">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline" >
                        <mat-label>Review By </mat-label>
                        <input matInput type="text" placeholder="Review By" name="reviewBy" formControlName="reviewBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Review Datetime </mat-label>
                        <input matInput type="date" placeholder="Review Datetime" name="reviewDatettime" formControlName="reviewDatettime">
                    </mat-form-field>
                </div>
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline"  >
                        <mat-label>Reconciled By </mat-label>
                        <input matInput type="text" placeholder="Reconciled By" name="reconcilledBy"
                            formControlName="reconcilledBy">
                    </mat-form-field>
                </div>
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Reconciled Datetime</mat-label>
                        <input matInput type="date" placeholder="Reconciled Datetime" name="reconcilledDatetime" formControlName="reconcilledDatetime">
                    </mat-form-field>
                </div>
               
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>NDC</mat-label>
                        <input matInput type="number" placeholder="NDC" name="patNDC" formControlName="patNDC">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>GPI </mat-label>
                        <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Dosage</mat-label>
                        <input matInput type="text" placeholder="Dosage" name="patDose" formControlName="patDose">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Form</mat-label>
                        <input matInput type="text"  formControlName="patForm" [matAutocomplete]="auto1">                    
                        <mat-autocomplete name="form" #auto1="matAutocomplete">
                            <mat-option *ngFor="let data of formData" [value]="data ">
                                {{data }}</mat-option>
                        </mat-autocomplete>
                        <!-- <input *ngIf = "enableForm['pat']" matInput type="text" placeholder="Form Value" name="patForm" formControlName="patForm">
                        <mat-select *ngIf = "!enableForm['pat']" (selectionChange) = "onChangeForm($event.value,'pat','N')" name="patForm" formControlName="patForm">
                            <mat-option *ngFor="let formdata of formData" [value]="formdata">
                                {{formdata}}
                            </mat-option>
                        </mat-select> -->
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route</mat-label>
                        <mat-select (selectionChange)="onChangeRoute($event.value,'pat')" name="patRoute" formControlName="patRoute">
                            <mat-option *ngFor="let formdata of routeData" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>               
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Route Desc</mat-label>
                        <input matInput type="text" placeholder="Route Value" name="patRouteDesc"
                            formControlName="patRouteDesc">
                    </mat-form-field>
                </div>                 
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency</mat-label>
                        <mat-select (selectionChange)="onChangeFrequency($event.value,'pat')" name="patFrequency"
                            formControlName="patFrequency">
                            <mat-option *ngFor="let formdata of frequencyData" [value]="formdata.meaning">{{formdata.abbrMeaning}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Frequency Desc</mat-label>
                        <input matInput type="text" placeholder="Frequency Value" name="freqValPat" formControlName="freqValPat">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>PRN</mat-label>
                        <mat-select name="patPRN" formControlName="patPRN">
                            <mat-option value="Yes">Yes</mat-option>
                            <mat-option value="No">No</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>Instructions</mat-label>
                        <input matInput type="text" placeholder="Instructions" name="patInstructions" formControlName="patInstructions">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Patient Taking Medication</mat-label>
                        <mat-select name="patTakingMed" formControlName="patTakingMed">
                            <mat-option *ngFor="let formdata of patientTakingDropdown" [value]="formdata">{{formdata}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Written Date</mat-label>
                        <input matInput type="date" placeholder="written Date" name="patWrittenDate" formControlName="patWrittenDate">
                    </mat-form-field>
                </div>
                <div class="col-md-2 dobinput">
                    <mat-form-field appearance="outline">
                        <mat-label>Last Filled Date</mat-label>
                        <input matInput type="date" placeholder="Last Filled Date" name="patlastFilledDate" formControlName="patlastFilledDate">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Strength</mat-label>
                        <input matInput type="text" placeholder="Strength" name="patStrength" formControlName="patStrength">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Unit of Measure</mat-label>
                        <input matInput type="text" placeholder="Unit of Measure" name="patUnits" formControlName="patUnits">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Quantity</mat-label>
                        <input matInput type="text" placeholder="Quantity" name="patQuantity" formControlName="patQuantity">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Days Supply</mat-label>
                        <input matInput type="number" placeholder="Days Supply" name="patDaysOfSupply" formControlName="patDaysOfSupply">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="false">
                <div class="col-md-2"  *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescription End Date</mat-label>
                        <input matInput type="date" placeholder="Prescription End Date" name="patprescriptionEnddate" formControlName="patprescriptionEnddate">
                    </mat-form-field>
                </div>
                <div class="col-md-2" >
                    <mat-form-field appearance="outline" >
                        <mat-label>Comments</mat-label>
                        <input matInput type="text" placeholder="Comments" name="patComments" formControlName="patComments">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>Provider Comments</mat-label>
                        <input matInput type="text" placeholder="provider Comments" name="providerComments"
                            formControlName="providerComments">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline" *ngIf="false">
                        <mat-label>reviewerComments </mat-label>
                        <input matInput type="text" placeholder="reviewer Comments" name="reviewerComments"
                            formControlName="reviewerComments">
                    </mat-form-field>
                </div>
                
                <div class="col-md-2" *ngIf="false">
                    <mat-form-field appearance="outline">
                        <mat-label>Claim Number</mat-label>
                        <input matInput type="text" placeholder="Claim Number" name="patRxNbr" formControlName="patRxNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Refill Number</mat-label>
                        <input matInput type="text" placeholder="Refill Number" name="patRefilNbr" formControlName="patRefilNbr">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Total No of Refill</mat-label>
                        <input matInput type="number" placeholder="Total No of Refill" name="patTotalRefill" formControlName="patTotalRefill">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis</mat-label>
                        <input matInput type="text" placeholder="Diagnosis" name="patDiagnosis"formControlName="patDiagnosis">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Diagnosis Description</mat-label>
                        <input matInput type="text" placeholder="Description" name="patDiagnosisDescription"
                            formControlName="patDiagnosisDescription">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Effective Date</mat-label>
                        <input matInput type="date" placeholder="Effective Date" name="patDiagnosisEffectFrom" formControlName="patDiagnosisEffectFrom">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="patprescriberName"
                            formControlName="patprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber NPI</mat-label>
                        <input matInput type="text" placeholder="NPI" name="patprescriberNPI" formControlName="patprescriberNPI">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Prescriber Speciality</mat-label>
                        <input matInput type="text" placeholder="Speciality" name="patprescriberSpeciality" formControlName="patprescriberSpeciality">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Name</mat-label>
                        <input matInput type="text" placeholder="Name" name="patprescriberName"
                            formControlName="patprescriberName">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Store</mat-label>
                        <input matInput type="text" placeholder="Store No" name="patPharmacyStore" formControlName="patPharmacyStore">
                    </mat-form-field>
                </div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Pharmacy Contact No</mat-label>
                        <input matInput type="text" placeholder="Contact" name="patPharmacyContact" formControlName="patPharmacyContact">
                    </mat-form-field>
                </div>
            </div>

        </mat-tab>

    </mat-tab-group>

    <div class="row mt-2 windowbtns">
        <div class="col-md-12 align-items-start">
            <button color="default" type="submit" mat-stroked-button mat-dialog-close style="margin-right: 10px;">Close</button>
            <button color="primary" type="submit" mat-raised-button (click)="saveDrugRegime()" *ngIf="isEditPatient==false" style="margin-right: 10px;">Save</button>
            <button color="primary" type="submit" mat-raised-button (click)="saveDrugRegime()" *ngIf="isEditPatient==true" style="margin-right: 10px;">Save</button>
            <button color="primary" type="submit" mat-raised-button (click)="saveDrugRegime()" *ngIf="isEditPatient==true" style="margin-right: 10px;">Save & Next</button>
            <button color="danger" type="submit" mat-raised-button mat-dialog-close *ngIf="isEditPatient==true" (click)="deletePatient()" class="danger">Delete</button>
        </div>
    </div>
</form>